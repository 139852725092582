import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { resetState } from '~/helpers/store'
import { BookingCondition } from '~/types/models'
import baseState, { bookingCondition } from './state'
import { BookingConditionsMutationTypes } from './types'

export const mutations = mutationTree(baseState, {
  ...createMutations<BookingCondition>(bookingCondition),
  [BookingConditionsMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },

})

export default mutations
