import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.entityType === _vm.CalendarEntitiesEnum.UNAVAILABILITIES)?_c('ValidationProvider',{attrs:{"name":"offer_services","rules":"min_length:1","tag":"div","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('FilteredServiceUnitsSelectCheckboxes',{attrs:{"data-cy":"serviceUnitsSelectChekboxesDeleteMany","services":_vm.services,"compact":true,"label":_vm.$t('rental.the_unavailability_applies_to').toString(),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors ? _vm.$t('form.fields.service_select') : ''},on:{"input":validate},model:{value:(_vm.getSelectedUnits),callback:function ($$v) {_vm.getSelectedUnits=$$v},expression:"getSelectedUnits"}})]}}],null,true)}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-full has-text-centered"},[_c('BButton',{staticClass:"is-primary is-uppercase has-text-weight-bold",attrs:{"type":"is-danger","data-cy":"submitButtonDeleteManyForm","size":"is-small","loading":_vm.ui.loading,"disabled":invalid || _vm.isSubmitDisabled},on:{"click":_vm.onSubmit}},[_vm._v("\n          "+_vm._s(_vm.$t('actions.delete_permanently'))+"\n        ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }