import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"div","vid":"aezaeze"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{staticClass:"has-margin-bottom-20",attrs:{"label":((_vm.$t('form.password')) + " *"),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{attrs:{"size":"is-medium","type":"password","password-reveal":""},model:{value:(_vm.state.password),callback:function ($$v) {_vm.$set(_vm.state, "password", $$v)},expression:"state.password"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"password_confirmation","rules":"required|min:8","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{staticClass:"has-margin-bottom-20",attrs:{"label":((_vm.$t('form.password_confirmation')) + " *"),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{attrs:{"size":"is-medium","type":"password","password-reveal":""},model:{value:(_vm.state.password_confirmation),callback:function ($$v) {_vm.$set(_vm.state, "password_confirmation", $$v)},expression:"state.password_confirmation"}})],1)]}}],null,true)}),_vm._v(" "),_c('BField',{staticClass:"has-text-centered"},[_c('BaseButton',{attrs:{"type":"is-primary","aria-disabled":invalid,"disabled":invalid},on:{"click":_vm.submitForm}},[_vm._v("\n      "+_vm._s(_vm.$t('form.reset_password'))+"\n    ")])],1),_vm._v(" "),_c('div',{staticClass:"has-text-centered"},[_c('BaseLink',{attrs:{"tag":"nuxt-link","to":_vm.localePath('auth-login')}},[_vm._v("\n      "+_vm._s(_vm.$t('back.login'))+"\n    ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }