





























import { defineComponent } from '@nuxtjs/composition-api'
import ProviderPresenceStatusBadge from '~/components/badges/ProviderPresenceStatusBadge.vue'

export default defineComponent({
  name: 'ImpersonatingLinks',
  components: {
    ProviderPresenceStatusBadge,
  },
  setup() {
    return {}
  },
})
