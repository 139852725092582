import { Middleware } from '@nuxt/types'

const optionMiddleware: Middleware = async({ app: { $accessor }, params }) => {
  $accessor.TOGGLE_APP_IS_LOADING()
  try {
    const optionId = parseInt(params.id)
    if (!$accessor.options.getAllIds().includes(optionId)) {
      await $accessor.options.fetchOne(optionId)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  $accessor.TOGGLE_APP_IS_LOADING()
}

export default optionMiddleware
