import { ModerationStatus } from './Moderation'
import { MarketplaceSpecificContent, Translation } from '~/types/Locale'
import { DateType } from '../DateType'
import { Period } from '~/types/models/Period'

export enum DomainStatusEnum {
  ACTIVE = 'ACTIVE',
  MAINTENANCE = 'MAINTENANCE',
  DISABLED = 'DISABLED',
}

export enum OnlineBookingStatusEnum {
  DISABLED = 'DISABLED',
  FORM = 'FORM',
  ENABLED = 'ENABLED'
}

export type DomainTimeFields = Pick<
  Domain,
  'checkinTimeStart' |
  'checkinTimeEnd' |
  'checkoutTimeStart' |
  'checkoutTimeEnd' |
  'flexibleCheckinTimeStart' |
  'flexibleCheckinTimeEnd' |
  'flexibleCheckoutTimeStart' |
  'flexibleCheckoutTimeEnd'
>

export interface DomainModeratableFields {
  commercialName?: Translation
  title?: Translation
  description?: Translation
  access?: Translation
  around?: Translation
  practicalInfo?: Translation
  openingDetails?: Translation
  checkinTimeDetails?: Translation
  visitDiscoveriesTitle?: Translation
  visitDiscoveriesContent?: Translation
  customRules?: Translation
  seoTitle?: Translation
  seoMetaDesc?: Translation
  seoH1?: Translation
  ownersPresentation?: Translation
  ownersWord?: Translation
  managerWord?: Translation
  status?: DomainStatusEnum
  onlineBookingStatus?: OnlineBookingStatusEnum
}

export interface DomainMetadata {
  cancelPolicy: 'FLEXIBLE' | 'MODERATE' | 'STRICT' | 'NONE' | null
  isPartyAccepted: boolean
  claimAddress: number | null
  claimTimeLimitInDays: number | null
  ecoCharter: boolean
}

export interface Domain {
  $isDirty: boolean
  abcdUrl?: Translation
  access: Translation
  address: number
  around: Translation
  bookingEmail: string
  bookingPhone: string
  cancelScore: number
  checkinTimeDetails: Translation
  checkinTimeEnd: DateType
  checkinTimeStart: DateType
  checkoutTimeEnd: DateType
  checkoutTimeStart: DateType
  commercialName: Translation
  customRules: Translation
  description: MarketplaceSpecificContent
  domainAmenitiesEntries: number[]
  domainActivityEntries: number[]
  domainEquipmentEntries: number[]
  domainHealthSafetyMeasures: number[]
  email: string
  externalId: DomainExternalId | null
  files: number[]
  flexibleCheckinTimeEnd: DateType
  flexibleCheckinTimeStart: DateType
  flexibleCheckoutTimeEnd: DateType
  flexibleCheckoutTimeStart: DateType
  id: number
  isActive: boolean
  isExternal: boolean
  isFlexibleCheckinout: boolean
  isIcalDescriptionEnabled: boolean
  isNoIndex: boolean
  isPublicTransportAccessible: boolean
  isSmsNotificationEnabled: boolean
  lastApprovedFields: DomainModeratableFields
  managerWord: Translation
  marketplaceConfigurations: number[]
  maxAgeChild: number | null,
  maxAgeInfant: number | null,
  maxHoursBeforeBooking: number
  metadata: DomainMetadata
  minHoursBeforeBooking: number
  moderatedAt: DateType
  moderationStatus: ModerationStatus
  name: string
  onlineBookingStatus: OnlineBookingStatusEnum
  openingDetails: Translation
  openingPeriods: Period[]
  ownersPresentation: Translation
  ownersWord: MarketplaceSpecificContent
  pendingModeration: DomainModeratableFields
  practicalInfo: Translation
  provider: number
  seoH1: Translation
  seoMetaDesc: Translation
  seoTitle: Translation
  services: number[]
  smsPhone: string
  status: DomainStatusEnum
  title: Translation
  visitsDiscoveriesContent: Translation,
  visitsDiscoveriesTitle: Translation,
}

export interface DomainCreateForm extends Omit<Domain,
  'address' |
  '$isDirty' |
  'bookingPhone' |
  'domainActivityEntries' |
  'domainEquipmentEntries' |
  'domainHealthSafetyMeasures' |
  'email' |
  'files' |
  'id' |
  'isExternal' |
  'isIcalDescriptionEnabled' |
  'marketplaceConfigurations' |
  'moderatedAt' |
  'moderationStatus' |
  'lastApprovedFields' |
  'openingPeriods' |
  'services' |
  'smsPhone' |
  'pendingModeration'
> {
  provider: 0
  address: null
}


export enum SecureHolidayEngineEnum {
  ENGINE_SANDBOX = '967',
  ENGINE_PRODUCTION = '1297',
}

export enum ExternalPlatformTypeEnum {
  SH = 'SECUREHOLIDAY',
  KE = 'KEBOOKING',
  EVIIVO = 'EVIIVO',
}

export type ExternalPlatformType = `${ExternalPlatformTypeEnum}`

export interface DomainSecureHolidayData {
  engineId: string
  establishmentId: string
}

export interface DomainEviivoData {
  ev_shortName: string // eslint-disable-line camelcase
}

export interface DomainKeBookingData {
  propertyId: string
}

// Discriminating union type to allow us to enforce strict typings on the `data` property
export type DomainExternalId = {
  platformId: ExternalPlatformTypeEnum.SH
  data: DomainSecureHolidayData
} | {
  platformId: ExternalPlatformTypeEnum.EVIIVO
  data: DomainEviivoData
} | {
  platformId: ExternalPlatformTypeEnum.KE
  data: DomainKeBookingData
}

export function isShExternalId(domainExternalId: DomainExternalId): domainExternalId is DomainExternalId {
  return domainExternalId.platformId === ExternalPlatformTypeEnum.SH
}
