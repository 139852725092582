





















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'DashboardWidget',
  props: {
    header: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
