import { defineComponent } from '@nuxtjs/composition-api';
import useDomain from '~/composable/useDomain';
export default defineComponent({
  name: 'DomainSustainabilityActions',
  setup: function setup() {
    var _useContext = useContext(),
        $accessor = _useContext.app.$accessor;

    var _useDomain = useDomain(),
        getDomainField = _useDomain.getDomainField;

    var sustainabilityActions = computed(function () {
      return $accessor.domainSustainabilityActions.getAllArray();
    });
    return {
      getDomainField: getDomainField,
      sustainabilityActions: sustainabilityActions
    };
  }
});