













































































































import { defineComponent, useContext } from '@nuxtjs/composition-api'
import { getMarketplaceById } from '~/server/data/constants'
import { Customer } from '~/types/models'
import useTable from '~/composable/useTable'
import TableSearch from '~/components/table/TableSearch.vue'
import TableEmpty from '~/components/table/TableEmpty.vue'

export default defineComponent({
  name: 'CustomersPage',
  components: {
    TableSearch,
    TableEmpty,
  },
  setup() {
    const { params } = useContext()
    const baseUrl = `domains/${params.value.domain}/customers`
    const {
      state: tableState,
      eventSortTable,
      eventSearchTable,
    } = useTable<Customer>(baseUrl)

    return {
      tableState,
      eventSortTable,
      eventSearchTable,
      getMarketplaceById,
    }
  },
})
