import { DateType } from '~/types'
import { Booking, BookingStatusEnum, UpdateBookingForm, Address, Customer, BookingCharge, BookingGuest } from '~/types/models'
import { computed, ComputedRef, onMounted, reactive, UnwrapRef, useContext, watch, WritableComputedRef } from '@nuxtjs/composition-api'
import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'
import { PatchPayload } from '~/helpers/api'
import { DateFormatEnum, getFormattedInternationalPhoneNumber, sortByDateAsc } from '~/helpers'
import useBookings from './useBookings'
import useOptions from './useOptions'

interface UpdateBookingHook {
  booking: ComputedRef<Booking>
  bookingDuration: ComputedRef<number>
  canSubmit: ComputedRef<boolean>
  computedOption: (optionId: number) => WritableComputedRef<number>
  dateFormatter: (date: Date) => string
  form: UnwrapRef<UpdateBookingForm>
  getAddressField: (field: keyof Address) => WritableComputedRef<number | boolean | DateType | undefined | null>
  getCustomerField: (field: keyof Customer) => WritableComputedRef<string | number | boolean | Date | DateTime | undefined | number[]>
  onDateChanged: (date: Date, key: 'start' | 'end') => void
  onGuestInput: (numberOfGuests: number) => void
  onNavigateNext: ({ force }: { force: boolean }) => Promise<void>
  state: UnwrapRef<UpdateBookingUiState>
  updateBooking: () => Promise<void>
}

interface UpdateBookingUiState {
  activeStep: number
  applyDiscount: boolean
  error: string
  initialCharge: Partial<BookingCharge> | null
  initialStatus: BookingStatusEnum
  isLoading: boolean
  toggleConfirm: boolean
  hasChosenToForceUpdate: boolean
  bookingInputIsValid: boolean
}

function useUpdateBooking(): UpdateBookingHook {
  const { fetchMany } = useOptions()
  const { params, app: { $accessor, $dateTime, i18n, router, localePath } } = useContext()
  const { previewBooking } = useBookings()

  const booking = computed(() => $accessor.bookings.getOne(parseInt(params.value.id)))
  const bookingDuration = computed(() => $dateTime.fromISO(booking.value.end as string).diff($dateTime.fromISO(booking.value.start as string), 'days').days)

  // Destructuring some fields to avoid mutating store when interacting with the form
  const customer = {
    ...$accessor.customers.getOne(booking.value.customer),
    dateOfBirth: new Date($accessor.customers.getOne(booking.value.customer).dateOfBirth as string),
  }
  const customerAddress = { ...$accessor.addresses.getOne(customer.billingAddress) }
  const guests = [...booking.value.guests.map(guest => ({
    dateOfBirth: new Date(guest.dateOfBirth.toString()),
    idx: guest.idx,
  }))]
  const bookingDiscounts = booking.value.discounts.map(discount => {
    if (discount.amount) {
      return {
        ...discount,
        amount: discount.amount / 100,
      }
    }
    return discount
  })
  const bookingCharge = { ...booking.value.bookingCharge }
  const options = [...booking.value.options]
  const { service } = { ...booking.value }

  const form = reactive<UpdateBookingForm>({
    bookingCharge,
    customer,
    customerAddress,
    discounts: bookingDiscounts,
    end: new Date(booking.value.end.toString()),
    guests,
    booking: booking.value.id,
    marketplace: booking.value.marketplace,
    options,
    service,
    start: new Date(booking.value.start.toString()),
    force: false,
  })

  const state = reactive<UpdateBookingUiState>({
    activeStep: 0,
    applyDiscount: bookingDiscounts.length > 0,
    error: '',
    initialCharge: bookingCharge || null,
    initialStatus: booking.value.status,
    isLoading: false,
    toggleConfirm: true,
    hasChosenToForceUpdate: false,
    bookingInputIsValid: true,
  })

  const fetchOptionsForService = async(serviceId: number) => {
    const newOptionIds = $accessor.services.getOne(serviceId).options
    if (!newOptionIds.every(optionId => $accessor.options.getAllIds().includes(optionId)) && newOptionIds.length > 0) {
      await fetchMany(newOptionIds)
    }
  }

  onMounted(async() => {
    await fetchOptionsForService(form.service)
  })

  watch(
    () => form.service,
    async(newValue: number) => await fetchOptionsForService(newValue),
  )

  function formatGuestsForPatch(): BookingGuest[] {
    return form.guests
      .sort((guest1, guest2) => sortByDateAsc($dateTime.fromJSDate(guest1.dateOfBirth as Date), $dateTime.fromJSDate(guest2.dateOfBirth as Date)))
      .map((guest, idx) => {
        return {
          ...guest,
          dateOfBirth: typeof guest.dateOfBirth === 'string'
            ? $dateTime.fromISO(guest.dateOfBirth).toISODate()
            : $dateTime.fromJSDate(guest.dateOfBirth as Date).toISODate(),
          firstName: guest.firstName ? guest.firstName : `Prénom-${nanoid(12)}`,
          lastName: guest.lastName ? guest.lastName : `Nom-${nanoid(12)}`,
          idx,
        }
      })
  }

  const buildPatchPayload = (): PatchPayload<Booking> => ({
    id: parseInt(params.value.id),
    discounts: form.discounts.map(discount => {
      if (discount.amount) {
        return {
          ...discount,
          amount: discount.amount * 100,
        }
      }
      return discount
    }),
    guests: formatGuestsForPatch(),
    options: form.options,
    service: form.service,
    start: $dateTime.fromJSDate(form.start as Date).toISODate(),
    end: $dateTime.fromJSDate(form.end as Date).toISODate(),
    status: state.toggleConfirm ? BookingStatusEnum.BOOKING_CONFIRMED : booking.value.status,
    force: form.force,
  })

  const updateBooking = async() => {
    const payload = buildPatchPayload()
    const res = await $accessor.bookings.patchOne(payload)

    if (form.customerAddress.$isDirty) {
      await $accessor.addresses.patchOne({
        id: form.customerAddress.id,
        payload: form.customerAddress,
      })
    }

    if (form.customer.$isDirty) {
      const payload: Customer = {
        ...form.customer,
        phone: getFormattedInternationalPhoneNumber(form.customer.phone),
        dateOfBirth: typeof form.customer.dateOfBirth === 'string'
          ? $dateTime.fromISO(form.customer.dateOfBirth).toISODate()
          : $dateTime.fromJSDate(form.customer.dateOfBirth as Date).toISODate(),
      }
      await $accessor.customers.patchOne({
        id: form.customer.id,
        payload,
      })
    }

    const provider = $accessor.providers.getFirstWhere(provider => provider.id === parseInt(params.value.provider))

    if (provider && res && router) {
      router.push(localePath({
        name: 'booking-id', params: {
          provider: provider.id.toString(),
          domain: params.value.domain,
          id: params.value.id,
        },
      }))
    }
  }

  const dateFormatter = (date: Date) => $dateTime.fromJSDate(date).toFormat(i18n.t('formats.date_short') as string)

  const computedOption = (optionId: number) => computed({
    get: () => {
      const existingOption = form.options.find(bookingOption => bookingOption.option === optionId)
      return existingOption ? existingOption.quantity : 0
    },
    set: (value: number) => {
      const existingOption = form.options.find(bookingOption => bookingOption.option === optionId)

      // If the option is in the form but we set quantity to 0, remove the option
      if (existingOption && value === 0) {
        form.options = form.options.filter(bookingOption => bookingOption.option !== optionId)

        // If the option is in the form just update the quantity
      } else if (existingOption) {
        existingOption.quantity = value

        // If the option is not in the form, add it
      } else {
        form.options.push({
          option: optionId,
          quantity: value,
        })
      }
    },
  })

  const onGuestInput = (numberOfGuests: number) => {
    if (numberOfGuests > form.guests.length) {
      form.guests.push({ dateOfBirth: new Date(), idx: numberOfGuests })
    } else {
      form.guests.pop()
    }
  }

  // Since you can't modify the booking duration, if one of the dates is modified, update the other one.
  const onDateChanged = (date: Date, key: 'start' | 'end') => {
    const formKey = key === 'start' ? 'end' : 'start'
    const dateTime = $dateTime.fromJSDate(date)
    const newValue = key === 'start' ? dateTime.plus({ days: bookingDuration.value }) : dateTime.minus({ days: bookingDuration.value })
    form[formKey] = newValue.toJSDate()
  }

  const buildPreviewPayload = (): UpdateBookingForm => ({
    ...form,
    guests: form.guests
      .sort((guest1, guest2) => sortByDateAsc($dateTime.fromJSDate(guest1.dateOfBirth as Date), $dateTime.fromJSDate(guest2.dateOfBirth as Date)))
      .map((guest, idx) => {
        return {
          ...guest,
          firstName: `Prénom-${nanoid(12)}`,
          lastName: `Nom-${nanoid(12)}`,
          idx,
        }
      }),
    discounts: form.discounts.map(discount => ({
      ...discount,
      amount: discount.amount ? discount.amount : 0,
      percent: discount.percent ? discount.percent : 0,
    })),
    start: $dateTime.fromJSDate(form.start as Date).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
    end: $dateTime.fromJSDate(form.end as Date).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
    force: form.force,
  })

  const handleRes = (res: string | BookingCharge) => {
    if (typeof res === 'object') {
      form.bookingCharge = res
      state.activeStep++
      state.error = ''
    } else {
      state.error = res
    }
  }

  const onNavigateNext = async({ force }: { force: boolean }) => {
    // Only set form.force to true once
    if (force && !form.force) {
      form.force = true
    }
    state.isLoading = true
    const res = await previewBooking(buildPreviewPayload())
    handleRes(res)
    state.isLoading = false
  }

  const getAddressField = (field: keyof Address): WritableComputedRef<Address[typeof field]> => computed({
    get: () => form.customerAddress[field],
    set: (value: Address[typeof field]) => {
      if (!form.customerAddress.$isDirty && field !== '$isDirty') {
        form.customerAddress.$isDirty = true
      }
      // Dirty cast, found this here: https://github.com/microsoft/TypeScript/issues/31663#issuecomment-518854171
      (form.customerAddress[field] as any) = value
    },
  })

  const getCustomerField = (field: keyof Customer): WritableComputedRef<Customer[typeof field]> => computed({
    get: () => form.customer[field],
    set: (value: Customer[typeof field]) => {
      if (!form.customer.$isDirty && field !== '$isDirty') {
        form.customer.$isDirty = true
      }
      // Dirty cast, found this here: https://github.com/microsoft/TypeScript/issues/31663#issuecomment-518854171
      (form.customer[field] as any) = value
    },
  })

  const canSubmit = computed(() => state.error.length === 0)

  return {
    booking,
    bookingDuration,
    canSubmit,
    computedOption,
    dateFormatter,
    form,
    getAddressField,
    getCustomerField,
    onDateChanged,
    onGuestInput,
    onNavigateNext,
    state,
    updateBooking,
  }
}

export default useUpdateBooking
