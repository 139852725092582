import { useContext } from '@nuxtjs/composition-api'
import { BASE_AMOUNT, DateFormatEnum } from '~/helpers'
import { PatchPayload } from '~/helpers/api'
import {
  Booking, BookingCharge, BookingEventsEnum, BookingIconClassesEnum, BookingStatusColorsEnum, BookingStatusEnum,
  Money, Payment, UpdateBookingForm,
} from '~/types/models'

export interface BookingHook {
  cancelBooking(bookingId: number, amount: number, currency: string): Promise<Booking | undefined>

  patchBooking(payload: PatchPayload<Booking>): Promise<Booking | undefined>

  getTotalAmount(payments: Payment[]): Money

  getFormattedGuests(booking: Booking): string

  getTranslatedStatus(status: BookingStatusEnum): string

  getClassFromStatus(status: BookingStatusEnum): BookingStatusColorsEnum

  getIconFromStatus(status: BookingEventsEnum): string,

  isCancelledBooking(status: BookingStatusEnum): boolean

  fetchOne(bookingId: number): Promise<void>

  onToggleBookingStandby(booking: Booking, fetchPage?: () => Promise<void>): Promise<void>

  previewBooking(payload: UpdateBookingForm): Promise<BookingCharge | string>
}

function useBookings(): BookingHook {
  const { app: { $accessor, i18n, $api, $dateTime } } = useContext()

  function getTotalAmount(payments: Payment[]) {
    const totalAmount: Money = payments.reduce<Money>((acc: Money, current: Payment) => {
      return {
        ...acc,
        amount: acc.amount + current.amount.amount,
      }
    }, BASE_AMOUNT)
    return totalAmount
  }

  function getFormattedGuests(booking: Booking) {
    const adults = i18n.tc('booking.guests.adults_number', booking.adultsNumber, { n: booking.adultsNumber })
    const infants = i18n.tc('booking.guests.infants_number', booking.infantsNumber, { n: booking.infantsNumber })
    const children = i18n.tc('booking.guests.children_number', booking.childrenNumber, { n: booking.childrenNumber })
    return `${adults} ${booking.infantsNumber > 0 ? `, ${infants}` : ''} ${booking.childrenNumber > 0 ? `, ${children}` : ''}`
  }

  function getTranslatedStatus(status: BookingStatusEnum) {
    const key = BookingStatusEnum[status].toLowerCase() as keyof typeof BookingStatusEnum
    return i18n.t(`booking.status_${key}`).toString()
  }

  function getClassFromStatus(status: BookingStatusEnum) {
    return BookingStatusColorsEnum[status]
  }

  function getIconFromStatus(status: BookingEventsEnum) {
    return BookingIconClassesEnum[status as keyof typeof BookingIconClassesEnum]
  }

  function isCancelledBooking(status: BookingStatusEnum) {
    return status === BookingStatusEnum.BOOKING_CANCELLED_CUSTOMER || status === BookingStatusEnum.BOOKING_CANCELLED_PROVIDER
  }

  async function fetchOne(bookingId: number) {
    return await $accessor.bookings.fetchOne(bookingId)
  }

  async function patchBooking(payload: PatchPayload<Booking>) {
    return await $accessor.bookings.patchOne(payload)
  }

  async function cancelBooking(bookingId: number, amount: number, currency: string) {
    return await $accessor.bookings.cancelBooking({ bookingId, amount, currency })
  }

  async function previewBooking(payload: UpdateBookingForm): Promise<BookingCharge | string> {
    try {
      const formattedPayload = {
        ...payload,
        guests: payload.guests.map((guest => ({
          ...guest,
          dateOfBirth: $dateTime.fromJSDate(guest.dateOfBirth as Date).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
        }))),
      }
      return await $api.post('bookings/preview', formattedPayload)
    } catch (error: any) {
      return error.response.data.message ? error.response.data.message : i18n.t('form.fix_errors')
    }
  }

  async function onToggleBookingStandby(booking: Booking, fetchPage?: () => Promise<void>) {
    const updatedBooking = booking.status === BookingStatusEnum.BOOKING_STANDBY
      ? (await $accessor.bookings.toggleBookingStandby({ id: booking.id, status: BookingStatusEnum.BOOKING_CONFIRMED }))
      : (await $accessor.bookings.toggleBookingStandby({ id: booking.id, status: BookingStatusEnum.BOOKING_STANDBY }))
    if (updatedBooking && fetchPage) {
      fetchPage()
    }
  }

  return {
    cancelBooking,
    patchBooking,
    getTotalAmount,
    getFormattedGuests,
    getTranslatedStatus,
    getClassFromStatus,
    getIconFromStatus,
    isCancelledBooking,
    fetchOne,
    onToggleBookingStandby,
    previewBooking,
  }
}

export default useBookings
