import { Middleware } from '@nuxt/types'

const providerMiddleware: Middleware = async({ params, app: { $accessor } }) => {
  const providerId = parseInt(params.provider)
  if (!$accessor.providers.getAllIds().includes(providerId)) {
    await $accessor.providers.fetchOne(providerId)
  }
}

export default providerMiddleware
