import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.map.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { PresenceStatus } from '~/types';
import { AuthMutationTypes } from '~/store/auth/types';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { RootMutationTypes } from '~/store/types';
export default function (_ref, inject) {
  var app = _ref.app,
      $config = _ref.$config,
      $featureToggle = _ref.$featureToggle,
      store = _ref.store,
      $sentry = _ref.$sentry;

  if ($featureToggle.toggles.echo) {
    var listeningProviderChannels = false;
    var listeningAdminChannels = false;
    window.Pusher = Pusher;
    window.echoChat = null;

    var logger = function logger(str) {
      return process.env.NODE_ENV === 'development' ? console.log("%c [ECHO] ".concat(str, " "), 'background: #033E43; color: #F5F5F5; padding: 2px') // eslint-disable-line
      : null;
    };

    var echoChat = null;
    var echo = new Echo(_objectSpread(_objectSpread({
      broadcaster: 'pusher',
      encrypted: true,
      disableStats: true
    }, $config.echo.clientOptions), {}, {
      authorizer: function authorizer(channel, _options) {
        return {
          authorize: function authorize(socketId, callback) {
            var authUrl = "".concat($config.axios.baseURL, "/broadcasting/auth");

            if ($config.echo.isAuthXdebugEnabled) {
              authUrl += '?XDEBUG_SESSION_START=1';
            }

            app.$axios.post(authUrl, {
              // eslint-disable-next-line camelcase
              socket_id: socketId,
              // eslint-disable-next-line camelcase
              channel_name: channel.name
            }, {
              headers: {
                Authorization: "Bearer ".concat(app.$api.getToken()),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }).then(function (response) {
              callback(null, response.data);
            }).catch(function (error) {
              callback(error);
            });
          }
        };
      }
    })); // Interval to check if Echo connexion succeeded.

    var checkSocketTimeout = 1000; // If we didn't manage to connect to ws after 20 tries, log in Sentry.

    var sentryIfNotConnectedTimeout = setTimeout(function () {
      if (!echo.socketId()) {
        $sentry.captureException(new Error('Echo failed to connect to WebSockets.'));
      }
    }, checkSocketTimeout * 20); // Check regularily if we managed to create the connection and add the Socket ID in Axios headers.

    var setSocketIdHeaderInterval = setInterval(function () {
      if (echo.socketId()) {
        clearInterval(setSocketIdHeaderInterval);
        clearTimeout(sentryIfNotConnectedTimeout);
        app.$axios.defaults.headers['X-Socket-ID'] = echo.socketId();
      }
    }, checkSocketTimeout);

    var listenProviderChannels = function listenProviderChannels(userId) {
      echo.private("user.".concat(userId)).listen('.address.updated', function (event) {
        return app.$accessor.addresses.echoUpdate({
          id: event.payload.id,
          payload: event.payload
        });
      });
      listeningProviderChannels = true;
    };

    var listenAdminChannels = function listenAdminChannels() {
      echo.private('admin').listen('.address.updated', function (event) {
        return app.$accessor.addresses.echoUpdate({
          id: event.payload.id,
          payload: event.payload
        });
      });
      listeningAdminChannels = true;
    };

    var joinOnlinePresenceChannel = function joinOnlinePresenceChannel() {
      var _echoChat, _echoChat3;

      echoChat = echo.join('online');
      echo.channel('online');
      (_echoChat = echoChat) === null || _echoChat === void 0 ? void 0 : _echoChat.here(function (users) {
        console.log('In echoChat.here'); // eslint-disable-line

        if (app.$accessor.users.currentUserIsAdmin) {
          console.log("echoChat.joining user is admin, users here are ".concat(users.map(function (u) {
            return u.email;
          }).join(','))); // eslint-disable-line

          users.forEach(function (user) {
            return store.commit(RootMutationTypes.PRESENCE_USER_CREATE, {
              id: user.id,
              providerId: user.providerId,
              status: PresenceStatus.ONLINE
            });
          });
        }
      }).joining(function (user) {
        console.log('In echoChat.joining'); // eslint-disable-line

        if (app.$accessor.users.currentUserIsAdmin) {
          console.log("echoChat.joining user is admin, user joining is ".concat(user.email)); // eslint-disable-line

          store.commit(RootMutationTypes.PRESENCE_USER_CREATE, {
            id: user.id,
            providerId: user.providerId,
            status: PresenceStatus.ONLINE
          });
        } else if (app.$accessor.users.currentUserIsProvider) {
          var _echoChat2;

          console.log("echoChat.joining user is provider, user joining is ".concat(user.email)); // eslint-disable-line
          // @FIXME only whisper if status is not default (as it should be handled by 'here'.

          (_echoChat2 = echoChat) === null || _echoChat2 === void 0 ? void 0 : _echoChat2.whisper('changeState', {
            id: app.$accessor.users.currentUserId,
            status: app.$accessor.currentUserPresenceStatus
          });
        }
      }).leaving(function (user) {
        console.log('In echoChat.leaving'); // eslint-disable-line

        if (app.$accessor.users.currentUserIsAdmin) {
          console.log("echoChat.leaving user is provider, user leaving is ".concat(user.email)); // eslint-disable-line

          store.commit(RootMutationTypes.PRESENCE_USER_REMOVE, user.id);
        }
      }).error(function (error) {
        console.error(error); // eslint-disable-line no-console

        $sentry.captureException(error);
      });
      (_echoChat3 = echoChat) === null || _echoChat3 === void 0 ? void 0 : _echoChat3.listenForWhisper('changeState', function (_ref2) {
        var status = _ref2.status,
            id = _ref2.id;
        console.log("user ".concat(id, " whispered a changeState event with new status ").concat(status)); // eslint-disable-line

        store.commit(RootMutationTypes.PRESENCE_USER_SET_STATUS, {
          id: id,
          status: status
        });
      }); // @TODO inject a ref() instead.

      window.echoChat = echoChat;
    };

    var logout = function logout() {
      echo.leave('online');
      echo.leave('admin');
      echo.leave("user.".concat(app.$accessor.users.currentUserId));
      listeningAdminChannels = false;
      listeningProviderChannels = false;
    };

    var joinChannels = function joinChannels() {
      // On first load we need to run some checks, as LOGIN etc probably happened during SSR.
      if (app.$accessor.users.currentUserIsAdmin && !listeningAdminChannels) {
        listenAdminChannels();
        logger('during init, listenAdminChannels');
      } else if (app.$accessor.users.currentUserIsProvider && !listeningProviderChannels) {
        listenProviderChannels(app.$accessor.users.currentUserId);
        logger('during init, listenProviderChannels');
      }

      if (app.$accessor.users.currentUserIsAdmin || app.$accessor.users.currentUserIsProvider) {
        joinOnlinePresenceChannel();
        logger('during init, joinOnlinePresenceChannel');
      }
    };

    if (app.$accessor.auth.getIsLoggedIn) {
      joinChannels();
    } // Register to channels on login, disconnect


    store.subscribe(function (mutation) {
      switch (mutation.type) {
        case "auth/".concat(AuthMutationTypes.LOGIN):
          joinChannels();
          break;

        case "auth/".concat(AuthMutationTypes.LOGOUT):
          logout();
          logger('in subscriber, LOGOUT');
          break;

        default:
          break;
      }
    });
    inject('echo', echo);
  }
}