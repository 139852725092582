import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Bookings"},[_c('section',{staticClass:"is-full is-relative"},[_c('BookingsTable',{attrs:{"base-url":_vm.baseUrl,"filters":_vm.filters},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var tableSearch = ref.tableSearch;
var tableState = ref.tableState;
return [_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('feature-toggle',{attrs:{"name":"bookingSearch","value":true}},[_c('TableSearch',{staticClass:"level-left",attrs:{"value":tableState.query},on:{"input":function($event){return tableSearch($event)}}})],1)],1),_vm._v(" "),_c('div',{staticClass:"level-right"},[_c('BaseButton',{attrs:{"disabled":!_vm.$featureToggle.toggles.todaysArrival,"size":"is-medium","tag":"nuxt-link","to":_vm.localePath({ name: 'booking-arrivals', params: { provider: _vm.$route.params.provider } })}},[_vm._v("\n              "+_vm._s(_vm.$t('filters.todays_arrival'))+"\n            ")])],1)]),_vm._v(" "),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"}),_vm._v(" "),_c('div',{staticClass:"level-right"},[_c('feature-toggle',{attrs:{"name":"bookingExport","value":true}},[_c('BaseLink',{attrs:{"type":"is-primary","has-icon":"","icon-pack":"usi","icon-left":"download"},on:{"click":_vm.handleDownloadExport}},[_vm._v("\n                "+_vm._s(_vm.$t('actions.export_csv'))+"\n              ")])],1)],1)])]}},{key:"actions",fn:function(ref){
var booking = ref.booking;
var fetchPage = ref.fetchPage;
return [_c('div',{staticClass:"tw-flex tw-gap-12"},[_c('BaseLink',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(_vm.$t('options.update_booking')),expression:"$t('options.update_booking')",modifiers:{"top-end":true}}],attrs:{"icon-only":"","icon-left":"pencil","icon-pack":"usi"},on:{"click":function($event){return _vm.onClickUpdateBooking(booking)}}}),_vm._v(" "),(_vm.isImpersonating)?_c('BaseLink',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(booking.status === _vm.BookingStatusEnum.BOOKING_STANDBY ? _vm.$t('options.resume_booking') : _vm.$t('options.pause_booking')),expression:"booking.status === BookingStatusEnum.BOOKING_STANDBY ? $t('options.resume_booking') : $t('options.pause_booking')",modifiers:{"top-end":true}}],attrs:{"icon-only":"","icon-left":"chrono","icon-pack":"usi"},on:{"click":function($event){return _vm.onToggleBookingStandby(booking, fetchPage)}}}):_vm._e(),_vm._v(" "),_c('BaseLink',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(_vm.$t('actions.view')),expression:"$t('actions.view')",modifiers:{"top-end":true}}],attrs:{"icon-only":"","icon-left":"eye-outline","icon-pack":"mdi"},on:{"click":function($event){return _vm.onClickViewBooking(booking)}}})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }