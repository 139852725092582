import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { Booking } from '~/types/models'

export enum BookingMutationTypes {
  EXPORT_DOWNLOAD_START = 'EXPORT_DOWNLOAD_START',
  EXPORT_DOWNLOAD_END = 'EXPORT_DOWNLOAD_END',
  SET_LASTS_FOR_DOMAIN = 'SET_LASTS_FOR_DOMAIN',
  SET_TODAY_FOR_DOMAIN = 'SET_TODAY_FOR_DOMAIN',
  RESET_STATE = 'RESET_STATE',
}

export interface BookingState extends BaseState<Booking> {
  isDownloadingExport: boolean,
  lastBookingsForDomain: number[],
  todayBookingsForDomain: number[],
}
