




























































































































































import { defineComponent, reactive, useContext } from '@nuxtjs/composition-api'
import { GiftCard } from '~/types/models'
import useGiftCards from '~/composable/useGiftCards'
import useTable from '~/composable/useTable'
import UploadCsvModal from '~/components/modal/UploadCsvModal.vue'
import TableSearch from '~/components/table/TableSearch.vue'
import TableEmpty from '~/components/table/TableEmpty.vue'
import StatusBadge from '~/components/badges/StatusBadge.vue'
import { ThemeColors } from '~/types/Tailwind'

export default defineComponent({
  name: 'GiftCardsListPage',
  components: {
    TableSearch,
    TableEmpty,
    UploadCsvModal,
    StatusBadge,
  },
  setup(_, ctx) {
    const { app: { i18n } } = useContext()
    const baseUrl = 'giftcards'
    const { state: tableState, eventSearchTable, eventSortTable, refreshTable } = useTable<GiftCard>(baseUrl)
    const { deleteGiftCard, downloadCsvExport } = useGiftCards()

    const state = reactive({
      uploadCsvModal: false,
      exportingCsv: false,
    })

    async function confirmDeleteGiftCard(id: number) {
      ctx.root.$buefy.dialog.confirm({
        title: i18n.t('dialog.giftcard_delete.title').toString(),
        message: i18n.t('dialog.giftcard_delete.message').toString(),
        confirmText: i18n.t('dialog.giftcard_delete.confirm_text').toString(),
        cancelText: i18n.t('dialog.giftcard_delete.cancel_text').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          await deleteGiftCard(id)
          refreshTable()
        },
      })
    }

    async function downloadCsv() {
      // CSV generation on the server takes a while so we disable the button while the request is pending
      // To avoid multiple clicks and duplicate downloads
      state.exportingCsv = true
      await downloadCsvExport()
      state.exportingCsv = false
    }

    return {
      confirmDeleteGiftCard,
      downloadCsv,
      eventSearchTable,
      eventSortTable,
      refreshTable,
      state,
      tableState,
      ThemeColors,
    }
  },
})
