









































































































































import { defineComponent, PropType, reactive, toRefs, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import MultipleDateRangePicker from '~/components/datetime/MultipleDateRangePicker.vue'
import MarketplacesSelectCheckboxes from '~/components/form/MarketplacesSelectCheckboxes.vue'
import PriceInput from '~/components/content/PriceInput.vue'
import HRSwitch from '~/components/HRSwitch.vue'

interface State {
  isIllimited: boolean
}

export default defineComponent({
  name: 'PromocodesForm',
  components: {
    PriceInput,
    MarketplacesSelectCheckboxes,
    MultipleDateRangePicker,
    HRSwitch,
  },
  props: {
    isCreation: {
      type: Boolean,
      default: false,
    },
    getField: {
      type: Function as PropType<(_key: string) => WritableComputedRef<any>>,
      required: true,
    },
  },
  setup() {
    const { app: { $dateTime } } = useContext()

    const defaultPeriod = {
      start: new Date(),
      end: $dateTime.fromJSDate(new Date()).plus({ day: 1 }).toJSDate(),
    }

    const state = reactive<State>({ isIllimited: true })

    return {
      ...toRefs(state),
      defaultPeriod,
    }
  },
})
