









































































import { Booking } from '~/types/models/Booking'
import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import { EntityFileTypeEnum } from '~/types/models'
import useBookings from '~/composable/useBookings'
import useImage from '~/composable/useImage'
import { dateDiff } from '~/helpers'

export default defineComponent({
  name: 'BookingDetailsReservation',
  middleware: ['customerMiddleware'],
  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },
  setup(props) {
    const { app: { $accessor } } = useContext()
    const { generateSignedUrl } = useImage()
    const { getFormattedGuests } = useBookings()

    const service = computed(() => $accessor.services.getOne(props.booking.service))
    const domain = computed(() => $accessor.domains.getOne(props.booking.domain))

    const rentalImg = computed(() => {
      if (service.value.files?.length > 0) {
        const serviceImg = $accessor.entityFiles.getWhereArray(file => file.type === EntityFileTypeEnum.SERVICE_PICTURE && service.value.files.includes(file.id))
        if (serviceImg.length > 0) {
          return generateSignedUrl(serviceImg[0].url)
        }
      }
    })

    return {
      dateDiff,
      getFormattedGuests,
      rentalImg,
      service,
      domain,
    }
  },
})
