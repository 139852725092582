import { Middleware } from '@nuxt/types'

const servicesMiddleware: Middleware = async({ params, app: { $accessor } }) => {
  const domainId = parseInt(params.domain)
  const serviceIds = $accessor.domains.getOne(domainId).services
  const missingServiceIds = serviceIds.filter(serviceId => !$accessor.services.getAllIds().includes(serviceId))
  if (missingServiceIds.length > 0) {
    await $accessor.services.fetchMany(missingServiceIds)
  }
}

export default servicesMiddleware
