import { computed, ComputedRef, getCurrentInstance, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import orderBy from 'lodash/orderBy'
import { EntityFile, EntityFileTypeEnum, Provider } from '~/types/models'

type ProviderHook = {
  fetchOne (id: number): Promise<void>
  fetchCurrentProvider (id: number): Promise<void>
  postOne (): Promise<void | boolean>
  patchCurrentProvider (): Promise<void>
  currentProviderId: ComputedRef<number>,
  currentProvider: ComputedRef<Provider | null>,
  getProviderTranslation: (field: string, locale?: string | undefined) => WritableComputedRef<string>,
  getProviderField: (field: string, id?: number) => WritableComputedRef<any>,
  providerOwnersPicture: WritableComputedRef<EntityFile[]>,
  deleteProviderFile: (id: number) => Promise<EntityFile[]>,
  deleteProviderPicture: (id: number) => void,
  addProviderFile: (file: EntityFile) => void,
}

function useProvider(): ProviderHook {
  const { app, params, store } = useContext()
  const { $accessor } = app
  const currentInstance = getCurrentInstance()
  const currentLocale: string = store.state.i18n.locale
  const currentProviderId = computed((): number => parseInt(params.value.provider))
  const currentProvider = computed(() => $accessor.providers.getOne(currentProviderId.value))

  async function fetchCurrentProvider(id: number): Promise<void> {
    return $accessor.providers.fetchOneForCurrent(id)
  }

  async function fetchOne(id: number) {
    return $accessor.providers.fetchOne(id)
  }

  async function postOne() {
    return $accessor.providers.postOne()
  }

  async function patchCurrentProvider() {
    return $accessor.providers.patchOne(currentProviderId.value)
  }

  const getCreateProviderFormField = (field: string) => computed({
    get: () => $accessor.providers.getCreateFormField(field),
    set: (value) => $accessor.providers.UPDATE_CREATE_FORM_FIELD({
      path: field,
      value,
    }),
  })

  const getProviderFieldById = (field: string, id: number) => computed({
    get: () => {
      return $accessor.providers.getField(id)(field)
    },
    set: value => {
      if (!$accessor.providers.getField(id)('$isDirty') && field !== '$isDirty') {
        $accessor.providers.UPDATE_FIELD({
          id,
          path: '$isDirty',
          value: true,
        })
      }
      return $accessor.providers.UPDATE_FIELD({
        id,
        path: field,
        value,
      })
    },
  })

  const getProviderField = (field: string, id?: number) => computed({
    get: () => id ? getProviderFieldById(field, id).value : getCreateProviderFormField(field).value,
    set: val => id
      ? getProviderFieldById(field, id).value = val
      : getCreateProviderFormField(field).value = val,
  })

  const getProviderTranslation: (field: string, locale?: string) => WritableComputedRef<string> = (field: string, locale: string = currentLocale) => computed({
    get: () => getProviderField(`${field}.${locale}`).value,
    set: val => getProviderField(`${field}.${locale}`).value = val,
  })

  function deleteProviderPicture(id: number): void {
    if (currentInstance) {
      currentInstance.$buefy.dialog.confirm({
        title: app.i18n.t('dialog.picture_delete.title').toString(),
        message: app.i18n.t('dialog.picture_delete.message').toString(),
        confirmText: app.i18n.t('dialog.picture_delete.confirmText').toString(),
        cancelText: app.i18n.t('dialog.picture_delete.cancelText').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          await deleteProviderFile(id)
        },
      })
    }
  }

  function addProviderFile(file: EntityFile): void {
    $accessor.entityFiles.CREATE(file)
    getProviderField('files').value = [
      ...getProviderField('files').value,
      file.id,
    ]
  }

  async function deleteProviderFile(id: number): Promise<EntityFile[]> {
    // const providerId = currentProvider.value.id
    // await $accessor.providers.deleteFile({ providerId, id  })
    const ordered = orderBy(
      getProviderField('files')
        .value
        .filter((f: EntityFile) => f.id !== id),
      'metadata.idx',
    )
    return getProviderField('files').value = ordered.map((f, idx) => ({
      ...f,
      metadata: {
        ...(f.metadata || {}),
        idx,
      },
    }))
  }


  const providerOwnersPicture: WritableComputedRef<EntityFile[]> = computed({
    get: () => getProviderField('files')
      .value
      .find((file: EntityFile) => file.type === EntityFileTypeEnum.DOMAIN_OWNERS_PICTURE) || {},
    set: val => {
      if (val) {
        return addProviderFile(val)
      } else if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn('Don\'t use providerOwnersPicture computed setter to delete, use deleteProviderPicture instead.')
      }
    },
  })


  return {
    fetchOne,
    fetchCurrentProvider,
    postOne,
    patchCurrentProvider,
    currentProviderId,
    currentProvider,
    getProviderTranslation,
    getProviderField,
    providerOwnersPicture,
    deleteProviderFile,
    deleteProviderPicture,
    addProviderFile,
  }
}

export default useProvider
