
















































































































































import { PropType, defineComponent, onMounted, reactive, ref, useContext, watch } from '@nuxtjs/composition-api'
import useAddress from '~/composable/useAddress'
import { Country, Department, Region } from '~/types/Geo'
import CountriesSelect from '~/components/form/CountriesSelect.vue'

interface LocationState {
  countries: Country[]
  regions: Region[]
  departments: Department[]
}

export default defineComponent({
  name: 'LocationForm',
  components: { CountriesSelect },
  props: {
    addressId: {
      type: Number as PropType<number | null>,
      required: false,
      default: null,
      validator: (prop: number | null) => typeof prop === 'number' || prop === null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    showMap: {
      type: Boolean,
      default: false,
    },
    canForceCoordinates: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { app: { $api } } = useContext()
    const { getAddressField } = useAddress()

    const state = reactive<LocationState>({
      countries: [],
      regions: [],
      departments: [],
    })
    const isLoading = ref(false)

    onMounted(async() => {
      isLoading.value = true
      state.countries = await $api.get('countries')
      isLoading.value = false
      if (props.addressId) {
        const currentCountry = state.countries?.find(country => country.code === getAddressField('country', props.addressId).value)
        if (currentCountry) {
          isLoading.value = true
          state.regions = await $api.get(`regions/${currentCountry.code}`)
          isLoading.value = false
          const currentRegion = state.regions?.find(region => region.code === getAddressField('subdivision', props.addressId).value)
          if (currentRegion) {
            isLoading.value = true
            state.departments = await $api.get(`subdivisions/${currentRegion.code}`)
            isLoading.value = false
          }
        }
      }
    })

    const onCountrySelected = (code: string) => {
      if (state.countries) {
        getAddressField('country', props.addressId).value = code
      }
    }

    const onRegionSelected = (code: string) => {
      if (state.regions) {
        getAddressField('subdivision', props.addressId).value = code
      }
    }

    const onDepartmentSelected = (code: string) => {
      if (state.departments) {
        getAddressField('subdivision2', props.addressId).value = code
      }
    }

    function resetCoordinates(): void {
      getAddressField('lat', props.addressId).value = null
      getAddressField('lng', props.addressId).value = null
    }

    watch(() => getAddressField('country', props.addressId).value, async(newCode) => {
      state.regions = []
      state.departments = []
      state.regions = await $api.get(`regions/${newCode}`)
    })

    // If the user selects a new region, fetch the associated departments
    watch(() => getAddressField('subdivision', props.addressId).value, async(newCode) => {
      state.departments = []
      state.departments = await $api.get(`subdivisions/${newCode}`)
    })

    return {
      state,
      onCountrySelected,
      onRegionSelected,
      onDepartmentSelected,
      getAddressField,
      resetCoordinates,
      isLoading,
    }
  },
})
