
















































































import { Unavailability } from '~/types/models'
import { CalendarDrawerNameEnum } from '~/types'
import { computed, defineComponent, PropType, reactive, toRefs, useContext } from '@nuxtjs/composition-api'
import IcalDisplayLine from '~/components/icals/IcalDisplayLine.vue'
import { DateTime } from 'luxon'
import useCalendar from '~/composable/useCalendar'
import { DrawerDisplayModeEnum } from '~/store/calendar/types'

export default defineComponent({
  name: 'UnavailabilityDetails',
  components: {
    IcalDisplayLine,
  },
  props: {
    unavailability: {
      type: Object as PropType<Unavailability>,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    showActions: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const { params, app: { $dateTime, i18n, $accessor, $translateEntityField, router, localePath } } = useContext()
    const { ui } = useCalendar()

    const state = reactive({
      service: computed(() => $accessor.services.getOne($accessor.calendar.ui.drawerData.serviceId)),
      serviceUnit: computed(() => $accessor.serviceUnits.getOne(props.unavailability?.serviceUnit)),
    })

    const isIcalEnabled = computed(() => $accessor.domains.getOne(parseInt(params.value.domain)).isIcalDescriptionEnabled)

    function getFormattedPeriod() {
      if (!props.unavailability) {
        return ''
      }
      const start = $dateTime.fromISO(props.unavailability.start as string)
        .toLocaleString(DateTime.DATE_SHORT)
      const end = $dateTime.fromISO(props.unavailability.end as string)
        .toLocaleString(DateTime.DATE_SHORT)
      return i18n.t('common.from_to_date', {
        start,
        end,
      })
    }

    function getFormattedServiceUnit() {
      return `${$translateEntityField(state.service.name)}, ${state.serviceUnit?.label}`
    }

    function redirectToIcalPage() {
      $accessor.calendar.RESET_UI_DRAWER()
      router?.push(localePath({ name: 'icals', params: {
        provider: params.value.provider.toString(),
        domain: params.value.domain.toString(),
      } }))
    }

    return {
      ...toRefs(state),
      CalendarDrawerNameEnum,
      DrawerDisplayModeEnum,
      getFormattedPeriod,
      getFormattedServiceUnit,
      isIcalEnabled,
      redirectToIcalPage,
      ui,
    }
  },
})
