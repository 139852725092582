





































import { DrawerDisplayModeEnum } from '~/store/calendar/types'
import { CalendarDrawerNameEnum } from '~/types/Calendar'
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import useCalendar from '~/composable/useCalendar'
import CalendarDrawer from '~/components/calendar/drawers/CalendarDrawer.vue'
import ServiceDiscountDetails from '~/components/calendar/serviceDiscount/ServiceDiscountDetails.vue'
import ServiceDiscountEditForm from '~/components/calendar/serviceDiscount/ServiceDiscountEditForm.vue'
import ServiceDiscountDeleteConfirm from '~/components/calendar/serviceDiscount/ServiceDiscountDeleteConfirm.vue'

export default defineComponent({
  name: 'CalendarDiscountDrawer',
  components: {
    CalendarDrawer,
    ServiceDiscountDetails,
    ServiceDiscountEditForm,
    ServiceDiscountDeleteConfirm,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { app: { $accessor, $dateTime, i18n } } = useContext()
    const { ui, isCalendarUserEnabled } = useCalendar()

    const isDrawerDiscountOpen = computed(() => discount.value && ui.value.drawer === CalendarDrawerNameEnum.DISCOUNT)

    const discount = computed(() => {
      if (ui.value.drawerDisplayMode !== DrawerDisplayModeEnum.CREATE) {
        return $accessor.serviceDiscounts.getFirstActive()
      }
    })
    const title = computed(() => {
      switch (ui.value.drawerDisplayMode) {
        case DrawerDisplayModeEnum.READ:
          return `${i18n.t('calendar.modal_titles.discounts_create')} "${discount.value?.label}"`
        case DrawerDisplayModeEnum.DELETE:
          return `${i18n.t('calendar.modal_titles.discounts_delete')} "${discount.value?.label}"`
        case DrawerDisplayModeEnum.UPDATE:
          return `${i18n.t('calendar.modal_titles.discounts_edit')} "${discount.value?.label}"`
        default:
          return i18n.t('calendar.modal_titles.discounts_create')
      }
    })

    function onEdit() {
      if (discount.value) {
        $accessor.calendar.UI_OPEN_DRAWER({
          drawer: CalendarDrawerNameEnum.DISCOUNT,
          mode: DrawerDisplayModeEnum.UPDATE,
          data: {
            startDate: $dateTime.fromISO(discount.value.start as string),
            endDate: $dateTime.fromISO(discount.value.end as string),
          },
        })
      }
    }

    function onDelete() {
      $accessor.calendar.UI_OPEN_DRAWER({
        drawer: CalendarDrawerNameEnum.DISCOUNT,
        mode: DrawerDisplayModeEnum.DELETE,
        data: {},
      })
    }

    function onClose() {
      $accessor.serviceDiscounts.RESET_ACTIVE()
      emit('on-close')
    }

    function onClickOutside() {
      emit('on-click-outside')
    }


    return {
      CalendarDrawerNameEnum,
      discount,
      DrawerDisplayModeEnum,
      isCalendarUserEnabled,
      isDrawerDiscountOpen,
      onClickOutside,
      onClose,
      onDelete,
      onEdit,
      title,
      ui,
    }
  },
})
