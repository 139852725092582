




































































import { defineComponent, reactive, ref, useContext } from '@nuxtjs/composition-api'
import useAuth from '~/composable/useAuth'

export default defineComponent({
  name: 'ForgotPasswordPage',
  setup() {
    const { resetPassword } = useAuth()
    const { app: { $errorToast, i18n }, route } = useContext()
    const form = ref(null)
    const state = reactive({
      email: route.value.query.email,
      password: '',
      password_confirmation: '', // eslint-disable-line camelcase
      token: route.value.query.token,
    })

    async function submitForm() {
      if (state.password !== state.password_confirmation) {
        $errorToast(i18n.t('toast.passwords_dont_match'))
      } else {
        resetPassword(state)
      }
    }

    return {
      form,
      state,
      submitForm,
    }
  },
})
