import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { DomainSustainabilityAction } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<DomainSustainabilityAction>(),

  async fetchAll({ commit }): Promise<void> {
    const res = await this.app.$api.get('domainsustainabilityactions?limit=-1')
    const sustainabilityActions = res.data
    if (sustainabilityActions.length) {
      return commit(BaseMutationTypes.CREATE_MANY, sustainabilityActions)
    }
  },

})

export default actions
