import { createActions } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { DomainActivity } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations },{ ...createActions<DomainActivity>() })

export default actions
