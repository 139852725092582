import { createEntity, createState } from '@kissmylabs/vuex-entitystore'
import { User, UserRoles } from '~/types/models'
import { UserCreateForm, UserState } from './types'

export const user = createEntity<User>('user')

export const baseCreateForm = (): UserCreateForm => ({
  email: '',
  username: '',
  password: '',
  provider: 0,
})

export const state = (): UserState => ({
  ...createState<User>(user),
  currentUser: {
    username: '',
    id: 0,
    email: '',
    roles: [] as UserRoles[],
  },
  currentUserId: 0,
  currentProviderId: 0,
  createForm: baseCreateForm(),
  isImpersonating: false,
})

export default state
