import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { DomainMarketplaceConfiguration, DomainMarketplaceConfigurationCreateForm } from '~/types/models'
import { DomainMarketplaceConfigurationMutationTypes } from './types'
import state from './state'

export const actions = actionTree({ state }, {
  ...createActions<DomainMarketplaceConfiguration>(),

  async fetchAll({ commit }): Promise<void> {
    try {
      const res = await this.app.$api.get('domainmarketplaceconfigurations')
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.domain_fetching'))
    }
  },

  async fetchMany({ commit }, mkpConfigIds: number[]) {
    if (mkpConfigIds.length > 0) {
      try {
        const ids = mkpConfigIds.join()
        const res = await this.app.$api.get(`domainmarketplaceconfigurations?filters[id_in]=${ids}&limit=${mkpConfigIds.length}`)
        if (res.data.length) {
          commit(BaseMutationTypes.CREATE_MANY, res.data)
        }
      } catch (error) {
        this.app.$errorToast(this.app.i18n.t('toast.domain_fetching'))
      }
    }
  },

  async postOne({ commit }, payload: DomainMarketplaceConfigurationCreateForm): Promise<void> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.marketplace.configuration_creating'))
    try {
      const res = await this.app.$api.post('domainmarketplaceconfigurations', payload)
      commit(BaseMutationTypes.CREATE, res)
      commit(DomainMarketplaceConfigurationMutationTypes.REMOVE_CREATE_FORM_ITEM, {
        marketplaceId: payload.marketplace,
        domainId: payload.domain,
      })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.marketplace.configuration_created'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.marketplace.configuration_error'))
    }
  },

  async patchOne({ commit }, { payload, isStatusUpdate = false }: { payload: DomainMarketplaceConfiguration, isStatusUpdate: boolean }): Promise<void> {
    const toast = this.app.$loadingToast(this.app.i18n.t(`toast.marketplace.${isStatusUpdate ? 'status_updating' : 'configuration_updating'}`))
    try {
      const res: DomainMarketplaceConfiguration = await this.app.$api.patch(`domainmarketplaceconfigurations/${payload.id}`, payload)
      commit(BaseMutationTypes.UPDATE, {
        id: res.id,
        payload: { ...res },
      })
      toast.goAway(0)
      let msg = 'configuration_updated'
      if (isStatusUpdate) {
        msg = res.isEnabled ? 'status_updated_true' : 'status_updated_false'
      }
      this.app.$successToast(this.app.i18n.t(`toast.marketplace.${msg}`))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t(`toast.marketplace.${isStatusUpdate ? 'status_updated_true' : 'configuration_error'}`))
    }
  },
})
export default actions
