import { useContext, computed, ComputedRef, WritableComputedRef } from '@nuxtjs/composition-api'
import { DateFormatEnum } from '~/helpers'
import { GiftCardCreateForm } from '~/store/giftCards/types'
import { GiftCard } from '~/types/models'

export interface GiftCardHook {
  fetchGiftCard(id: number): Promise<void>,
  currentGiftCard: ComputedRef<GiftCard>,
  getGiftCardField: (field: string) => WritableComputedRef<any>,
  patchGiftCard(id: number): Promise<void>,
  deleteGiftCard(id: number): Promise<void>,
  getCreateGiftCardFormField: (field: string) => WritableComputedRef<any>,
  postGiftCard(): Promise<void>,
  downloadCsvExport(): Promise<any>,
}

function useGiftCard(): GiftCardHook {
  const { app: { router, localePath, $accessor, $dateTime, $toCent }, params } = useContext()
  const currentGiftCard = computed((): GiftCard => $accessor.giftCards.getOne(parseInt(params.value.id)))

  async function fetchGiftCard(id: number) {
    return $accessor.giftCards.fetchOne(id)
  }

  const getGiftCardField = (field: string) => computed({
    get: () => $accessor.giftCards.getField(currentGiftCard.value.id)(field),
    set: val => {
      if (field === 'code') {
        val = val.toUpperCase()
      }
      return $accessor.giftCards.UPDATE_FIELD({
        id: currentGiftCard.value.id,
        path: field,
        value: val,
      })
    },
  })

  const getCreateGiftCardFormField = (field: string) => computed({
    get: () => $accessor.giftCards.getCreateFormField(field),
    set: value => {
      if (field === 'code') {
        value = value.toUpperCase()
      }
      return $accessor.giftCards.UPDATE_CREATE_FORM_FIELD({
        path: field,
        value,
      })
    },
  })

  async function postGiftCard() {
    const date = $accessor.giftCards.createForm.validUntil as Date
    const form: GiftCardCreateForm = {
      ...$accessor.giftCards.createForm,
      totalAmount: $toCent($accessor.giftCards.createForm.totalAmount),
      validUntil: $dateTime.fromJSDate(date).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT).replace('.', ''),
    }
    await $accessor.giftCards.postOne(form)
    if (router) {
      router.push(localePath('marketplace-giftCards'))
    }
  }

  async function downloadCsvExport() {
    return $accessor.giftCards.downloadCsvExport()
  }

  async function patchGiftCard(id: number) {
    return $accessor.giftCards.patchOne(id)
  }

  async function deleteGiftCard(id: number) {
    await $accessor.giftCards.deleteOne(id)
  }

  return {
    fetchGiftCard,
    currentGiftCard,
    getGiftCardField,
    patchGiftCard,
    deleteGiftCard,
    getCreateGiftCardFormField,
    postGiftCard,
    downloadCsvExport,
  }
}

export default useGiftCard
