import { useContext } from '@nuxtjs/composition-api'
import createHmac from 'create-hmac'

interface ImgProxyOptions {
  resizingType: string,
  gravity: string,
  enlarge: number,
  extension: string,
}

interface ImageHook {
  generateSignedUrl(url: string, width?: number | undefined, height?: number | undefined, options?: Partial<ImgProxyOptions>): string
  convertHeicToJpg(file: File): Promise<File>
}

function useImage(): ImageHook {
  const { $config } = useContext()
  const defaultSigningOptions: ImgProxyOptions = {
    resizingType: 'fill',
    gravity: 'sm',
    enlarge: 1,
    extension: 'jpg',
  }

  const imgKey = $config.imgProxyKey
  const imgSalt = $config.imgProxySalt

  function getExtension(url: string, options?: Partial<ImgProxyOptions>) {
    if (options?.extension) {
      return options.extension
    }
    const ext = url.substr(url.lastIndexOf('.') + 1)
    return ext === 'jpeg' ? 'jpg' : ext
  }

  function urlSafeBase64(string: string) {
    return Buffer.from(string)
      .toString('base64')
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
  }

  function hexDecode(hex: string) {
    return Buffer.from(hex, 'hex')
  }

  function signUrl(salt: string, target: string, secret: string) {
    const hmac = createHmac('sha256', hexDecode(secret))
    hmac.update(hexDecode(salt))
    hmac.update(target)
    return urlSafeBase64(hmac.digest())
  }

  function generateSignedUrl(url: string, width = 300, height = 300, options?: Partial<ImgProxyOptions>) {
    const opts = {
      ...defaultSigningOptions,
      ...options,
      extension: getExtension(url, options),
    }
    const encodedUrl = urlSafeBase64(url)
    const path = `/${opts.resizingType}/${width}/${height}/${opts.gravity}/${opts.enlarge}/${encodedUrl}.${opts.extension}`
    const signature = signUrl(imgSalt, path, imgKey)
    return `${$config.imgProxyUrl}/${signature}${path}`
  }

  async function convertHeicToJpg(file: File) {
    // We import this here to make sure we're actually in a browser since importing it in a server context breaks the app.
    const heic2any = (await import('heic2any')).default

    const fileBlob = await heic2any({ blob: file, toType: 'image/jpeg' }) as File
    const newFile = new File([fileBlob], `${file.name}.jpg`, { type: 'image/jpeg' })

    // transfer current file details
    // @ts-expect-error really don't want to override typings of VueDropzone
    newFile.accepted = file.accepted
    // @ts-expect-error really don't want to override typings of VueDropzone
    newFile.processing = file.processing
    // @ts-expect-error really don't want to override typings of VueDropzone
    newFile.status = file.status
    // @ts-expect-error really don't want to override typings of VueDropzone
    newFile.upload = file.upload

    // Change the upload file name to reflect the converted details
    // @ts-expect-error really don't want to override typings of VueDropzone
    newFile.upload.filename = newFile.name

    return newFile
  }

  return { generateSignedUrl, convertHeicToJpg }
}

export default useImage
