import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import { defineComponent, reactive, toRefs, useContext } from '@nuxtjs/composition-api';
import { CalendarExternalSourceSyncStatusEnum } from '~/types/models';
import useCalendarExternalSources from '~/composable/useCalendarExternalSources';
import IcalImportLine from '~/components/icals/IcalImportLine.vue';
export default defineComponent({
  name: 'IcalDisplayLine',
  components: {
    IcalImportLine: IcalImportLine
  },
  props: {
    calendarExternalSource: {
      type: Object,
      required: true
    },
    isInDrawer: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(_props, _ref) {
    var emit = _ref.emit,
        $buefy = _ref.root.$buefy;

    var _useContext = useContext(),
        _useContext$app = _useContext.app,
        $dateTime = _useContext$app.$dateTime,
        i18n = _useContext$app.i18n;

    var _useCalendarExternalS = useCalendarExternalSources(),
        getIntervalText = _useCalendarExternalS.getIntervalText;

    var _usePermissions = usePermissions(),
        isAdmin = _usePermissions.isAdmin;

    var state = reactive({
      isEditMode: false
    });

    var getSyncStatusClass = function getSyncStatusClass(status) {
      switch (status) {
        case CalendarExternalSourceSyncStatusEnum.SYNC_STATUS_SUCCESS:
          return 'success';

        case CalendarExternalSourceSyncStatusEnum.SYNC_STATUS_FAILED:
          return 'danger';

        default:
          return 'warning';
      }
    };

    var getSyncStatusText = function getSyncStatusText(calendarExternalSource) {
      if (calendarExternalSource.lastSyncStatus === CalendarExternalSourceSyncStatusEnum.SYNC_STATUS_PENDING) {
        return i18n.t('ical.sync_status.sync_status_pending');
      }

      return i18n.t('ical.display.label_sync_status', {
        status: i18n.t("ical.sync_status.".concat(calendarExternalSource.lastSyncStatus.toLowerCase())),
        date: $dateTime.fromISO(calendarExternalSource.lastSync.toString()).toLocaleString($dateTime.DATETIME_SHORT)
      });
    };

    var onEdit = function onEdit() {
      return state.isEditMode = true;
    };

    var onDelete = function onDelete() {
      $buefy.dialog.confirm({
        title: i18n.t('dialog.ical_delete.title').toString(),
        message: i18n.t('dialog.ical_delete.message').toString(),
        confirmText: i18n.t('dialog.delete.confirmText').toString(),
        cancelText: i18n.t('dialog.delete.cancelText').toString(),
        hasIcon: true,
        type: 'is-danger',
        onConfirm: function onConfirm() {
          emit('delete');
        }
      });
    };

    var onSave = function onSave(form) {
      state.isEditMode = false;
      emit('patch', form);
    };

    var onSync = function onSync() {
      return emit('sync');
    };

    return _objectSpread(_objectSpread({}, toRefs(state)), {}, {
      CalendarExternalSourceSyncStatusEnum: CalendarExternalSourceSyncStatusEnum,
      isAdmin: isAdmin,
      getIntervalText: getIntervalText,
      getSyncStatusClass: getSyncStatusClass,
      getSyncStatusText: getSyncStatusText,
      onDelete: onDelete,
      onEdit: onEdit,
      onSave: onSave,
      onSync: onSync
    });
  }
});