import { defineComponent } from '@nuxtjs/composition-api';
import HRSwitch from '../HRSwitch.vue';
import PriceInput from '../content/PriceInput.vue';
import DistanceInput from '../content/DistanceInput.vue';
export default defineComponent({
  name: 'DomainAmenitiesModale',
  components: {
    HRSwitch: HRSwitch,
    PriceInput: PriceInput,
    DistanceInput: DistanceInput
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    amenity: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var form = reactive({
      distanceInMeters: 0,
      id: 1,
      isFree: true,
      isOnSite: true,
      priceFrom: {
        amount: 0,
        currency: '€'
      }
    });

    function submit() {// TODO: handle submit
    }

    return {
      submit: submit,
      form: form
    };
  }
});