import { Middleware } from '@nuxt/types'
import { BookingOption } from '~/types/models'

const bookingOptionsMiddleware: Middleware = async({ app: { $accessor }, route }) => {
  const { options } = $accessor.bookings.getOne(parseInt(route.params.id))
  const missingOptionsIds = options.map((o: BookingOption) => o.option)
    .filter((id: number) => !$accessor.options.getAllIds().includes(id))

  if (missingOptionsIds.length > 0) {
    await $accessor.options.fetchMany(missingOptionsIds)
  }
}

export default bookingOptionsMiddleware
