import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { DateTime } from 'luxon'
import pick from 'lodash/pick'
import { Domain, DomainCreateForm, DomainTimeFields, Period } from '~/types/models'
import state from './state'
import { DateFormatEnum, getFormattedInternationalPhoneNumber } from '~/helpers'

const parseDomain: (domain: Domain) => Domain = (domain) => {
  const parsed: Domain = domain
  const timeFields: Array<keyof DomainTimeFields> = [
    'checkinTimeStart', 'checkinTimeEnd',
    'checkoutTimeStart', 'checkoutTimeEnd',
    'flexibleCheckinTimeStart', 'flexibleCheckinTimeEnd',
    'flexibleCheckoutTimeStart', 'flexibleCheckoutTimeEnd',
  ]

  timeFields.forEach(field => {
    parsed[field] = DateTime.fromISO(domain[field] as string, { zone: 'utc' })
      .toFormat(DateFormatEnum.HOURS_MINUTES_SECONDES)
  })
  return parsed
}

export const actions = actionTree({ state }, {
  ...createActions<Domain>(),

  async fetchAll({ commit }): Promise<void> {
    try {
      const res: { data: Domain[] } = await this.app.$api.get('domains')
      if (res.data.length) {
        const claimAddresses = res.data
          .map((domain: Domain) => {
            if (domain.metadata.claimAddress) {
              return domain.metadata.claimAddress
            }
          })
          .filter((value) => value !== undefined)
        await this.app.$accessor.addresses.fetchMany(claimAddresses as number[])
        commit(BaseMutationTypes.CREATE_MANY, res.data.map((domain: Domain) => parseDomain(domain)))
      }
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.domain_fetching'))
    }
  },

  async fetchMany({ commit }, domainIds: number[]): Promise<void> {
    if (domainIds.length > 0) {
      try {
        const ids = domainIds.join()
        const res: { data: Domain[] } = await this.app.$api.get(`domains?filters[id_in]=${ids}&limit=${domainIds.length}`)
        if (res.data.length) {
          const claimAddresses = res.data
            .map((domain: Domain) => {
              if (domain.metadata.claimAddress) {
                return domain.metadata.claimAddress
              }
            })
            .filter((value) => value !== undefined)
          await this.app.$accessor.addresses.fetchMany(claimAddresses as number[])
          commit(BaseMutationTypes.CREATE_MANY, res.data.map((domain: Domain) => parseDomain(domain)))
        }
      } catch (error) {
        this.app.$errorToast(this.app.i18n.t('toast.domain_fetching'))
      }
    }
  },

  async fetchOne({ commit }, id: number) {
    try {
      const domain: Domain = await this.app.$api.get(`domains/${id}`)
      if (domain.metadata.claimAddress) {
        await this.app.$accessor.addresses.fetchOne(domain.metadata.claimAddress)
      }
      if (domain) {
        commit(BaseMutationTypes.CREATE, parseDomain(domain))
        commit(BaseMutationTypes.RESET_ACTIVE)
        return commit(BaseMutationTypes.SET_ACTIVE, domain.id)
      }
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.domain_fetching'))
    }
  },

  async patchOne({ commit }, id: number) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.domain_updating'))
    const { $dateTime } = this.app
    try {
      let domain: Domain = this.app.$accessor.domains.getOne(id)
      // Clean internal keys (starting with '$').
      const payload = pick(domain, Object.keys(domain)
        .filter(k => k[0] !== '$' && k !== 'externalId'))

      if (payload.openingPeriods && payload.openingPeriods.length > 0) {
        payload.openingPeriods = payload.openingPeriods.map((period: Period) => ({
          start: $dateTime.fromJSDate(new Date(period.start.toString()))
            .toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
          end: $dateTime.fromJSDate(new Date(period.end.toString()))
            .toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
        }))
      }

      if (payload.smsPhone) {
        payload.smsPhone = getFormattedInternationalPhoneNumber(payload.smsPhone)
      }

      if (payload.bookingPhone) {
        payload.bookingPhone = getFormattedInternationalPhoneNumber(payload.bookingPhone)
      }

      domain = await this.app.$api.patch(`domains/${id}`, payload)
      commit(BaseMutationTypes.UPDATE, {
        id,
        payload: parseDomain(domain),
      })

      if (payload.metadata?.claimAddress) {
        await this.app.$accessor.addresses.patchOne({ id: payload.metadata.claimAddress })
      } else if (this.app.$accessor.addresses.getCreateFormField('$isDirty')) {
        const address = await this.app.$accessor.addresses.postOne()
        if (address) {
          const domain = {
            ...payload,
            metadata: {
              ...payload.metadata,
              claimAddress: address.id,
            },
          }
          await this.app.$api.patch(`domains/${id}`, domain)
        }
      }

      if (payload.address) {
        await this.app.$accessor.addresses.patchOne({ id: payload.address })

      } else if (this.app.$accessor.addresses.getCreateFormField('$isDirty')) {
        const address = await this.app.$accessor.addresses.postOne()

        if (address) {
          const domain = {
            ...payload,
            address: address.id,
          }
          await this.app.$api.patch(`domains/${id}`, domain)
        }
      }
      const isAdmin = this.app.$accessor.users.currentUserIsAdmin
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t(isAdmin ? 'toast.domain_updated' : 'toast.domain_updated_provider'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.domain_update_error'))
    }
  },

  async postOne({ commit }, { providerId, domain }: { providerId: number, domain: DomainCreateForm }): Promise<Domain | null> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.domain_creating'))
    try {
      const data = {
        ...domain,
        provider: providerId,
      }

      const res: Domain = await this.app.$api.post('domains', data)
      commit(BaseMutationTypes.CREATE, parseDomain(res))
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.domain_created'))
      return res
      // TODO: Find a way to properly type errors in try/catch blocks
    } catch (error: any) {
      toast.goAway(0)
      this.app.$errorToast(`${this.app.i18n.t('toast.domain_create_error')} :
        ${error.response?.data?.message}
      `)
      return null
    }
  },

  async moderateOne({ commit }, { id, isApproved }: { id: number, isApproved: boolean }) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.moderation_submitted'))

    try {
      const domain: Domain = await this.app.$api.post(`moderation/domain/${id}`, { isApproved })
      commit(BaseMutationTypes.UPDATE, {
        id,
        payload: parseDomain(domain),
      })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.moderation_success'))
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.moderation_error'))
    }
  },
})

export default actions
