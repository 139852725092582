



















import { computed, defineComponent } from '@nuxtjs/composition-api'
import CalendarDrawer from '~/components/calendar/drawers/CalendarDrawer.vue'
import ServicePricingCreationForm from '~/components/calendar/servicePricing/ServicePricingCreationForm.vue'
import useCalendar from '~/composable/useCalendar'
import { DrawerDisplayModeEnum } from '~/store/calendar/types'
import { CalendarDrawerNameEnum } from '~/types'

export default defineComponent({
  name: 'CalendarDefaultPricingDrawer',
  components: {
    CalendarDrawer,
    ServicePricingCreationForm,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { ui } = useCalendar()

    function onClose() {
      emit('on-close')
    }

    const isDrawerOpen = computed(() => ui.value.drawerDisplayMode === DrawerDisplayModeEnum.CREATE_DEFAULT && ui.value.drawer === CalendarDrawerNameEnum.DEFAULT_PRICING)

    function onClickOutside() {
      emit('on-click-outside')
    }

    return {
      onClose,
      onClickOutside,
      isDrawerOpen,
    }
  },
})
