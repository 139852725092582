






































































































import { defineComponent, reactive, useContext } from '@nuxtjs/composition-api'
import { CurrencyEnum } from '~/types/models'
import PriceInput from '~/components/content/PriceInput.vue'

export default defineComponent({
  name: 'UpdateSpendingModal',
  components: { PriceInput },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    spending: {
      type: Object,
      required: true,
    },
    giftCardId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { app: { $api, i18n, $successToast, $loadingToast, $errorToast } } = useContext()

    const form = reactive({
      booking: props.spending.booking,
      amountSpent: {
        amount: props.spending.amountSpent.amount,
        currency: CurrencyEnum.CURRENCY_EURO,
      },
      isUnicstayPaid: props.spending.isUnicstayPaid,
    })

    async function submit() {
      const toast = $loadingToast(i18n.t('toast.spending_updating'))
      try {
        await $api.patch(`giftcardspendings/${props.spending.id}`, form)
        toast.goAway(0)
        $successToast(i18n.t('toast.spending_updated'))
        emit('update:active')
        emit('spending-updated')
      } catch (error) {
        toast.goAway(0)
        $errorToast(i18n.t('toast.spending_update_error'))
      }
    }

    return {
      form,
      submit,
    }
  },
})
