import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api'

export interface LoginCredentials {
  email: string
  password: string
  remember: boolean
}

export interface ResetPasswordForm {
  email: string | (string | null)[]
  password: string
  password_confirmation: string // eslint-disable-line camelcase
  token: string | (string | null)[]
}

export interface AuthHook {
  isLoggedIn: ComputedRef<boolean>

  login(credentials: LoginCredentials): Promise<void>

  logout(): Promise<void>

  sendResetPasswordEmail(email: string): Promise<void>

  resetPassword(form: ResetPasswordForm): Promise<void>
}

function useAuth(): AuthHook {
  const { app: { $accessor, router, localePath } } = useContext()

  const isLoggedIn = computed(() => $accessor.auth.getIsLoggedIn)

  async function login(credentials: LoginCredentials) {
    await $accessor.auth.login(credentials)

    if ($accessor.users.currentUserIsAdmin && router) {
      await $accessor.marketplaces.fetchAll()
      router.push(localePath('marketplace'))
    } else if ($accessor.users.currentUserIsProvider && router) {
      await $accessor.marketplaces.fetchAll()
      await $accessor.providers.fetchAll()
      await $accessor.domains.fetchAll()
      const firstDomain = $accessor.domains.getFirst()
      const { currentProvider } = $accessor.providers

      if (firstDomain && currentProvider) {
        $accessor.domains.setActive([firstDomain.id])
        if (firstDomain.services.length > 0) {
          $accessor.calendar.UI_TOGGLE_ALL_SERVICES_IDS_FILTER(firstDomain.services)
          await $accessor.services.fetchMany(firstDomain.services)
        }
        router.push(localePath({
          name: 'home',
          params: {
            provider: currentProvider.id.toString(),
            domain: firstDomain.id.toString(),
          },
        }))
      }
    }
  }

  function logout() {
    return $accessor.auth.logout()
  }

  async function sendResetPasswordEmail(email: string) {
    await $accessor.auth.sendResetPasswordEmail(email)
    if (router) {
      router.push(localePath({ name: 'auth-login' }))
    }
  }

  async function resetPassword(form: ResetPasswordForm) {
    await $accessor.auth.resetPassword(form)
  }

  return {
    isLoggedIn,
    login,
    logout,
    sendResetPasswordEmail,
    resetPassword,
  }
}

export default useAuth
