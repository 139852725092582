


















































import { CalendarShowDaysEnum } from '~/types'
import { defineComponent } from '@nuxtjs/composition-api'
import useCalendar from '~/composable/useCalendar'
import useMq from '~/composable/useMq'

export default defineComponent({
  name: 'CalendarHeader',
  setup() {
    const {
      navigatePrev,
      navigateNext,
    } = useCalendar()
    const { isWide } = useMq()

    function getDayByScreenSizeSimpleArrow() {
      if (isWide.value) {
        return CalendarShowDaysEnum.TOUCH
      } else {
        return 1
      }
    }

    function getDayByScreenSizeDoubleArrow() {
      if (isWide.value) {
        return CalendarShowDaysEnum.TWO_WEEKS
      } else {
        return CalendarShowDaysEnum.TOUCH
      }

    }

    return {
      getDayByScreenSizeSimpleArrow,
      getDayByScreenSizeDoubleArrow,
      navigatePrev,
      navigateNext,
    }
  },
})
