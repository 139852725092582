import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import AppContentHeading from '~/components/content/AppContentHeading.vue';
import ServiceListingItem from '~/components/service/ServiceListingItem.vue';
import CreateServiceModal from '~/components/service/CreateServiceModal.vue';
export default defineComponent({
  name: 'ListingPage',
  components: {
    AppContentHeading: AppContentHeading,
    ServiceListingItem: ServiceListingItem,
    CreateServiceModal: CreateServiceModal
  },
  middleware: ['servicesListing', 'defaultServicePricingsMiddleware'],
  setup: function setup() {
    var _useContext = useContext(),
        $accessor = _useContext.app.$accessor;

    var _useDomain = useDomain(),
        getDomainTranslation = _useDomain.getDomainTranslation,
        currentDomain = _useDomain.currentDomain;

    var servicesForCurrentDomain = computed(function () {
      return $accessor.services.getWhereArray(function (service) {
        return service.domain === currentDomain.value.id;
      });
    });

    function getServiceMainPicture(service) {
      return $accessor.entityFiles.getWhereArray(function (file) {
        return service.files.includes(file.id);
      }).find(function (file) {
        return file.idx === 0;
      });
    }

    return {
      servicesForCurrentDomain: servicesForCurrentDomain,
      currentDomain: currentDomain,
      getDomainTranslation: getDomainTranslation,
      getServiceMainPicture: getServiceMainPicture
    };
  },
  data: function data() {
    return {
      isCreateRentalModalActive: false,
      modifyPrice: '',
      modifyStay: '',
      bulkEdit: false
    };
  }
});