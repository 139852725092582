import { createState, createEntity } from '@kissmylabs/vuex-entitystore'
import { Notification, NotificationTypeEnum } from '~/types/models'
import { NotificationState } from './types'

export const notification = createEntity<Notification>('notification')

function createTypeFiltersState() {
  const stateTypeFilters: Record<string, boolean>  = {}
  Object.values(NotificationTypeEnum).forEach(filter => stateTypeFilters[filter] = true)
  return stateTypeFilters
}

export const state = (): NotificationState  => ({
  ...createState<Notification>(notification),
  filters: createTypeFiltersState(),
})

export default state
