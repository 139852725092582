import { createState, createEntity } from '@kissmylabs/vuex-entitystore'
import { Option, OptionFeeTypeEnum, OptionsCategoriesEnum, CurrencyEnum } from '~/types/models'
import { defaultEmptyTranslation } from '~/helpers/locale'
import { OptionCreateForm, OptionState } from './types'

export const option = createEntity<Option>('option')

export const baseCreateForm = (): OptionCreateForm => ({
  domain: 0,
  label: '',
  name: defaultEmptyTranslation(),
  description: defaultEmptyTranslation(),
  min: 0,
  max: 0,
  periods: [],
  isRecurringPeriod: false,
  feeType: OptionFeeTypeEnum.FLAT,
  fee: {
    amount: 0,
    currency: CurrencyEnum.CURRENCY_EURO,
  },
  appliesOnServices: [],
  minPreBookingDays: 0,
  isActive: true,
  category: OptionsCategoriesEnum.GENERAL,
})

export const state = (): OptionState => ({
  ...createState<Option>(option),
  createForm: baseCreateForm(),
})

export default state
