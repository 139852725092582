
































































































import { defineComponent, onMounted, PropType, reactive } from '@nuxtjs/composition-api'
import { DiscountTypeEnum, NewBookingDiscount } from '~/types/models/BookingDiscount'
import SymbolInput from '~/components/content/SymbolInput.vue'
import { CurrencyEnum } from '~/types/models'

interface State {
  bookingDiscounts: NewBookingDiscount[]
}

export default defineComponent({
  name: 'BookingDiscountInput',
  components: { SymbolInput },
  props: {
    value: {
      type: Array as PropType<NewBookingDiscount[]>,
      required: true,
    },
    defaultAmount: {
      type: Number,
      required: false,
      default: 30,
    },
    maxAmount: {
      type: Number,
      required: false,
      default: Number.MAX_SAFE_INTEGER,
    },
    maxPercent: {
      type: Number,
      required: false,
      default: 100,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive<State>({ bookingDiscounts: [...props.value] })

    onMounted(() => {
      if (state.bookingDiscounts.length === 0) {
        onAddBookingDiscount()
      }
    })

    const onFieldUpdated = (value: string, index: number, field: 'amount' | 'percent', validate: (_value: number) => boolean, valid: boolean) => {
      const parsedValue = parseFloat(value)
      if (parsedValue === 0 || isNaN(parsedValue)) {
        state.bookingDiscounts[index][field] = 0
      } else {
        state.bookingDiscounts[index][field] = parsedValue
      }
      validate(parsedValue)
      emit('input', state.bookingDiscounts)
      emit('validity-change', valid)
    }

    const isDisabledField = (index: number, field: 'amount' | 'percent') => {
      return field === 'amount'
        ? state.bookingDiscounts[index].percent !== 0
        : state.bookingDiscounts[index].amount !== 0
    }

    const onAddBookingDiscount = () => {
      // Immutable method to avoid mutating any Vuex state when mutating the prop
      state.bookingDiscounts = [...state.bookingDiscounts, {
        discountType: DiscountTypeEnum.BOOKING_GLOBAL,
        currency: CurrencyEnum.CURRENCY_EURO,
        amount: props.defaultAmount,
        percent: 0,
      }]
      emit('input', state.bookingDiscounts)
    }

    const onRemoveBookingDiscount = (index: number) => {
      // Immutable method to avoid mutating any Vuex state when mutating the prop
      state.bookingDiscounts = state.bookingDiscounts.filter((_, idx) => idx !== index)
      emit('input', state.bookingDiscounts)
    }

    return {
      state,
      onFieldUpdated,
      isDisabledField,
      DiscountTypeEnum,
      onAddBookingDiscount,
      onRemoveBookingDiscount,
    }
  },
})
