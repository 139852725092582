




















































import { CalendarEntitiesEnum } from '~/types'
import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import useCalendar from '~/composable/useCalendar'
import CalendarEntitiesDeleteManyForm from '~/components/calendar/CalendarEntitiesDeleteManyForm.vue'

export default defineComponent({
  name: 'CalendarAccordionDeleteManyForms',
  components: {
    CalendarEntitiesDeleteManyForm,
  },
  setup() {
    const { isCalendarUserEnabled } = useCalendar()

    const activeEntity = ref<CalendarEntitiesEnum>(CalendarEntitiesEnum.UNAVAILABILITIES)

    const isFormOpen = (active: CalendarEntitiesEnum) => computed(() => {
      if (active === CalendarEntitiesEnum.UNAVAILABILITIES) {
        return activeEntity.value === CalendarEntitiesEnum.UNAVAILABILITIES
      } else {
        return activeEntity.value === active && isCalendarUserEnabled.value
      }
    })

    return {
      activeEntity,
      CalendarEntitiesEnum,
      isCalendarUserEnabled,
      isFormOpen,
    }
  },
})
