import usersMutations from './mutations'
import usersGetters from './getters'
import usersActions from './actions'
import usersState from './state'

export const users = {
  namespaced: true,
  state: usersState,
  actions: usersActions,
  mutations: usersMutations,
  getters: usersGetters,
}
