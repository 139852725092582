import { WeekDaysEnum } from '~/helpers'
import { CalendarDrawerNameEnum, CalendarEntitiesEnum, CalendarFiltersEnum, CalendarRecurrenceValueEnum, CalendarShowDaysEnum, CalendarViewsEnum } from '~/types'
import { Booking, BookingConditionCalendar, Period, ServiceDiscountCalendar, ServicePricingCalendar, ServiceUnitUnavailabilityCalendar, Unavailability } from '~/types/models'

export interface ServiceEvent {
  offer: number,
  unavailabilities: any[]
}

export interface ServiceEvents {
  [key: string]: ServiceEvent
}

export interface ServiceEventsList {
  [key: string]: ServiceEvents
}

export enum CalendarMutationTypes {
  APPEND_SERVICE_EVENTS = 'APPEND_SERVICE_EVENTS',
  REFRESH_DATA = 'REFRESH_DATA',
  RESET_COMMON_FORM = 'RESET_COMMON_FORM',
  RESET_CURRENT_UNAVAILABILITY = 'RESET_CURRENT_UNAVAILABILITY',
  RESET_FILTERS = 'RESET_FILTERS',
  RESET_STATE = 'RESET_STATE',
  RESET_UI_DRAWER = 'RESET_UI_DRAWER',
  SET_BOOKING_CONDITIONS_CALENDAR = 'SET_BOOKING_CONDITIONS_CALENDAR',
  SET_CURRENT_UNAVAILABILITY = 'SET_CURRENT_UNAVAILABILITY',
  SET_DATE = 'SET_DATE',
  SET_DISCOUNTS_CALENDAR = 'SET_DISCOUNTS_CALENDAR',
  SET_MANY_ALL_ENTITIES = 'SET_MANY_ALL_ENTITIES',
  SET_MANY_BOOKING_CONDITIONS_CALENDAR = 'SET_MANY_BOOKING_CONDITIONS_CALENDAR',
  SET_MANY_DISCOUNTS_CALENDAR = 'SET_MANY_DISCOUNTS_CALENDAR',
  SET_MANY_SERVICE_PRICINGS_CALENDAR = 'SET_MANY_SERVICE_PRICINGS_CALENDAR',
  SET_MANY_UNAVAILABILITY_CALENDAR = 'SET_MANY_UNAVAILABILITY_CALENDAR',
  SET_SERVICE_EVENTS = 'SET_SERVICE_EVENTS',
  SET_SERVICE_PRICINGS_CALENDAR = 'SET_SERVICE_PRICINGS_CALENDAR',
  SET_SHOW_DAYS = 'SET_SHOW_DAYS',
  SET_UI_DATA = 'SET_UI_DATA',
  SET_UNAVAILABILITIES = 'SET_UNAVAILABILITIES',
  SET_UNAVAILABILITY_CALENDAR = 'SET_UNAVAILABILITY_CALENDAR',
  UDPATE_SELECTED_PERIODS = 'UDPATE_SELECTED_PERIODS',
  UI_CLOSE_DRAWER = 'UI_CLOSE_DRAWER',
  UI_DRAWER_LOADING_END = 'UI_DRAWER_LOADING_END',
  UI_DRAWER_LOADING_START = 'UI_DRAWER_LOADING_START',
  UI_LOADING_END = 'UI_LOADING_END',
  UI_LOADING_START = 'UI_LOADING_START',
  UI_OPEN_DRAWER = 'UI_OPEN_DRAWER',
  UI_TOGGLE_ALL_SERVICES_IDS_FILTER = 'UI_TOGGLE_ALL_SERVICES_IDS_FILTER',
  UI_TOGGLE_FILTER = 'UI_TOGGLE_FILTER',
  UI_TOGGLE_FILTER_GROUP = 'UI_TOGGLE_FILTER_GROUP',
  UI_TOGGLE_FULLSCREEN = 'UI_TOGGLE_FULLSCREEN',
  UI_TOGGLE_MODAL = 'UI_TOGGLE_MODAL',
  UI_TOGGLE_SERVICE_ON_FILTER = 'UI_TOGGLE_SERVICE_ON_FILTER',
  UI_TOGGLE_VIEW = 'UI_TOGGLE_VIEW',
  UPDATE_ENTITY_FORM_OPENED = 'UPDATE_ENTITY_FORM_OPENED',
  UPDATE_SELECTED_RECURRENCE = 'UPDATE_SELECTED_RECURRENCE',
  UPDATE_SELECTED_SERVICES = 'UPDATE_SELECTED_SERVICES',
  UPDATE_SELECTED_UNITS = 'UPDATE_SELECTED_UNITS',
  UPDATE_SELECTED_WEEKDAYS = 'UPDATE_SELECTED_WEEKDAYS',
  UPDATE_UNAVAILABILITY_CALENDAR = 'UPDATE_UNAVAILABILITY_CALENDAR',
  UPDATE_DRAWER_DATA = 'UPDATE_DRAWER_DATA',
  SET_DISABLED_SERVICES = 'SET_DISABLED_SERVICES'
}

export enum DrawerDisplayModeEnum {
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  CREATE = 'create',
  CREATE_DEFAULT = 'create_default',
}

export interface CalendarUiState {
  isFullScreen: boolean,
  filters: Record<keyof typeof CalendarFiltersEnum, boolean>,
  views: CalendarViewsEnum
  servicesFilters: number[]
  loading: boolean,
  drawerLoading: boolean,
  isDrawerOpened: boolean,
  drawerDisplayMode: DrawerDisplayModeEnum | null,
  drawer: CalendarDrawerNameEnum | null,
  drawerData: Record<string, any>
  entityFormOpened: CalendarEntitiesEnum
}

export interface CalendarState {
  unavailabilityCalendars: ServiceUnitUnavailabilityCalendar[]
  servicePricingCalendars: ServicePricingCalendar[],
  bookingConditionCalendars: BookingConditionCalendar[],
  serviceDiscountCalendars: ServiceDiscountCalendar[],
  serviceEvents: ServiceEventsList,
  date: Date,
  disabledServices: number[]
  currentBooking: Booking | null,
  currentUnavailability: Unavailability | null,
  showDays: CalendarShowDaysEnum,
  ui: CalendarUiState,
  commonForm: {
    selectedPeriods: Period[]
    selectedServices: number[]
    selectedUnits: number[]
    selectedWeekdays: WeekDaysEnum[]
    numberYearsRecurrence: CalendarRecurrenceValueEnum
  }
}
