





































import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import ContactToggleButton from '~/components/buttons/ContactToggleButton.vue'
import { Booking, Customer } from '~/types/models'

export default defineComponent({
  name: 'CustomerContactActions',
  components: { ContactToggleButton },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true,
    },
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
    isInline: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { app: { $accessor } } = useContext()
    const provider = computed(() => $accessor.providers.getOne(props.booking.provider))
    return {
      provider,
    }
  },
})
