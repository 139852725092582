import { computed, ComputedRef, getCurrentInstance, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import { EntityFile, ServiceEquipmentTypeEnum, ServicePricing, ServiceExternalId } from '~/types/models'
import orderBy from 'lodash/orderBy'
import { decode } from 'universal-base64'
import useFiles from '~/composable/useFiles'

interface ServiceHook {
  addCurrentServiceFile(file: EntityFile): void
  canAccomodateEnoughPeople(serviceId: number, people: number): boolean
  currentServiceId: ComputedRef<number | null>
  currentServicePhotos: WritableComputedRef<EntityFile[]>
  deleteManyServicePictures: (fileArray: EntityFile[]) => Promise<void>
  deleteServicePicture(serviceId: number, fileId: number): Promise<void>
  deleteOne(id: number): Promise<void>
  duplicateOne(id: number): Promise<void>
  getCalendarDisabledServices: WritableComputedRef<number[]>
  getCurrentServiceEquipmentMutex: (mutex: ServiceEquipmentTypeEnum[]) => WritableComputedRef<number>
  getCurrentServiceEquipmentsByType: (type: ServiceEquipmentTypeEnum) => WritableComputedRef<number[]>
  getCurrentServiceField: (field: string) => WritableComputedRef<any>
  getCurrentServiceFile: (id: number) => WritableComputedRef<any>
  getCurrentServiceUniqueEquipmentByType: (type: ServiceEquipmentTypeEnum) => WritableComputedRef<number>
  getServiceExternalIdObject: ComputedRef<ServiceExternalId | null>
  getServiceFieldByServiceId: (id: number, field: string) => WritableComputedRef<any>
  getServiceFileByServiceId: (fileId: number, serviceId: number) => WritableComputedRef<EntityFile>
  getServiceIdByUnitId(unitId: number): number
  hasDefaultPricing(serviceId: number): ServicePricing | null
  orderableServicePhotos: WritableComputedRef<EntityFile[]>
  patchService(id: number): Promise<void>
}

function useServices(): ServiceHook {
  const { app, route, params, $config } = useContext()
  const { $errorToast, $successToast, $loadingToast, $accessor } = app
  const { getFileField } = useFiles()

  const currentServiceId = computed(() => route.value.matched.some(r => r.meta.isServiceDetails) ? parseInt(params.value.id) : null)
  const currentInstance = getCurrentInstance()

  function getServiceIdByUnitId(unitId: number) {
    return $accessor.serviceUnits.getOne(unitId).service
  }

  const getServiceFieldByServiceId: (id: number, field: string) => WritableComputedRef<any> = (id: number, field: string) => computed({
    get: () => $accessor.services.getField(id)(field),
    set: val => {
      // TODO DRY this
      if (!$accessor.services.getField(id)('$isDirty') && field !== '$isDirty') {
        $accessor.services.UPDATE_FIELD({
          id,
          path: '$isDirty',
          value: true,
        })
      }
      return $accessor.services.UPDATE_FIELD({
        id,
        path: field,
        value: val,
      })
    },
  })

  const getServiceFileByServiceId: (fileId: number, serviceId: number) => WritableComputedRef<EntityFile> = (fileId: number, _serviceId: number) => computed({
    get: () => $accessor.entityFiles.getOne(fileId),
    set: (val: any) => {
      if (val && typeof val !== 'number') {
        return $accessor.entityFiles.UPDATE({
          id: fileId,
          payload: val,
        })
      }
    },
  })

  const getCurrentServiceField: (field: string) => WritableComputedRef<any> = (field: string) => computed({
    get: () => getServiceFieldByServiceId(currentServiceId.value!, field).value,
    set: val => getServiceFieldByServiceId(currentServiceId.value!, field).value = val,
  })


  const getCurrentServiceFile: (id: number) => WritableComputedRef<any> = (id: number) => computed({
    get: () => getServiceFileByServiceId(id, currentServiceId.value!).value,
    set: (val: any) => getServiceFileByServiceId(id, currentServiceId.value!).value = {
      ...val,
      $isDirty: true,
    },
  })

  const getCalendarDisabledServices = computed({
    get: () => $accessor.calendar.disabledServices,
    set: (val) => $accessor.calendar.SET_DISABLED_SERVICES(val),
  })

  function addCurrentServiceFile(file: EntityFile) {
    $accessor.entityFiles.CREATE(file)
    getCurrentServiceField('files').value = [
      ...getCurrentServiceField('files').value,
      file.id,
    ]
  }

  async function deleteServicePicture(serviceId: number, fileId: number): Promise<void> {

    if (currentInstance) {
      currentInstance.$buefy.dialog.confirm({
        title: app.i18n.t('dialog.picture_delete.title').toString(),
        message: app.i18n.t('dialog.picture_delete.message').toString(),
        confirmText: app.i18n.t('dialog.picture_delete.confirmText').toString(),
        cancelText: app.i18n.t('dialog.picture_delete.cancelText').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          await deleteServiceFile(serviceId, fileId)
          if (currentServicePhotos.value.length > 0) {
            await $accessor.entityFiles.resetIdx(currentServicePhotos.value)
          }
        },
      })
    }
  }

  async function deleteManyServicePictures(fileArray: EntityFile[]): Promise<void> {
    if (currentInstance) {
      currentInstance.$buefy.dialog.confirm({
        title: app.i18n.t('dialog.pictures_delete_many.title').toString(),
        message: app.i18n.t('dialog.pictures_delete_many.message').toString(),
        confirmText: app.i18n.t('dialog.pictures_delete_many.confirmText').toString(),
        cancelText: app.i18n.t('dialog.pictures_delete_many.cancelText').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          if (currentServiceId.value) {
            // TS dont reconize currentServiceId.value is check before fonction => force his value in number
            await Promise.all(fileArray.map(file => deleteServiceFile(currentServiceId.value!, file.id)))
            if (currentServicePhotos.value.length > 0) {
              await $accessor.entityFiles.resetIdx(currentServicePhotos.value)
            }
          }
        },
      })
    }
  }


  async function deleteServiceFile(serviceId: number, id: number) {
    await $accessor.entityFiles.deleteFile(id)
    const domainFiles: number[] = getServiceFieldByServiceId(serviceId, 'files').value
    getServiceFieldByServiceId(serviceId, 'files').value = domainFiles.filter((file: number) => file !== id)
  }

  const currentServicePhotos: WritableComputedRef<EntityFile[]> = computed({
    get: () => orderBy(
      $accessor.entityFiles.getWhereArray((file) => file.service === parseInt(params.value.id)),
      'idx',
    ),
    set: (values: EntityFile[]) => {
      const valuesWithIdxOrdered = values.map((item, index) => ({
        ...item,
        $isDirty: true,
        idx: index,
      }))
      valuesWithIdxOrdered.forEach(file => getFileField(file.id, 'idx').value = file.idx)
    },
  })

  /**
   * This looks a lot like currentServicePhotos, the only difference is that is does not flag the file as '$dirty'
   * when setting a value. Its used to prevent enabling patching the Service when re-ordering file, since this is
   * done in a separate request and should not trigger a PATCH of the file itself.
   */
  const orderableServicePhotos: WritableComputedRef<EntityFile[]> = computed({
    get: () => orderBy(
      $accessor.entityFiles.getWhereArray((file) => file.service === parseInt(params.value.id)),
      'idx',
    ),
    set: (values: EntityFile[]) => {
      const valuesWithIdxOrdered = values.map((item, index) => ({
        ...item,
        idx: index,
      }))
      valuesWithIdxOrdered.forEach(file => $accessor.entityFiles.UPDATE_FIELD({
        id: file.id,
        path: 'idx',
        value: file.idx,
      }))
    },
  })

  const getCurrentServiceEquipmentsByType = (type: ServiceEquipmentTypeEnum) => computed({
    get: () => getCurrentServiceField('serviceEquipments').value.filter(
      (eqId: number) => $accessor.serviceEquipments.getOne(eqId).type === type,
    ),
    set: (val) => {
      // Remove potential null values that can be set by <ServiceEquipmentTypeSelect />
      // when not required
      const withoutNulls = val.filter((item: number | null) => item !== null)
      // Get all current service equipments that are not of this type.
      const otherEquipmentsIds = getCurrentServiceField('serviceEquipments').value.filter(
        (eqId: number) => $accessor.serviceEquipments.getOne(eqId).type !== type,
      )
      return getCurrentServiceField('serviceEquipments').value = [
        ...otherEquipmentsIds,
        ...withoutNulls,
      ]
    },
  })

  /**
   * Writable computed handling service equipments having a uniqueness constraint.
   * @param type
   */
  const getCurrentServiceUniqueEquipmentByType = (type: ServiceEquipmentTypeEnum) => computed({
    get: () => {
      const currentValue = getCurrentServiceEquipmentsByType(type).value
      // Sanity check so that we know when values are messy.
      if (currentValue.length > 1 && $config.environment !== 'production') {
        // TODO remove soon
        // eslint-disable-next-line no-console
        console.warn(`getCurrentServiceUniqueEquipmentByType error: got multiple (${currentValue.length}) ${type} values.`)
      }
      return currentValue[0]
    },
    set: (val) => {
      // Same check to avoid setting multiple values (here we don't set it at all to avoid fucking the data even more).
      if (Array.isArray(val) && val.length > 1) {
        if ($config.environment !== 'production') {
          // TODO remove soon
          // eslint-disable-next-line no-console
          console.error(`getCurrentServiceUniqueEquipmentByType error: tring to set multiple (${val.length}) ${type} values is forbidden.`)
        }
        return
      }
      return getCurrentServiceEquipmentsByType(type).value = [val]
    },
  })

  const getCurrentServiceEquipmentMutex = (mutex: ServiceEquipmentTypeEnum[]) => computed({
    get: () => {
      const currentValue: number[] = mutex.map(mutexType => getCurrentServiceUniqueEquipmentByType(mutexType).value)
        .filter((val: number | undefined) => typeof val !== 'undefined')

      return currentValue[0]
    },
    set: (val) => {
      const mutexEquipmentIds = $accessor.serviceEquipments.getIdsWhere(
        seq => mutex.includes(seq.type),
      )
      const otherEquipmentsIds = getCurrentServiceField('serviceEquipments').value.filter(
        (eqId: number) => !mutexEquipmentIds.includes(eqId),
      )
      if (val !== null) {
        getCurrentServiceField('serviceEquipments').value = [
          ...otherEquipmentsIds,
          val,
        ]
        return
      }
      getCurrentServiceField('serviceEquipments').value = otherEquipmentsIds
    },
  })

  async function patchService(id: number): Promise<void> {
    const toast = $loadingToast(app.i18n.t('toast.service_updating'))
    try {
      await $accessor.services.patchOne(id)
      toast.goAway(0)
      $successToast(app.i18n.t('toast.service_updated'))
    } catch (error) {
      toast.goAway(0)
      $errorToast(app.i18n.t('toast.service_update_error'))
    }
  }

  const getServiceExternalIdObject = computed(() => {
    if (getCurrentServiceField('isExternal').value && getCurrentServiceField('externalId').value) {
      return JSON.parse(decode(getCurrentServiceField('externalId').value)) as unknown as ServiceExternalId
    }
    return null
  })

  function hasDefaultPricing(serviceId: number) {
    return $accessor.servicePricings.getFirstWhere(pricing => pricing.service === serviceId && pricing.isDefault)
  }

  function canAccomodateEnoughPeople(serviceId: number, people: number) {
    return people <= $accessor.services.getOne(serviceId).maxPeopleAllowed
  }

  function duplicateOne(id: number) {
    return $accessor.services.duplicateOne(id)
  }

  function deleteOne(id: number) {
    return $accessor.services.deleteOne(id)
  }

  return {
    addCurrentServiceFile,
    canAccomodateEnoughPeople,
    currentServiceId,
    currentServicePhotos,
    deleteManyServicePictures,
    deleteServicePicture,
    deleteOne,
    duplicateOne,
    getCalendarDisabledServices,
    getCurrentServiceEquipmentMutex,
    getCurrentServiceEquipmentsByType,
    getCurrentServiceField,
    getCurrentServiceFile,
    getCurrentServiceUniqueEquipmentByType,
    getServiceExternalIdObject,
    getServiceFieldByServiceId,
    getServiceFileByServiceId,
    getServiceIdByUnitId,
    hasDefaultPricing,
    orderableServicePhotos,
    patchService,
  }
}

export default useServices
