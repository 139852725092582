import { CurrencyEnum, DefaultServicePricingCreateForm, DefaultServicePricingEditForm, ServicePricing, ServicePricingCreateForm, ServicePricingEditForm } from '~/types/models'
import { toCent } from '~/helpers/money'

export function formatPricingRates(pricingForm: Omit<ServicePricingCreateForm | ServicePricingEditForm | DefaultServicePricingCreateForm | DefaultServicePricingEditForm, 'isDefault' | 'id' | 'service'>):
  Pick<ServicePricing, 'baseRate' | 'additionalPricePerAdult' | 'additionalPricePerChild' | 'additionalPricePerInfant'> {

  return {
    baseRate: toCent({
      // @todo just like we did with toDateTime(), add a toMoney() helper.
      amount: pricingForm.baseRate as number,
      currency: CurrencyEnum.CURRENCY_EURO,
    }),
    additionalPricePerAdult: toCent({
      amount: pricingForm.additionalPricePerAdult as number,
      currency: CurrencyEnum.CURRENCY_EURO,
    }),
    additionalPricePerChild: toCent({
      amount: pricingForm.additionalPricePerChild as number,
      currency: CurrencyEnum.CURRENCY_EURO,
    }),
    additionalPricePerInfant: toCent({
      amount: pricingForm.additionalPricePerInfant as number,
      currency: CurrencyEnum.CURRENCY_EURO,
    }),
  }
}
