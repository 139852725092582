import giftCardSpendingsMutations from './mutations'
import giftCardSpendingsGetters from './getters'
import giftCardSpendingsActions from './actions'
import giftCardSpendingsState from './state'

export const giftCardSpendings = {
  namespaced: true,
  state: giftCardSpendingsState,
  actions: giftCardSpendingsActions,
  mutations: giftCardSpendingsMutations,
  getters: giftCardSpendingsGetters,
}
