import { createGetters } from '@kissmylabs/vuex-entitystore'
import { getterTree } from 'typed-vuex'
import { getField } from 'vuex-map-fields'
import { DomainMarketplaceConfiguration } from '~/types/models'
import { state } from './state'

export const getters = getterTree(state, {
  ...createGetters<DomainMarketplaceConfiguration>(),
  getField: state => (id: number) => getField(state.entities.byId[id]),
  getOneByMkpId: state => (marketplaceId: number, domainId: number) => Object.values(state.entities.byId)
    .filter(mkp => mkp.domain === domainId && mkp.marketplace === marketplaceId)[0],
  getCreateForm: state => (marketplaceId: number) => state.createForm.filter((form) => form.marketplace === marketplaceId)[0],
  getFormField: state => (marketplaceId: number) => getField(state.createForm.filter((form) => form.marketplace === marketplaceId)[0]),
})
export default getters
