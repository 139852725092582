import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { CalendarDrawerNameEnum, CalendarShowDaysEnum, CalendarViewsEnum, CreateDeleteManyNavEnum } from '~/types';
import { DrawerDisplayModeEnum } from '~/store/calendar/types';
import { computed, defineComponent, onMounted, ref, useContext, watch } from '@nuxtjs/composition-api';
import useCalendar from '~/composable/useCalendar';
import useMq from '~/composable/useMq';
import CalendarTwoMonthView from '~/components/calendar/CalendarTwoMonthView.vue';
import CalendarHeader from '~/components/calendar/CalendarHeader.vue';
import CalendarDayHeader from '~/components/calendar/CalendarDayHeader.vue';
import CalendarService from '~/components/calendar/CalendarService.vue';
import CalendarFilters from '~/components/calendar/CalendarFilters.vue';
export default defineComponent({
  name: 'Calendar',
  components: {
    CalendarFilters: CalendarFilters,
    CalendarTwoMonthView: CalendarTwoMonthView,
    CalendarHeader: CalendarHeader,
    CalendarDayHeader: CalendarDayHeader,
    CalendarService: CalendarService
  },
  props: {
    services: {
      type: Array,
      required: true
    }
  },
  setup: function setup(props) {
    var _useContext = useContext(),
        params = _useContext.params,
        _useContext$app = _useContext.app,
        $accessor = _useContext$app.$accessor,
        router = _useContext$app.router,
        localePath = _useContext$app.localePath;

    var _useCalendar = useCalendar(),
        currentDate = _useCalendar.currentDate,
        getMonthViewTitle = _useCalendar.getMonthViewTitle,
        navigateToJsDate = _useCalendar.navigateToJsDate,
        navigateToToday = _useCalendar.navigateToToday,
        ui = _useCalendar.ui;

    var _useMq = useMq(),
        isWide = _useMq.isWide;

    var _useDomain = useDomain(),
        currentDomain = _useDomain.currentDomain,
        currentDomainIsSh = _useDomain.currentDomainIsSh;

    var _useI18n = useI18n(),
        currentRegionLocale = _useI18n.currentRegionLocale,
        getOrderOfDayInWeek = _useI18n.getOrderOfDayInWeek;

    var isFilterModalOpen = ref(false);
    var enabledServices = computed(function () {
      return props.services;
    });
    var calendarDisabled = computed(function () {
      return enabledServices.value.length === 0 || currentDomainIsSh.value;
    });

    function setCalendarShowDays(isWideScreen) {
      if (isWideScreen) {
        $accessor.calendar.SET_SHOW_DAYS(CalendarShowDaysEnum.WIDE);
      } else {
        $accessor.calendar.SET_SHOW_DAYS(CalendarShowDaysEnum.TOUCH);
      }
    }

    onMounted(function () {
      setCalendarShowDays(isWide.value);
      watch(function () {
        return isWide.value;
      }, function (val) {
        return setCalendarShowDays(val);
      });
    });

    function openCreationDrawer() {
      $accessor.calendar.UDPATE_SELECTED_PERIODS([{
        start: currentDate.value.toJSDate(),
        end: currentDate.value.toJSDate()
      }]);
      $accessor.calendar.UI_OPEN_DRAWER({
        drawer: CalendarDrawerNameEnum.CREATE,
        mode: DrawerDisplayModeEnum.CREATE,
        data: {}
      });
    }

    function createDeleteManyNav(action) {
      $accessor.calendar.UI_TOGGLE_VIEW(CalendarViewsEnum.TWO_MONTHS);

      if (action === CreateDeleteManyNavEnum.CREATE_MANY && router) {
        router.push(localePath({
          name: 'calendar-create-many',
          params: {
            provider: params.value.provider,
            domain: params.value.domain
          }
        }));
      }

      if (action === CreateDeleteManyNavEnum.DELETE_MANY && router) {
        router.push(localePath({
          name: 'calendar-delete-many',
          params: {
            provider: params.value.provider,
            domain: params.value.domain
          }
        }));
      }
    }

    var showService = function showService(serviceId) {
      return computed(function () {
        return ui.value.servicesFilters.includes(serviceId);
      });
    };

    return {
      calendarDisabled: calendarDisabled,
      CalendarViewsEnum: CalendarViewsEnum,
      createDeleteManyNav: createDeleteManyNav,
      CreateDeleteManyNavEnum: CreateDeleteManyNavEnum,
      currentDate: currentDate,
      currentDomain: currentDomain,
      enabledServices: enabledServices,
      getMonthViewTitle: getMonthViewTitle,
      isFilterModalOpen: isFilterModalOpen,
      getOrderOfDayInWeek: getOrderOfDayInWeek,
      currentRegionLocale: currentRegionLocale,
      navigateToJsDate: navigateToJsDate,
      navigateToToday: navigateToToday,
      openCreationDrawer: openCreationDrawer,
      showService: showService
    };
  }
});