import { DateType } from '../DateType'

export interface EmailLog {
  id: number
  booking: number
  changesetId: number
  notificationClass: string
  sender: string
  subject: string
  html: string
  status: EmailLogStatus
  createdAt: DateType
  updatedAt: DateType
  deletedAt: DateType
  isDeleted: false
}

export enum EmailLogStatus {
  SENT = 'SENT',
  FAILED = 'FAILED'
}
