import { computed, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import { DomainMarketplaceConfiguration, DomainMarketplaceConfigurationCreateForm } from '~/types/models'

interface useDomainMarketplaceConfigurationHook {
  getDomainMarketplaceConfigurationFieldById: (id: number, field: string) => WritableComputedRef<any>
  getCreateFormField:(marketplaceId: number, field: string) => WritableComputedRef<any>
  postOne: (payload: DomainMarketplaceConfigurationCreateForm) => Promise<void>
  patchOne: (payload: DomainMarketplaceConfiguration, isStatusUpdate: boolean) => Promise<void>
}

function useDomainMarketplaceConfiguration(): useDomainMarketplaceConfigurationHook {
  const { app: { $accessor } } = useContext()

  const getDomainMarketplaceConfigurationFieldById = (id: number, field: string) => computed({
    get: () => $accessor.domainMarketplaceConfigurations.getField(id)(field),
    set: value => {
      if (!$accessor.domainMarketplaceConfigurations.getField(id)('$isDirty') && field !== '$isDirty') {
        $accessor.domainMarketplaceConfigurations.UPDATE_FIELD({
          id,
          path: '$isDirty',
          value: true,
        })
      }
      return $accessor.domainMarketplaceConfigurations.UPDATE_FIELD({
        id,
        path: field,
        value,
      })
    },
  })

  const getCreateFormField = (marketplaceId: number, field: string) => computed({
    get: () => $accessor.domainMarketplaceConfigurations.getFormField(marketplaceId)(field),
    set: value => {
      if (!$accessor.domainMarketplaceConfigurations.getFormField(marketplaceId)('$isDirty') && field !== '$isDirty') {
        $accessor.domainMarketplaceConfigurations.UPDATE_CREATE_FORM_FIELD({
          marketplaceId,
          path: '$isDirty',
          value: true,
        })
      }
      return $accessor.domainMarketplaceConfigurations.UPDATE_CREATE_FORM_FIELD({
        marketplaceId,
        path: field,
        value,
      })
    },
  })

  async function postOne(payload: DomainMarketplaceConfigurationCreateForm): Promise<void> {
    await $accessor.domainMarketplaceConfigurations.postOne(payload)
  }

  async function patchOne(payload: DomainMarketplaceConfiguration, isStatusUpdate: boolean): Promise<void> {
    await $accessor.domainMarketplaceConfigurations.patchOne({ payload, isStatusUpdate })
  }

  return {
    postOne,
    patchOne,
    getCreateFormField,
    getDomainMarketplaceConfigurationFieldById,
  }
}
export default useDomainMarketplaceConfiguration
