



































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import { EntityFile, EntityFileTypeEnum } from '~/types/models'
import useDomain from '~/composable/useDomain'
import useFiles from '~/composable/useFiles'
import PicturesList from '~/components/media/PicturesList.vue'

export default defineComponent({
  name: 'DomainPhotos',
  components: { PicturesList },
  middleware: ['domainFilesMiddleware'],
  setup() {
    const { app: { $errorToast, $accessor } } = useContext()
    const {
      currentDomainPhotos,
      addCurrentDomainFile,
      deleteDomainPicture,
      deleteManyDomainPictures,
      orderableDomainPhotos,
    } = useDomain()
    const { patchOne } = useFiles()
    const { params } = useContext()
    const domainId = computed(() => parseInt(params.value.domain))

    const acceptedPhotos = computed(() => currentDomainPhotos.value)

    // Ignore first param as its a wrapper from DropZone.js
    function onFileUpload(_: any, file: EntityFile) {
      addCurrentDomainFile(file)
    }

    async function onPictureUpdate(file: EntityFile) {
      await patchOne(file)
    }

    async function onPictureDelete(id: number) {
      await deleteDomainPicture(id)
    }

    async function onDeleteAllPictures(fileArray: EntityFile[]) {
      await deleteManyDomainPictures(fileArray)
    }

    async function onOrderUpdate() {
      await $accessor.entityFiles.resetIdx(orderableDomainPhotos.value)
    }

    function onFileUploadError(payload: { msg: string, file: File }): void {
      $errorToast(payload.msg)
    }

    return {
      acceptedPhotos,
      currentDomainPhotos,
      domainId,
      EntityFileTypeEnum,
      onDeleteAllPictures,
      onFileUpload,
      onFileUploadError,
      onOrderUpdate,
      onPictureDelete,
      onPictureUpdate,
      orderableDomainPhotos,
    }
  },
})
