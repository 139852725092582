import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { resetState } from '~/helpers/store'
import { Address } from '~/types/models'
import baseState, { address, baseCreateForm } from './state'
import { AddressMutationTypes } from './types'

export const mutations = mutationTree(baseState,{
  ...createMutations<Address>(address),

  [AddressMutationTypes.UPDATE_FIELD](state, { id, path, value }: { id: number, path: string, value: any }) {
    updateField(state.entities.byId[id], { path, value })
  },

  [AddressMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },

  [AddressMutationTypes.UPDATE_CREATE_FORM_FIELD](state, payload) {
    updateField(state.createForm, payload)
  },

  [AddressMutationTypes.RESET_CREATE_FORM](state) {
    state.createForm = baseCreateForm()
  },
})

export default mutations
