import { addresses } from './addresses'
import { auth } from './auth'
import { bookingConditions } from './bookingConditions'
import { bookings } from './bookings'
import { calendar } from './calendar'
import { calendarExternalSources } from './calendarExternalSources'
import { customers } from './customers'
import { domainActivities } from './domainActivities'
import { domainActivitiesEntries } from './domainActivitiesEntries'
import { domainEquipments } from './domainEquipments'
import { domainEquipmentsEntries } from './domainEquipmentsEntries'
import { domainMarketplaceConfigurations } from './domainMarketplaceConfigurations'
import { domains } from './domains'
import { domainSustainabilityActions } from './domainSustainabilityActions'
import { emailLogs } from './emailLogs'
import { entityFiles } from './entityFiles'
import { giftCards } from './giftCards'
import { giftCardSpendings } from './giftCardSpendings'
import { healthSafetyMeasures } from './healthSafetyMeasures'
import { marketplaces } from './marketplaces'
import { notifications } from './notifications'
import { options } from './options'
import { payments } from './payments'
import { promocodes } from './promocodes'
import { providers } from './providers'
import { serviceDiscounts } from './serviceDiscounts'
import { serviceEquipments } from './serviceEquipments'
import { servicePricings } from './servicePricings'
import { services } from './services'
import { serviceThemes } from './serviceThemes'
import { serviceTypes } from './serviceTypes'
import { serviceUnits } from './serviceUnits'
import { serviceLabels } from './serviceLabels'
import { unavailabilities } from './unavailabilities'
import { users } from './users'

export const modules = {
  addresses,
  auth,
  bookingConditions,
  bookings,
  calendar,
  customers,
  domainActivities,
  domainActivitiesEntries,
  domainEquipments,
  domainEquipmentsEntries,
  domainMarketplaceConfigurations,
  domainSustainabilityActions,
  domains,
  calendarExternalSources,
  emailLogs,
  entityFiles,
  giftCards,
  giftCardSpendings,
  healthSafetyMeasures,
  marketplaces,
  notifications,
  options,
  payments,
  providers,
  promocodes,
  serviceDiscounts,
  serviceEquipments,
  servicePricings,
  services,
  serviceLabels,
  serviceThemes,
  serviceTypes,
  serviceUnits,
  unavailabilities,
  users,
}
