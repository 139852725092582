import { Address } from '~/types/models'
import { State as BaseState } from '@kissmylabs/vuex-entitystore'

export interface AddressCreateForm {
  addressLine1: string
  addressLine2: string
  addressLine3: string
  postalCode: string
  city: string
  subdivision: string   // Code ISO des régions (pour la France)
  subdivision2: string  // Code ISO des départements (pour la France)
  country: string
  lat: number | null
  lng: number | null
  $isDirty?: boolean
}

export interface AddressState extends BaseState<Address> {
  createForm: AddressCreateForm,
}

export enum AddressMutationTypes {
  UPDATE_FIELD = 'UPDATE_FIELD',
  RESET_STATE = 'RESET_STATE',
  RESET_CREATE_FORM = 'RESET_CREATE_FORM',
  UPDATE_CREATE_FORM_FIELD = 'UPDATE_CREATE_FORM_FIELD',
}


