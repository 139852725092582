import ClickOutside from 'vue-click-outside';
export default defineComponent({
  name: 'ServiceSelectDropdown',
  directives: {
    ClickOutside: ClickOutside
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    availableServices: {
      type: Array,
      required: true
    },
    disabledServices: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var state = reactive({
      expanded: props.expanded
    });
    var model = computed({
      get: function get() {
        return props.value;
      },
      set: function set(value) {
        return emit('input', value);
      }
    });

    function onClickOutside() {
      state.expanded = false;
    }

    function onToggleExpanded() {
      state.expanded = !state.expanded;
    }

    return {
      state: state,
      model: model,
      onClickOutside: onClickOutside,
      onToggleExpanded: onToggleExpanded
    };
  }
});