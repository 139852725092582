import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isFullScreenModal && !_vm.isCreateDefaultMode)?_c('ValidationProvider',{staticClass:"block",attrs:{"name":"unavail_periods","rules":"min_length:1","skip-if-empty":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('MultipleDateRangePicker',{staticClass:"unavailabilityDatePicker",attrs:{"label":_vm.$t('calendar.unavailability_period').toString(),"delete-label":_vm.$t('calendar.unavailability_period_delete').toString(),"format":_vm.$t('formats.date_short'),"default-period":_vm.defaultPeriod,"min-duration":1},on:{"input":validate},model:{value:(_vm.getSetSelectedUnavailPeriods),callback:function ($$v) {_vm.getSetSelectedUnavailPeriods=$$v},expression:"getSetSelectedUnavailPeriods"}})],1)]}}],null,true)}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"unavailability_reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('calendar.unavailability_reason'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{on:{"focus":_vm.onCommentFocus},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)]}}],null,true)}),_vm._v(" "),(_vm.view === _vm.CalendarViewsEnum.TWO_WEEKS)?_c('ValidationProvider',{attrs:{"name":"units_select","rules":"min_length:1","tag":"div","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('ServiceUnitsSelectCheckboxes',{attrs:{"services":_vm.services,"disabled-services":_vm.disabledServices,"compact":"","label":_vm.$t('rental.the_unavailability_applies_to').toString(),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors ? _vm.$t('form.fields.service_select') : ''},on:{"input":validate},model:{value:(_vm.getSelectedUnits),callback:function ($$v) {_vm.getSelectedUnits=$$v},expression:"getSelectedUnits"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.view === _vm.CalendarViewsEnum.TWO_MONTHS)?_c('ValidationProvider',{attrs:{"name":"units_select","rules":"min_length:1","tag":"div","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('FilteredServiceUnitsSelectCheckboxes',{attrs:{"services":_vm.services,"compact":"","label":_vm.$t('rental.the_unavailability_applies_to').toString(),"type":{ 'is-danger': errors[0], 'is-success': valid }},on:{"input":validate},model:{value:(_vm.getSelectedUnits),callback:function ($$v) {_vm.getSelectedUnits=$$v},expression:"getSelectedUnits"}})]}}],null,true)}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-full has-text-centered"},[_c('BButton',{staticClass:"is-primary is-size-5 is-uppercase has-text-weight-bold",attrs:{"data-cy":"UnavailabilityCreationFormSaveButton","size":"is-large","loading":_vm.isSubmitting,"disabled":invalid || _vm.isSubmitDisabled || _vm.isSubmitting},on:{"click":_vm.onSubmit}},[_vm._v("\n          "+_vm._s(_vm.$t('actions.save'))+"\n        ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }