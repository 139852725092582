












import { defineComponent, useContext } from '@nuxtjs/composition-api'
import useAuth from '~/composable/useAuth'

export default defineComponent({
  name: 'LoginToggleLink',
  setup() {
    const { isLoggedIn, logout: doLogout } = useAuth()
    const { app: { $api, $successToast, i18n, router, localePath } } = useContext()

    function logout() {
      if (router) {
        doLogout()
        $api.deleteCredentials()
        $successToast(i18n.t('auth.signed_out_success'))
        router.push(localePath('auth-login'))
      }
    }

    return {
      isLoggedIn,
      logout,
    }
  },
})
