import { useContext } from '@nuxtjs/composition-api'
import { RouteConfig } from '@nuxt/types/config/router'

interface FeatureToggleHook {
  isEnabledRoute(route: RouteConfig): boolean
}

function useFeatureToggle(): FeatureToggleHook {
  const { app } = useContext()

  function isEnabledRoute(route: RouteConfig) {
    if (route.meta?.featureGate) {
      return app.$featureToggle.toggles[route.meta.featureGate] === true
    }
    return true
  }

  return { isEnabledRoute }
}

export default useFeatureToggle
