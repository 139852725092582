




































import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CalendarAccordion',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isOverlay: {
      type: Boolean,
      default: false,
    },
    hasTriggerBg: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const isOpenState = ref(props.isOpen)

    function onOpen() {
      isOpenState.value = !isOpenState.value
      emit('open')
    }

    function onClose() {
      isOpenState.value = !isOpenState.value
      emit('close')
    }

    return {
      onOpen,
      onClose,
      isOpenState,
    }
  },
})
