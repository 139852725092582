






























































































import { defineComponent, reactive, useContext } from '@nuxtjs/composition-api'
import { CurrencyEnum } from '~/types/models'
import SymbolInput from '~/components/content/SymbolInput.vue'

export default defineComponent({
  name: 'AddSpendingModal',
  components: { SymbolInput },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    giftCardId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { app: { $api, i18n, $toCent, $successToast, $loadingToast, $errorToast } } = useContext()

    const form = reactive({
      booking: '',
      providerId: '',
      amountSpent: {
        amount: 0,
        currency: CurrencyEnum.CURRENCY_EURO,
      },
    })

    async function submit() {
      const toast = $loadingToast(i18n.t('toast.spending_creating'))
      try {
        const spending = {
          giftCard: props.giftCardId,
          booking: parseInt(form.booking),
          providerId: parseInt(form.providerId),
          amountSpent: $toCent(form.amountSpent),
          unicstayPaid: false,
        }
        await $api.post('giftcardspendings', spending)
        toast.goAway(0)
        $successToast(i18n.t('toast.spending_created'))
        emit('update:active')
        emit('spending-created')
      } catch (error) {
        toast.goAway(0)
        $errorToast(i18n.t('toast.spending_create_error'))
      }
    }

    return {
      form,
      submit,
    }
  },
})
