







































import { defineComponent } from '@nuxtjs/composition-api'
import AppContentHeading from '~/components/content/AppContentHeading.vue'

export default defineComponent({
  components: {
    AppContentHeading,
  },
  setup() { },
})
