import { DateType } from '~/types/DateType'

// EXAMPLES ICALS
// https://lodgesdesgrandscrus.com/?feed=hbook-calendar.ics&accom_id=1214&accom_num=1
// https://www.cabanesdesgrandscepages.com/calendar-opt/47.ics
// https://io.eviivo.com/pms/v2/open/property/BulledR/rooms/9e8ba96d-4735-4831-bb24-a50a5bb8306d/ical
// https://api.eviivo.com/eviivo.eagle.services.online/CalendarService.svc/property/Quenecan22570/room/112207
// https://monhebergementinsolite.elloha.com/99ddd706-f85f-4069-8ab2-b919a3a14307/123a5c4b-47fd-4fae-b536-bfc9f63f4d9d.ics
// https://lodgesdesgrandscrus.com/?feed=hbook-calendar.ics&accom_id=1217&accom_num=1

export enum CalendarExternalSourceTypesEnum {
  ICAL = 'ICAL',
  VALET = 'VALET'
}

export enum CalendarExternalSourceSyncStatusEnum {
  SYNC_STATUS_PENDING = 'SYNC_STATUS_PENDING',
  SYNC_STATUS_SUCCESS = 'SYNC_STATUS_SUCCESS',
  SYNC_STATUS_FAILED = 'SYNC_STATUS_FAILED'
}

// Values in minutes
export enum CalendarExternalSourceSyncIntervals {
  '30_MIN' = 30,
  '1_HOUR' = 60,
  '2_HOURS' = 120,
  '4_HOURS' = 240,
  '8_HOURS' = 480,
  '16_HOURS' = 960,
  '24_HOURS' = 1440,
  '48_HOURS' = 2880,
}

export type ExternalSourceEntity = 'service' | 'serviceUnit'
export interface CalendarExternalSourceForm {
  label: string
  sourceUrl: string
  syncInterval: CalendarExternalSourceSyncIntervals
  sourceType: CalendarExternalSourceTypesEnum
}

export interface CalendarExternalSource {
  id: number
  label: string
  lastSync: DateType
  lastSyncStatus: CalendarExternalSourceSyncStatusEnum
  matchingString: string
  service?: number | null
  serviceUnit?: number | null
  sourceType: CalendarExternalSourceTypesEnum
  sourceUrl: string
  syncInterval: CalendarExternalSourceSyncIntervals
  isDeleted: boolean
  deletedAt: DateType
}
