import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { Promocode, Period } from '~/types/models'

export enum PromocodeMutationTypes {
  UPDATE_FIELD = 'UPDATE_FIELD',
  UPDATE_CREATE_FORM_FIELD = 'UPDATE_CREATE_FORM_FIELD',
  RESET_CREATE_FORM = 'RESET_CREATE_FORM',
}


export interface PromocodeCreateForm {
  code: string
  description: string
  marketplaces: number[]
  maxUses?: number | null
  name: string
  percentDiscount: number
  periods: Period[]
  usageCount: number
}

export interface PromocodeState extends BaseState<Promocode> {
  createForm: PromocodeCreateForm,
}
