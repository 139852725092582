









































































































































import { defineComponent, useContext } from '@nuxtjs/composition-api'
import to from 'await-to-js'
import { Cart, CartStatusClassesEnum, CartStatusEnum, Customer } from '~/types/models'
import useCart from '~/composable/useCart'
import useTable from '~/composable/useTable'
import useCustomers from '~/composable/useCustomers'
import StatusBadge from '~/components/badges/StatusBadge.vue'
import TableSearch from '~/components/table/TableSearch.vue'
import TableEmpty from '~/components/table/TableEmpty.vue'
import { ISOToShortEnum } from '~/types'

export default defineComponent({
  name: 'MarketplaceCartsPage',
  components: {
    TableSearch,
    TableEmpty,
    StatusBadge,
  },
  setup(_, ctx) {
    const { app: { $api, i18n, $successToast, $loadingToast, $errorToast, $accessor }, store } = useContext()
    const baseUrl = 'carts'
    const { getTranslatedStatus, getClassFromStatus } = useCart()
    const { fetchMany } = useCustomers()

    const fetchRelations = async(items: Cart[]) => {
      const customerIds = [...new Set(items.map(cart => cart.customer))]
      const missingCustomerIds = customerIds.filter(id => id && !$accessor.customers.getAllIds().includes(id))
      if (missingCustomerIds.length > 0) {
        await fetchMany(missingCustomerIds as number[], { fetchAddress: true }) // Cast here because TS can't infer that the array has no undefined members after filtering
      }
    }

    const { state: tableState, eventSearchTable, eventSortTable } = useTable<Cart>(baseUrl, fetchRelations)

    tableState.filters = { 'status_in': [CartStatusEnum.CART_PAID, CartStatusEnum.CART_ABANDONNED, CartStatusEnum.CART_AWAITING_PAYMENT] }

    const getCustomerField = (id: number, field: keyof Customer) => {
      const customer = $accessor.customers.getOne(id)
      if (customer && customer[field]) {
        return customer[field]
      }
      return '?'
    }

    async function copyToClipboard(cart: Cart) {
      const marketplace = store.getters['marketplaces/getOne'](cart.marketplace)
      await ctx.root.$copyText(`https://${marketplace.hostname}/${ISOToShortEnum[cart.lang]}/step/booking?cartId=${cart.sessionId}`)
      $successToast(i18n.t('toast.copied_to_clipboard'))
    }

    async function confirmForceValidate({ sessionId }: Cart) {
      ctx.root.$buefy.dialog.confirm({
        title: i18n.t('dialog.force_validate_cart.title').toString(),
        message: i18n.t('dialog.force_validate_cart.message').toString(),
        confirmText: i18n.t('dialog.force_validate_cart.confirmText').toString(),
        cancelText: i18n.t('dialog.force_validate_cart.cancelText').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          await forceValidateCart(sessionId)
        },
      })
    }

    async function forceValidateCart(sessionId: string) {
      const toast = $loadingToast(i18n.t('toast.cart_validating'))
      try {
        await $api.post(`carts/${sessionId}/book`)
        toast.goAway(0)
        $successToast(i18n.t('toast.cart_validated'))
      } catch (error) {
        $errorToast(i18n.t('toast.cart_invalid'))
      }
    }

    async function checkCartPayment({ sessionId }: Cart) {
      const toast = $loadingToast(i18n.t('toast.checking_cart_payment'))
      const [err, _] = await to($api.post(`carts/${sessionId}/check`))
      toast.goAway(0)
      err ? $errorToast(i18n.t('toast.check_cart_payment_error')) : $successToast(i18n.t('toast.check_cart_payment'))
    }

    return {
      tableState,
      eventSearchTable,
      eventSortTable,
      copyToClipboard,
      confirmForceValidate,
      checkCartPayment,
      CartStatusEnum,
      CartStatusClassesEnum,
      getTranslatedStatus,
      getClassFromStatus,
      getCustomerField,
    }
  },
})
