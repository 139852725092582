













































import { defineComponent, watch } from '@nuxtjs/composition-api'
import { ServiceEquipmentTypeEnum } from '~/types/models'
import useServices from '~/composable/useServices'
import ServiceEquipmentTypeSelect from '~/components/service/ServiceEquipmentTypeSelect.vue'
import HRSwitch from '~/components/HRSwitch.vue'

export default defineComponent({
  name: 'ServiceCatering',
  components: { ServiceEquipmentTypeSelect, HRSwitch },
  setup() {
    const {
      getCurrentServiceField: getField,
      getCurrentServiceEquipmentsByType,
    } = useServices()

    // When isBreakfastIncluded changes, we get the opposite type and nullify this equipment in serviceEquipments.
    // eg if isBreakfastIncluded = true, nullify BREAKFAST_NOT_INCLUDED SEQ
    // and if isBreakfastIncluded = false, nullify BREAKFAST_INCLUDED SEQ.
    watch(() => getField('isBreakfastIncluded').value, val => {
      const removeType = val
        ? ServiceEquipmentTypeEnum.BREAKFAST_NOT_INCLUDED
        : ServiceEquipmentTypeEnum.BREAKFAST_INCLUDED
      getCurrentServiceEquipmentsByType(removeType).value = []
    })

    return {
      getField,
      ServiceEquipmentTypeEnum,
    }
  },
})
