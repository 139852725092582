




































































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import { ServiceStatusColorsEnum } from '~/types/models'
import { entityRoutes } from '~/routes/entityRoutes'
import useServices from '~/composable/useServices'
import useTabsNavigation from '~/composable/useTabsNavigation'
import useFeatureToggle from '~/composable/useFeatureToggle'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import TabsNavigation from '~/components/navigation/TabsNavigation.vue'
import HelpLinks from '~/components/content/HelpLinks.vue'
import StatusBadge from '~/components/badges/StatusBadge.vue'
import ServiceBookingUrl from '~/components/service/ServiceBookingUrl.vue'
import ExternalConnectivityServiceInfo from '~/components/service/ExternalConnectivityServiceInfo.vue'

export default defineComponent({
  name: 'ServiceDetailsPage',
  components: {
    ExternalConnectivityServiceInfo,
    ServiceBookingUrl,
    AppContentHeading,
    TabsNavigation,
    HelpLinks,
    StatusBadge,
  },
  middleware: ['serviceDetailMiddleware', 'defaultServicePricingsMiddleware'],
  setup() {
    const {
      params,
      app: { $accessor },
    } = useContext()
    const { patchService } = useServices()
    const { isEnabledRoute } = useFeatureToggle()
    const providerId = computed(() => parseInt(params.value.provider))
    const domainId = computed(() => parseInt(params.value.domain))
    const serviceId = computed(() => parseInt(params.value.id))
    const service = computed(() => $accessor.services.getOne(parseInt(params.value.id)))

    const childRoutes = entityRoutes.find(route => route.name === 'service-id')!.children!
    // TODO: Remove 'as any' if we find a way for TS not to throw errors over intersection types
    const enabledRoutes = childRoutes.filter(route => isEnabledRoute(route as any))

    const { currentIndex } = useTabsNavigation({
      childRoutes: enabledRoutes as any,
      root: 'service-id',
      defaultRoute: 'service-id-general',
      routeParams: {
        provider: providerId.value.toString(),
        domain: domainId.value.toString(),
        id: serviceId.value.toString(),
      },
    })

    return {
      enabledRoutes,
      service,
      currentIndex,
      patchService,
      providerId,
      ServiceStatusColorsEnum,
    }
  },
  data() {
    return {
      types: [],
      options: [],
    }
  },
})
