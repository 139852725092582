















































import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import useServices from '~/composable/useServices'

export default defineComponent({
  name: 'ServiceDeleteModal',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { app: { $accessor } } = useContext()
    const { currentServiceId } = useServices()

    const confirmation = ref('')
    const service = computed(() => $accessor.services.getOne(currentServiceId.value!))
    const canDelete = computed(() => service.value && confirmation.value)

    function onConfirmDelete() {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('Deleting a Service is not implemented yet.This modal should not be even visible')
      }
    }

    return {
      confirmation,
      service,
      canDelete,
      onConfirmDelete,
    }
  },
})
