




















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Country } from '~/types/Geo'

export default defineComponent({
  name: 'CountriesSelect',
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
    availableCountries: {
      type: Array as PropType<Country[]>,
      default: () => [],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
