import { computed, onMounted, reactive, useContext, watch, WritableComputedRef } from '@nuxtjs/composition-api'
import { PaginatedResponse } from '~/helpers/api'
import { sortAmounts, sortDates } from '~/helpers/tables'

export interface TableHook<T> {
  state: TableState<T>,
  fetchPage(): Promise<void>,
  eventSortTable(orderBy: string, orderDir: string): void,
  eventSearchTable(query: string): Promise<void>,
  sortDates: typeof sortDates,
  sortAmounts: typeof sortAmounts,
  refreshTable(): Promise<void>,
}

export interface TableState<T> {
  currentPage: WritableComputedRef<number> | number,
  perPage: number,
  total: number | null,
  orderDir: string,
  orderBy: string,
  query: string,
  isLoading: boolean,
  isSearching: boolean,
  items: T[],
  filters: Record<string, string | string[]> | null,
  excludeFromSearch: string[]
}

/**
 *
 * @param baseUrl The url of the entity getting fetched for the table
 * @param onFetched An optional async callback recieving the fetched data defined in the component to fetch additional data (i.e. relations to the main entity)
 */
function useTable<T>(baseUrl: string, onFetched?: ((items: T[]) => Promise<void>), options?: { orderBy: string }): TableHook<T> {
  const { app: { $api, router }, route } = useContext()

  let searchTimeout: number

  const state = (reactive<TableState<T>>({
    currentPage: computed({
      get: () => {
        const page = Array.isArray(route.value.query.page) ? route.value.query.page[0] : route.value.query.page
        return page ? parseInt(page) : 1
      },
      set: val => {
        if (router && route.value.name) {
          router.push({
            name: route.value.name,
            params: route.value.params,
            query: { page: val.toString() },
          })
        }
      },
    }),
    perPage: 10,
    total: 0,
    orderBy: options?.orderBy ? options.orderBy : 'id',
    orderDir: 'desc',
    query: '',
    isLoading: false,
    isSearching: false,
    items: [] as T[],
    filters: null,
    excludeFromSearch: [],
  }) as unknown) as TableState<T>

  onMounted(() => {
    fetchPage()
    // Only watch when mounted to give consumers a chance to change defaults, add filters etc.
    watch(() => [route.value.query.page, state.orderDir, state.orderBy, state.filters], async() => {
      await fetchPage()
    })
    // Watch filters separately
    watch(() => state.filters, async() => {
      await fetchPage()
    }, { deep: true })
  })

  async function fetchPage() {
    state.isLoading = true
    let url = `${baseUrl}?page=${state.currentPage}&limit=${state.perPage}&orderBy=${state.orderBy}&orderDir=${state.orderDir}`
    if (state.query) {
      url += `&search=${state.query}`
    }
    if (state.filters) {
      url += `&${Object.keys(state.filters)
        .map(field => {
          let value = state.filters![field]
          if (Array.isArray(value)) {
            value = value.join(',')
          }
          // Don't create empty filters.
          if (value.length) {
            return `filters[${field}]=${value}`
          }
          return null
        })
        .filter((filter: string | null) => filter !== null)
        .join('&')}`
    }
    if (state.excludeFromSearch.length) {
      url += state.excludeFromSearch.map(field => `&excludeFromSearch[${field}]`)
    }
    const res: PaginatedResponse<T> = await $api.get(url)

    // If a callback has been provided, call it so the consuming component can fetch any additional data
    if (onFetched) {
      await onFetched(res.data)
    }
    state.items = res.data
    state.total = res.total
    state.isLoading = false
  }

  function eventSortTable(orderBy: string, orderDir: string) {
    state.orderBy = orderBy
    state.orderDir = orderDir
  }

  async function eventSearchTable(query: string) {
    state.query = query

    clearTimeout(searchTimeout)

    searchTimeout = window.setTimeout(async() => {
      state.isSearching = query.length > 0
      const page = Array.isArray(route.value.query.page) ? route.value.query.page[0] : route.value.query.page
      if (page && parseInt(page) !== 1) {
        state.currentPage = 1
      } else {
        await fetchPage()
      }
    }, 500)

  }

  async function refreshTable() {
    await fetchPage()
  }

  return {
    state,
    fetchPage,
    eventSortTable,
    eventSearchTable,
    sortDates,
    sortAmounts,
    refreshTable,
  }
}

export default useTable
