export enum PresenceStatus {
  OFFLINE = 'OFFLINE',
  IDLE = 'IDLE',
  ONLINE = 'ONLINE',
}

export interface PresenceUser {
  id: number
  email: string
  name: string
  providerId?: number
}
