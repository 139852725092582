import { DrawerDisplayModeEnum } from '~/store/calendar/types';
import { CalendarDrawerNameEnum, CalendarEntitiesEnum } from '~/types/Calendar';
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import CalendarServiceUnitLine from '~/components/calendar/CalendarServiceUnitLine.vue';
import CalendarServicePricingLines from '~/components/calendar/CalendarServicePricingLines.vue';
import CalendarServiceDiscountLine from '~/components/calendar/CalendarServiceDiscountLine.vue';
import CalendarServiceBookingConditionsLine from '~/components/calendar/CalendarServiceBookingConditionsLine.vue';
import useServices from '~/composable/useServices';
export default defineComponent({
  name: 'CalendarService',
  components: {
    CalendarServiceUnitLine: CalendarServiceUnitLine,
    CalendarServicePricingLines: CalendarServicePricingLines,
    CalendarServiceDiscountLine: CalendarServiceDiscountLine,
    CalendarServiceBookingConditionsLine: CalendarServiceBookingConditionsLine
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    isFirst: {
      type: Boolean,
      default: false,
      description: 'Whether it\'s the first row in the Calendar (defines child rows tooltip position).'
    }
  },
  setup: function setup(props) {
    var _useContext = useContext(),
        $accessor = _useContext.app.$accessor;

    var _useDomain = useDomain(),
        currentDomain = _useDomain.currentDomain;

    var _useServices = useServices(),
        hasDefaultPricing = _useServices.hasDefaultPricing;

    var _useCalendar = useCalendar(),
        isCalendarUserEnabled = _useCalendar.isCalendarUserEnabled;

    var hasServiceDefaultPricing = computed(function () {
      return hasDefaultPricing(props.service.id);
    });

    function onCreateDefaultPricing() {
      $accessor.servicePricings.resetActive();
      var drawerOpts = {
        drawer: CalendarDrawerNameEnum.DEFAULT_PRICING,
        mode: DrawerDisplayModeEnum.CREATE_DEFAULT,
        data: {
          serviceId: props.service.id
        }
      };
      $accessor.calendar.UPDATE_SELECTED_SERVICES([props.service.id]);
      $accessor.calendar.UPDATE_ENTITY_FORM_OPENED(CalendarEntitiesEnum.PRICING);
      $accessor.calendar.UI_OPEN_DRAWER(drawerOpts);
    }

    return {
      hasServiceDefaultPricing: hasServiceDefaultPricing,
      onCreateDefaultPricing: onCreateDefaultPricing,
      currentDomain: currentDomain,
      isCalendarUserEnabled: isCalendarUserEnabled
    };
  }
});