






























































































import { computed, defineComponent, reactive, ref, useContext } from '@nuxtjs/composition-api'
import orderBy from 'lodash/orderBy'
import { ServiceEquipment, ServiceEquipmentTypeEnum } from '~/types/models'
import useServices from '~/composable/useServices'
import ServiceEquipmentTypeSelect from './ServiceEquipmentTypeSelect.vue'
import HRSwitch from '~/components/HRSwitch.vue'

export default defineComponent({
  name: 'ServiceSpa',
  components: { ServiceEquipmentTypeSelect, HRSwitch },
  setup() {
    const {
      getCurrentServiceField: getField,
      getCurrentServiceEquipmentsByType: getEquipments,
    } = useServices()
    const { app: { $accessor, $translateEntityField } } = useContext()

    const input = ref('')
    const spaType = [ServiceEquipmentTypeEnum.SPA_CHARACTERISTIC, ServiceEquipmentTypeEnum.SPA_LOCATION, ServiceEquipmentTypeEnum.SPA, ServiceEquipmentTypeEnum.SPA_TYPE]
    const characteristics = computed(() =>
      $accessor.serviceEquipments.getWhereArray(eq => eq.type === ServiceEquipmentTypeEnum.SPA_CHARACTERISTIC)
        .filter(eq => getField('serviceEquipments').value.includes(eq.id)),
    )
    const filteredAutocompleteItems = computed(() => {
      const items = orderBy(
        $accessor.serviceEquipments.getWhere(e => e.type === ServiceEquipmentTypeEnum.SPA_CHARACTERISTIC),
        eq => $translateEntityField(eq.name),
      )
      return Object.values(items)
        .filter(i => !characteristics.value.some((item: ServiceEquipment) => item.id === i.id) && $translateEntityField(i.name)
          .includes(input.value))
    })

    const state = reactive({
      isAvailable: $accessor.serviceEquipments.getWhereArray(eq => eq.type === ServiceEquipmentTypeEnum.SPA && getField('serviceEquipments')
        .value.includes(eq.id)).length > 0,
    })

    const spaAvailable = computed({
      get: () => $accessor.serviceEquipments
        .getWhereArray(
          eq => eq.type === ServiceEquipmentTypeEnum.SPA && getField('serviceEquipments').value.includes(eq.id),
        ).length > 0,
      set: (val) => {
        if (!val) {
          state.isAvailable = false
          getField('serviceEquipments').value = getField('serviceEquipments').value.filter((id: number) => {
            const equipment = $accessor.serviceEquipments.getWhere(eq => eq.id === id && !spaType.includes(eq.type))
            return equipment[id] && equipment[id]
          })
        } else {
          return state.isAvailable = true
        }
      },
    })

    function addOrRemoveCharacteristic(id: number) {
      if (!getField('serviceEquipments').value.includes(id)) {
        getField('serviceEquipments').value = [
          ...getField('serviceEquipments').value,
          id,
        ]
      } else {
        getField('serviceEquipments').value = getField('serviceEquipments').value.filter((eq: number) => eq !== id)
      }
    }

    function eventSelectCharacteristic(item: ServiceEquipment) {
      if (item) {
        return addOrRemoveCharacteristic(item.id)
      }
    }

    return {
      spaAvailable,
      getField,
      getEquipments,
      input,
      filteredAutocompleteItems,
      characteristics,
      eventSelectCharacteristic,
      ServiceEquipmentTypeEnum,
      spaType,
      state,
    }
  },
})
