import { DateType } from '../DateType'
import { GenderEnum } from './Customer'

export enum CancelPolicyEnum {
  SOFT = 'SOFT',
  MEDIUM = 'MEDIUM',
  HARD = 'HARD'
}

export enum PayoutFrequencyEnum {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum ProviderStatusEnum {
  MAINTENANCE = 'MAINTENANCE',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}
export enum StructureStatusEnum {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL'
}
export interface Provider {
  $isDirty: boolean
  bic: string
  cancelPolicy: CancelPolicyEnum
  createdAt: DateType
  deletedAt: DateType
  domains: number[]
  email: string
  files: number[]
  hlcpId: string
  hlcpPassword: string
  iban: string
  id: number
  legalContactBirthDate: string
  legalContactCountryOfResidence: string
  legalContactFirstname: string
  legalContactLastname: string
  legalContactNationality: string
  legalContactTitle: GenderEnum
  mybeezboxId: string
  mybeezboxPassword: string
  mybeezboxUrl: string
  name: string
  payoutFrequency: PayoutFrequencyEnum
  phone: string
  status: ProviderStatusEnum
  structureAPENAFCode: string
  structureCompanyName: string
  structureLegalForm: string
  structureRCSNumber: string
  structureStatus: StructureStatusEnum
  unicstayContactAddress: number
  unicstayContactBirthdate: string
  unicstayContactEmail: string
  unicstayContactFirstname: string
  unicstayContactLastname: string
  unicstayContactPhone: string
  unicstayContactTitle: string
  updatedAt: DateType
  users: number[]
}
