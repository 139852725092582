import { Middleware } from '@nuxt/types'


const bookingServiceFilesMiddelware: Middleware = async({ route, app: { $accessor } }) => {
  const service = $accessor.services.getOne($accessor.bookings.getOne(parseInt(route.params.id)).service)
  const missingFilesIds = service.files.filter(id => !$accessor.entityFiles.getAllIds().includes(id))

  if (missingFilesIds.length > 0) {
    await $accessor.entityFiles.fetchMany(missingFilesIds)
  }
}

export default bookingServiceFilesMiddelware
