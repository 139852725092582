import { createActions } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import {
  BookingCondition, BookingConditionBatchCreateForm, BookingConditionBatchEditForm, BookingConditionBatchDeleteForm,
} from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { toIsoDateStringPeriod, toIsoStringPeriod } from '~/helpers'
import { ICalendarBatchParams, ICalendarBatchActionParams } from '~/types'
import { RootMutationTypes } from '~/store/types'

type IBookingConditionCreateBatchParams = ICalendarBatchParams<BookingConditionBatchCreateForm | BookingConditionBatchEditForm>
type IBookingConditionResetBatchParams = ICalendarBatchParams<BookingConditionBatchDeleteForm>

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<BookingCondition>(),

  async createBatch(
    _,
    { domainId, payload, options }
      : IBookingConditionCreateBatchParams,
  ): Promise<void> {
    const { i18n, $loadingToast, $errorToast, $successToast, $api, $accessor, $sentry } = this.app
    const { toastText } = options

    const toast = $loadingToast(toastText.pending)
    $accessor[RootMutationTypes.START_PREVENT_WINDOW_CLOSE](i18n.t('prevent_window_close'))

    try {
      const cleanPayload = {
        ...payload,
        isDeleted: false,
        isDefault: false,
        periods: payload.periods.map(period => toIsoStringPeriod(period)),
      }

      await $api.post(`domains/${domainId}/calendar/batch/bookingcondition/create`, cleanPayload)

      // @todo Don't have enough time to do this another way,
      //  but we should be able to fetch everything with a single HTTP request.
      await Promise.all(
        payload.services.map(
          async(serviceId) => await $accessor.calendar.fetchSingleServiceBookingConditions(serviceId),
        ),
      )

      toast.goAway(0)
      $successToast(toastText.success)
    } catch (error) {
      $sentry.captureException(error)
      toast.goAway(0)
      $errorToast(toastText.error)
      throw error
    } finally {
      $accessor[RootMutationTypes.END_PREVENT_WINDOW_CLOSE]()
    }
  },

  async resetBatch(_, { domainId, payload, options }
    : IBookingConditionResetBatchParams,
  ): Promise<void> {
    const { i18n, $loadingToast, $errorToast, $successToast, $api, $accessor, $sentry } = this.app
    const { toastText } = options

    const toast = $loadingToast(toastText.pending)
    $accessor[RootMutationTypes.START_PREVENT_WINDOW_CLOSE](i18n.t('prevent_window_close'))

    try {
      const cleanPayload = {
        ...payload,
        periods: payload.periods.map(period => toIsoStringPeriod(period)),
      }

      await $api.patch(`domains/${domainId}/calendar/batch/bookingcondition/reset`, cleanPayload)

      // @todo Don't have enough time to do this another way,
      //  but we should be able to fetch everything with a single HTTP request.
      await Promise.all(
        payload.services.map(
          async(serviceId) => await $accessor.calendar.fetchSingleServiceBookingConditions(serviceId),
        ),
      )

      toast.goAway(0)
      $successToast(toastText.success)
    } catch (error) {
      $sentry.captureException(error)
      toast.goAway(0)
      $errorToast(toastText.error)
      throw error
    } finally {
      $accessor[RootMutationTypes.END_PREVENT_WINDOW_CLOSE]()
    }
  },

  async postMany({ dispatch }, { domainId, payload }
    : ICalendarBatchActionParams<BookingConditionBatchCreateForm>,
  ): Promise<void> {
    const { i18n } = this.app

    const createBatchOptions: IBookingConditionCreateBatchParams['options'] = {
      toastText: {
        pending: i18n.tc('toast.booking_condition_creating', 2),
        success: i18n.tc('toast.booking_condition_created', 2),
        error: i18n.tc('toast.booking_condition_create_error', 2),
      },
    }

    await dispatch('createBatch', { domainId, payload, options: createBatchOptions })
  },

  async patchOne(_, { bookingCondition }: { bookingCondition: BookingCondition }): Promise<void> {
    const { $api, $accessor, $loadingToast, $errorToast, i18n, $successToast, $sentry } = this.app

    const toast = $loadingToast(i18n.t('toast.booking_condition_updating'))
    try {
      const payload = {
        ...bookingCondition,
        ...toIsoDateStringPeriod(bookingCondition),
      }

      await $api.patch(
        `services/${bookingCondition.service}/bookingconditions/${bookingCondition.id}`,
        payload,
      )
      await $accessor.calendar.fetchSingleServiceBookingConditions(bookingCondition.service)
      toast.goAway(0)
      $successToast(i18n.t('toast.booking_condition_updated'))
    } catch (e) {
      $sentry.captureException(e)
      toast.goAway(0)
      $errorToast(i18n.t('toast.booking_condition_update_error'))
      throw e
    }
  },

  async deleteOne(_, { bookingCondition }: { bookingCondition: BookingCondition }): Promise<void> {
    const { $api, $accessor, $loadingToast, $errorToast, i18n, $successToast, $sentry } = this.app

    const toast = $loadingToast(i18n.tc('toast.booking_condition_deleting', 1))
    try {
      await $api.delete(`services/${bookingCondition.service}/bookingconditions/${bookingCondition.id}`)
      await $accessor.calendar.fetchSingleServiceBookingConditions(bookingCondition.service)
      toast.goAway(0)
      $successToast(i18n.tc('toast.booking_condition_deleted', 1))
    } catch (e) {
      $sentry.captureException(e)
      toast.goAway(0)
      $errorToast(i18n.tc('toast.booking_condition_delete_error', 1))
      throw e
    }
  },

  async resetMany(
    { dispatch },
    { domainId, payload }: ICalendarBatchActionParams<BookingConditionBatchDeleteForm>): Promise<void> {
    const { i18n } = this.app

    const batchOptions: IBookingConditionResetBatchParams['options'] = {
      toastText: {
        pending: i18n.tc('toast.booking_condition_deleting', 2),
        success: i18n.tc('toast.booking_condition_deleted', 2),
        error: i18n.tc('toast.booking_condition_delete_error', 2),
      },
    }
    await dispatch('resetBatch', { domainId, payload, options: batchOptions })
  },
})

export default actions
