















import { defineComponent } from '@nuxtjs/composition-api'
import { ShortToISOEnum } from '~/types'

export default defineComponent({
  name: 'LocaleSelect',
  setup() {

    return { ShortToISOEnum }
  },
})
