import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { Notification } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<Notification>(),

  async fetchAll({ commit }) {
    const res: Notification[] = await this.app.$api.get('notifications')

    const notificationDomainsIds = [...new Set(res.filter(notification => notification.data.objectType === 'domain').map(notification => notification.data.objectId))]
    const missingDomainIds = notificationDomainsIds.filter(id => !this.app.$accessor.domains.getAllIds().includes(id))
    if (missingDomainIds.length > 0) {
      await this.app.$accessor.domains.fetchMany(missingDomainIds)
    }

    const notificationServicesIds = [...new Set(res.filter(notification => notification.data.objectType === 'service').map(notification => notification.data.objectId))]
    const missingServiceIds = notificationServicesIds.filter(id => !this.app.$accessor.services.getAllIds().includes(id))
    if (missingServiceIds.length > 0) {
      await this.app.$accessor.services.fetchMany(missingServiceIds)
    }

    const notificationOptionsIds = [...new Set(res.filter(notification => notification.data.objectType === 'option').map(notification => notification.data.objectId))]
    const missingOptionIds = notificationOptionsIds.filter(id => !this.app.$accessor.options.getAllIds().includes(id))
    if (missingOptionIds.length > 0) {
      await this.app.$accessor.options.fetchMany(missingOptionIds)
    }

    if (res.length) {
      commit(BaseMutationTypes.CREATE_MANY, res)
    }
  },

  async patchOne({ commit }, { id, payload }: { id: number, payload?: Notification }) {
    const res = await this.app.$api.patch(`notifications/${id}`, payload)
    commit(BaseMutationTypes.UPDATE, { id, payload: res })
  },
})

export default actions
