import { Middleware } from '@nuxt/types'

const serviceUnitsMiddleware: Middleware = async({ params, app: { $accessor } }) => {
  const domainId = parseInt(params.domain)
  const servicesIds = $accessor.domains.getOne(domainId).services
  const services = $accessor.services.getWhereArray(service => servicesIds.includes(service.id))

  const missingServicesUnitsByServiceId = services.filter(service => !service.serviceUnits.every(id => $accessor.serviceUnits.getAllIds().includes(id)))
    .map(service => service.id)

  if (missingServicesUnitsByServiceId.length > 0) {
    await Promise.all(missingServicesUnitsByServiceId.map(id => $accessor.serviceUnits.fetchAllForService(id!)))
  }
}

export default serviceUnitsMiddleware
