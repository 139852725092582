import { CurrencyEnum, Money } from '~/types/models'
import Decimal from 'decimal.js-light'
import Dinero from 'dinero.js'
import { ShortToISOEnum } from '~/types'

export const DEFAULT_CURRENCY = CurrencyEnum.CURRENCY_EURO
export const DEFAULT_CURRENCY_SIGN = '€'
export const MONEY_LONG_FORMAT = '$0,0.00'

export const BASE_AMOUNT: Money = {
  amount: 0,
  currency: DEFAULT_CURRENCY,
  sign: { prefix: '', suffix: DEFAULT_CURRENCY_SIGN },
}

export function toCent(item: Money): Money {
  return {
    ...item,
    amount: new Decimal(item.amount).times(100).toNumber(),
  }
}

export function fromCent(item: Money): Money {
  return {
    ...item,
    amount: new Decimal(item.amount).dividedBy(100).toNumber(),
  }
}

export function parseAmount(item: Money, locale = ShortToISOEnum.FR): Dinero.Dinero {
  return Dinero({
    amount: item.amount,
    currency: item.currency,
  })
    .setLocale(locale as string)
}

export function formatAmount(item: Money, locale = ShortToISOEnum.FR, format = MONEY_LONG_FORMAT): string {
  return parseAmount(item, locale).toFormat(format)
}

export function parseNumberInputValue(value: number): number {
  let strVal = value.toString().replace(',', '.')
  if (strVal[strVal.length - 1] === '.') {
    strVal = strVal.substring(0, strVal.length - 1)
  }
  return new Decimal(strVal).toNumber()
}
