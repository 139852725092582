import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { ServiceEquipment } from '~/types/models'
import { resetState } from '~/helpers/store'
import { ServicesEquipmentMutationTypes } from './types'
import baseState, { serviceEquipment } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<ServiceEquipment>(serviceEquipment),
  [ServicesEquipmentMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
  [ServicesEquipmentMutationTypes.UPDATE_FIELD]: (state, { id, path, value }: { id: number, path: string, value: any }) => updateField(state.entities.byId[id], { path, value }),

})

export default mutations
