import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'DomainEquipmentsOrActivities',
  props: {
    domainPath: {
      type: String,
      required: true,
      description: 'The attribute path to the list of items to update'
    },
    amenityType: {
      type: String,
      required: true,
      validator: function validator(prop) {
        return ['equipment', 'activity'].includes(prop);
      }
    },
    items: {
      type: Array,
      required: true
    },
    autocompleteItems: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    tagsLabel: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    priceable: {
      type: Boolean,
      required: false,
      default: false,
      description: 'Should the tags be a dropdown with free/charged options?'
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit,
        _ref$root = _ref.root,
        $buefy = _ref$root.$buefy,
        $i18n = _ref$root.$i18n;

    var _useContext = useContext(),
        _useContext$app = _useContext.app,
        $accessor = _useContext$app.$accessor,
        $translateEntityField = _useContext$app.$translateEntityField;

    var _useDomainEquipments = useDomainEquipments(),
        isEquipmentEntry = _useDomainEquipments.isEquipmentEntry;

    var input = ref('');
    var filteredAutocompleteItems = computed(function () {
      return props.autocompleteItems.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          name: $translateEntityField(item.name)
        });
      }).filter(function (item) {
        return item.name.toLowerCase().includes(input.value.toLowerCase()) && !props.items.some(function (i) {
          return i.id === item.id;
        });
      });
    });

    var getItemName = function getItemName(item) {
      // item composition define type of himself DomainEquipmententry | DomainActivityEntry
      if (isEquipmentEntry(item)) {
        return $translateEntityField($accessor.domainEquipments.getOne(item.domainEquipment).name);
      } else if (item.domainActivity) {
        return $translateEntityField($accessor.domainActivities.getOne(item.domainActivity).name);
      } else {
        return 'N/A';
      }
    };

    var onSelectItem = function onSelectItem(item) {
      // Return immediatly if null
      // This can happen because <BAutocomplete> sometimes triggers the "select" event twice with a null value on the second instance
      if (!item) {
        return;
      }

      emit('on-select', item);
    };

    var onDelete = function onDelete(item) {
      if (!item) {
        return;
      }

      $buefy.dialog.confirm({
        title: $i18n.t("dialog.".concat(props.domainPath, "_delete.title")).toString(),
        message: $i18n.t("dialog.".concat(props.domainPath, "_delete.message")).toString(),
        confirmText: $i18n.t('dialog.delete.confirmText').toString(),
        cancelText: $i18n.t('dialog.delete.cancelText').toString(),
        hasIcon: true,
        type: 'is-danger',
        onConfirm: function onConfirm() {
          emit('on-delete', item);
        }
      });
    };

    return {
      filteredAutocompleteItems: filteredAutocompleteItems,
      onDelete: onDelete,
      onSelectItem: onSelectItem,
      getItemName: getItemName,
      input: input
    };
  }
});