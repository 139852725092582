























































import { defineComponent, PropType, reactive } from '@nuxtjs/composition-api'
import { DegressivityRate } from '~/types/models'
import SymbolInput from '~/components/content/SymbolInput.vue'

interface State {
  degressivityRates: DegressivityRate[]
}

export default defineComponent({
  name: 'DiscountsInput',
  components: { SymbolInput },
  props: {
    value: {
      type: Array as PropType<DegressivityRate[]>,
      required: true,
    },
    deleteLabel: {
      type: String,
      required: false,
      default: '',
    },
    defaultDegressivityRate: {
      type: Object as PropType<DegressivityRate>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      // Immutable array here to avoir mutating any Vuex state when mutating the prop
      degressivityRates: [...props.value],
    })

    const onNightNumberUpdated = ($event: string, index: number) => {
      if (parseInt($event)) {
        state.degressivityRates[index].nightNumber = parseInt($event)
      } else {
        state.degressivityRates[index].nightNumber = 0
      }
    }

    const onPercentDiscountUpdated = ($event: string, index: number) => {
      if (parseFloat($event)) {
        state.degressivityRates[index].percentDiscount = parseFloat($event.replace(/,/g, '.'))
      } else {
        state.degressivityRates[index].percentDiscount = 0
      }
    }

    const onAddDegressivityRate = () => {
      // Immutable method to avoid mutating any Vuex state when mutating the prop
      const { nightNumber, percentDiscount } = props.defaultDegressivityRate
      state.degressivityRates = [...state.degressivityRates, { nightNumber, percentDiscount }]
      emit('input', state.degressivityRates)
    }

    const onRemoveDegressivityRate = (index: number) => {
      // Immutable method to avoid mutating any Vuex state when mutating the prop
      state.degressivityRates = state.degressivityRates.filter((_, idx) => idx !== index)
      emit('input', state.degressivityRates)
    }

    return {
      state,
      onAddDegressivityRate,
      onNightNumberUpdated,
      onPercentDiscountUpdated,
      onRemoveDegressivityRate,
    }
  },
})
