import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import max from 'lodash/max'
import min from 'lodash/min'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import reverse from 'lodash/reverse'
import uniq from 'lodash/uniq'
import unescape from 'lodash/unescape'

export const lodashCustom = {
  cloneDeep,
  filter,
  isEmpty,
  kebabCase,
  map,
  max,
  min,
  orderBy,
  reduce,
  reverse,
  uniq,
  unescape,
}

const lodashPlugin = {
  install(VueN: typeof Vue) {
    VueN.prototype.$_ = lodashCustom
  },
}

Vue.use(lodashPlugin)
