import { RouteConfig } from '@nuxt/types/config/router'
import { computed, useContext, watch, WritableComputedRef } from '@nuxtjs/composition-api'

export interface Route {
  path: string;
  component: any;
  children: any[];
  name: string;
  meta: Record<string, string>
}

interface HookParams {
  childRoutes: RouteConfig[],
  root: string,
  defaultRoute: string,
  routeParams?: Record<string, string>
}

export interface TabNavigationHook {
  currentIndex: WritableComputedRef<number>
}

function useTabsNavigation(params: HookParams): TabNavigationHook {
  const { app, route } = useContext()
  const { childRoutes, root, defaultRoute, routeParams = {} } = params

  const currentIndex = computed({
    get: () => {
      let index = 0
      index = childRoutes.findIndex((_route) => {
        return _route.name === route.value.name?.split('___')[0]
      })
      return index >= 0 ? index : 0
    },
    set: async(value) => {
      if (app.router && value >= 0 && value !== currentIndex.value) {
        await app.router.push(app.localePath({
          name: childRoutes[value].name,
          params: routeParams,
        }))
      }
    },
  })

  // If root route, push first tab.
  function redirectIfRoot(routeName: string | null | undefined) {
    if (app.router && routeName?.includes(root) && routeName.split('___')[0] === root) {
      return app.router.replace(app.localePath({
        name: defaultRoute,
        params: routeParams,
      }))
    }
  }

  redirectIfRoot(route.value.name)

  watch(() => route.value.name, val => redirectIfRoot(val))

  return { currentIndex }
}

export default useTabsNavigation
