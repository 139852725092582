import serviceUnitsMutations from './mutations'
import serviceUnitsGetters from './getters'
import serviceUnitsActions from './actions'
import serviceUnitsState from './state'

export const serviceUnits = {
  namespaced: true,
  state: serviceUnitsState,
  actions: serviceUnitsActions,
  mutations: serviceUnitsMutations,
  getters: serviceUnitsGetters,
}
