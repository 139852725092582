import { DateType } from '~/types/DateType'
import { BookingCharge } from './BookingCharge'
import { BookingOption } from './BookingOption'
import { BookingGuest } from './BookingGuest'
import { BookingGiftCard } from './BookingGiftCard'
import { Customer, GenderEnum } from '~/types/models/Customer'
import { Address } from './Address'
import { BookingDiscount } from './BookingDiscount'
import { Money } from '~/types/models/Money'

export enum BookingStatusEnum {
  BOOKING_PENDING = 'BOOKING_PENDING',
  BOOKING_CANCELLED_CUSTOMER = 'BOOKING_CANCELLED_CUSTOMER',
  BOOKING_CANCELLED_PROVIDER = 'BOOKING_CANCELLED_PROVIDER',
  BOOKING_CANCELLED_SYSTEM = 'BOOKING_CANCELLED_SYSTEM',
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  BOOKING_STANDBY = 'BOOKING_STANDBY',
  BOOKING_CLOSED = 'BOOKING_CLOSED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  SUCCESS = 'SUCCESS'
}

export function filterBookingsStatusCompleteAccepted(): BookingStatusEnum[] {
  return Object.values(BookingStatusEnum).filter(status => {
    return status !== BookingStatusEnum.BOOKING_CANCELLED_SYSTEM &&
      status !== BookingStatusEnum.CANCELLED &&
      status !== BookingStatusEnum.BOOKING_PENDING &&
      status !== BookingStatusEnum.BOOKING_CANCELLED_CUSTOMER &&
      status !== BookingStatusEnum.BOOKING_CANCELLED_PROVIDER &&
      status !== BookingStatusEnum.FAILED
  })
}

/**
 * @deprecated. Use `BookingStatusColorsEnum` instead.
 */
export enum BookingStatusClassesEnum {
  BOOKING_CANCELLED_CUSTOMER = 'is-danger',
  BOOKING_CANCELLED_PROVIDER = 'is-danger',
  BOOKING_CANCELLED_SYSTEM = 'is-danger',
  BOOKING_CLOSED = 'is-text',
  BOOKING_CONFIRMED = 'is-success',
  BOOKING_PENDING = 'is-warning',
  BOOKING_STANDBY = 'is-warning',
  CANCELLED = 'is-danger',
  CREATED = 'is-primary',
  FAILED = 'is-danger',
  SUCCESS = 'is-success',
  PAYMENT_RECEIVED = 'is-success',
  GIFTCARD_PAYMENT_RECEIVED = 'is-success',
  REFUND_RECEIVED = 'is-warning',
  UPDATED = 'is-warning'
}

/**
 * @todo we cannot use computed values from ThemeColors. Maybe this should be computed from TW config file and not a type.
 */
export enum BookingStatusColorsEnum {
  BOOKING_CANCELLED_CUSTOMER = 'red',
  BOOKING_CANCELLED_PROVIDER = 'red',
  BOOKING_CANCELLED_SYSTEM = 'red',
  BOOKING_CLOSED = 'gray',
  BOOKING_CONFIRMED = 'green',
  BOOKING_PENDING = 'orange',
  BOOKING_STANDBY = 'orange',
  CANCELLED = 'red',
  CREATED = 'primary',
  FAILED = 'red',
  SUCCESS = 'green',
  PAYMENT_RECEIVED = 'green',
  GIFTCARD_PAYMENT_RECEIVED = 'green',
  REFUND_RECEIVED = 'orange',
  UPDATED = 'orange'
}

export enum BookingIconClassesEnum {
  BOOKING_CANCELLED_CUSTOMER = 'usi-cross',
  BOOKING_CANCELLED_PROVIDER = 'usi-cross',
  BOOKING_CANCELLED_SYSTEM = 'usi-cross',
  BOOKING_CLOSED = 'usi-cross',
  BOOKING_CONFIRMED = 'usi-validation',
  BOOKING_PENDING = 'usi-contract',
  BOOKING_STANDBY = 'usi-treatment',
  CANCELLED = 'usi-cross',
  CREATED = 'usi-contract',
  FAILED = 'usi-cross',
  PAYMENT_RECEIVED = 'usi-euro-full',
  GIFTCARD_PAYMENT_RECEIVED = 'usi-euro-full',
  SUCCESS = 'usi-contract',
  UPDATED = 'usi-pencil',
  REFUND_RECEIVED = 'usi-euro-full'
}

export enum BookingEventsEnum {
  'BOOKING_PENDING' = 'BOOKING_PENDING',
  'BOOKING_CANCELLED_CUSTOMER' = 'BOOKING_CANCELLED_CUSTOMER',
  'BOOKING_CANCELLED_PROVIDER' = 'BOOKING_CANCELLED_PROVIDER',
  'BOOKING_CANCELLED_SYSTEM' = 'BOOKING_CANCELLED_SYSTEM',
  'CANCELLED' = 'CANCELLED',
  'FAILED' = 'BOOKING_FAILED',
  'BOOKING_CONFIRMED' = 'BOOKING_CONFIRMED',
  'BOOKING_STANDBY' = 'BOOKING_STANDBY',
  'BOOKING_CLOSED' = 'BOOKING_CLOSED',
  'CREATED' = 'CREATED',
  'UPDATED' = 'UPDATED',
  'PAYMENT_RECEIVED' = 'PAYMENT_RECEIVED',
  'GIFTCARD_PAYMENT_RECEIVED' = 'GIFTCARD_PAYMENT_RECEIVED',
  'SUCCESS' = 'SUCCESS',
  'REFUND_RECEIVED' = 'REFUND_RECEIVED'
}

export enum BookingActionEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

export interface IBookingTimelineItem {
  id: number
  eventType: BookingEventsEnum
  giftCard?: number | null
  loggedAt: DateType
  data: Partial<Booking> & Money
}


export interface BookingEvent {
  action: BookingActionEnum
  data: Partial<Booking>
  id: number
  loggedAt: DateType
}

export interface Booking {
  $isDirty?: boolean
  adultsNumber: number
  bookingCharge?: BookingCharge
  bookingGiftCards: BookingGiftCard[]
  cart: number
  childrenNumber: number
  createdAt: DateType
  customer: number
  discounts: BookingDiscount[]
  domain: number
  end: DateType
  giftCardSpendings: number[]
  guests: BookingGuest[]
  hostMessage: string | null
  id: number
  infantsNumber: number
  marketplace: number
  options: BookingOption[]
  payments: number[]
  promocodes: number[]
  provider: number
  service: number
  serviceUnit: number
  start: DateType
  status: BookingStatusEnum
  totalPersons: number
  updatedAt: DateType
  thirdPartyData: ThirdPartyData | null
}

export interface ThirdPartyData {
  gender: GenderEnum
  firstName: string
  lastName: string
  isNotificationReceiver: boolean
  contactEmail: string | null
  phoneNumber: string | null
}

export interface UpdateBookingForm {
  booking: number
  bookingCharge: Partial<BookingCharge>
  customer: Customer
  customerAddress: Address
  discounts: BookingDiscount[]
  end: DateType
  guests: BookingGuest[]
  marketplace: number
  options: BookingOption[]
  service: number
  start: DateType
  force: boolean
}
