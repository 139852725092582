import { createState, createEntity } from '@kissmylabs/vuex-entitystore'
import { Promocode } from '~/types/models'
import { DateTime } from 'luxon'
import { PromocodeCreateForm, PromocodeState } from './types'

export const promocode = createEntity<Promocode>('promocode')

export const baseCreateForm = (): PromocodeCreateForm => ({
  code: '',
  description: '',
  marketplaces: [],
  maxUses: null,
  name: '',
  percentDiscount: 0,
  periods: [{
    start: new Date(),
    end: DateTime.fromJSDate(new Date()).plus({ day: 1 }).toJSDate(),
  }],
  usageCount: 0,
})

export const state = (): PromocodeState => ({
  ...createState<Promocode>(promocode),
  createForm: baseCreateForm(),
})

export default state


