import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { CreateServiceUnitPayload, ServiceUnit } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<ServiceUnit>(),

  async fetchAllForService({ commit }, serviceId: number): Promise<void> {
    const res = await this.app.$api.get(`services/${serviceId}/units`)
    const servicesUnits = res.data
    if (servicesUnits.length) {
      return commit(BaseMutationTypes.CREATE_MANY, servicesUnits)

    }
  },
  // TODO fetch all for domain to have on commit

  async postOne({ commit }, payload: CreateServiceUnitPayload): Promise<void> {
    const res = await this.app.$api.post(`services/${payload.service}/units`, payload)
    return commit(BaseMutationTypes.CREATE, res)
  },
})

export default actions
