import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.filter.js";
import { computed, defineComponent } from '@nuxtjs/composition-api';
import useAddress from '~/composable/useAddress';
import useModeration from '~/composable/useModeration';
import ModeratableTextArea from '~/components/moderation/ModeratableTextArea.vue';
import LocationForm from '~/components/form/LocationForm.vue';
import HRSwitch from '~/components/HRSwitch.vue';
export default defineComponent({
  name: 'DomainLocation',
  components: {
    LocationForm: LocationForm,
    ModeratableTextArea: ModeratableTextArea,
    HRSwitch: HRSwitch
  },
  middleware: ['domainAddressMiddleware'],
  props: {
    asAdmin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup: function setup() {
    var _useDomain = useDomain(),
        getDomainField = _useDomain.getDomainField,
        currentDomain = _useDomain.currentDomain;

    var _useModeration = useModeration(),
        getDomainFieldToModerate = _useModeration.getDomainFieldToModerate,
        domainFieldPreviousVersion = _useModeration.domainFieldPreviousVersion;

    var _useAddress = useAddress(),
        getAddressField = _useAddress.getAddressField;

    var currentAddressId = computed(function () {
      return currentDomain.value.address;
    }); // Hardcoded value here since these don't change and it's the easiest way

    var PUBLIC_TRANSPORTS_ACCESSIBLE_ID = 7;
    var isPublicTransportAccessible = computed({
      get: function get() {
        return getDomainField('sustainabilityActions').value.includes(PUBLIC_TRANSPORTS_ACCESSIBLE_ID);
      },
      set: function set(val) {
        if (val) {
          getDomainField('sustainabilityActions').value = [].concat(_toConsumableArray(getDomainField('sustainabilityActions').value), [PUBLIC_TRANSPORTS_ACCESSIBLE_ID]);
        } else {
          getDomainField('sustainabilityActions').value = getDomainField('sustainabilityActions').value.filter(function (id) {
            return id !== PUBLIC_TRANSPORTS_ACCESSIBLE_ID;
          });
        }
      }
    });
    return {
      currentAddressId: currentAddressId,
      currentDomain: currentDomain,
      domainFieldPreviousVersion: domainFieldPreviousVersion,
      getAddressField: getAddressField,
      getDomainField: getDomainField,
      getDomainFieldToModerate: getDomainFieldToModerate,
      isPublicTransportAccessible: isPublicTransportAccessible
    };
  }
});