






























import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import usePromocodes from '~/composable/usePromocodes'
import PromocodeForm from '~/components/promocodes/PromocodeForm.vue'

export default defineComponent({
  name: 'PromocodeId',
  components: { PromocodeForm },
  middleware: ['promocodeMiddleware'],
  setup() {
    const { params, app: { $accessor, router, localePath } } = useContext()
    const { getPromocodeField, patchOne } = usePromocodes()

    const promocode = computed(() => $accessor.promocodes.getOne(parseInt(params.value.id)))

    const onSubmit = async() => {
      await patchOne({
        id: promocode.value.id,
        payload: promocode.value,
      })
      router!.push(localePath('promocodes'))
    }

    return {
      onSubmit,
      getPromocodeField,
      promocode,
    }
  },
})

