






























import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import ServicePricingDetails from '~/components/calendar/servicePricing/ServicePricingDetails.vue'
import useServicePricings from '~/composable/useServicePricings'
import { ServicePricing } from '~/types/models'

export default defineComponent({
  name: 'ServicePricingDeleteConfirm',
  components: { ServicePricingDetails },
  props: {
    pricing: {
      type: Object as PropType<ServicePricing>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { deleteOne } = useServicePricings()
    const { app: { $accessor } } = useContext()

    async function onDelete() {
      $accessor.calendar.UI_LOADING_START()

      await deleteOne(props.pricing)
      $accessor.calendar.UI_LOADING_END()
      emit('deleted')
    }

    return { onDelete }
  },
})
