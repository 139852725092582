import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MarketplaceCreateProvider"},[_c('AppContentHeading',{attrs:{"data-cy":"CreateProviderHeading"}},[_vm._v("\n    "+_vm._s(_vm.$t('marketplace.create_provider'))+"\n  ")]),_vm._v(" "),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('BasePageSection',{attrs:{"title":_vm.$t('marketplace.provider'),"aria-id":"addProvider"}},[_c('div',{staticClass:"columns is-multiline align-items-flex-end"},[_c('ValidationProvider',{staticClass:"column is-half-desktop",attrs:{"name":"email","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"data-cy":"CreateProviderEmailField","label":_vm.$t('form.fields.email') + ' *',"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{attrs:{"type":"email","aria-required":"true"},model:{value:(_vm.getField('email').value),callback:function ($$v) {_vm.$set(_vm.getField('email'), "value", $$v)},expression:"getField('email').value"}})],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"column is-half-desktop is-hidden-touch"}),_vm._v(" "),_c('div',{staticClass:"column is-full mr-0"},[_c('BField',{staticClass:"tw-space-x-20",attrs:{"data-cy":"CreateProviderIsExternalSwitch","grouped":""}},[_c('HRSwitch',{attrs:{"label":_vm.$t('form.fields.is_external')},model:{value:(_vm.isExternal),callback:function ($$v) {_vm.isExternal=$$v},expression:"isExternal"}}),_vm._v(" "),(_vm.isExternal)?_c('ExternalPlatformSelect',{staticClass:"is-half",attrs:{"data-cy":"CreateProviderExternalPlatformSelect","options":_vm.availableExternalPlatforms},model:{value:(_vm.externalPlatformId),callback:function ($$v) {_vm.externalPlatformId=$$v},expression:"externalPlatformId"}}):_vm._e()],1)],1)],1)]),_vm._v(" "),_c('BasePageSection',{attrs:{"title":_vm.$t('marketplace.user'),"aria-id":"addUser"}},[_c('div',{staticClass:"columns is-multiline"},[_c('ValidationProvider',{staticClass:"column is-full is-one-third-desktop",attrs:{"name":"email","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.email') + ' *',"type":{ 'is-danger': errors[0], 'is-success': valid },"data-cy":"CreateProviderUserEmailField","message":errors}},[_c('BInput',{attrs:{"type":"email","aria-required":"true"},model:{value:(_vm.getCreateUserFormField('email').value),callback:function ($$v) {_vm.$set(_vm.getCreateUserFormField('email'), "value", $$v)},expression:"getCreateUserFormField('email').value"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-full is-one-third-desktop",attrs:{"name":"username","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.username') + ' *',"type":{ 'is-danger': errors[0], 'is-success': valid },"data-cy":"CreateProviderUserNameField","message":errors}},[_c('BInput',{attrs:{"type":"text","aria-required":"true"},model:{value:(_vm.getCreateUserFormField('username').value),callback:function ($$v) {_vm.$set(_vm.getCreateUserFormField('username'), "value", $$v)},expression:"getCreateUserFormField('username').value"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-full is-one-third-desktop",attrs:{"name":"password","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.password') + ' *',"type":{ 'is-danger': errors[0], 'is-success': valid },"data-cy":"CreateProviderUserPasswordField","message":errors}},[_c('BInput',{attrs:{"size":"is-medium","type":"password","password-reveal":""},model:{value:(_vm.getCreateUserFormField('password').value),callback:function ($$v) {_vm.$set(_vm.getCreateUserFormField('password'), "value", $$v)},expression:"getCreateUserFormField('password').value"}})],1)]}}],null,true)})],1)]),_vm._v(" "),_c('BasePageSection',{attrs:{"title":_vm.$t('marketplace.establishment') +" (" + (_vm.domainForms ? _vm.domainForms.length : 0) + ")","aria-id":"addEstablishment"}},[_vm._l((_vm.domainForms),function(domain,index){return _c('div',{key:index,staticClass:"columns is-multiline",attrs:{"data-cy":"CreateProviderDomainForm"}},[_c('ValidationProvider',{staticClass:"column is-full is-one-third-desktop",attrs:{"name":"domainName","rules":"translation","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.domainName') + " " + (index + 1),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"data-cy":"CreateProviderDomainNameInput"}},[_c('TranslatableInput',{attrs:{"size":"is-medium","content-scope":"domain"},model:{value:(_vm.getDomainFormItemField('commercialName', index).value),callback:function ($$v) {_vm.$set(_vm.getDomainFormItemField('commercialName', index), "value", $$v)},expression:"getDomainFormItemField('commercialName', index).value"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-full is-one-third-desktop",attrs:{"name":"email","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.booking_email') + ' *',"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"data-cy":"CreateProviderBookingEmailField"}},[_c('BInput',{attrs:{"type":"email","aria-required":"true"},model:{value:(_vm.getDomainFormItemField('bookingEmail', index).value),callback:function ($$v) {_vm.$set(_vm.getDomainFormItemField('bookingEmail', index), "value", $$v)},expression:"getDomainFormItemField('bookingEmail', index).value"}})],1)]}}],null,true)}),_vm._v(" "),(_vm.isExternal && _vm.getDomainFormItemField('externalId', index).value)?_c('DomainExternalPlatformDataInput',{attrs:{"platform-id":_vm.externalPlatformId},model:{value:(_vm.getDomainFormExternalIdField('data', index).value),callback:function ($$v) {_vm.$set(_vm.getDomainFormExternalIdField('data', index), "value", $$v)},expression:"getDomainFormExternalIdField('data', index).value"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"is-1 tw-flex tw-items-center"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({ content: _vm.$t('actions.delete'), class: 'tw-text-red' }),expression:"{ content: $t('actions.delete'), class: 'tw-text-red' }",modifiers:{"left":true}}],staticClass:"delete is-danger is-outlined is-paddingless",attrs:{"data-cy":"CreateProviderRemoveDomainButton"},on:{"click":function($event){return _vm.onRemoveDomain(index)}}})])],1)}),_vm._v(" "),_c('BaseLink',{attrs:{"icon-left":"plus","icon-pack":"usi","data-cy":"CreateProviderAddDomainButton"},on:{"click":_vm.onAddDomain}},[_vm._v("\n        "+_vm._s(_vm.$t('common.add_domain'))+"\n      ")])],2),_vm._v(" "),_c('section',{staticClass:"has-text-centered"},[_c('BaseButton',{attrs:{"size":"is-medium","aria-disabled":invalid,"disabled":invalid,"data-cy":"CreateProviderSubmitButton"},on:{"click":_vm.postProvider}},[_vm._v("\n        "+_vm._s(_vm.$t('actions.save'))+"\n      ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }