








import { defineComponent, useContext } from '@nuxtjs/composition-api'
import CalendarFullScreenModal from '~/components/calendar/CalendarFullScreenModal.vue'

export default defineComponent({
  name: 'CalendarCreateMany',
  components: {
    CalendarFullScreenModal,
  },
  setup() {
    const { params, app: { router, localePath } } = useContext()

    function onGoBack() {
      router!.push(localePath({
        name: 'calendar',
        params: {
          provider: params.value.provider,
          domain: params.value.domain,
        },
      }))
    }

    return {
      onGoBack,
    }
  },
})
