import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import { defineComponent } from '@nuxtjs/composition-api';
import AppContentHeading from '~/components/content/AppContentHeading.vue';
import { mainMenuRoutes } from '~/routes/mainMenuRoutes';
export default defineComponent({
  name: 'ReviewsPage',
  components: {
    AppContentHeading: AppContentHeading
  },
  setup: function setup() {
    var _useContext = useContext(),
        params = _useContext.params;

    var _useFeatureToggle = useFeatureToggle(),
        isEnabledRoute = _useFeatureToggle.isEnabledRoute;

    var providerId = computed(function () {
      return parseInt(params.value.provider, 10);
    });
    var domainId = computed(function () {
      return parseInt(params.value.domain);
    });
    var reviewsRoutes = mainMenuRoutes.find(function (route) {
      return route.name === 'reviews';
    }).children; // TODO: Remove 'as any' if we find a way for TS not to throw errors over intersection types

    var enabledRoutes = reviewsRoutes.filter(function (route) {
      return isEnabledRoute(route);
    }); // We use this hook only to trigger the navigation but we don't use tabs here so no return or anything.

    useTabsNavigation({
      // TODO: Remove 'as any' if we find a way for TS not to throw errors over intersection types
      childRoutes: enabledRoutes,
      root: 'reviews',
      defaultRoute: 'reviews-list',
      routeParams: {
        provider: providerId.value.toString(),
        domain: domainId.value.toString()
      }
    });
  }
});