import domainActivitiesEntriesMutations from './mutations'
import domainActivitiesEntriesGetters from './getters'
import domainActivitiesEntriesActions from './actions'
import domainActivitiesEntriesState from './state'

export const domainActivitiesEntries = {
  namespaced: true,
  state: domainActivitiesEntriesState,
  actions: domainActivitiesEntriesActions,
  mutations: domainActivitiesEntriesMutations,
  getters: domainActivitiesEntriesGetters,
}
