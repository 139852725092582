import { createEntity, createState } from '@kissmylabs/vuex-entitystore'
import { Domain, DomainCreateForm, DomainExternalId, DomainStatusEnum, OnlineBookingStatusEnum } from '~/types/models'
import { defaultEmptyMultiMarketplaceTranslation, defaultEmptyTranslation } from '~/helpers/locale'
import { DomainState } from './types'
import { getDefaultDomainExternalId } from '~/helpers'

export const domain = createEntity<Domain>('domain')

export function baseCreateForm(externalId: DomainExternalId | null = getDefaultDomainExternalId()): DomainCreateForm {
  return {
    abcdUrl: defaultEmptyTranslation(),
    access: defaultEmptyTranslation(),
    address: null,
    around: defaultEmptyTranslation(),
    bookingEmail: '',
    cancelScore: 10,
    checkinTimeDetails: defaultEmptyTranslation(),
    checkinTimeEnd: '12:00:00',
    checkinTimeStart: '09:00:00',
    checkoutTimeEnd: '12:00:00',
    checkoutTimeStart: '09:00:00',
    commercialName: defaultEmptyTranslation(),
    description: defaultEmptyMultiMarketplaceTranslation(),
    domainAmenitiesEntries: [],
    externalId,
    flexibleCheckinTimeEnd: '12:00:00',
    flexibleCheckinTimeStart: '09:00:00',
    flexibleCheckoutTimeEnd: '09:00:00',
    flexibleCheckoutTimeStart: '19:00:00',
    isActive: false,
    isFlexibleCheckinout: false,
    isNoIndex: false,
    isPublicTransportAccessible: false,
    isSmsNotificationEnabled: false,
    managerWord: defaultEmptyTranslation(),
    maxAgeChild: 17,
    maxAgeInfant: 2,
    maxHoursBeforeBooking: 8760,
    minHoursBeforeBooking: 24,
    name: '',
    onlineBookingStatus: OnlineBookingStatusEnum.DISABLED,
    openingDetails: defaultEmptyTranslation(),
    ownersPresentation: defaultEmptyTranslation(),
    ownersWord: defaultEmptyMultiMarketplaceTranslation(),
    practicalInfo: defaultEmptyTranslation(),
    provider: 0,
    seoH1: defaultEmptyTranslation(),
    seoMetaDesc: defaultEmptyTranslation(),
    seoTitle: defaultEmptyTranslation(),
    status: DomainStatusEnum.DISABLED,
    title: defaultEmptyTranslation(),
    visitsDiscoveriesContent: defaultEmptyTranslation(),
    visitsDiscoveriesTitle: defaultEmptyTranslation(),
    customRules: defaultEmptyTranslation(),
    metadata: {
      cancelPolicy: 'MODERATE',
      claimAddress: null,
      claimTimeLimitInDays: null,
      ecoCharter: false,
      isPartyAccepted: false,
    },

  }
}

export const state = (): DomainState => ({
  ...createState<Domain>(domain),
  createForm: [baseCreateForm()],
})

export default state
