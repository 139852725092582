import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { ThemeColors } from '~/types/Tailwind';
import StatusBadge from '~/components/badges/StatusBadge.vue';
export default defineComponent({
  name: 'OptionsListItem',
  components: {
    StatusBadge: StatusBadge
  },
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var _useDomain = useDomain(),
        currentDomain = _useDomain.currentDomain;

    var appliesOnAllServices = computed(function () {
      return currentDomain.value.services.every(function (id) {
        return props.option.appliesOnServices.includes(id);
      });
    });
    var servicesCount = computed(function () {
      return currentDomain.value.services.length;
    });
    return {
      servicesCount: servicesCount,
      appliesOnAllServices: appliesOnAllServices,
      ThemeColors: ThemeColors
    };
  }
});