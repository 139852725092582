import { render, staticRenderFns } from "./CalendarFilters.vue?vue&type=template&id=4b4b60c0&"
import script from "./CalendarFilters.vue?vue&type=script&lang=ts&"
export * from "./CalendarFilters.vue?vue&type=script&lang=ts&"
import style0 from "./CalendarFilters.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockSubtitle: require('/var/app/components/common/BlockSubtitle.vue').default})
