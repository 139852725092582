import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api'
import { DomainActivity, DomainActivityTypeEnum, DomainActivityEntry, NewDomainActivityEntry } from '~/types/models'
import { PatchPayload } from '~/helpers/api'
import useI18n from '~/composable/useI18n'

interface DomainActivitiesHook {
  nearbyActivities: ComputedRef<DomainActivity[]>
  availableOnsiteActivities: ComputedRef<DomainActivity[]>
  nearbyEntries: ComputedRef<DomainActivityEntry[]>
  onsiteEntries: ComputedRef<DomainActivityEntry[]>
  deleteActivityEntry: (id: number) => Promise<void>
  createActivityEntry: (payload: NewDomainActivityEntry) => Promise<void>
  patchActivityEntry: (payload: PatchPayload<DomainActivityEntry>) => Promise<void>
}

function useDomainActivities(): DomainActivitiesHook {
  const {
    params,
    app: { $accessor },
  } = useContext()
  const { currentRegionLocale } = useI18n()
  const domainId = computed(() => parseInt(params.value.domain))

  const collator = new Intl.Collator()
  const sortActivities = (a: DomainActivity, b: DomainActivity) => collator.compare(a.name[currentRegionLocale.value as never], b.name[currentRegionLocale.value as never])

  const nearbyActivities = computed(() =>
    $accessor.domainActivities.getWhereArray((activity) => activity.type === DomainActivityTypeEnum.NEARBY)
      .filter(activity => !$accessor.domainActivitiesEntries.getAllActivityIds
        .includes(activity.id))
      .sort(sortActivities),
  )

  const availableOnsiteActivities = computed(() => $accessor.domainActivities.getWhereArray((activity) => activity.type === DomainActivityTypeEnum.ESTABLISHMENT)
    .filter(activity => !$accessor.domainActivitiesEntries.getAllActivityIds
      .includes(activity.id))
    .sort(sortActivities),
  )

  const nearbyEntries = computed(() => $accessor.domainActivitiesEntries.getAllArray().filter(entry =>
    $accessor.domainActivities.getWhereArray(
      activity => activity.type === DomainActivityTypeEnum.NEARBY
      && activity.id === entry.domainActivity
      && entry.domain === domainId.value,
    ).length !== 0,
  ))

  const onsiteEntries = computed(() => $accessor.domainActivitiesEntries.getAllArray().filter(entry =>
    $accessor.domainActivities.getWhereArray(
      activity => activity.type === DomainActivityTypeEnum.ESTABLISHMENT
      && activity.id === entry.domainActivity
      && entry.domain === domainId.value,
    ).length !== 0,
  ))

  const deleteActivityEntry = async(id: number) => {
    await $accessor.domainActivitiesEntries.deleteOne({
      id,
      domainId: domainId.value,
    })
  }

  const createActivityEntry = async(payload: NewDomainActivityEntry) => {
    await $accessor.domainActivitiesEntries.postOne(payload)
  }

  const patchActivityEntry = async(payload: PatchPayload<DomainActivityEntry>) => {
    await $accessor.domainActivitiesEntries.patchOne(payload)
  }

  return {
    nearbyActivities,
    availableOnsiteActivities,
    nearbyEntries,
    onsiteEntries,
    deleteActivityEntry,
    createActivityEntry,
    patchActivityEntry,
  }
}

export default useDomainActivities
