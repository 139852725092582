




































import { defineComponent } from '@nuxtjs/composition-api'
import TermsInfo from '~/components/account/TermsInfo.vue'

export default defineComponent({
  name: 'TermsAndCondition',
  components: { TermsInfo },
})
