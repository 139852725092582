




































import { DrawerDisplayModeEnum } from '~/store/calendar/types'
import { CalendarDrawerNameEnum } from '~/types/Calendar'
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import useCalendar from '~/composable/useCalendar'
import CalendarDrawer from '~/components/calendar/drawers/CalendarDrawer.vue'
import BookingConditionDetails from '~/components/calendar/bookingCondition/BookingConditionDetails.vue'
import BookingConditionEditForm from '~/components/calendar/bookingCondition/BookingConditionEditForm.vue'
import BookingConditionDeleteConfirm from '~/components/calendar/bookingCondition/BookingConditionDeleteConfirm.vue'

export default defineComponent({
  name: 'CalendarBookingConditionDrawer',
  components: {
    CalendarDrawer,
    BookingConditionDetails,
    BookingConditionEditForm,
    BookingConditionDeleteConfirm,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { app: { $accessor, i18n } } = useContext()
    const { ui, getCalendarDrawerTitle, isCalendarUserEnabled } = useCalendar()

    const bookingCondition = computed(() => {
      if (ui.value.drawerDisplayMode !== DrawerDisplayModeEnum.CREATE) {
        return $accessor.bookingConditions.getFirstActive()
      }
    })

    const bookingConditionBackground = computed(() => {
      if (bookingCondition.value) {
        if (bookingCondition.value.isDefault) {
          return CalendarDrawerNameEnum.BOOKING_CONDITION_DEFAULT
        }
        return CalendarDrawerNameEnum.BOOKING_CONDITION
      }
    })

    const isDrawerBookingConditionOpen = computed(() => bookingCondition.value && ui.value.drawer === CalendarDrawerNameEnum.BOOKING_CONDITION)

    const title = computed(() => {
      return i18n.t(`calendar.modal_titles.conditions_${getCalendarDrawerTitle(ui.value.drawerDisplayMode, bookingCondition.value)}`)
    })

    function onEdit() {
      if (bookingCondition.value) {
        $accessor.calendar.UI_OPEN_DRAWER({
          drawer: CalendarDrawerNameEnum.BOOKING_CONDITION,
          mode: DrawerDisplayModeEnum.UPDATE,
          data: {
            startDate: ui.value.drawerData.startDate,
            serviceId: bookingCondition.value.service,
          },
        })
      }
    }

    function onDelete() {
      $accessor.calendar.UI_OPEN_DRAWER({
        drawer: CalendarDrawerNameEnum.BOOKING_CONDITION,
        mode: DrawerDisplayModeEnum.DELETE,
        data: {},
      })
    }

    function onClose() {
      emit('on-close')
      $accessor.calendar.RESET_UI_DRAWER()
      $accessor.bookingConditions.resetActive()
    }
    function onClickOutside() {
      onClose()
      emit('on-click-outside')
    }


    return {
      bookingCondition,
      bookingConditionBackground,
      CalendarDrawerNameEnum,
      DrawerDisplayModeEnum,
      isCalendarUserEnabled,
      isDrawerBookingConditionOpen,
      onClickOutside,
      onClose,
      onDelete,
      onEdit,
      title,
      ui,
    }
  },
})
