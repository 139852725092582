import { Duration } from 'luxon'
import { DrawerDisplayModeEnum } from '~/store/calendar/types'
import { Unavailability } from '~/types/models/Unavailability'
import {
  Period,
  BookingCondition, BookingConditionCalendar,
  ServiceDiscount, ServiceDiscountCalendar,
  ServicePricing, ServicePricingCalendar,
  ServiceUnitUnavailabilityCalendar,
} from '~/types/models'
import { WeekDaysEnum } from '~/helpers'
import { TranslateResult } from 'vue-i18n'

export interface DataReceivedWorker {
  view: CalendarEntitiesEnum,
  calendarUnavails: ServiceUnitUnavailabilityCalendar[],
  calendarPricings: ServicePricingCalendar[],
  calendarBookingCondition: BookingConditionCalendar[],
  calendarDiscount: ServiceDiscountCalendar[],
  calendarFilters: Record<string, boolean>,
  from: Date,
  to: Date,
}

export enum CalendarEntitiesEnum {
  UNAVAILABILITIES = 'UNAVAILABILITIES',
  PRICING = 'PRICING',
  BOOKING_CONDITION = 'BOOKING_CONDITION',
  DISCOUNT = 'DISCOUNT',
}

export const CalendarEntitiesArray = Object.values(CalendarEntitiesEnum).map(entity => entity)

export enum CalendarViewsEnum {
  TWO_MONTHS = 'TWO_MONTHS',
  TWO_WEEKS = 'TWO_WEEKS',
}

export enum CreateDeleteManyNavEnum {
  CREATE_MANY = 'CREATE_MANY',
  DELETE_MANY = 'DELETE_MANY',
}

export enum CalendarDrawerNameEnum {
  UNAVAILABILITY = 'CalendarUnavailabilityDrawer',
  PRICING = 'CalendarPricingDrawer',
  DEFAULT_PRICING = 'CalendarDefaultPricingDrawer',
  BOOKING_CONDITION = 'CalendarBookingConditionDrawer',
  BOOKING_CONDITION_DEFAULT = 'CalendarBookingConditionDefaultDrawer',
  BOOKING = 'CalendarBookingDrawer',
  DISCOUNT = 'CalendarDiscountDrawer',
  CREATE = 'CalendarCreateDrawer',
  DELETE = 'CalendarDeleteMany',
  ICALS = 'CalendarIcalsDrawer'
}

export enum CalendarRecurrenceEnum {
  FIVE_YEARS = 'FIVE_YEARS',
  NEXT_YEAR = 'NEXT_YEAR',
  NONE = 'NONE',
  TWO_YEARS = 'TWO_YEARS',
}

export enum CalendarRecurrenceValueEnum {
  FIVE_YEARS = 5,
  NEXT_YEAR = 1,
  TWO_YEARS = 2,
  NONE = 0,
}

export enum CalendarFiltersEnum {
  BOOKING = 'BOOKING',
  UNAVAILABILITY = 'UNAVAILABILITY',
  ICALS = 'ICALS',
  PRICING_DEFAULT = 'PRICING_DEFAULT',
  PRICING_CUSTOM = 'PRICING_CUSTOM',
  DURATION = 'DURATION',
  DISCOUNT = 'DISCOUNT',
}

export const CalendarFiltersArray = Object.values(CalendarFiltersEnum).map(filter => filter)

export enum CalendarGroupFiltersEnum {
  PRICING = 'PRICING',
  BOOKINGS_UNAVAILABILITIES_ICALS = 'BOOKINGS_UNAVAILABILITIES_ICALS',
  DURATION = 'DURATION',
  DISCOUNT = 'DISCOUNT',
}

export enum CalendarFiltersClassesEnum {
  PRICING_DEFAULT = 'has-background-darker',
  PRICING_CUSTOM = 'has-background-darker',
  DISCOUNT = 'is-purple',
  BOOKING = 'is-blue',
  UNAVAILABILITY = 'is-danger',
  DURATION = 'is-success',
  ICALS = 'has-background-grey',
}

export enum CalendarModalModesEnum {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  CREATE_DEFAULT = 'create-default',
}

export enum CalendarShowDaysEnum {
  WIDE = 30,
  TWO_WEEKS = 15,
  TOUCH = 7
}

export interface CalendarModalOptions {
  modal: string,
  mode: CalendarModalModesEnum,
  data: Record<string, any>
}

export interface CalendarDrawerOptions {
  drawer?: CalendarDrawerNameEnum,
  mode: DrawerDisplayModeEnum,
  data: Record<string, any>
}

export interface CalendarEvent<T> {
  id: number
  start: string
  end: string
  left: number
  right: number
  entity: T
}

export interface CalendarUnavailabilityEvent extends CalendarEvent<Unavailability> {
  booking: number | null
  entity: Unavailability
  externalSource: number | null
}

export interface ServiceUnitUnavailabilityEvent {
  id: number,
  booking: number | null,
  externalSource: number | null,
  start: string,
  end: string,
  entity: Unavailability,
  entityType?: string, // TODO better typing
  isStartDay: boolean,
  isEndDay: boolean,
  isEmpty: boolean,
  duration: Duration,
}

export interface FormattedCalendarEvent {
  isStartDay: boolean;
  isEndDay: boolean;
  serviceId: null | number;
  duration: number;
  start: string | null;
  end: string | null;
  entity: CalendarEventEntity;
  entityType: string;
  isEmpty: boolean;
  id: number;
  booking: number | null;
  externalSource: number | null;
}

export interface WorkerData {
  slots: FormattedCalendarEvent[][]
  allEventsForDay: FormattedCalendarEvent[]
}

export type CalendarModalTitleTypes = 'create_default' | 'create_custom' | 'read_default' | 'read_custom' | 'update_custom' | 'update_default' | 'delete_custom'

export type CalendarEventEntity = BookingCondition | Unavailability | ServiceDiscount | ServicePricing

export type entitiesCalendar = ServiceUnitUnavailabilityCalendar[] | ServicePricingCalendar[] | BookingConditionCalendar[] | ServiceDiscountCalendar[]

export type ServiceBoundCalendarEntity = BookingCondition | ServiceDiscount | ServicePricing

export interface CalendarBatchPayload {
  periods: Period[]
  recurrence: number
}

export interface CalendarBatchCreatePayload<T> extends CalendarBatchPayload {
  weekdays: WeekDaysEnum[]
  entity: T
}

export type CalendarBatchDeletePayload = CalendarBatchPayload

export interface CalendarBatchServiceBound {
  services: number[]
}

export interface CalendarBatchServiceUnitBound {
  serviceUnits: number[]
}

export type CalendarBatchCreateServiceBoundPayload<T> = CalendarBatchCreatePayload<T> & CalendarBatchServiceBound

export type CalendarBatchCreateServiceUnitBoundPayload<T> = CalendarBatchCreatePayload<T> & CalendarBatchServiceUnitBound

export type CalendarBatchDeleteServiceBoundPayload = CalendarBatchDeletePayload & CalendarBatchServiceBound

export type CalendarBatchDeleteServiceUnitBoundPayload = CalendarBatchDeletePayload & CalendarBatchServiceUnitBound

export interface ICalendarBatchParams<T> {
  domainId: number
  payload: T
  options: {
    toastText: {
      pending: string | TranslateResult
      success: string | TranslateResult
      error: string | TranslateResult
    }
  }
}

export interface ICalendarBatchActionParams<T> {
  domainId: number,
  payload: T,
}

export interface ICalendarResetManyParams<T> {
  domainId: number,
  payload: T,
}
