import { createState, createEntity } from '@kissmylabs/vuex-entitystore'
import { BookingState } from './types'
import { Booking } from '~/types/models'

export const booking = createEntity<Booking>('booking')

export const state = (): BookingState => ({
  ...createState<Booking>(booking),
  lastBookingsForDomain: [],
  todayBookingsForDomain: [],
  isDownloadingExport: false,
})

export default state
