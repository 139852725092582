import bookingsMutations from './mutations'
import bookingsActions from './actions'
import bookingsState from './state'
import bookingsGetters from './getters'

export const bookings = {
  namespaced: true,
  state: bookingsState,
  actions: bookingsActions,
  mutations: bookingsMutations,
  getters: bookingsGetters,
}
