
















































































































import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CguModal',
  props: {
    active: {
      type: Boolean,
      required: true,
    },

  },
})
