














































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { statusClasses } from '~/helpers/constants'
import { DomainStatusEnum } from '~/types/models'

export default defineComponent({
  name: 'StatusField',
  props: {
    value: {
      type: String as PropType<DomainStatusEnum>,
      validator: (prop: DomainStatusEnum) => Object.values(DomainStatusEnum).includes(prop),
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.value,
      set: value => emit('input', value),
    })

    return {
      model,
      DomainStatusEnum,
      statusClasses,
    }
  },
})
