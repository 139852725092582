import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{staticClass:"relative",attrs:{"active":_vm.active,"card":""},on:{"update:active":function (val) { return _vm.$emit('update:active', val); }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n    "+_vm._s(_vm.$t('giftcards.spending_update', {date: _vm.$dateTime.fromISO(_vm.spending.createdAt).toLocaleString(_vm.$dateTime.DATE_SHORT)}))+"\n  ")]},proxy:true}])},[_vm._v(" "),_c('div',{staticClass:"modal-card-body",attrs:{"data-cy":"updateSpendingModal"}},[_c('ValidationObserver',{staticClass:"columns is-multiline",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"booking_id","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.booking_id'),"type":{ 'is-danger': errors[0] },"message":errors}},[_c('BInput',{attrs:{"required":"","data-cy":"updateSpendingForm-booking"},model:{value:(_vm.form.booking),callback:function ($$v) {_vm.$set(_vm.form, "booking", $$v)},expression:"form.booking"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"t('giftcardtotal_amount","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('giftcards.total_amount')}},[_c('PriceInput',{attrs:{"use-cents":"","data-cy":"updateSpendingForm-amount"},model:{value:(_vm.form.amountSpent.amount),callback:function ($$v) {_vm.$set(_vm.form.amountSpent, "amount", $$v)},expression:"form.amountSpent.amount"}},[_vm._v("\n            €\n          ")])],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"t('giftcardtotal_amount","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('giftcards.unicstay_payment_status')}},[_c('BRadio',{attrs:{"native-value":true,"data-cy":"updateSpendingForm-unicstayPaid"},model:{value:(_vm.form.isUnicstayPaid),callback:function ($$v) {_vm.$set(_vm.form, "isUnicstayPaid", $$v)},expression:"form.isUnicstayPaid"}},[_vm._v("\n            "+_vm._s(_vm.$t('giftcards.unicstay_paid'))+"\n          ")]),_vm._v(" "),_c('BRadio',{attrs:{"native-value":false,"data-cy":"updateSpendingForm-unicstayNotPaid"},model:{value:(_vm.form.isUnicstayPaid),callback:function ($$v) {_vm.$set(_vm.form, "isUnicstayPaid", $$v)},expression:"form.isUnicstayPaid"}},[_vm._v("\n            "+_vm._s(_vm.$t('giftcards.unicstay_not_paid'))+"\n          ")])],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"column is-full has-text-centered"},[_c('BaseButton',{staticClass:"is-success",attrs:{"disabled":invalid,"data-cy":"updateSpending-submit"},on:{"click":_vm.submit}},[_vm._v("\n          "+_vm._s(_vm.$t('actions.validate'))+"\n        ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }