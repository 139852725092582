import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { Notification, NotificationTypeEnum } from '~/types/models'

export interface NotificationState extends BaseState<Notification> {
  filters: Record<keyof typeof NotificationTypeEnum, boolean>
}

export enum NotificationMutationTypes {
  RESET_STATE = 'RESET_STATE',
  UPDATE_FIELD = 'UPDATE_FIELD',
  TOGGLE_FILTER = 'TOGGLE_FILTER',
}
