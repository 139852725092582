


































































































import { computed, defineComponent, PropType, reactive, toRefs, useContext } from '@nuxtjs/composition-api'
import Decimal from 'decimal.js-light'
import { Booking, CurrencyEnum, CurrencyToSymbolEnum } from '~/types/models'
import useBookings from '~/composable/useBookings'
import PriceInput from '~/components/content/PriceInput.vue'

export default defineComponent({
  name: 'CancelBookingModal',
  components: { PriceInput },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { app: { $accessor, $dateTime } } = useContext()
    const { cancelBooking, getTotalAmount } = useBookings()

    const state = reactive({ refundAmount: 0 })

    const refundableAmount = computed(() => getTotalAmount($accessor.payments.getWhereArray(payment => props.booking.payments.includes(payment.id))))

    const onSubmit = async() => {
      if (props.booking.bookingCharge) {
        const success = await cancelBooking(props.booking.id, new Decimal(state.refundAmount).times(100).toNumber(), props.booking.bookingCharge.totalChargeWithDiscounts.currency)
        if (success) {
          emit('update:active', false)
          await $accessor.bookings.fetchOne(props.booking.id)
        }
      }
    }
    const domainForBooking = computed(() => $accessor.domains.getOne(props.booking.domain))

    const canCancelBooking = computed(() => {
      const dateNowPlusMinHoursBeforeBooking = $dateTime.fromJSDate(new Date()).plus({ hours: domainForBooking.value.minHoursBeforeBooking })
      const startBooking = $dateTime.fromJSDate(new Date(props.booking.start.toString()))
      return dateNowPlusMinHoursBeforeBooking < startBooking
    })

    return {
      ...toRefs(state),
      refundableAmount,
      onSubmit,
      CurrencyEnum,
      CurrencyToSymbolEnum,
      canCancelBooking,
      domainForBooking,
    }
  },
})
