











































import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import { ExternalSourceEntity } from '~/types/models'

export default defineComponent({
  name: 'IcalExportModal',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    entityId: {
      type: Number as PropType<number | null>,
      required: true,
    },
    entityType: {
      type: String as PropType<ExternalSourceEntity | null>,
      required: true,
    },
  },
  setup(props, { root: { $copyText } }) {
    const { app: { $accessor, $config, $successToast, i18n } } = useContext()

    const modalTitle = computed(() => {
      if (props.entityId) {
        if (props.entityType === 'service') {
          return `exporter ical ${$accessor.services.getOne(props.entityId).label}`
        }
        return `exporter ical ${$accessor.serviceUnits.getOne(props.entityId).label}`
      }
      return 'exporter ical'
    })

    const generateUrl = (type: 'us' | 'external') => computed(() => {
      if (props.entityId) {
        if (props.entityType === 'service') {
          return `${$config.axios.baseURL}/calendar/services/${props.entityId}${type === 'us' ? '' : '?include_external=true'}`
        }
        return `${$config.axios.baseURL}/calendar/serviceunits/${props.entityId}${type === 'us' ? '' : '?include_external=true'}`
      }
    })

    const copyToClipboard = async(type: 'us' | 'external') => {
      const text = generateUrl(type).value
      if (text) {
        await $copyText(text)
        $successToast(i18n.t('toast.copied_to_clipboard'))
      }
    }

    return {
      copyToClipboard,
      generateUrl,
      modalTitle,
    }
  },
})
