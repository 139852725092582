
















import { defineComponent, PropType, ref, useContext } from '@nuxtjs/composition-api'
import { ShortToISOEnum, Translation } from '~/types/Locale'
import LocaleSelect from '~/components/i18n/LocaleSelect.vue'
type ContentScopeProp = null | 'domain' | 'service' | 'provider' | 'option'

export default defineComponent({
  name: 'TranslatableInput',
  components: { LocaleSelect },
  props: {
    value: {
      type: Object as PropType<Translation>,
      required: true,
    },
    contentScope: {
      type: String as PropType<ContentScopeProp>,
      required: false,
      default: null,
      validator: (prop: ContentScopeProp) => [ null, 'domain', 'provider', 'service', 'option'].includes(prop),
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    forceDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props :any , ctx: { listeners: any; emit:any }) {
    const { app } = useContext()
    const activeLocale = ref(ShortToISOEnum[app.i18n.locale.toUpperCase() as keyof typeof ShortToISOEnum])

    const customListeners = {
      ...ctx.listeners,
      input: (e: any) => ctx.emit('input', {
        ...props.value,
        [activeLocale.value]: e,
      }),
    }

    function onLocaleChange($event: ShortToISOEnum) {
      ctx.emit('locale-change', $event)
    }

    return {
      activeLocale,
      customListeners,
      onLocaleChange,
    }
  },
})
