import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/addresses/actions.ts'), 'addresses/actions.ts')
  resolveStoreModules(require('../store/addresses/getters.ts'), 'addresses/getters.ts')
  resolveStoreModules(require('../store/addresses/mutations.ts'), 'addresses/mutations.ts')
  resolveStoreModules(require('../store/addresses/state.ts'), 'addresses/state.ts')
  resolveStoreModules(require('../store/auth/actions.ts'), 'auth/actions.ts')
  resolveStoreModules(require('../store/auth/getters.ts'), 'auth/getters.ts')
  resolveStoreModules(require('../store/auth/mutations.ts'), 'auth/mutations.ts')
  resolveStoreModules(require('../store/auth/state.ts'), 'auth/state.ts')
  resolveStoreModules(require('../store/bookingConditions/actions.ts'), 'bookingConditions/actions.ts')
  resolveStoreModules(require('../store/bookingConditions/getters.ts'), 'bookingConditions/getters.ts')
  resolveStoreModules(require('../store/bookingConditions/mutations.ts'), 'bookingConditions/mutations.ts')
  resolveStoreModules(require('../store/bookingConditions/state.ts'), 'bookingConditions/state.ts')
  resolveStoreModules(require('../store/bookings/actions.ts'), 'bookings/actions.ts')
  resolveStoreModules(require('../store/bookings/getters.ts'), 'bookings/getters.ts')
  resolveStoreModules(require('../store/bookings/mutations.ts'), 'bookings/mutations.ts')
  resolveStoreModules(require('../store/bookings/state.ts'), 'bookings/state.ts')
  resolveStoreModules(require('../store/calendar/actions.ts'), 'calendar/actions.ts')
  resolveStoreModules(require('../store/calendar/getters.ts'), 'calendar/getters.ts')
  resolveStoreModules(require('../store/calendar/mutations.ts'), 'calendar/mutations.ts')
  resolveStoreModules(require('../store/calendar/state.ts'), 'calendar/state.ts')
  resolveStoreModules(require('../store/calendarExternalSources/actions.ts'), 'calendarExternalSources/actions.ts')
  resolveStoreModules(require('../store/calendarExternalSources/getters.ts'), 'calendarExternalSources/getters.ts')
  resolveStoreModules(require('../store/calendarExternalSources/mutations.ts'), 'calendarExternalSources/mutations.ts')
  resolveStoreModules(require('../store/calendarExternalSources/state.ts'), 'calendarExternalSources/state.ts')
  resolveStoreModules(require('../store/customers/actions.ts'), 'customers/actions.ts')
  resolveStoreModules(require('../store/customers/getters.ts'), 'customers/getters.ts')
  resolveStoreModules(require('../store/customers/mutations.ts'), 'customers/mutations.ts')
  resolveStoreModules(require('../store/customers/state.ts'), 'customers/state.ts')
  resolveStoreModules(require('../store/domainActivities/actions.ts'), 'domainActivities/actions.ts')
  resolveStoreModules(require('../store/domainActivities/getters.ts'), 'domainActivities/getters.ts')
  resolveStoreModules(require('../store/domainActivities/mutations.ts'), 'domainActivities/mutations.ts')
  resolveStoreModules(require('../store/domainActivities/state.ts'), 'domainActivities/state.ts')
  resolveStoreModules(require('../store/domainActivitiesEntries/actions.ts'), 'domainActivitiesEntries/actions.ts')
  resolveStoreModules(require('../store/domainActivitiesEntries/getters.ts'), 'domainActivitiesEntries/getters.ts')
  resolveStoreModules(require('../store/domainActivitiesEntries/mutations.ts'), 'domainActivitiesEntries/mutations.ts')
  resolveStoreModules(require('../store/domainActivitiesEntries/state.ts'), 'domainActivitiesEntries/state.ts')
  resolveStoreModules(require('../store/domainAmenities/actions.ts'), 'domainAmenities/actions.ts')
  resolveStoreModules(require('../store/domainAmenities/getters.ts'), 'domainAmenities/getters.ts')
  resolveStoreModules(require('../store/domainAmenities/mutations.ts'), 'domainAmenities/mutations.ts')
  resolveStoreModules(require('../store/domainAmenities/state.ts'), 'domainAmenities/state.ts')
  resolveStoreModules(require('../store/domainEquipments/actions.ts'), 'domainEquipments/actions.ts')
  resolveStoreModules(require('../store/domainEquipments/getters.ts'), 'domainEquipments/getters.ts')
  resolveStoreModules(require('../store/domainEquipments/mutations.ts'), 'domainEquipments/mutations.ts')
  resolveStoreModules(require('../store/domainEquipments/state.ts'), 'domainEquipments/state.ts')
  resolveStoreModules(require('../store/domainEquipmentsEntries/actions.ts'), 'domainEquipmentsEntries/actions.ts')
  resolveStoreModules(require('../store/domainEquipmentsEntries/getters.ts'), 'domainEquipmentsEntries/getters.ts')
  resolveStoreModules(require('../store/domainEquipmentsEntries/mutations.ts'), 'domainEquipmentsEntries/mutations.ts')
  resolveStoreModules(require('../store/domainEquipmentsEntries/state.ts'), 'domainEquipmentsEntries/state.ts')
  resolveStoreModules(require('../store/domainMarketplaceConfigurations/actions.ts'), 'domainMarketplaceConfigurations/actions.ts')
  resolveStoreModules(require('../store/domainMarketplaceConfigurations/getters.ts'), 'domainMarketplaceConfigurations/getters.ts')
  resolveStoreModules(require('../store/domainMarketplaceConfigurations/mutations.ts'), 'domainMarketplaceConfigurations/mutations.ts')
  resolveStoreModules(require('../store/domainMarketplaceConfigurations/state.ts'), 'domainMarketplaceConfigurations/state.ts')
  resolveStoreModules(require('../store/domains/actions.ts'), 'domains/actions.ts')
  resolveStoreModules(require('../store/domains/getters.ts'), 'domains/getters.ts')
  resolveStoreModules(require('../store/domains/mutations.ts'), 'domains/mutations.ts')
  resolveStoreModules(require('../store/domains/state.ts'), 'domains/state.ts')
  resolveStoreModules(require('../store/domainSustainabilityActions/actions.ts'), 'domainSustainabilityActions/actions.ts')
  resolveStoreModules(require('../store/domainSustainabilityActions/getters.ts'), 'domainSustainabilityActions/getters.ts')
  resolveStoreModules(require('../store/domainSustainabilityActions/mutations.ts'), 'domainSustainabilityActions/mutations.ts')
  resolveStoreModules(require('../store/domainSustainabilityActions/state.ts'), 'domainSustainabilityActions/state.ts')
  resolveStoreModules(require('../store/emailLogs/actions.ts'), 'emailLogs/actions.ts')
  resolveStoreModules(require('../store/emailLogs/getters.ts'), 'emailLogs/getters.ts')
  resolveStoreModules(require('../store/emailLogs/mutations.ts'), 'emailLogs/mutations.ts')
  resolveStoreModules(require('../store/emailLogs/state.ts'), 'emailLogs/state.ts')
  resolveStoreModules(require('../store/entityFiles/actions.ts'), 'entityFiles/actions.ts')
  resolveStoreModules(require('../store/entityFiles/getters.ts'), 'entityFiles/getters.ts')
  resolveStoreModules(require('../store/entityFiles/mutations.ts'), 'entityFiles/mutations.ts')
  resolveStoreModules(require('../store/entityFiles/state.ts'), 'entityFiles/state.ts')
  resolveStoreModules(require('../store/giftCards/actions.ts'), 'giftCards/actions.ts')
  resolveStoreModules(require('../store/giftCards/getters.ts'), 'giftCards/getters.ts')
  resolveStoreModules(require('../store/giftCards/mutations.ts'), 'giftCards/mutations.ts')
  resolveStoreModules(require('../store/giftCards/state.ts'), 'giftCards/state.ts')
  resolveStoreModules(require('../store/giftCardSpendings/actions.ts'), 'giftCardSpendings/actions.ts')
  resolveStoreModules(require('../store/giftCardSpendings/getters.ts'), 'giftCardSpendings/getters.ts')
  resolveStoreModules(require('../store/giftCardSpendings/mutations.ts'), 'giftCardSpendings/mutations.ts')
  resolveStoreModules(require('../store/giftCardSpendings/state.ts'), 'giftCardSpendings/state.ts')
  resolveStoreModules(require('../store/healthSafetyMeasures/actions.ts'), 'healthSafetyMeasures/actions.ts')
  resolveStoreModules(require('../store/healthSafetyMeasures/getters.ts'), 'healthSafetyMeasures/getters.ts')
  resolveStoreModules(require('../store/healthSafetyMeasures/mutations.ts'), 'healthSafetyMeasures/mutations.ts')
  resolveStoreModules(require('../store/healthSafetyMeasures/state.ts'), 'healthSafetyMeasures/state.ts')
  resolveStoreModules(require('../store/marketplaces/actions.ts'), 'marketplaces/actions.ts')
  resolveStoreModules(require('../store/marketplaces/getters.ts'), 'marketplaces/getters.ts')
  resolveStoreModules(require('../store/marketplaces/mutations.ts'), 'marketplaces/mutations.ts')
  resolveStoreModules(require('../store/marketplaces/state.ts'), 'marketplaces/state.ts')
  resolveStoreModules(require('../store/notifications/actions.ts'), 'notifications/actions.ts')
  resolveStoreModules(require('../store/notifications/getters.ts'), 'notifications/getters.ts')
  resolveStoreModules(require('../store/notifications/mutations.ts'), 'notifications/mutations.ts')
  resolveStoreModules(require('../store/notifications/state.ts'), 'notifications/state.ts')
  resolveStoreModules(require('../store/options/actions.ts'), 'options/actions.ts')
  resolveStoreModules(require('../store/options/getters.ts'), 'options/getters.ts')
  resolveStoreModules(require('../store/options/mutations.ts'), 'options/mutations.ts')
  resolveStoreModules(require('../store/options/state.ts'), 'options/state.ts')
  resolveStoreModules(require('../store/payments/actions.ts'), 'payments/actions.ts')
  resolveStoreModules(require('../store/payments/getters.ts'), 'payments/getters.ts')
  resolveStoreModules(require('../store/payments/mutations.ts'), 'payments/mutations.ts')
  resolveStoreModules(require('../store/payments/state.ts'), 'payments/state.ts')
  resolveStoreModules(require('../store/promocodes/actions.ts'), 'promocodes/actions.ts')
  resolveStoreModules(require('../store/promocodes/getters.ts'), 'promocodes/getters.ts')
  resolveStoreModules(require('../store/promocodes/mutations.ts'), 'promocodes/mutations.ts')
  resolveStoreModules(require('../store/promocodes/state.ts'), 'promocodes/state.ts')
  resolveStoreModules(require('../store/providers/actions.ts'), 'providers/actions.ts')
  resolveStoreModules(require('../store/providers/getters.ts'), 'providers/getters.ts')
  resolveStoreModules(require('../store/providers/mutations.ts'), 'providers/mutations.ts')
  resolveStoreModules(require('../store/providers/state.ts'), 'providers/state.ts')
  resolveStoreModules(require('../store/serviceDiscounts/actions.ts'), 'serviceDiscounts/actions.ts')
  resolveStoreModules(require('../store/serviceDiscounts/getters.ts'), 'serviceDiscounts/getters.ts')
  resolveStoreModules(require('../store/serviceDiscounts/mutations.ts'), 'serviceDiscounts/mutations.ts')
  resolveStoreModules(require('../store/serviceDiscounts/state.ts'), 'serviceDiscounts/state.ts')
  resolveStoreModules(require('../store/serviceEquipments/actions.ts'), 'serviceEquipments/actions.ts')
  resolveStoreModules(require('../store/serviceEquipments/getters.ts'), 'serviceEquipments/getters.ts')
  resolveStoreModules(require('../store/serviceEquipments/mutations.ts'), 'serviceEquipments/mutations.ts')
  resolveStoreModules(require('../store/serviceEquipments/state.ts'), 'serviceEquipments/state.ts')
  resolveStoreModules(require('../store/serviceLabels/actions.ts'), 'serviceLabels/actions.ts')
  resolveStoreModules(require('../store/serviceLabels/getters.ts'), 'serviceLabels/getters.ts')
  resolveStoreModules(require('../store/serviceLabels/mutations.ts'), 'serviceLabels/mutations.ts')
  resolveStoreModules(require('../store/serviceLabels/state.ts'), 'serviceLabels/state.ts')
  resolveStoreModules(require('../store/servicePricings/actions.ts'), 'servicePricings/actions.ts')
  resolveStoreModules(require('../store/servicePricings/getters.ts'), 'servicePricings/getters.ts')
  resolveStoreModules(require('../store/servicePricings/mutations.ts'), 'servicePricings/mutations.ts')
  resolveStoreModules(require('../store/servicePricings/state.ts'), 'servicePricings/state.ts')
  resolveStoreModules(require('../store/services/actions.ts'), 'services/actions.ts')
  resolveStoreModules(require('../store/services/getters.ts'), 'services/getters.ts')
  resolveStoreModules(require('../store/services/mutations.ts'), 'services/mutations.ts')
  resolveStoreModules(require('../store/services/state.ts'), 'services/state.ts')
  resolveStoreModules(require('../store/serviceThemes/actions.ts'), 'serviceThemes/actions.ts')
  resolveStoreModules(require('../store/serviceThemes/getters.ts'), 'serviceThemes/getters.ts')
  resolveStoreModules(require('../store/serviceThemes/mutations.ts'), 'serviceThemes/mutations.ts')
  resolveStoreModules(require('../store/serviceThemes/state.ts'), 'serviceThemes/state.ts')
  resolveStoreModules(require('../store/serviceTypes/actions.ts'), 'serviceTypes/actions.ts')
  resolveStoreModules(require('../store/serviceTypes/getters.ts'), 'serviceTypes/getters.ts')
  resolveStoreModules(require('../store/serviceTypes/mutations.ts'), 'serviceTypes/mutations.ts')
  resolveStoreModules(require('../store/serviceTypes/state.ts'), 'serviceTypes/state.ts')
  resolveStoreModules(require('../store/serviceUnits/actions.ts'), 'serviceUnits/actions.ts')
  resolveStoreModules(require('../store/serviceUnits/getters.ts'), 'serviceUnits/getters.ts')
  resolveStoreModules(require('../store/serviceUnits/mutations.ts'), 'serviceUnits/mutations.ts')
  resolveStoreModules(require('../store/serviceUnits/state.ts'), 'serviceUnits/state.ts')
  resolveStoreModules(require('../store/unavailabilities/actions.ts'), 'unavailabilities/actions.ts')
  resolveStoreModules(require('../store/unavailabilities/getters.ts'), 'unavailabilities/getters.ts')
  resolveStoreModules(require('../store/unavailabilities/mutations.ts'), 'unavailabilities/mutations.ts')
  resolveStoreModules(require('../store/unavailabilities/state.ts'), 'unavailabilities/state.ts')
  resolveStoreModules(require('../store/users/actions.ts'), 'users/actions.ts')
  resolveStoreModules(require('../store/users/getters.ts'), 'users/getters.ts')
  resolveStoreModules(require('../store/users/mutations.ts'), 'users/mutations.ts')
  resolveStoreModules(require('../store/users/state.ts'), 'users/state.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
