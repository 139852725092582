import { Translation } from '~/types/Locale'

export enum ServiceEquipmentTypeEnum  {
  SPA = 'SPA',
  SPA_TYPE = 'SPA_TYPE',
  SPA_CHARACTERISTIC = 'SPA_CHARACTERISTIC',
  SPA_LOCATION = 'SPA_LOCATION',
  ENERGY = 'ENERGY',
  WATER = 'WATER',
  BATHROOM_FURNISH = 'BATHROOM_FURNISH',
  BED_FURNISH = 'BED_FURNISH',
  KITCHEN = 'KITCHEN',
  OUTDOORS = 'OUTDOORS',
  ACCESS = 'ACCESS',
  CATERING = 'CATERING',
  INSIDE_TOILET_TYPE = 'INSIDE_TOILET_TYPE',
  OUTSIDE_TOILET_TYPE = 'OUTSIDE_TOILET_TYPE',
  COMMODITY = 'COMMODITY',
  OUTSIDE_BATHROOM_LOCATION = 'OUTSIDE_BATHROOM_LOCATION',
  INSIDE_BATHROOM_LOCATION = 'INSIDE_BATHROOM_LOCATION',
  BREAKFAST_INCLUDED = 'BREAKFAST_INCLUDED',
  BREAKFAST_NOT_INCLUDED = 'BREAKFAST_NOT_INCLUDED'
}
export interface ServiceEquipment {
  id: number
  slug: string
  type: ServiceEquipmentTypeEnum
  name: Translation
}
