import { Middleware } from '@nuxt/types'

const domainActivitiesMiddleware: Middleware = async({ route, app: { $api, $accessor } }) => {
  const domainId = parseInt(route.params.domain)

  if ($accessor.domainActivitiesEntries.getAllIds().length === 0) {
    const currentDomainActivities = await $api.get(`domains/${domainId}/activities`)
    const allDomainActivities = await $api.get('domainactivities?limit=-1')

    $accessor.domainActivitiesEntries.CREATE_MANY(currentDomainActivities)
    $accessor.domainActivities.CREATE_MANY(allDomainActivities.data)
  }
}

export default domainActivitiesMiddleware
