import { useContext } from '@nuxtjs/composition-api'

export interface GiftCardSpendings {
    updateSpendingIsUnicstayPaid (id: number): Promise<void>
}

function useGiftCardSpendings(): GiftCardSpendings {
  const { app: { $accessor } } = useContext()

  async function updateSpendingIsUnicstayPaid(id: number) {
    const giftCardSpending = $accessor.giftCardSpendings.getOne(id)
    const payload = {
      id: giftCardSpending.id,
      isUnicstayPaid: true,
    }
    return $accessor.giftCardSpendings.patchOne(payload)
  }

  return {
    updateSpendingIsUnicstayPaid,
  }
}

export default useGiftCardSpendings
