import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { DomainMarketplaceConfiguration, DomainMarketplaceConfigurationCreateForm } from '~/types/models'

export enum DomainMarketplaceConfigurationMutationTypes {
  UPDATE_FIELD = 'UPDATE_FIELD',
  RESET_STATE = 'RESET_STATE',
  RESET_CREATE_FORM = 'RESET_CREATE_FORM',
  UPDATE_CREATE_FORM_FIELD = 'UPDATE_CREATE_FORM_FIELD',
  ADD_CREATE_FORM_ITEM = 'ADD_CREATE_FORM_ITEM',
  REMOVE_CREATE_FORM_ITEM = 'REMOVE_CREATE_FORM_ITEM',
}

export interface DomainMarketplaceConfigurationState extends BaseState<DomainMarketplaceConfiguration> {
  createForm: DomainMarketplaceConfigurationCreateForm[]
}
