







































































































































































import { computed, defineComponent, reactive, useContext } from '@nuxtjs/composition-api'
import { BookingStatusEnum, GiftCardSpending } from '~/types/models'
import { ThemeColors } from '~/types/Tailwind'
import useBookings from '~/composable/useBookings'
import useTable from '~/composable/useTable'
import GiftCardPaymentModal from '~/components/modal/GiftCardPaymentModal.vue'
import TableSearch from '~/components/table/TableSearch.vue'
import TableEmpty from '~/components/table/TableEmpty.vue'
import useGiftCardSpendings from '~/composable/useGiftCardSpendings'
import StatusBadge from '~/components/badges/StatusBadge.vue'

export default defineComponent({
  name: 'GiftCardsBookingsListPage',
  components: {
    StatusBadge,
    TableSearch,
    TableEmpty,
    GiftCardPaymentModal,
  },
  setup(_, { root: { $buefy, $i18n } }) {
    const baseUrl = 'giftcardspendings'
    const { app: { $accessor, $translateEntityField, $parseAmount } } = useContext()
    const { getTotalAmount } = useBookings()
    const { updateSpendingIsUnicstayPaid: updateSpending } = useGiftCardSpendings()

    const updateSpendingIsUnicstayPaid = async(bookingId: number) => {
      const spendingIds = $accessor.bookings.getOne(bookingId).giftCardSpendings
      $buefy.dialog.confirm({
        title: $i18n.t('dialog.force_update_spending.title').toString(),
        message: $i18n.t('dialog.force_update_spending.message').toString(),
        confirmText: $i18n.t('dialog.force_update_spending.confirmText').toString(),
        cancelText: $i18n.t('dialog.force_update_spending.cancelText').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          await Promise.all(spendingIds.map((id) => {
            return updateSpending(id)
          }))
          await fetchPage()
        },
      })
    }

    const fetchRelations = async(items: GiftCardSpending[]) => {
      tableState.isLoading = true

      // Unique Booking IDs since some bookings can have multiple Spendings
      // spending.booking is nullable.
      const bookingIds = [...new Set(items.filter(spending => spending.booking !== null).map(spending => spending.booking))] as number[]
      const missingBookingsIds = bookingIds.filter(id => !$accessor.bookings.getAllIds().includes(id))
      if (missingBookingsIds.length > 0) {
        await $accessor.bookings.fetchMany(missingBookingsIds)

        // Array of unique ServiceIDs to fetch
        const serviceIds = [...new Set(bookingIds.map(booking => $accessor.bookings.getOne(booking)?.service))]
        const missingServiceIds = serviceIds.filter(serviceId => !$accessor.services.getAllIds().includes(serviceId))

        if (missingServiceIds.length > 0) {
          await $accessor.services.fetchMany(missingServiceIds)
        }

        const paymentIds = bookingIds.flatMap(id => $accessor.bookings.getOne(id)?.payments)
        const missingPaymentIds = paymentIds.filter(id => !$accessor.payments.getAllIds().includes(id) && id)

        if (missingPaymentIds.length > 0) {
          await $accessor.payments.fetchMany(paymentIds)
        }

        const gifCartSpendingsIds = bookingIds.flatMap(id => $accessor.bookings.getOne(id)?.giftCardSpendings)
        const missgingGiftCardSpendingsIds = gifCartSpendingsIds.filter(id => !$accessor.giftCardSpendings.getAllIds()
          .includes(id))
        if (missgingGiftCardSpendingsIds.length) {
          await $accessor.giftCardSpendings.fetchMany(missgingGiftCardSpendingsIds)
        }
      }

      const giftCardIds = [...new Set(items.filter(spending => !!spending.giftCard).map(spending => spending.giftCard))]
      const missingGiftCardIds = giftCardIds.filter(giftCardId => !$accessor.giftCards.getAllIds().includes(giftCardId))
      if (missingGiftCardIds.length > 0) {
        await $accessor.giftCards.fetchMany(missingGiftCardIds)
      }

      tableState.isLoading = false
    }

    const {
      state: tableState, eventSearchTable, eventSortTable, refreshTable, fetchPage,
    } = useTable<GiftCardSpending>(baseUrl, fetchRelations)

    const state = reactive({
      paymentModal: false,
      selectedBooking: 0,
      checkBookingPaymentLoading: null,
    })

    function displayPaymentModal(bookingId: number) {
      state.selectedBooking = bookingId
      state.paymentModal = true
    }

    function shouldDisplayPaymentButton(spending: GiftCardSpending) {
      if (!spending.booking) {
        return false
      }
      const bookingForSpending = $accessor.bookings.getOne(spending.booking)
      const isBookingPending = bookingForSpending.status === BookingStatusEnum.BOOKING_PENDING
      return !spending.isUnicstayPaid && !isBookingPending
    }

    function resetPaymentModal() {
      state.paymentModal = false
      state.selectedBooking = 0
    }

    const getMarketplaceNameWithBooking = (bookingId: number) => computed(() => {
      const booking = $accessor.bookings.getOne(bookingId)
      if (booking && $accessor.marketplaces.getOne(booking.marketplace)) {
        return $translateEntityField($accessor.marketplaces.getOne(booking.marketplace).name)
      }
      return '-'
    })

    const getTotalAmountWithBooking = (bookingId: number) => computed(() => {
      const booking = $accessor.bookings.getOne(bookingId)
      if (booking && booking.bookingCharge) {
        return $parseAmount(booking.bookingCharge.totalChargeWithDiscounts).toFormat('$0,0.00')
      }
      return '-'
    })


    const getServiceNameWithBooking = (bookingId: number) => computed(() => {
      const booking = $accessor.bookings.getOne(bookingId)
      if (booking && booking.service) {
        const serviceName = $accessor.services.getOne(booking.service)?.name
        if (serviceName) {
          return $translateEntityField(serviceName)
        }
      }
      return '-'
    })

    return {
      tableState,
      eventSearchTable,
      eventSortTable,
      displayPaymentModal,
      state,
      getTotalAmount,
      refreshTable,
      resetPaymentModal,
      shouldDisplayPaymentButton,
      updateSpendingIsUnicstayPaid,
      getMarketplaceNameWithBooking,
      getTotalAmountWithBooking,
      getServiceNameWithBooking,
      ThemeColors,
    }
  },
})
