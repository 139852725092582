import { computed, ComputedRef, getCurrentInstance } from '@nuxtjs/composition-api'
import { breakpoints } from '~/plugins/vue-mq'

interface MqHook {
  isWide: ComputedRef<boolean>
  isTouch: ComputedRef<boolean>
  isMobile: ComputedRef<boolean>
  breakpoints: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  }
}

function useMq(): MqHook {

  const vm = getCurrentInstance()
  const isWide = computed(() => vm!.isWide)
  const isTouch = computed(() => vm!.isTouch)
  const isMobile = computed(() => vm!.isMobile)

  return {
    isWide,
    isTouch,
    isMobile,
    breakpoints,
  }
}

export default useMq
