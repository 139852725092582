import { RouteConfig } from '@nuxt/types/config/router'
import BookingIdPage from '~/components/pages/bookings/booking-id.vue'
import BookingUpdate from '~/components/booking/BookingUpdate.vue'
import CustomerIdPage from '~/components/pages/customers/customer-id.vue'
import OptionIdPage from '~/components/pages/options/option-id.vue'
import ServiceIdPage from '~/components/pages/services/service-id.vue'
import ServiceGeneralInformations from '~/components/service/ServiceGeneralInformations.vue'
import ServiceSettings from '~/components/service/ServiceSettings.vue'
import ServiceEquipment from '~/components/service/ServiceEquipment.vue'
import ServiceOffer from '~/components/service/ServiceOffer.vue'
import ServiceOptions from '~/components/service/ServiceOptions.vue'
import ServicePhotos from '~/components/service/ServicePhotos.vue'
import DeleteServicePage from '~/components/pages/services/delete.vue'
import NotificationsPage from '~/components/pages/notifications.vue'

export const entityRoutes: RouteConfig[] = [
  {
    path: '/p/:provider/d/:domain/bookings/:id',
    component: BookingIdPage,
    name: 'booking-id',
    children: [
      {
        path: '/p/:provider/d/:domain/bookings/:id/update',
        component: BookingUpdate,
        name: 'booking-update',
        props: true,
      },
    ],
  },
  {
    path: '/p/:provider/d/:domain/customers/:id',
    component: CustomerIdPage,
    name: 'client-id',
  },
  {
    path: '/p/:provider/d/:domain/options/:id',
    component: OptionIdPage,
    name: 'option-id',
  },
  {
    path: '/p/:provider/d/:domain/services/:id',
    component: ServiceIdPage,
    name: 'service-id',
    meta: { isServiceDetails: true },
    children: [
      {
        path: '/p/:provider/d/:domain/services/:id/general',
        name: 'service-id-general',
        component: ServiceGeneralInformations,
        props: true,
        meta: { isServiceDetails: true },
      },
      {
        path: '/p/:provider/d/:domain/services/:id/settings',
        name: 'service-id-settings',
        component: ServiceSettings,
        props: true,
        meta: { isServiceDetails: true },
      },
      {
        path: '/p/:provider/d/:domain/services/:id/equipment',
        name: 'service-id-equipment',
        component: ServiceEquipment,
        props: true,
        meta: {
          featureGate: 'serviceEquipments',
          isServiceDetails: true,
        },
      },
      {
        path: '/p/:provider/d/:domain/services/:id/offer',
        name: 'service-id-offer',
        component: ServiceOffer,
        props: true,
        meta: { isServiceDetails: true },
      },
      {
        path: '/p/:provider/d/:domain/services/:id/options',
        name: 'service-id-options',
        component: ServiceOptions,
        props: true,
        meta: {
          isServiceDetails: true,
          featureGate: 'serviceOptions',
        },
      },
      {
        path: '/p/:provider/d/:domain/services/:id/medias',
        name: 'service-id-medias',
        component: ServicePhotos,
        props: true,
        meta: { isServiceDetails: true },
      },
    ],
  },
  {
    path: '/p/:provider/d/:domain/services/:id/delete',
    name: 'service-id-delete',
    component: DeleteServicePage,
    meta: { isServiceDetails: true },
  },
  {
    path: '/p/:provider/notifications/:id',
    component: NotificationsPage,
    name: 'notifications',
  },
]
