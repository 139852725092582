







import { defineComponent, reactive, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'Badge',
  props: {
    count: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      default: 100,
    },
  },
  setup(props) {
    const state = reactive({ text: computed(() => props.count < props.limit ? props.count : `${props.limit - 1}+`) })
    return { state }
  },
})
