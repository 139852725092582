import { computed, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import { Address } from '~/types/models/Address'

export interface AddressHook {
  fetchOne (id: number): Promise<void>,
  getAddressField: (field: string, id: number | null) => WritableComputedRef<any>
  patchOne (id: number, payload?: Partial<Address> | null): Promise<void>
}

function useAddress(): AddressHook {
  const { app: { $accessor } } = useContext()

  async function fetchOne(id: number) {
    return $accessor.addresses.fetchOne(id)
  }

  const getCreateAddressFormField = (field: string) => computed({
    get: () => $accessor.addresses.getCreateFormField(field),
    set: value => {
      if (!$accessor.addresses.getCreateFormField('$isDirty') && field !== '$isDirty') {
        $accessor.addresses.UPDATE_CREATE_FORM_FIELD({
          path: '$isDirty',
          value: true,
        })
      }
      return $accessor.addresses.UPDATE_CREATE_FORM_FIELD({
        path: field,
        value,
      })
    },
  })


  const getAddressFieldById = (id: number, field: string) => computed({
    get: () => $accessor.addresses.getField(id)(field),
    set: (value) => {
      if (!$accessor.addresses.getField(id)('$isDirty') && field !== '$isDirty') {
        $accessor.addresses.UPDATE_FIELD({
          id,
          path: '$isDirty',
          value: true,
        })
      }
      return $accessor.addresses.UPDATE_FIELD({
        id,
        path: field,
        value,
      })
    },
  })

  const getAddressField = (field: string, id: number | null = null) => computed({
    get: () => id ? getAddressFieldById(id, field).value : getCreateAddressFormField(field).value,
    set: val => id ? getAddressFieldById(id, field).value = val : getCreateAddressFormField(field).value = val,
  })

  const patchOne = async(id: number, payload?: Partial<Address> | null) => {
    await $accessor.addresses.patchOne({ id, payload })
  }

  return {
    fetchOne,
    getAddressField,
    patchOne,
  }
}

export default useAddress
