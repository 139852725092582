import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.state.bookingDiscounts),function(bookingDiscount,index){return _c('div',{key:index},[_c('ValidationObserver',{staticClass:"columns"},[_c('BaseField',{staticClass:"column is-2 is-full-touch",attrs:{"label":_vm.$t('form.fields.booking_discount_type')}},[_c('div',{staticClass:"block radio-container"},[_c('BRadio',{attrs:{"native-value":_vm.DiscountTypeEnum.BOOKING_GLOBAL},model:{value:(bookingDiscount.discountType),callback:function ($$v) {_vm.$set(bookingDiscount, "discountType", $$v)},expression:"bookingDiscount.discountType"}},[_vm._v("\n            "+_vm._s(_vm.$t('form.fields.booking_discount_type_global'))+"\n          ")]),_vm._v(" "),_c('BRadio',{attrs:{"native-value":_vm.DiscountTypeEnum.BOOKING_SERVICE},model:{value:(bookingDiscount.discountType),callback:function ($$v) {_vm.$set(bookingDiscount, "discountType", $$v)},expression:"bookingDiscount.discountType"}},[_vm._v("\n            "+_vm._s(_vm.$t('form.fields.booking_discount_type_service'))+"\n          ")])],1)]),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-one-third",attrs:{"name":"booking_discount_percent","rules":("max_value:" + _vm.maxPercent),"tag":"div","skip-if-empty":false,"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.booking_discount_percent'),"subtitle":_vm.$t('form.fields.booking_discount_percent_subtitle'),"type":{ 'is-danger': errors[0] },"message":errors}},[_c('SymbolInput',{attrs:{"value":bookingDiscount.percent,"disabled":_vm.isDisabledField(index, 'percent'),"type":"text"},on:{"input":function($event){return _vm.onFieldUpdated($event, index, 'percent', validate, valid)}}},[_vm._v("\n            %\n          ")])],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-one-third",attrs:{"name":"booking_discount_amount","rules":("max_value:" + _vm.maxAmount),"tag":"div","skip-if-empty":false,"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.booking_discount_amount'),"subtitle":_vm.$t('form.fields.booking_discount_amount_subtitle'),"type":{ 'is-danger': errors[0] },"message":errors}},[_c('SymbolInput',{attrs:{"value":bookingDiscount.amount,"disabled":_vm.isDisabledField(index, 'amount'),"type":"text"},on:{"input":function($event){return _vm.onFieldUpdated($event, index, 'amount', validate, valid)}}},[_vm._v("\n            €\n          ")])],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"tw-ml-8 tw-mt-8 tw-flex tw-items-center"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.$t('actions.delete'), class: 'text-red' }),expression:"{ content: $t('actions.delete'), class: 'text-red' }"}],staticClass:"delete is-primary is-outlined is-paddingless",on:{"click":function($event){return _vm.onRemoveBookingDiscount(index)}}})])],1)],1)}),_vm._v(" "),_c('BaseLink',{attrs:{"icon-left":"plus","icon-pack":"usi","disabled":_vm.disabled},on:{"click":_vm.onAddBookingDiscount}},[_vm._v("\n    "+_vm._s(_vm.$t('discounts.add_discount_label'))+"\n  ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }