import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { Domain, EntityFile, Provider, User } from '~/types/models'
import { ProviderMutationTypes } from './types'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<Provider>(),

  async patchFile(_, { providerId, file }: { providerId: number, file: EntityFile }): Promise<void> {
    await this.app.$api.patch(`providers/${providerId}/files/${file.id}`, file)
  },

  async fetchOneForCurrent({ commit }, id: number): Promise<void> {
    const res = await this.app.$api.get(`providers/${id}?getPending=true`)
    if (res) {
      commit(BaseMutationTypes.CREATE, res)
      return commit(ProviderMutationTypes.SET_CURRENT, res)
    }
  },

  async fetchOne({ commit }, id: number): Promise<void> {
    try {
      const res = await this.app.$api.get(`providers/${id}`)
      if (res) {
        return commit(BaseMutationTypes.CREATE, res)
      }
    } catch (error) {
      this.app.$errorToast('Error while fetching providers')
    }
  },

  async fetchMany({ commit }, providerIds: number[]) {
    if (providerIds.length > 0) {
      const ids = providerIds.join()
      const res = await this.app.$api.get(`providers?filters[id_in]=${ids}&limit=${providerIds.length}`)
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },

  async fetchAll({ commit }): Promise<void> {
    try {
      const res = await this.app.$api.get('/providers')
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
        return commit(ProviderMutationTypes.SET_CURRENT, res.data[0])
      }
    } catch (error) {
      this.app.$errorToast('Error while fetching providers')
    }
  },

  async postOne(): Promise<void | boolean> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.provider_creating'))

    const handleError: (error?: string | null) => void = (error = '') => {
      this.app.$errorToast(
        `this.app.i18n.t('toast.provider_create_error'):
        ${error}`,
      )
    }
    try {
      let provider: Provider | null = null
      let user: User | null = null
      let domains: Array<Domain | null> = []

      const providerPayload = this.app.$accessor.providers.createForm
      provider = await this.app.$api.post('providers', providerPayload)

      if (provider) {
        this.app.$accessor.providers.create(provider)
        user = await this.app.$accessor.users.postOne(provider.id)
      }

      // Only create domains if user creation was successful (thus provider creation also was).
      if (user) {
        domains = await Promise.all(this.app.$accessor.domains.createForm.map((domain) =>
          this.app.$accessor.domains.postOne({ providerId: provider!.id, domain }),
        ))
      } else if (provider) {
        // Otherwise if no user but provider exists, delete the provider (as creating the user failed).
        await this.app.$api.delete(`providers/${provider.id}`)
      }

      if (user && provider && domains && domains.length) {
        toast.goAway(0)
        this.app.$successToast(this.app.i18n.t('toast.provider_created'))
        this.app.$accessor.providers.RESET_CREATE_FORM()
        this.app.$accessor.domains.RESET_CREATE_FORM()
        this.app.$accessor.users.RESET_CREATE_FORM()
        return true
      }
      return false
    } catch (error: any) {
      handleError(error.response?.data?.message)
      return false
    }
  },

  async patchOne({ commit }, id: number): Promise<void> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.provider_updating'))
    try {

      const provider = this.app.$accessor.providers.getOne(id)

      const res = await this.app.$api.patch(`providers/${id}`, provider)
      commit(BaseMutationTypes.UPDATE, {
        id,
        payload: res,
      })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.provider_updated'))
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.provider_update_error'))
    }
  },
})

export default actions
