import calendarExternalSourcesMutations from './mutations'
import calendarExternalSourcesGetters from './getters'
import calendarExternalSourcesActions from './actions'
import calendarExternalSourcesState from './state'

export const calendarExternalSources = {
  namespaced: true,
  state: calendarExternalSourcesState,
  actions: calendarExternalSourcesActions,
  mutations: calendarExternalSourcesMutations,
  getters: calendarExternalSourcesGetters,
}
