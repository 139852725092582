import promocodesMutations from './mutations'
import promocodesGetters from './getters'
import promocodesActions from './actions'
import promocodesState from './state'

export const promocodes = {
  namespaced: true,
  state: promocodesState,
  actions: promocodesActions,
  mutations: promocodesMutations,
  getters: promocodesGetters,
}
