import { RouteConfig } from '@nuxt/types/config/router'
import LegalPage from '~/components/pages/account/legal.vue'
import Legalinfos from '~/components/legal/LegalInfo.vue'
import LegalDocuments from '~/components/legal/LegalDocuments.vue'
import ContractsPage from '~/components/pages/account/contracts.vue'
import BankInfo from '~/components/legal/BankInfo.vue'

export const accountRoutes: RouteConfig[] = [
  {
    path: '/p/:provider/d/:domain/me/legal',
    component: LegalPage,
    name: 'legal',
    children: [
      {
        path: '/p/:provider/d/:domain/me/legal/infos',
        name: 'legalInfos',
        component: Legalinfos,
        props: true,
      },
      {
        path: '/p/:provider/d/:domain/me/legal/documents',
        name: 'legalDocuments',
        component: LegalDocuments,
        props: true,
        meta: { featureGate: 'legalDocuments' },
      },
      {
        path: '/p/:provider/d/:domain/me/legal/bankInfos',
        name: 'bankInfos',
        component: BankInfo,
        props: true,

      },
    ],
  },
  {
    path: '/p/:provider/d/:domain/me/contracts',
    component: ContractsPage,
    name: 'contracts',
    meta: { featureGate: 'legalContracts' },

  },
]

export default accountRoutes
