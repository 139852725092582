import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api'
import useServices from '~/composable/useServices'
import useDomain from './useDomain'
import { DomainStatusEnum, ServiceStatusEnum } from '~/types/models'

export interface PermissionsHook {
  canEditCriticalFields: (scope: 'service' | 'domain') => ComputedRef<boolean | undefined>
  isAdmin: ComputedRef<boolean>
}

function usePermissions(): PermissionsHook {
  const { app: { $accessor } } = useContext()

  const canEditCriticalFields = (scope: 'service' | 'domain') => computed(() => {
    if (!scope || $accessor.users.currentUserIsAdmin) {
      return true
    } else if (scope === 'domain') {
      const { getDomainField } = useDomain()
      return getDomainField('status').value === DomainStatusEnum.DISABLED
    } else if (scope === 'service') {
      const { getCurrentServiceField } = useServices()
      return getCurrentServiceField('status').value === ServiceStatusEnum.DISABLED
    }
  })

  const isAdmin = computed(() => $accessor.users.currentUserIsAdmin)

  return {
    canEditCriticalFields,
    isAdmin,
  }
}

export default usePermissions
