import pick from 'lodash/pick'

// Clean internal keys (starting with '$').
export function cleanStateKeys<T extends Record<string, any>>(item: T): Partial<any> {
  return pick(item, Object.keys(item)
    .filter(k => k[0] !== '$'))
}

// Reset state based on baseState function
export function resetState<T extends Record<string, any>>(state: T, baseState: () => T): void {
  Object.assign(state, baseState())
}

export default {}
