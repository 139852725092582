import { CalendarFiltersEnum, CalendarRecurrenceValueEnum, CalendarShowDaysEnum, CalendarViewsEnum, CalendarEntitiesEnum } from '~/types'
import { CalendarState, DrawerDisplayModeEnum } from '~/store/calendar/types'
import { DateTime } from 'luxon'
import { WeekDaysEnumArray } from '~/helpers'

export const CALENDAR_SHOW_DAYS_WIDE = 30
export const CALENDAR_SHOW_DAYS_TWO_WEEKS = 15
export const CALENDAR_SHOW_DAYS_TOUCH = 7

export function createTypeFiltersState(): Record<string, boolean> {
  const stateTypeFilters: Record<string, boolean> = {}
  Object.values(CalendarFiltersEnum)
    .forEach(filter => stateTypeFilters[filter] = true)
  return stateTypeFilters
}

export function resetTwoMonthViewFilterState(): Record<string, boolean> {
  const stateTypeFilters: Record<string, boolean> = {}
  Object.keys(CalendarFiltersEnum)
    .forEach((filter) => {
      if (
        CalendarFiltersEnum.BOOKING === filter ||
        CalendarFiltersEnum.UNAVAILABILITY === filter ||
        CalendarFiltersEnum.ICALS === filter
      ) {
        stateTypeFilters[filter] = (CalendarFiltersEnum.BOOKING === filter ||
          CalendarFiltersEnum.UNAVAILABILITY === filter ||
          CalendarFiltersEnum.ICALS === filter)
      } else {
        stateTypeFilters[filter] = false
      }
    })
  return stateTypeFilters
}

export const state = (): CalendarState => ({
  unavailabilityCalendars: [],
  servicePricingCalendars: [],
  bookingConditionCalendars: [],
  serviceDiscountCalendars: [],
  serviceEvents: {},
  date: DateTime.local().toJSDate(),
  disabledServices: [],
  currentBooking: null,
  currentUnavailability: null,
  showDays: CalendarShowDaysEnum.WIDE,
  commonForm: {
    selectedPeriods: [],
    selectedServices: [],
    selectedUnits: [],
    selectedWeekdays: WeekDaysEnumArray,
    numberYearsRecurrence: CalendarRecurrenceValueEnum.NONE,
  },
  ui: {
    isFullScreen: false,
    filters: createTypeFiltersState(),
    servicesFilters: [],
    loading: true,
    drawerLoading: false,
    views: CalendarViewsEnum.TWO_WEEKS,
    isDrawerOpened: false,
    drawerDisplayMode: DrawerDisplayModeEnum.READ,
    entityFormOpened: CalendarEntitiesEnum.UNAVAILABILITIES,
    drawer: null,
    drawerData: {},
  },
})

export default state
