import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { Customer } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { DateFormatEnum, getFormattedInternationalPhoneNumber } from '~/helpers'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<Customer>(),

  async fetchOne({ commit }, customerId: number) {
    const { $accessor } = this.app
    const customer: Customer = await this.app.$api.get(`customers/${customerId}`)
    if (!$accessor.addresses.getAllIds().includes(customer.billingAddress)) {
      await $accessor.addresses.fetchOne(customer.billingAddress)
    }
    commit(BaseMutationTypes.CREATE, customer)
  },

  async fetchMany({ commit }, customerIds: number[]) {
    if (customerIds.length > 0) {
      const ids = customerIds.join()
      const res = await this.app.$api.get(`customers?filters[id_in]=${ids}&limit=${customerIds.length}`)
      const customers: Customer[] = res.data
      if (customers.length) {
        commit(BaseMutationTypes.CREATE_MANY, customers)
        return customers
      }
    }
  },

  async patchOne({ commit, state }, { id, payload = null }: { id: number, payload?: Partial<Customer> | null }) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.customer_updating'))
    try {
      const data = payload ? payload : state.entities.byId[id]
      const formattedData: Partial<Customer> = {
        ...data,
        dateOfBirth: this.app.$dateTime.fromISO(data.dateOfBirth as string).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
      }
      if (payload?.phone) {
        formattedData.phone = getFormattedInternationalPhoneNumber(payload.phone)
      }
      const customer: Customer = await this.app.$api.patch(`customers/${id}`, formattedData)
      commit(BaseMutationTypes.UPDATE, {
        id,
        payload: { ...customer },
      })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.customer_updated'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.common_updating_error'))
    }
  },
})

export default actions
