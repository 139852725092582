import { DrawerDisplayModeEnum } from '~/store/calendar/types';
import { CalendarDrawerNameEnum } from '~/types/Calendar';
import CalendarDrawer from '~/components/calendar/drawers/CalendarDrawer.vue';
import UnavailabilityDetails from '~/components/calendar/unavailability/UnavailabilityDetails.vue';
import UnavailabilityEditForm from '~/components/calendar/unavailability/UnavailabilityEditForm.vue';
import UnavailabilityDeleteConfirm from '~/components/calendar/unavailability/UnavailabilityDeleteConfirm.vue';
export default defineComponent({
  name: 'CalendarUnavailabilityDrawer',
  components: {
    CalendarDrawer: CalendarDrawer,
    UnavailabilityDetails: UnavailabilityDetails,
    UnavailabilityEditForm: UnavailabilityEditForm,
    UnavailabilityDeleteConfirm: UnavailabilityDeleteConfirm
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(_, _ref) {
    var emit = _ref.emit;

    var _useContext = useContext(),
        _useContext$app = _useContext.app,
        $accessor = _useContext$app.$accessor,
        $dateTime = _useContext$app.$dateTime,
        i18n = _useContext$app.i18n;

    var _useDomain = useDomain(),
        currentDomainIsSh = _useDomain.currentDomainIsSh;

    var _useCalendar = useCalendar(),
        ui = _useCalendar.ui;

    var isDrawerUnavailOpen = computed(function () {
      return unavailability && (ui.value.drawer === CalendarDrawerNameEnum.UNAVAILABILITY || ui.value.drawer === CalendarDrawerNameEnum.ICALS);
    });
    var hasActions = computed(function () {
      if (currentDomainIsSh.value) {
        return $accessor.users.currentUserIsAdmin;
      }

      return true;
    });
    var unavailability = computed(function () {
      if (ui.value.drawerDisplayMode !== DrawerDisplayModeEnum.CREATE) {
        return $accessor.unavailabilities.getFirstActive();
      }
    });

    function onEdit() {
      if (unavailability.value) {
        $accessor.calendar.UI_OPEN_DRAWER({
          drawer: CalendarDrawerNameEnum.UNAVAILABILITY,
          mode: DrawerDisplayModeEnum.UPDATE,
          data: {
            startDate: $dateTime.fromISO(unavailability.value.start),
            endDate: $dateTime.fromISO(unavailability.value.end)
          }
        });
      }
    }

    function onDelete() {
      var drawerOptions = {
        drawer: CalendarDrawerNameEnum.UNAVAILABILITY,
        mode: DrawerDisplayModeEnum.DELETE,
        data: {
          serviceId: $accessor.calendar.ui.drawerData.serviceId
        }
      };

      if (ui.value.drawer === CalendarDrawerNameEnum.ICALS && unavailability.value) {
        drawerOptions.drawer = CalendarDrawerNameEnum.ICALS;
        drawerOptions.mode = DrawerDisplayModeEnum.DELETE;
        drawerOptions.data.ical = $accessor.calendarExternalSources.getOne(unavailability.value.externalSource);
      }

      $accessor.calendar.UI_OPEN_DRAWER(drawerOptions);
    }

    function onClose() {
      $accessor.calendar.RESET_UI_DRAWER();
      $accessor.unavailabilities.RESET_ACTIVE();
      emit('on-close');
    }

    function onClickOutside() {
      onClose();
    }

    var getModalTitle = computed(function () {
      if (ui.value.drawer === CalendarDrawerNameEnum.UNAVAILABILITY) {
        if (ui.value.drawerDisplayMode === DrawerDisplayModeEnum.READ) {
          return i18n.t('calendar.closed_dates_unavail');
        }

        if (ui.value.drawerDisplayMode === DrawerDisplayModeEnum.DELETE) {
          return i18n.t('calendar.delete_unavail');
        }
      } else {
        if (ui.value.drawerDisplayMode === DrawerDisplayModeEnum.DELETE) {
          return i18n.t('calendar.delete_ical');
        }

        return i18n.t('calendar.modal_titles.ical');
      }
    });
    return {
      CalendarDrawerNameEnum: CalendarDrawerNameEnum,
      currentDomainIsSh: currentDomainIsSh,
      DrawerDisplayModeEnum: DrawerDisplayModeEnum,
      getModalTitle: getModalTitle,
      hasActions: hasActions,
      isDrawerUnavailOpen: isDrawerUnavailOpen,
      onDelete: onDelete,
      onClickOutside: onClickOutside,
      onClose: onClose,
      onEdit: onEdit,
      ui: ui,
      unavailability: unavailability
    };
  }
});