




























import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import ServiceSelectCheckboxes from '~/components/service/ServiceSelectCheckboxes.vue'
import useOptions from '~/composable/useOptions'

export default defineComponent({
  name: 'OptionApplicableServicesForm',
  components: { ServiceSelectCheckboxes },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionId: {
      required: false,
      default: null,
      validator: (prop: number | null) => typeof prop === 'number' || prop === null,
    },
  },
  setup() {
    const { params, app: { $accessor } } = useContext()
    const { getOptionField: getField } = useOptions()
    const mounted = ref(false)

    const selectedServices = computed({
      get: () => {
        if (params.value.id) {
          return $accessor.services.getWhereArray((service) => $accessor.options.getOne(parseInt(params.value.id)).appliesOnServices.includes(service.id)).map((service) => {
            return service.id
          })
        } else {
          return getField('appliesOnServices').value
        }
      },
      set: (value) => {
        if (value) {
          if (params.value.id) {
            return $accessor.options.UPDATE_FIELD({
              id: parseInt(params.value.id),
              path: 'appliesOnServices',
              value,
            })
          } else {
            return $accessor.options.UPDATE_CREATE_FORM_FIELD({
              path: 'appliesOnServices',
              value,
            })
          }
        }
      },
    })

    return {
      selectedServices,
      mounted,
    }
  },
})
