import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { DomainActivity } from '~/types/models'
import { resetState } from '~/helpers/store'
import { DomainActivityMutationTypes } from './types'
import baseState, { domainActivity } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<DomainActivity>(domainActivity),
  [DomainActivityMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
  [DomainActivityMutationTypes.UPDATE_FIELD]: (state, { id, path, value }: { id: number, path: string, value: any }) =>  updateField(state.entities.byId[id], { path, value }),

})

export default mutations
