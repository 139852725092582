





































import { DrawerDisplayModeEnum } from '~/store/calendar/types'
import { CalendarDrawerNameEnum } from '~/types/Calendar'
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import useCalendar from '~/composable/useCalendar'
import CalendarDrawer from '~/components/calendar/drawers/CalendarDrawer.vue'
import ServicePricingDetails from '~/components/calendar/servicePricing/ServicePricingDetails.vue'
import ServicePricingEditForm from '~/components/calendar/servicePricing/ServicePricingEditForm.vue'
import ServicePricingDeleteConfirm from '~/components/calendar/servicePricing/ServicePricingDeleteConfirm.vue'

export default defineComponent({
  name: 'CalendarPricingDrawer',
  components: {
    CalendarDrawer,
    ServicePricingDetails,
    ServicePricingEditForm,
    ServicePricingDeleteConfirm,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { app: { $accessor, $dateTime, i18n } } = useContext()
    const { ui, getCalendarDrawerTitle, isCalendarUserEnabled } = useCalendar()

    const isDrawerPricingOpen = computed(() => pricing.value && ui.value.drawer === CalendarDrawerNameEnum.PRICING)

    const pricing = computed(() => {
      if (ui.value.drawerDisplayMode !== DrawerDisplayModeEnum.CREATE) {
        return $accessor.servicePricings.getFirstActive()
      }
    })

    const title = computed(() => {
      return `${i18n.t(`calendar.modal_titles.pricings_${getCalendarDrawerTitle(ui.value.drawerDisplayMode, pricing.value)}`)}`
    })

    function onEdit() {
      if (pricing.value) {
        $accessor.calendar.UI_OPEN_DRAWER({
          drawer: CalendarDrawerNameEnum.PRICING,
          mode: DrawerDisplayModeEnum.UPDATE,
          data: {
            startDate: $dateTime.fromISO(pricing.value.start as string),
            endDate: $dateTime.fromISO(pricing.value.end as string),
            serviceId: ui.value.drawerData.serviceId,
          },
        })
      }
    }

    function onDelete() {
      $accessor.calendar.UI_OPEN_DRAWER({
        drawer: CalendarDrawerNameEnum.PRICING,
        mode: DrawerDisplayModeEnum.DELETE,
        data: {},
      })
    }

    function onClose() {
      $accessor.calendar.RESET_UI_DRAWER()
      $accessor.servicePricings.RESET_ACTIVE()
      emit('on-close')
    }
    function onClickOutside() {
      onClose()
      emit('on-click-outside')
    }

    return {
      CalendarDrawerNameEnum,
      DrawerDisplayModeEnum,
      isCalendarUserEnabled,
      isDrawerPricingOpen,
      onClickOutside,
      onClose,
      onDelete,
      onEdit,
      pricing,
      title,
      ui,
    }
  },
})
