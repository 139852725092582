import { Middleware } from '@nuxt/types'

const bookingProviderMiddleware: Middleware = async({ app: { $accessor }, route }): Promise<void> => {
  const booking = $accessor.bookings.getOne(parseInt(route.params.id))
  if (!$accessor.providers.getAllIds().includes(booking.provider)) {
    await $accessor.providers.fetchOne(booking.provider)
  }
}

export default bookingProviderMiddleware
