import { mutationTree } from 'typed-vuex'
import { CalendarDrawerOptions, CalendarEntitiesEnum, CalendarFiltersEnum, CalendarRecurrenceValueEnum, CalendarViewsEnum } from '~/types'
import { BookingConditionCalendar, Period, ServiceDiscountCalendar, ServicePricingCalendar, ServiceUnitUnavailabilityCalendar, Unavailability } from '~/types/models'
import { resetState } from '~/helpers/store'
import getBaseState, { createTypeFiltersState, resetTwoMonthViewFilterState } from '~/store/calendar/state'
import { CalendarMutationTypes, DrawerDisplayModeEnum } from './types'
import { WeekDaysEnum, WeekDaysEnumArray } from '~/helpers'

export const mutations = mutationTree(getBaseState, {
  [CalendarMutationTypes.RESET_STATE](state) {
    resetState(state, getBaseState)
  },

  [CalendarMutationTypes.UDPATE_SELECTED_PERIODS](state, value: Period[]) {
    state.commonForm.selectedPeriods = value
  },

  [CalendarMutationTypes.UPDATE_SELECTED_SERVICES](state, value: number[]) {
    state.commonForm.selectedServices = value
  },

  [CalendarMutationTypes.UPDATE_SELECTED_UNITS](state, value: number[]) {
    state.commonForm.selectedUnits = value
  },

  [CalendarMutationTypes.UPDATE_SELECTED_WEEKDAYS](state, value: WeekDaysEnum[]) {
    state.commonForm.selectedWeekdays = value
  },

  [CalendarMutationTypes.UPDATE_SELECTED_RECURRENCE](state, value: CalendarRecurrenceValueEnum) {
    state.commonForm.numberYearsRecurrence = value
  },

  [CalendarMutationTypes.RESET_COMMON_FORM](state) {
    state.commonForm = {
      selectedPeriods: [],
      selectedServices: [],
      selectedUnits: [],
      selectedWeekdays: WeekDaysEnumArray,
      numberYearsRecurrence: CalendarRecurrenceValueEnum.NONE,
    }
  },

  [CalendarMutationTypes.SET_UNAVAILABILITY_CALENDAR](state, payload: ServiceUnitUnavailabilityCalendar[]) {
    // Keep data that is not related to the serviceUnit we are adding data for
    const filteredState = state.unavailabilityCalendars.filter(calendar => {
      return !payload
        .map(unavailability => unavailability.serviceUnit)
        .includes(calendar.serviceUnit)
    })
    state.unavailabilityCalendars = [...filteredState, ...payload]
  },

  [CalendarMutationTypes.UPDATE_UNAVAILABILITY_CALENDAR](state, { payload, serviceUnit }: { payload: Record<string, Unavailability[]>, serviceUnit: number }) {
    const updatedCalendar = state.unavailabilityCalendars.find(calendar => calendar.serviceUnit === serviceUnit)
    const filteredCalendar = state.unavailabilityCalendars.filter(calendar => calendar.serviceUnit !== serviceUnit)
    if (updatedCalendar) {
      const formattedCalendar = [{
        serviceUnit,
        calendar: payload,
      }]
      state.unavailabilityCalendars = [...formattedCalendar, ...filteredCalendar]
    }
  },

  [CalendarMutationTypes.SET_SERVICE_PRICINGS_CALENDAR](state, payload: ServicePricingCalendar) {
    // Keep data that is not related to the service we are adding data for
    const filteredState = state.servicePricingCalendars.filter(calendar => calendar.service !== payload.service)
    state.servicePricingCalendars = [...filteredState, ...[payload]]
  },

  [CalendarMutationTypes.SET_MANY_SERVICE_PRICINGS_CALENDAR](state, payload: ServicePricingCalendar[]) {
    const filteredState = state.servicePricingCalendars.filter(pricingCalendar => !payload.find(pricingPayload => pricingCalendar.service === pricingPayload.service))
    state.servicePricingCalendars = [...filteredState, ...payload]
  },

  [CalendarMutationTypes.SET_BOOKING_CONDITIONS_CALENDAR](state, payload: BookingConditionCalendar) {
    // Keep data that is not related to the service we are adding data for
    const filteredState = state.bookingConditionCalendars.filter(calendar => calendar.service !== payload.service)
    state.bookingConditionCalendars = [...filteredState, ...[payload]]
  },

  [CalendarMutationTypes.SET_MANY_BOOKING_CONDITIONS_CALENDAR](state, payload: BookingConditionCalendar[]) {
    // Keep data that is not related to the service we are adding data for
    const filteredState = state.bookingConditionCalendars.filter(conditionCalendar => !payload.find(conditionPayload => conditionCalendar.service === conditionPayload.service))
    state.bookingConditionCalendars = [...filteredState, ...payload]
  },

  [CalendarMutationTypes.SET_DISCOUNTS_CALENDAR](state, payload: ServiceDiscountCalendar) {
    // Keep data that is not related to the service we are adding data for
    const filteredState = state.serviceDiscountCalendars.filter(calendar => calendar.service !== payload.service)
    state.serviceDiscountCalendars = [...filteredState, ...[payload]]
  },

  [CalendarMutationTypes.SET_MANY_DISCOUNTS_CALENDAR](state, payload: ServiceDiscountCalendar[]) {
    // Keep data that is not related to the service we are adding data for
    const filteredState = state.serviceDiscountCalendars.filter(discountCalendar => !payload.find(discountPayload => discountCalendar.service === discountPayload.service))
    state.serviceDiscountCalendars = [...filteredState, ...payload]
  },

  [CalendarMutationTypes.SET_MANY_ALL_ENTITIES](state, payload:
    {
      pricingsCalendar: ServicePricingCalendar[],
      bookingConditionCalendar: BookingConditionCalendar[],
      discountsCalendar: ServiceDiscountCalendar[],
      unavailabilitiesCalendar: ServiceUnitUnavailabilityCalendar[]
    }) {

    const { bookingConditionCalendar, discountsCalendar, pricingsCalendar, unavailabilitiesCalendar } = payload

    state.serviceDiscountCalendars = discountsCalendar
    state.bookingConditionCalendars = bookingConditionCalendar
    state.servicePricingCalendars = pricingsCalendar
    state.unavailabilityCalendars = unavailabilitiesCalendar
  },

  [CalendarMutationTypes.SET_DATE](state, date: Date) {
    state.date = date
  },

  [CalendarMutationTypes.SET_CURRENT_UNAVAILABILITY](state, unavailabilty: Unavailability) {
    state.currentUnavailability = unavailabilty
  },

  [CalendarMutationTypes.RESET_CURRENT_UNAVAILABILITY](state) {
    state.currentUnavailability = null
  },

  [CalendarMutationTypes.SET_SHOW_DAYS](state, nbDays: 30 | 7) {
    state.showDays = nbDays
  },

  [CalendarMutationTypes.UI_OPEN_DRAWER](state, options?: CalendarDrawerOptions | null) {
    state.ui.isDrawerOpened = true
    state.ui.drawerDisplayMode = options?.mode || null
    state.ui.drawer = options?.drawer || null
    state.ui.drawerData = options?.data || {}
  },

  [CalendarMutationTypes.SET_UI_DATA](state, options?: CalendarDrawerOptions | null) {
    state.ui.drawerDisplayMode = options?.mode || null
    state.ui.drawer = options?.drawer || null
    state.ui.drawerData = options?.data || {}
  },

  [CalendarMutationTypes.RESET_UI_DRAWER](state) {
    state.ui = {
      ...state.ui,
      isDrawerOpened: false,
      drawerDisplayMode: DrawerDisplayModeEnum.READ,
      drawer: null,
      drawerData: {},
    }
  },

  [CalendarMutationTypes.UI_TOGGLE_FULLSCREEN](state) {
    state.ui.isFullScreen = !state.ui.isFullScreen
  },

  [CalendarMutationTypes.UI_TOGGLE_FILTER](state, filter: CalendarFiltersEnum) {
    if (typeof state.ui.filters[filter] !== 'undefined') {
      state.ui.filters[filter] = !state.ui.filters[filter]
    }
  },

  [CalendarMutationTypes.UI_TOGGLE_FILTER_GROUP](state, filters: CalendarFiltersEnum[]) {
    Object.keys(state.ui.filters).forEach(key => {
      // necessary cast here since TS is not smart enough to type object keys when using Object.keys
      state.ui.filters[key as CalendarFiltersEnum] = filters.includes(key as CalendarFiltersEnum)
    })
  },

  [CalendarMutationTypes.RESET_FILTERS](state, view: CalendarViewsEnum) {
    if (view === CalendarViewsEnum.TWO_WEEKS) {
      state.ui.filters = createTypeFiltersState()
    } else if (view === CalendarViewsEnum.TWO_MONTHS) {
      state.ui.filters = resetTwoMonthViewFilterState()
    }
  },

  [CalendarMutationTypes.UI_TOGGLE_ALL_SERVICES_IDS_FILTER](state, ids: number[]) {
    state.ui.servicesFilters = ids
  },

  [CalendarMutationTypes.UI_TOGGLE_SERVICE_ON_FILTER](state, serviceId: number) {
    if (state.ui.servicesFilters.includes(serviceId)) {
      state.ui.servicesFilters = state.ui.servicesFilters.filter(id => id !== serviceId)
    } else {
      state.ui.servicesFilters.push(serviceId)
    }
  },

  [CalendarMutationTypes.UI_TOGGLE_VIEW](state, view: CalendarViewsEnum) {
    state.ui.views = view
    if (view === CalendarViewsEnum.TWO_WEEKS) {
      state.ui.filters = createTypeFiltersState()
    } else if (view === CalendarViewsEnum.TWO_MONTHS) {
      state.ui.filters = resetTwoMonthViewFilterState()
    }
    state.ui = {
      ...state.ui,
      isDrawerOpened: false,
      drawerDisplayMode: DrawerDisplayModeEnum.READ,
      drawer: null,
      drawerData: {},
    }

  },

  [CalendarMutationTypes.UI_LOADING_START](state) {
    state.ui.loading = true
  },

  [CalendarMutationTypes.UI_LOADING_END](state) {
    state.ui.loading = false
  },

  [CalendarMutationTypes.UI_DRAWER_LOADING_START](state) {
    state.ui.drawerLoading = true
  },

  [CalendarMutationTypes.UI_DRAWER_LOADING_END](state) {
    state.ui.drawerLoading = false
  },

  [CalendarMutationTypes.UPDATE_ENTITY_FORM_OPENED](state, formOpened: CalendarEntitiesEnum) {
    state.ui.entityFormOpened = formOpened
  },

  [CalendarMutationTypes.UPDATE_DRAWER_DATA](state, data: any) {
    state.ui.drawerData = data
  },

  [CalendarMutationTypes.SET_DISABLED_SERVICES](state, data: number[]) {
    state.disabledServices = data
  },

})

export default mutations
