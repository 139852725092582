import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { HealthSafetyMeasure } from '~/types/models'

export type HealthSafetyMeasuresState = BaseState<HealthSafetyMeasure>


export enum HealthSafetyMeasuresMutationTypes {
  RESET_STATE = 'RESET_STATE',
  UPDATE_FIELD = 'UPDATE_FIELD'
}
