import optionsMutations from './mutations'
import optionsGetters from './getters'
import optionsActions from './actions'
import optionsState from './state'

export const options = {
  namespaced: true,
  state: optionsState,
  actions: optionsActions,
  mutations: optionsMutations,
  getters: optionsGetters,
}
