

































import { defineComponent } from '@nuxtjs/composition-api'
import useCalendar from '~/composable/useCalendar'

export default defineComponent({
  name: 'CalendarDayHeader',
  setup() {
    const { getCalendarDays } = useCalendar()
    return { getCalendarDays }
  },
})
