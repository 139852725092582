import { Middleware } from '@nuxt/types'

const domainAddressMiddleware: Middleware = async({ params, app: { $accessor } }) => {
  $accessor.TOGGLE_APP_IS_LOADING()
  try {
    const domainId = parseInt(params.domain)
    const addressId = $accessor.domains.getOne(domainId).address

    if (!$accessor.addresses.getAllIds().includes(addressId) && addressId) {
      await $accessor.addresses.fetchOne(addressId)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  $accessor.TOGGLE_APP_IS_LOADING()
}

export default domainAddressMiddleware
