

















































































import { defineComponent, ref } from '@nuxtjs/composition-api'
import useMenuRoutes from '~/composable/useMenuRoutes'
import useDomainNavigation from '~/composable/useDomainNavigation'
import LoginToggleLink from '~/components/auth/LoginToggleLink.vue'
import DomainNavigationDropdown from '~/components/navigation/DomainNavigationDropdown.vue'
import NotificationsDropdown from '~/components/notifications/NotificationsDropdown.vue'

export default defineComponent({
  name: 'MobileMainMenu',
  components: {
    LoginToggleLink,
    DomainNavigationDropdown,
    NotificationsDropdown,
  },
  setup() {
    const { availableRoutes } = useMenuRoutes()
    const { filteredDomains, switchDomain } = useDomainNavigation()

    const isOpen = ref(false)

    return {
      availableRoutes,
      filteredDomains,
      isOpen,
      switchDomain,
    }
  },
})
