























































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import useOptions from '~/composable/useOptions'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import OptionDescriptionForm from '~/components/option/OptionDescriptionForm.vue'
import OptionPriceForm from '~/components/option/OptionPriceForm.vue'
import OptionConditionsForm from '~/components/option/OptionConditionsForm.vue'
import OptionApplicableServicesForm from '~/components/option/OptionApplicableServicesForm.vue'

export default defineComponent({
  name: 'OptionIdPage',
  components: {
    AppContentHeading,
    OptionDescriptionForm,
    OptionPriceForm,
    OptionConditionsForm,
    OptionApplicableServicesForm,
  },
  middleware: ['optionMiddleware'],
  setup() {
    const { params, app: { $accessor } } = useContext()
    const { getOptionTranslationField } = useOptions()
    const optionId = computed(() => parseInt(params.value.id))

    async function patchOneOption(id: number) {
      const option = $accessor.options.getOne(id)
      await $accessor.options.patchOne(option)
    }

    const isAdditionnalCost = computed(() => $accessor.options.getOne(optionId.value).min > 0)

    return {
      getTranslationField: (field: string) => getOptionTranslationField(field, optionId.value),
      patchOneOption,
      optionId,
      isAdditionnalCost,
    }
  },
})
