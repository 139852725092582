

















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import BookingCustomer from '~/components/booking/BookingCustomer.vue'
import BookingMarketplace from '~/components/booking/BookingMarketplace.vue'
import BookingDetailsReservation from '~/components/booking/BookingDetailsReservation.vue'
import BookingTimeline from '~/components/booking/BookingTimeline.vue'
import { Booking } from '~/types/models'
import useBookingHistory from '@/composable/useBookingHistory'

export default defineComponent({
  name: 'BookingDetails',
  components: {
    BookingCustomer,
    BookingMarketplace,
    BookingDetailsReservation,
    BookingTimeline,
  },
  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },
  setup(props) {
    const { state: historyState } = useBookingHistory(props.booking.id)

    return { historyState }
  },
})
