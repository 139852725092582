import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { User } from '~/types/models'

export interface UserState extends BaseState<User> {
  createForm: UserCreateForm
  currentUser: User | null,
  currentUserId: number | null,
  currentProviderId: number | null,
  isImpersonating: boolean
}

export enum UserMutationTypes {
  SET_CURRENT = 'SET_CURRENT',
  SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID',
  SET_CURRENT_PROVIDER_ID = 'SET_CURRENT_PROVIDER_ID',
  SET_IS_IMPERSONATING = 'SET_IS_IMPERSONATING',
  RESET_STATE = 'RESET_STATE',
  UPDATE_CREATE_FORM_FIELD = 'UPDATE_CREATE_FORM_FIELD',
  RESET_CREATE_FORM = 'RESET_CREATE_FORM',
}

export interface UserCreateForm {
  email: string
  password: string
  provider: number
  username: string
}
