import { Optional, PresenceStatus, ThreeDS2Data } from '~/types'
import { TranslateResult } from 'vue-i18n'

export enum RootMutationTypes {
  SET_3DS2_CLIENT_IP = 'SET_3DS2_CLIENT_IP',
  SET_3DS2_CLIENT_INFO = 'SET_3DS2_CLIENT_INFO',
  TOGGLE_APP_IS_LOADING = 'TOGGLE_APP_IS_LOADING',
  START_PREVENT_WINDOW_CLOSE = 'START_PREVENT_WINDOW_CLOSE',
  END_PREVENT_WINDOW_CLOSE = 'END_PREVENT_WINDOW_CLOSE',
  PRESENCE_USER_CREATE = 'PRESENCE_USER_CREATE',
  PRESENCE_USER_SET_STATUS = 'PRESENCE_USER_SET_STATUS',
  PRESENCE_USER_REMOVE = 'PRESENCE_USER_REMOVE',
  PRESENCE_CURRENT_USER_SET_STATUS = 'PRESENCE_CURRENT_USER_SET_STATUS',
}

export type RootState = {
  threeDS2Data: ThreeDS2Data | Optional<Pick<ThreeDS2Data, 'clientIPAddress' | 'isComplete'>, 'isComplete'>,
  isAppLoading: boolean,
  userPresenceStatus: Record<number, { providerId: number, status: PresenceStatus }>,
  currentUserPresenceStatus: PresenceStatus,
  ui: {
    preventWindowClose: null | string | TranslateResult,
  },
}

export interface PaginationState {
  total: number
}
