


















































import { defineComponent, reactive, ref } from '@nuxtjs/composition-api'
import useAuth from '~/composable/useAuth'

export default defineComponent({
  name: 'ForgotPasswordPage',
  setup() {
    const { sendResetPasswordEmail } = useAuth()
    const form = ref(null)
    const state = reactive({
      email: '',
      isSubmitting: false,
    })

    async function submitForm() {
      await sendResetPasswordEmail(state.email)
    }

    return {
      form,
      state,
      submitForm,
    }
  },
})
