import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { Domain, DomainCreateForm } from '~/types/models'

export enum DomainMutationTypes {
  SET_LIST = 'SET_LIST',
  SET_CURRENT = 'SET_CURRENT',
  UPDATE_FIELD = 'UPDATE_FIELD',
  RESET_STATE = 'RESET_STATE',
  RESET_CREATE_FORM = 'RESET_CREATE_FORM',
  UPDATE_CREATE_FORM_FIELD = 'UPDATE_CREATE_FORM_FIELD',
  UPDATE_CREATE_FORM_EXTERNAL_ID_FIELD = 'UPDATE_CREATE_FORM_EXTERNAL_ID_FIELD',
  ADD_CREATE_FORM_ITEM = 'ADD_CREATE_FORM_ITEM',
  REMOVE_CREATE_FORM_ITEM = 'REMOVE_CREATE_FORM_ITEM',
}

export interface DomainState extends BaseState<Domain> {
  createForm: DomainCreateForm[],
}
