
































































import { defineComponent } from '@nuxtjs/composition-api'
import DomainTableModeration from '~/components/establishment/DomainTableModeration.vue'
import OptionTableModeration from '~/components/option/OptionsTableModeration.vue'
import FilesTableModeration from '~/components/media/FilesTableModeration.vue'
import ServiceTableModeration from '~/components/service/ServiceTableModeration.vue'

export default defineComponent({
  name: 'MarketplaceModerationsPage',
  components: {
    DomainTableModeration,
    OptionTableModeration,
    FilesTableModeration,
    ServiceTableModeration,
  },
})
