import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{staticClass:"relative",attrs:{"active":_vm.active,"card":""},on:{"update:active":function (val) { return _vm.$emit('update:active', val); }}},[_c('template',{slot:"title"},[_vm._v("\n    "+_vm._s(_vm.$t('giftcards.add_spending'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"modal-card-body",attrs:{"data-cy":"addSpendingModal"}},[_c('ValidationObserver',{staticClass:"columns is-multiline",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"booking_id","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.booking_id'),"type":{ 'is-danger': errors[0] },"message":errors}},[_c('BInput',{attrs:{"required":"","data-cy":"createSpendingForm-booking"},model:{value:(_vm.form.booking),callback:function ($$v) {_vm.$set(_vm.form, "booking", $$v)},expression:"form.booking"}})],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"column is-full-desktop is-hidden-touch"}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"provider_id","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.provider_id'),"type":{ 'is-danger': errors[0] },"message":errors}},[_c('BInput',{attrs:{"required":"","data-cy":"createSpendingForm-provider"},model:{value:(_vm.form.providerId),callback:function ($$v) {_vm.$set(_vm.form, "providerId", $$v)},expression:"form.providerId"}})],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"column is-full-desktop is-hidden-touch"}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"t('giftcardtotal_amount","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('giftcards.spending_amount')}},[_c('SymbolInput',{attrs:{"data-cy":"createSpendingForm-amount"},model:{value:(_vm.form.amountSpent.amount),callback:function ($$v) {_vm.$set(_vm.form.amountSpent, "amount", $$v)},expression:"form.amountSpent.amount"}},[_vm._v("\n            €\n          ")])],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"column is-full has-text-centered"},[_c('BaseButton',{staticClass:"is-success",attrs:{"disabled":invalid,"data-cy":"addSpending-submit"},on:{"click":_vm.submit}},[_vm._v("\n          "+_vm._s(_vm.$t('actions.add'))+"\n        ")])],1)]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }