import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import useDomainNavigation from '~/composable/useDomainNavigation';
import CreateDomainModal from '~/components/modal/CreateDomainModal.vue';
export default defineComponent({
  name: 'DomainNavigationDropdown',
  components: {
    CreateDomainModal: CreateDomainModal
  },
  setup: function setup(_, _ref) {
    var emit = _ref.emit;

    var _useDomain = useDomain(),
        currentDomain = _useDomain.currentDomain;

    var _useDomainNavigation = useDomainNavigation(),
        filteredDomains = _useDomainNavigation.filteredDomains,
        switchDomain = _useDomainNavigation.switchDomain;

    var state = reactive({
      isModalVisible: false
    });

    var onClick = function onClick(domainId) {
      switchDomain(domainId);
      emit('itemClick');
    };

    return _objectSpread(_objectSpread({}, toRefs(state)), {}, {
      currentDomain: currentDomain,
      switchDomain: switchDomain,
      filteredDomains: filteredDomains,
      onClick: onClick
    });
  }
});