import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('MessageToggleButton',{staticClass:"has-text-weight-bold mb-5",attrs:{"type":"is-info","auto-toggle":false}},[_c('div',{staticClass:"has-text-centered"},[_c('span',{staticClass:"has-text-weight-normal"},[_vm._v("\n        "+_vm._s(_vm.$t('calendar.messages.create_booking_conditions'))+"\n      ")])])]),_vm._v(" "),_c('div',{staticClass:"columns is-multiline"},[_c('ValidationProvider',{staticClass:"column is-full",attrs:{"name":"offer_periods","rules":"period","skip-if-empty":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('BaseField',{attrs:{"label":_vm.$tc('offer.periods', 2),"type":{ 'is-danger': errors[0], 'has-text-dark': valid },"message":errors}},[_c('DateRangePicker',{attrs:{"range-item-formatter":_vm.calendarDatesFormatter,"month-names":_vm.$t('hotel_datepicker.month-names'),"day-names":_vm.$t('hotel_datepicker.day-names'),"first-day-of-week":_vm.getOrderOfDayInWeek,"locale":_vm.currentRegionLocale,"mobile-native":false},on:{"input":function($event){return validate($event)}},model:{value:(_vm.periods[0]),callback:function ($$v) {_vm.$set(_vm.periods, 0, $$v)},expression:"periods[0]"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"condition_min_nights","tag":"div","rules":("isSmaller:" + _vm.maxBookingDuration)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('rental.min_nights'),"type":{ 'is-danger': errors[0], 'has-text-dark': valid },"message":("" + (errors[0] ? errors + _vm.$t('form.fields.condition_max_nights'): ''))}},[_c('BNumberinput',{model:{value:(_vm.minBookingDuration),callback:function ($$v) {_vm.minBookingDuration=$$v},expression:"minBookingDuration"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"condition_max_nights","tag":"div","rules":("isBigger:" + _vm.minBookingDuration)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('rental.max_nights'),"type":{ 'is-danger': errors[0], 'has-text-dark': valid },"message":("" + (errors[0] ? errors + _vm.$t('form.fields.condition_min_nights'): ''))}},[_c('BNumberinput',{model:{value:(_vm.maxBookingDuration),callback:function ($$v) {_vm.maxBookingDuration=$$v},expression:"maxBookingDuration"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-full",attrs:{"name":"offer_services","rules":"min_length:1","skip-if-empty":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('BaseField',{attrs:{"label":_vm.$t('calendar.checking_days'),"type":{ 'is-danger': errors[0], 'has-text-dark': valid },"message":errors}},[_c('WeekdayCheckboxes',{attrs:{"selected-days":_vm.getSelectedDays,"message":errors},on:{"input":validate},model:{value:(_vm.checkinDays),callback:function ($$v) {_vm.checkinDays=$$v},expression:"checkinDays"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-full",attrs:{"name":"offer_services","rules":"min_length:1","skip-if-empty":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('BaseField',{attrs:{"label":_vm.$t('calendar.checkout_days'),"type":{ 'is-danger': errors[0], 'has-text-dark': valid },"message":errors}},[_c('WeekdayCheckboxes',{attrs:{"message":errors},on:{"input":validate},model:{value:(_vm.checkoutDays),callback:function ($$v) {_vm.checkoutDays=$$v},expression:"checkoutDays"}})],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"column is-full has-text-centered"},[_c('BButton',{staticClass:"is-primary is-size-5 is-uppercase has-text-weight-bold",attrs:{"size":"is-large","disabled":invalid},on:{"click":_vm.onSubmit}},[_vm._v("\n        "+_vm._s(_vm.$t('actions.save'))+"\n      ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }