import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePageSection',{attrs:{"title":_vm.required ? _vm.$t('add_option.additional_cost_description') : _vm.$t('add_option.option_description'),"aria-id":"option-description","can-toggle":""}},[_c('ValidationProvider',{staticClass:"column is-full-touch is-half-desktop",attrs:{"name":"option_category","rules":"required","tag":"div","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.required ? _vm.$t('add_option.additional_cost_category') : _vm.$t('add_option.option_category'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BSelect',{attrs:{"placeholder":_vm.$t('common.select'),"size":"is-medium","expanded":"","required":""},model:{value:(_vm.getField('category').value),callback:function ($$v) {_vm.$set(_vm.getField('category'), "value", $$v)},expression:"getField('category').value"}},_vm._l((_vm.optionsCategoriesArray),function(category){return _c('option',{key:category,domProps:{"value":category}},[_vm._v("\n          "+_vm._s(_vm.$t(("options.categories." + (category.toLowerCase()))))+"\n        ")])}),0)],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"column is-hidden-touch is-half-desktop"}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"option_name","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.required ? _vm.$t('add_option.additional_cost_name') : _vm.$t('add_option.option_name'),"type":{ 'is-warning':_vm.getOptionFieldToModerate('name').value, 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-full-touch is-half-desktop"},[_c('TranslatableInput',{attrs:{"type":"text","size":"is-medium","content-scope":"option","is-new":!_vm.optionId,"force-disabled":_vm.currentDomainIsSh},on:{"locale-change":function($event){return _vm.onUpdatedLocale($event, 'name')}},model:{value:(_vm.getField('name').value),callback:function ($$v) {_vm.$set(_vm.getField('name'), "value", $$v)},expression:"getField('name').value"}})],1),_vm._v(" "),(_vm.getOptionFieldToModerate('name').value && _vm.optionFieldPreviousVersion('name').value)?_c('div',{staticClass:"column is-half-desktop is-full-touch"},[_c('PreviousVersionDisplay',{attrs:{"value":_vm.optionFieldPreviousVersion('name').value,"current-locale":_vm.state.name}})],1):_vm._e()])])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"option_description","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ModeratableTextArea',{attrs:{"label":(_vm.required ? _vm.$t('options.the_additional_cost_description') : _vm.$t('options.the_description')) + ' *',"subtitle":_vm.$t('establishment.help_description'),"type":{ 'is-warning':_vm.getOptionFieldToModerate('description').value, 'is-danger': errors[0], 'is-primary': true },"previous-version":_vm.optionFieldPreviousVersion('description').value,"content-scope":"domain"},model:{value:(_vm.getField('description').value),callback:function ($$v) {_vm.$set(_vm.getField('description'), "value", $$v)},expression:"getField('description').value"}},[_c('p',[_vm._v(_vm._s(_vm.$t('add_option.description_help_text')))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }