import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ImageMetadataForm"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"name":"image_title","as":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('media.title'),"message":errors}},[_c('BInput',{model:{value:(_vm.getCurrentFileTranslation(_vm.file.id, 'name').value),callback:function ($$v) {_vm.$set(_vm.getCurrentFileTranslation(_vm.file.id, 'name'), "value", $$v)},expression:"getCurrentFileTranslation(file.id, 'name').value"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"image_alt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('media.alt'),"message":errors}},[_c('BInput',{model:{value:(_vm.getCurrentFileTranslation(_vm.file.id, 'alt').value),callback:function ($$v) {_vm.$set(_vm.getCurrentFileTranslation(_vm.file.id, 'alt'), "value", $$v)},expression:"getCurrentFileTranslation(file.id, 'alt').value"}})],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-end"},[_c('BaseButton',{attrs:{"size":"is-small","disabled":invalid || !_vm.$accessor.entityFiles.getOne(_vm.file.id).$isDirty},on:{"click":_vm.onSubmit}},[_vm._v("\n        "+_vm._s(_vm.$t('actions.save'))+"\n      ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }