import providersMutations from './mutations'
import providersGetters from './getters'
import providersActions from './actions'
import providersState from './state'

export const providers = {
  namespaced: true,
  state: providersState,
  actions: providersActions,
  mutations: providersMutations,
  getters: providersGetters,
}
