
















































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import { DateTime } from 'luxon'
import { BookingStatusColorsEnum, MarketplaceLabelAffilaeEnum } from '~/types/models'
import StatusBadge from '~/components/badges/StatusBadge.vue'

export default defineComponent({
  name: 'LastBookings',
  components: { StatusBadge },
  setup() {
    const { app: { $accessor } } = useContext()
    const isAdmin = computed(() => $accessor.users.currentUserIsAdmin)


    const lastBookingsForDomain = computed(() => $accessor.bookings.lastBookingsForDomain)
    const bookingFromId = (id: number) => $accessor.bookings.getOne(id)
    const customerFromBookingId = (id: number) =>  computed(() => $accessor.customers.getOne(bookingFromId(id).customer))
    const serviceFromBookingId = (id: number) => $accessor.services.getOne(bookingFromId(id).service)
    const marketplaceFromBookingId = (id: number) => $accessor.marketplaces.getOne(bookingFromId(id).marketplace)

    const displayMarketPlaceForBooking = (id: number) => {
      const isAffiliateMarketplace = Object.values(MarketplaceLabelAffilaeEnum).includes(marketplaceFromBookingId(id).label as MarketplaceLabelAffilaeEnum)
      return isAffiliateMarketplace || isAdmin.value
    }

    const formatDate = (date: string) => DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
    const formatDateFull = (date: string) => DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)

    return {
      bookingFromId,
      customerFromBookingId,
      serviceFromBookingId,
      marketplaceFromBookingId,
      displayMarketPlaceForBooking,
      formatDate,
      formatDateFull,
      BookingStatusColorsEnum,
      lastBookingsForDomain,
    }
  },
})
