import { WeekDayNamesEnum } from '~/helpers'

export enum OfferDayEnum {
  PUBLIC_HOLIDAY = 'public_holiday',
  PUBLIC_HOLIDAY_EVE = 'public_holiday_eve',
}

export type OfferDays =
WeekDayNamesEnum.MONDAY |
WeekDayNamesEnum.TUESDAY |
WeekDayNamesEnum.WEDNESDAY |
WeekDayNamesEnum.THURSDAY |
WeekDayNamesEnum.FRIDAY |
WeekDayNamesEnum.SATURDAY |
WeekDayNamesEnum.SUNDAY |
OfferDayEnum.PUBLIC_HOLIDAY |
OfferDayEnum.PUBLIC_HOLIDAY_EVE
export interface Offer {
  id: number
  $fullyLoaded?: boolean
}
