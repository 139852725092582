import { Middleware } from '@nuxt/types'

const domainEquipmentsMiddleware: Middleware = async({ route, app: { $api, $accessor } }) => {
  const domainId = parseInt(route.params.domain)

  if ($accessor.domainEquipmentsEntries.getAllIds().length === 0) {
    const currentDomainEquipments = await $api.get(`domains/${domainId}/equipments`)
    const allDomainEquipments = await $api.get('domainequipments?limit=-1')

    $accessor.domainEquipmentsEntries.CREATE_MANY(currentDomainEquipments)
    $accessor.domainEquipments.CREATE_MANY(allDomainEquipments.data)
  }
}

export default domainEquipmentsMiddleware
