



























import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SymbolInput',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'number',
    },
    step: {
      type: Number,
      default: 0.1,
    },
  },
})
