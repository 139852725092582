import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-full"},[(_vm.platformId === _vm.ExternalPlatformTypeEnum.SH)?[_c('ValidationProvider',{attrs:{"name":"sh_establishment_id","rules":"required","data-cy":"CreateServiceShIdInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('form.fields.sh_service_id'),"message":errors}},[_c('BInput',{model:{value:(_vm.getInnerValueField('productId').value),callback:function ($$v) {_vm.$set(_vm.getInnerValueField('productId'), "value", $$v)},expression:"getInnerValueField('productId').value"}})],1)]}}],null,false,2167625722)})]:_vm._e(),_vm._v(" "),(_vm.platformId === _vm.ExternalPlatformTypeEnum.EVIIVO)?[_c('BaseMessage',{attrs:{"type":"is-warning","has-icon":"","closable":false,"data-cy":"CreateEviivoServiceMessage"}},[_c('i18n',{attrs:{"path":"service.details.eviivo_service_help.message","tag":"p"}},[_c('a',{staticClass:"tw-font-bold",attrs:{"href":_vm.valetEviivoHelperUrl,"target":"_blank","data-cy":"CreateEviivoServiceHelperLink"}},[_vm._v("\n          "+_vm._s(_vm.$t('service.details.eviivo_service_help.link'))+"\n        ")])])],1),_vm._v(" "),(_vm.platformId === _vm.ExternalPlatformTypeEnum.EVIIVO)?_c('ValidationProvider',{attrs:{"data-cy":"CreateServiceEviivoRoomTypeIdInput","name":"eviivo_room_type_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('form.fields.eviivo_room_type_id'),"message":errors,"data-cy":"CreateServiceEviivoRoomTypeIdInput"}},[_c('BInput',{model:{value:(_vm.getInnerValueField('ev_roomTypeId').value),callback:function ($$v) {_vm.$set(_vm.getInnerValueField('ev_roomTypeId'), "value", $$v)},expression:"getInnerValueField('ev_roomTypeId').value"}})],1)]}}],null,false,1074168624)}):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.platformId === _vm.ExternalPlatformTypeEnum.KE)?_c('ValidationProvider',{attrs:{"data-cy":"CreateServiceKePropertyIdInput","name":"ke_unit_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('form.fields.ke_unit_id'),"message":errors}},[_c('BInput',{model:{value:(_vm.getInnerValueField('unitId').value),callback:function ($$v) {_vm.$set(_vm.getInnerValueField('unitId'), "value", $$v)},expression:"getInnerValueField('unitId').value"}})],1)]}}],null,false,1738631577)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }