import authMutations from './mutations'
import authGetters from './getters'
import authActions from './actions'
import authState from './state'

export const auth = {
  namespaced: true,
  state: authState,
  actions: authActions,
  mutations: authMutations,
  getters: authGetters,
}
