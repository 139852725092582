import { Middleware } from '@nuxt/types'

const domainSustainabilityActionsMiddleware: Middleware = async({ app: { $accessor } }) => {

  if ($accessor.domainSustainabilityActions.getAllIds().length === 0) {
    await $accessor.domainSustainabilityActions.fetchAll()
  }
}

export default domainSustainabilityActionsMiddleware
