import addressesActions from './actions'
import addressesMutations from './mutations'
import addressesGetters from './getters'
import addressesState from './state'

export const addresses = {
  namespaced: true,
  state: addressesState,
  actions: addressesActions,
  mutations: addressesMutations,
  getters: addressesGetters,
}
