

















































import { DrawerDisplayModeEnum } from '~/store/calendar/types'
import { CalendarDrawerNameEnum, CalendarDrawerOptions, CalendarEntitiesEnum } from '~/types/Calendar'
import { BookingCondition, BookingConditionCalendar, Service } from '~/types/models'
import { CalendarEvent } from '~/types'
import { computed, ComputedRef, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import { DateTime } from 'luxon'
import useCalendar from '~/composable/useCalendar'

export default defineComponent({
  name: 'CalendarRentalDurationLines',
  props: {
    service: {
      type: Object as PropType<Service>,
      required: true,
    },
  },
  setup(props) {
    const { app: { $accessor, i18n } } = useContext()
    const { getCalendarDays, getCalendarPushEvents, lineRef, lineWidth, showCondition, onBookingConditionClick } = useCalendar()

    const bookingConditions: ComputedRef<BookingConditionCalendar | null> = computed(() => {
      return $accessor.calendar.bookingConditionCalendars.find((bookingCondition) => bookingCondition.service === props.service.id) || null
    })

    function onDayClicked(day: DateTime) {
      $accessor.calendar.UDPATE_SELECTED_PERIODS([{
        start: day.toJSDate(),
        end: day.toJSDate(),
      }])

      const drawerOpts: CalendarDrawerOptions = {
        drawer: CalendarDrawerNameEnum.CREATE,
        mode: DrawerDisplayModeEnum.CREATE,
        data: {
          startDate: day,
          service: props.service.id,
        },
      }
      if ($accessor.calendar.ui.isDrawerOpened) {
        $accessor.calendar.RESET_UI_DRAWER()
      }
      $accessor.calendar.UI_OPEN_DRAWER(drawerOpts)
      if ($accessor.calendar.ui.drawer === CalendarDrawerNameEnum.CREATE) {
        $accessor.calendar.UPDATE_SELECTED_SERVICES([props.service.id])
        $accessor.calendar.UPDATE_ENTITY_FORM_OPENED(CalendarEntitiesEnum.BOOKING_CONDITION)
      }
    }

    const events: ComputedRef<Record<number, CalendarEvent<BookingCondition> & { displayKey: number }>> = computed(() => {
      if (lineWidth.value && bookingConditions.value) {
        let key = 0
        return getCalendarPushEvents<BookingCondition>(bookingConditions.value.calendar, lineWidth.value)
          .reduce((acc, event) => {
            key++
            return {
              ...acc,
              [key]: {
                displayKey: key,
                ...event,
              },
            }
          }
          , {})
      }
      return []
    })

    function getLineContent(entity: BookingCondition) {
      return `${i18n.tc('rate.min_nights', entity.minBookingDuration)} ${i18n.tc('rate.max_nights', entity.maxBookingDuration)}`
    }

    return {
      lineRef,
      getLineContent,
      events,
      showCondition,
      getCalendarDays,
      onBookingConditionClick,
      onDayClicked,
      bookingConditions,
    }
  },
})

