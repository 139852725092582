import { ByIdParams, createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { Address } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<Address>(),

  echoUpdate(_, { id, payload }: ByIdParams<Address>): void {
    const currentAddress = this.app.$accessor.addresses.getOne(payload.id)
    // We're only updating lat/lng for now, until we can properly check for $isDirty etc.
    // @TODO Avoid updating an already dirty address, we'll update it when they stop editing.
    if (currentAddress && (currentAddress.lat !== payload.lat || currentAddress.lng !== payload.lng)) {
      // @TODO don't log in production.
      console.log('updating from echo') // eslint-disable-line
      this.app.$accessor.addresses.update({
        id,
        payload: {
          ...currentAddress,
          lat: payload.lat,
          lng: payload.lng,
        },
      })
    }
  },


  async fetchOne({ commit }, id: number): Promise<void> {
    try {
      const address: Address = await this.app.$api.get(`addresses/${id}`)
      return commit(BaseMutationTypes.CREATE, address)
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('domain_adresses_fetching'))
    }
  },

  async fetchMany({ commit }, addressIds: number[]) {
    if (addressIds.length > 0) {
      const ids = addressIds.join()
      const res = await this.app.$api.get(`addresses?filters[id_in]=${ids}&limit=${addressIds.length}`)
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },

  async patchOne({ state, commit }, { id, payload = null }: { id: number, payload?: Partial<Address> | null }): Promise<void> {
    try {
      const address: Address = await this.app.$api.patch(`addresses/${id}`, payload ? payload : state.entities.byId[id])
      commit(BaseMutationTypes.UPDATE, {
        id,
        payload: {
          ...address,
          $isDirty: false,
        },
      })
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.common_updating_error'))
    }
  },

  async postOne({ commit }): Promise<Address | void> {
    try {
      const payload = this.app.$accessor.addresses.getCreateForm
      const address = await this.app.$api.post('addresses', payload)
      commit(BaseMutationTypes.CREATE, address)
      return address
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.address_created'))
    }
  },
})

export default actions
