

































































































































































































































































































import { ServiceEquipmentTypeEnum } from '~/types/models'
import { defineComponent, watch } from '@nuxtjs/composition-api'
import range from 'lodash/range'
import useServices from '~/composable/useServices'
import useModeration from '~/composable/useModeration'
import ServiceEquipmentTypeSelect from '~/components/service/ServiceEquipmentTypeSelect.vue'
import ModeratableTextArea from '~/components/moderation/ModeratableTextArea.vue'
import HRSwitch from '~/components/HRSwitch.vue'

export default defineComponent({
  name: 'ServiceSettings',
  components: {
    ServiceEquipmentTypeSelect,
    ModeratableTextArea,
    HRSwitch,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      getCurrentServiceField: getField,
    } = useServices()
    const { getServiceFieldToModerate, serviceFieldPreviousVersion } = useModeration()

    const minAgeValues = range(0, 19)

    watch(
      () => getField('isMinorsAllowed').value,
      newVal => {
        getField('minimumAge').value =  newVal ? 0 : 18
      },
    )

    return {
      getField,
      getServiceFieldToModerate,
      minAgeValues,
      ServiceEquipmentTypeEnum,
      serviceFieldPreviousVersion,
    }
  },
})
