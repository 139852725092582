


























import { ShortToISOEnum, Translation } from '~/types'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { defaultEmptyTranslation } from '~/helpers'

export default defineComponent({
  name: 'PreviousVersionDisplay',
  props: {
    value: {
      type: Object as PropType<Translation | null>,
      default: defaultEmptyTranslation(),
    },
    currentLocale: {
      type: String as PropType<ShortToISOEnum>,
      default: ShortToISOEnum.FR,
    },
  },
  setup() {
    return {
      ShortToISOEnum,
    }
  },
})
