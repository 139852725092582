import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { ServiceUnit } from '~/types/models'
import { resetState } from '~/helpers/store'
import { ServicesTypeMutationTypes } from './types'
import baseState, { serviceUnit } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<ServiceUnit>(serviceUnit),
  [ServicesTypeMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
})

export default mutations
