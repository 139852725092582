
















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { GenderEnum } from '~/types/models/Customer'

export default defineComponent({
  name: 'GenderSelect',
  props: {
    value: {
      type: String as PropType<GenderEnum>,
      default: '',
    },
  },
  setup() {
    return { GenderEnum }
  },
})
