import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { GiftCardSpending } from '~/types/models'
import { state } from './state'
import { mutations } from './mutations'

export const actions = actionTree({ state, mutations }, {
  ...createActions<GiftCardSpending>(),

  async fetchMany({ commit }, giftCardSpendingsIds: number[]) {
    if (giftCardSpendingsIds.length > 0) {
      const ids = giftCardSpendingsIds.join()
      const res = await this.app.$api.get(`giftcardspendings?filters[id_in]=${ids}&limit=${giftCardSpendingsIds.length}`)
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
        return res.data
      }
    }
  },

  async patchOne({ commit }, payload: Partial<GiftCardSpending>) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.giftcard_updating'))
    try {
      const res = await this.app.$api.patch(`giftcardspendings/${payload.id}`, payload)
      commit(BaseMutationTypes.UPDATE, {
        id: res.id,
        payload: res,
      })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.giftcard_updated'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.giftcard_update_error'))
    }
  },

  async deleteOne({ commit }, id: number) {
    await this.app.$api.delete(`giftcardspendings/${id}`)
    commit(BaseMutationTypes.DELETE, id)
  },
})

export default actions
