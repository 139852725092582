import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"GeneralInformations"},[_c('BaseMessage',{attrs:{"type":"is-info","has-icon":"","closable":false}},[_vm._v("\n    "+_vm._s(_vm.$t('messages.warning_publication'))+"\n  ")]),_vm._v(" "),_c('BasePageSection',{attrs:{"title":_vm.$t('establishment.the_establishment'),"aria-id":"establishment","can-toggle":""}},[_c('ValidationProvider',{attrs:{"name":"establishmentName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [(_vm.currentDomain)?_c('BaseField',{attrs:{"label":_vm.$t('establishment.the_name') + '*',"type":{ 'is-warning':_vm.getDomainFieldToModerate('commercialName').value, 'is-danger': errors[0], 'is-success': valid, 'is-primary': true },"message":errors}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half-desktop"},[_c('TranslatableInput',{attrs:{"content-scope":"domain"},on:{"locale-change":function($event){return _vm.onUpdatedLocale($event, 'commercialName')}},model:{value:(_vm.getDomainField('commercialName').value),callback:function ($$v) {_vm.$set(_vm.getDomainField('commercialName'), "value", $$v)},expression:"getDomainField('commercialName').value"}})],1),_vm._v(" "),(_vm.getDomainFieldToModerate('commercialName').value && _vm.domainFieldPreviousVersion('commercialName').value)?_c('div',{staticClass:"column is-half-desktop",attrs:{"label":""}},[_c('PreviousVersionDisplay',{attrs:{"value":_vm.domainFieldPreviousVersion('commercialName').value,"current-locale":_vm.state.commercialName}})],1):_vm._e()])]):_vm._e()]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"establishmentDescription","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ModeratableMarketplaceTextArea',{attrs:{"label":_vm.$t('establishment.the_description') + '*',"subtitle":_vm.$t('establishment.help_description'),"type":{ 'is-warning': !!_vm.getDomainFieldToModerate('description').value ,'is-danger': errors[0], 'is-primary': true },"message":errors,"previous-version":_vm.domainFieldPreviousVersion('description').value,"content-scope":"domain"},model:{value:(_vm.getDomainField('description').value),callback:function ($$v) {_vm.$set(_vm.getDomainField('description'), "value", $$v)},expression:"getDomainField('description').value"}},[_c('p',[_vm._v(_vm._s(_vm.$t('establishment.message_description')))]),_vm._v(" "),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('common.example').toString() + _vm.$t('col').toString()))])]),_vm._v(" "),_c('p',[_c('em',[_vm._v(_vm._s(_vm.$t('establishment.message_description_example')))])])])]}}])})],1),_vm._v(" "),_c('BasePageSection',{attrs:{"title":_vm.$t('establishment.the_owners'),"aria-id":"owners","can-toggle":""}},[_c('ValidationProvider',{attrs:{"name":"establishmentOwnersName","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ModeratableTextArea',{attrs:{"label":_vm.$t('establishment.the_owners_name') + '*',"subtitle":_vm.$t('establishment.help_description'),"type":{ 'is-warning':_vm.getDomainFieldToModerate('ownersPresentation').value, 'is-danger': errors[0], 'is-primary': true },"message":errors,"previous-version":_vm.domainFieldPreviousVersion('ownersPresentation').value,"content-scope":"domain"},model:{value:(_vm.getDomainField('ownersPresentation').value),callback:function ($$v) {_vm.$set(_vm.getDomainField('ownersPresentation'), "value", $$v)},expression:"getDomainField('ownersPresentation').value"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"establishmentOwnersWord","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ModeratableMarketplaceTextArea',{attrs:{"label":_vm.$t('establishment.the_owners_word') + '*',"subtitle":_vm.$t('establishment.help_description'),"type":{ 'is-warning':_vm.getDomainFieldToModerate('ownersWord').value, 'is-danger': errors[0], 'is-primary': true },"message":errors,"previous-version":_vm.domainFieldPreviousVersion('ownersWord').value,"content-scope":"domain"},model:{value:(_vm.getDomainField('ownersWord').value),callback:function ($$v) {_vm.$set(_vm.getDomainField('ownersWord'), "value", $$v)},expression:"getDomainField('ownersWord').value"}},[_c('p',[_vm._v(_vm._s(_vm.$t('establishment.the_owners_word_help_message')))])])]}}])}),_vm._v(" "),_c('BaseField',{attrs:{"label":_vm.$t('establishment.the_owners_pic'),"subtitle":_vm.$t('establishment.help_logo')}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half is-one-quarter-desktop"},[(!_vm.ownersPicture)?_c('ImageDropzone',{attrs:{"id":"establishmentOwnersPicUpload","url":((_vm.$api.getBaseUrl()) + "/files"),"upload-params":{
              type: _vm.EntityFileTypeEnum.DOMAIN_OWNERS_PICTURE,
              'name[fr-FR]': '',
              'alt[fr-FR]': '',
              'description[fr-FR]': '',
              'filename[fr-FR]': '',
              isPrivate: 0,
              'metadata[valign_pct]': 50,
              idx: 1,
              domain: _vm.$route.params.domain
            },"upload-headers":{
              Authorization: ("Bearer " + (_vm.$api.getToken())),
            },"custom-dropzone-options":_vm.customDropZoneOptions,"dropzone-empty-classes":"has-text-centered has-padding-top-50 has-padding-bottom-50","dropzone-processing-classes":"columns is-multiline is-mobile has-padding-20"},on:{"onFileUploaded":_vm.eventFileUploaded},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var dropzoneState = ref.dropzoneState;
return [(!dropzoneState.hasFiles)?_c('BaseLink',{attrs:{"has-icon":"","icon-left":"plus","icon-pack":"usi"}},[_vm._v("\n                "+_vm._s(_vm.$t('actions.add'))+"\n              ")]):_vm._e()]}}],null,false,651867224)}):_c('img',{attrs:{"src":_vm.ownersPicture.url,"alt":""}})],1),_vm._v(" "),(_vm.ownersPicture && _vm.ownersPicture.url)?_c('div',{staticClass:"column is-half is-one-quarter-desktop"},[_c('div',[_c('BaseLink',{attrs:{"has-icon":"","icon-left":"delete","icon-pack":"usi","type":"is-danger"},on:{"click":function($event){return _vm.deleteDomainPicture(_vm.ownersPicture.id)}}},[_vm._v("\n              "+_vm._s(_vm.$t('actions.delete'))+"\n            ")])],1)]):_vm._e()])])],1),_vm._v(" "),_c('DomainSustainabilityActions')],1)}
var staticRenderFns = []

export { render, staticRenderFns }