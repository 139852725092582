


















import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import { marketplaceRoutes } from '~/routes/marketplaceRoutes'
import useFeatureToggle from '~/composable/useFeatureToggle'
import useTabsNavigation from '~/composable/useTabsNavigation'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import TabsNavigation from '~/components/navigation/TabsNavigation.vue'

export default defineComponent({
  name: 'Marketplace',
  components: {
    AppContentHeading,
    TabsNavigation,
  },
  setup() {
    const { route, params, app } = useContext()
    const { isEnabledRoute } = useFeatureToggle()
    const providerId = computed(() => parseInt(params.value.provider, 10))

    const pageTitle = computed(() =>
      app.i18n.t(`routes.${route.value!.name!.split('___')[0]}.page_title`),
    )

    // TODO: Remove 'as any' if we find a way for TS not to throw errors over intersection types
    const childRoutes = marketplaceRoutes.find(_route => _route.name === 'marketplace')!.children!
    const enabledRoutes = childRoutes.filter(route => isEnabledRoute(route as any))

    const { currentIndex } = useTabsNavigation({
      childRoutes: enabledRoutes as any,
      root: 'marketplace',
      defaultRoute: 'marketplace-domains',
      routeParams: {},
    })

    return {
      providerId,
      pageTitle,
      enabledRoutes,
      currentIndex,
    }
  },
})
