import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { resetState } from '~/helpers/store'
import { ServiceLabel } from '~/types/models'
import baseState, { serviceLabel } from './state'
import { ServiceLabelsMutationTypes } from './types'

export const mutations = mutationTree(baseState, {
  ...createMutations<ServiceLabel>(serviceLabel),
  [ServiceLabelsMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
})

export default mutations
