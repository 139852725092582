import { actionTree } from 'typed-vuex'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { AuthMutationTypes } from './types'
import { LoginCredentials, ResetPasswordForm } from '~/composable/useAuth'
import { AuthCookie, isValidAuthCookie } from '~/helpers'
import { Route } from 'vue-router'

export const actions = actionTree({ state, getters, mutations }, {
  async login({ commit }, credentials: LoginCredentials): Promise<void | Route> {
    const { $api, $warningToast, $errorToast, i18n, $successToast, $accessor } = this.app
    if (
      this.app.$featureToggle.toggles.maintenanceMode &&
      !['adminus', 'reservation@unicstay.com'].includes(credentials.email)
    ) {
      return this.$router.push(`${this.app.localePath('auth-login')}?maintenance=1`)
    }
    try {
      const res: AuthCookie = await $api.post('/auth/login', {
        email: credentials.email,
        password: credentials.password,
      })
      $api.setToken(res.token).setAuthCookie(res.token, res.expiresAt, res.user, res.refreshToken)

      await $accessor.users.fetchOne(res.user)
      await $accessor.marketplaces.fetchAll()

      commit(AuthMutationTypes.LOGIN, res.user)

      $successToast(i18n.t('auth.logged_in_success'))
    } catch (error: any) {
      if (error.response.data.code === 10000) {
        $warningToast(i18n.t('form.error.wrong_id_or_password'))
        return
      }
      $errorToast(this.app.i18n.t('form.error.auth_contact_support'))
    }
  },
  async logout({ commit }): Promise<void> {

    // Use a function to clean state that we'll call using setTimeout,
    // so it's triggered after navigating back to login form + works in Node.
    const cleanupState = () => {
      commit(AuthMutationTypes.LOGOUT)
      commit(AuthMutationTypes.RESET_STATE)
      this.app.$accessor.calendar.RESET_STATE()
      // this.app.$accessor.offers.RESET_STATE()
      this.app.$accessor.options.RESET_STATE()
      this.app.$accessor.providers.RESET_STATE()
      this.app.$accessor.domains.RESET_STATE()
      this.app.$accessor.serviceEquipments.RESET_STATE()
      this.app.$accessor.services.RESET_STATE()
      this.app.$accessor.serviceThemes.RESET_STATE()
      this.app.$accessor.unavailabilities.RESET_STATE()
      this.app.$accessor.users.RESET_STATE()
      this.app.$accessor.servicePricings.RESET_STATE()
      this.app.$accessor.entityFiles.RESET_STATE()
      this.app.$accessor.serviceTypes.RESET_STATE()
      this.app.$accessor.customers.RESET_STATE()
      this.app.$accessor.addresses.RESET_STATE()
      this.app.$accessor.bookings.RESET_STATE()
      this.app.$accessor.domainMarketplaceConfigurations.RESET_STATE()
      this.app.$accessor.notifications.RESET_STATE()
    }
    setTimeout(cleanupState, 1000)
  },
  async refreshAuthToken(_, refreshToken: string) {
    const res: AuthCookie = await this.app.$api.post('/auth/refresh', { refreshToken })
    if (isValidAuthCookie(res)) {
      this.app.$api.setToken(res.token).setAuthCookie(res.token, res.expiresAt, res.user, res.refreshToken)
      this.app.$autoRefreshToken.update(res)
    } else {
      await this.app.$accessor.auth.logout()
      await this.app.redirect(`${this.app.localePath('auth')}?inactive=1`)
    }
  },
  async sendResetPasswordEmail(_, email: string) {
    try {
      await this.app.$api.post('/auth/forgot', { email })
      this.app.$successToast(this.app.i18n.t('toast.password_change_email_sent'))
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.password_change_email_sent_error'))
    }
  },
  async resetPassword(_, form: ResetPasswordForm) {
    const { $loadingToast, $successToast, $errorToast, $api, router, i18n, localePath } = this.app

    const toast = $loadingToast(i18n.t('toast.password_updating'))

    try {
      await $api.post('/auth/reset', form)
      toast.goAway(0)
      $successToast(i18n.t('toast.password_reset'))
      if (router) {
        router.push(localePath({ name: 'auth-login' }))
      }
    } catch (error) {
      toast.goAway(0)
      $errorToast(i18n.t('toast.error_generic'))
    }
  },
})

export default actions
