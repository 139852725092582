import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validator",staticClass:"columns has-margin-top-10",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{staticClass:"column is-one-fifth",attrs:{"name":"ical_name","rules":"required","tag":"div","skip-if-empty":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.ical_name'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{attrs:{"placeholder":_vm.$t('ical.import.name_placeholder')},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half",attrs:{"name":"ical_url","rules":"required","tag":"div","skip-if-empty":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.ical_url'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{attrs:{"placeholder":_vm.$t('ical.import.url_placeholder')},model:{value:(_vm.sourceUrl),callback:function ($$v) {_vm.sourceUrl=$$v},expression:"sourceUrl"}})],1)]}}],null,true)}),_vm._v(" "),_c('BaseField',{staticClass:"column",attrs:{"label":_vm.$t('ical.import.label_sync_interval')}},[_c('BSelect',{attrs:{"disabled":!_vm.isAdmin},model:{value:(_vm.syncInterval),callback:function ($$v) {_vm.syncInterval=$$v},expression:"syncInterval"}},_vm._l((_vm.intervals),function(option,index){return _c('option',{key:index,domProps:{"value":option}},[_vm._v("\n        "+_vm._s(_vm.getIntervalText(option))+"\n      ")])}),0)],1),_vm._v(" "),_c('div',{staticClass:"column level is-full-touch is-flex is-justify-content-center"},[_c('BaseButton',{attrs:{"disabled":invalid},on:{"click":_vm.onSubmit}},[_vm._v("\n      "+_vm._s(_vm.isEditMode ? _vm.$t('actions.save') : _vm.$t('actions.import'))+"\n    ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }