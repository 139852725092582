import { MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'nuxt-typed-vuex'
import { User } from '~/types/models'
import { UserMutationTypes } from './types'
import state from './state'
import { mutations } from './mutations'
import { getters } from './getters'

export const actions = actionTree({ state, getters, mutations }, {
  async fetchOne({ commit }, id: number): Promise<void> {
    const { $api } = this.app
    const user: User = await $api.get(`/users/${id}`)
    commit(UserMutationTypes.SET_CURRENT, user)
    commit(UserMutationTypes.SET_CURRENT_USER_ID, user.id)
  },

  async postOne({ commit }, providerId: number): Promise<User | null> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.user_creating'))
    try {
      const data = {
        ...this.app.$accessor.users.createForm,
        provider: providerId,
      }
      const res = await this.app.$api.post('users', data)
      commit(BaseMutationTypes.CREATE, res)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.user_created'))
      return res
    } catch (error: any) {
      toast.goAway(0)
      this.app.$errorToast(`${this.app.i18n.t('toast.user_create_error')} :
        ${error.response?.data?.message}
      `)
      return null
    }
  },

  startImpersonatingProvider(_, providerId: number): void {
    this.app.$accessor.users.SET_CURRENT_PROVIDER_ID(providerId)
    this.app.$accessor.users.SET_IS_IMPERSONATING(true)
  },

  stopImpersonatingProvider(): void {
    this.app.$accessor.users.SET_CURRENT_PROVIDER_ID(0)
    this.app.$accessor.users.SET_IS_IMPERSONATING(false)
  },
})

export default actions
