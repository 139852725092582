




















































































































































































































































































































































import { defineComponent, useContext, reactive, onMounted } from '@nuxtjs/composition-api'
import useProvider from '~/composable/useProvider'
import useAddress from '~/composable/useAddress'
import { GenderEnum } from '~/types/models/Customer'
import { StructureStatusEnum } from '~/types/models/Provider'
import LocationForm from '~/components/form/LocationForm.vue'
import CountriesSelect from '~/components/form/CountriesSelect.vue'
import GenderSelect from '../form/GenderSelect.vue'
import orderBy from 'lodash/orderBy'
import { Country } from '~/types/Geo'

export default defineComponent({
  name: 'LegalInfo',
  components: {
    LocationForm,
    CountriesSelect,
    GenderSelect,
  },
  setup() {
    const { app: { $api } } = useContext()
    const { currentProvider, currentProviderId, getProviderField } = useProvider()
    const { getAddressField } = useAddress()

    const addressId = getProviderField('unicstayContactAddress', currentProviderId.value).value
    const state = reactive({ countries: [] as Country[] })

    onMounted(async() => {
      const countries = await $api.get('countries')
      state.countries = orderBy(countries, 'name', 'asc')
    })

    return {
      state,
      currentProvider,
      getProviderField,
      GenderEnum,
      addressId,
      getAddressField,
      StructureStatusEnum,
      currentProviderId,
    }
  },
})
