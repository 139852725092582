import { Translation } from '~/types'

export enum DomainActivityTypeEnum {
  ESTABLISHMENT = 'ESTABLISHMENT',
  NEARBY = 'NEARBY'
}

export type DomainActivityType = DomainActivityTypeEnum.ESTABLISHMENT | DomainActivityTypeEnum.NEARBY

export interface DomainActivity {
  id: number
  slug: string
  name: Translation
  type: DomainActivityType
}
