





















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'TermsInfo',
  props: {
    date: {
      type: String,
      required: true,
    },
    downloadText: {
      type: String,
      default: 'Télécharger', // FIXME translate default values?
    },
  },
})
