import { Middleware } from '@nuxt/types'

const customerAddressMiddleware: Middleware = async({ route, app: { $accessor } }) => {
  const customerId = parseInt(route.params.id)
  const customer = $accessor.customers.getOne(customerId)

  if (!$accessor.addresses.getAllIds().includes(customer.billingAddress)) {
    await $accessor.addresses.fetchOne(customer.billingAddress)
  }
}

export default customerAddressMiddleware
