import invert from 'lodash/invert'
import { DateTime } from 'luxon'

export const ageTypePluralToSingular = {
  'adults': 'adult',
  'children': 'child',
  'infants': 'infant',
}

export const ageTypeSingularToPlural = invert(ageTypePluralToSingular)

export const getAgeTypeSingular = (val: string): string => ageTypePluralToSingular[val as keyof typeof ageTypePluralToSingular]

export const getAgeTypePlural = (val: string): string => ageTypeSingularToPlural[val]

export const getAgeTypeOfOccupant = (birthDate: string | Date, maxChildren: number, maxInfant: number): string => {
  const birthDateParsed = DateTime.fromJSDate(new Date(birthDate))
  const yearsDiff = birthDateParsed.diffNow('years').toObject().years
  if (yearsDiff) {
    if (Math.floor(Math.abs(yearsDiff)) <= maxChildren) {
      return 'child'
    }
    if (Math.floor(Math.abs(yearsDiff)) <= maxInfant) {
      return 'infant'
    }
  }
  return 'adult'
}
