import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { DomainMarketplaceConfiguration } from '~/types/models'
import { resetState } from '~/helpers/store'
import { DomainMarketplaceConfigurationMutationTypes } from './types'
import baseState, { baseCreateForm, domainMarketplaceConfiguration } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<DomainMarketplaceConfiguration>(domainMarketplaceConfiguration),
  [DomainMarketplaceConfigurationMutationTypes.UPDATE_FIELD](state, { id, path, value }: { id: number, path: string, value: any }) {
    updateField(state.entities.byId[id], { path, value })
  },

  [DomainMarketplaceConfigurationMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },

  [DomainMarketplaceConfigurationMutationTypes.RESET_CREATE_FORM](state) {
    state.createForm = [baseCreateForm()]
  },

  [DomainMarketplaceConfigurationMutationTypes.ADD_CREATE_FORM_ITEM](state, { marketplaceId, domainId }: { marketplaceId: number, domainId: number }) {
    state.createForm.push({ ...baseCreateForm(), marketplace: marketplaceId, domain: domainId })
  },

  [DomainMarketplaceConfigurationMutationTypes.UPDATE_CREATE_FORM_FIELD](state, { marketplaceId, path, value }: { marketplaceId: number, path: string, value: any }) {
    updateField(state.createForm.filter((form) => form.marketplace === marketplaceId)[0], { path, value })
  },

  [DomainMarketplaceConfigurationMutationTypes.REMOVE_CREATE_FORM_ITEM](state, { marketplaceId, domainId }: { marketplaceId: number, domainId: number }) {
    state.createForm = state.createForm.filter(form => form.marketplace !== marketplaceId && form.domain !== domainId)
  },
})
export default mutations
