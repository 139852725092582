import Dinero from 'dinero.js';
import { formatAmount, fromCent, parseAmount, parseNumberInputValue, toCent } from '~/helpers';
export default function (ctx, inject) {
  inject('dinero', Dinero);
  inject('parseAmount', function (value) {
    return parseAmount(value, ctx.app.i18n.locale);
  });
  inject('formatAmount', function (value, format) {
    return formatAmount(value, ctx.app.i18n.locale, format);
  });
  inject('toAmount', function (value) {
    return value.toJSON();
  });
  inject('toCent', function (value) {
    return toCent(value);
  });
  inject('fromCent', function (value) {
    return fromCent(value);
  });
  inject('parseNumberInputValue', function (value) {
    return parseNumberInputValue(value);
  });
}