


































































import { defineComponent } from '@nuxtjs/composition-api'
import useMenuRoutes from '~/composable/useMenuRoutes'

export default defineComponent({
  name: 'MainMenu',
  setup() {
    const { availableRoutes } = useMenuRoutes()

    return { availableRoutes }
  },
})
