
















import { defineComponent, useContext } from '@nuxtjs/composition-api'
import CalendarDrawer from '~/components/calendar/drawers/CalendarDrawer.vue'
import CalendarAccordionEntityForms from '~/components/calendar/CalendarAccordionEntityForms.vue'

export default defineComponent({
  name: 'CalendarCreateDrawer',
  components: {
    CalendarDrawer,
    CalendarAccordionEntityForms,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { app: { $accessor } } = useContext()

    function onClose() {
      $accessor.calendar.RESET_UI_DRAWER()
      emit('on-close')
    }

    function onClickOutside() {
      emit('on-click-outside')
    }

    return {
      onClose,
      onClickOutside,
    }
  },
})
