import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api'
import { Domain } from '~/types/models'
import useDomain from './useDomain'
import useProvider from './useProvider'

interface DomainNavigationHook {
  filteredDomains: ComputedRef<Record<number, Domain>>
  switchDomain: (id: number) => Promise<void>
}

function useDomainNavigation(): DomainNavigationHook {
  const { route, app: { router, localePath, $accessor } } = useContext()
  const { currentDomain, fetchOne: fetchDomain } = useDomain()
  const { currentProviderId } = useProvider()

  const filteredDomains = computed(() => {
    return $accessor.domains.getWhere(domain => domain.id !== currentDomain.value.id)
  })

  const switchDomain = async(id: number) => {
    if (router && route.value.name) {
      $accessor.calendar.RESET_STATE()
      $accessor.domainActivitiesEntries.RESET_STATE()
      $accessor.domainEquipmentsEntries.RESET_STATE()

      // Check if the current route has other params than Provider and Domain
      // This indicates that the current route only depends on the Domain context,
      // with no Domain specific entities like Bookings or Customer
      const isSubRoute = Object.keys(route.value.params).length > 2

      // Compute the redirection based on this
      const name = isSubRoute
        ? `${route.value.name.split('-')[0]}s` // Take the current route name, split before '-id' and pluralise it to go back to entities
        : route.value.name.split('___')[0] // Take the current route name and split the locale

      // Check if this route exists (better safe than sorry, since the above logic isnt really error proof)
      const isExistingRoute = router.resolve(localePath({ name })).resolved.matched.length > 0

      // If the route exists, redirect to it with required params
      if (isExistingRoute) {
        await fetchDomain(id, { fetchServices: true })
        router.push(localePath({
          name,
          params: {
            provider: currentProviderId.value.toString(),
            domain: id.toString(),
          },
        }))
        // Exit early since the route exists. No need to execute the rest.
        return
      } else {
        // Else, compute a fallback route based on some business conditions then redirect
        let fallBackName = ''
        if ($accessor.users.currentUserIsProvider) {
          fallBackName = 'home'
        }

        if ($accessor.users.currentUserIsAdmin) {
          // The check to 'isAdminRoute' is here to know if the Admin is impersonating a Provider
          fallBackName = route.value.meta?.isAdminRoute ? 'marketplace-establishments' : 'domain-generalinfo'
        }

        await fetchDomain(id, { fetchServices: true })

        router.push(localePath({
          name: fallBackName,
          params: {
            provider: currentProviderId.value.toString(),
            domain: id.toString(),
          },
        }))
      }
    }
  }

  return {
    filteredDomains,
    switchDomain,
  }
}

export default useDomainNavigation
