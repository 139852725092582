































































































import { OptionModeratableFields, optionsCategoriesArray } from '~/types/models/Option'
import { defineComponent, reactive, useContext, computed } from '@nuxtjs/composition-api'
import { ShortToISOEnum } from '~/types'
import useOptions from '~/composable/useOptions'
import useDomain from '~/composable/useDomain'
import useModeration from '~/composable/useModeration'
import useI18n from '~/composable/useI18n'
import TranslatableInput from '~/components/i18n/TranslatableInput.vue'
import PreviousVersionDisplay from '~/components/content/PreviousVersionDisplay.vue'
import ModeratableTextArea from '~/components/moderation/ModeratableTextArea.vue'

export default defineComponent({
  name: 'OptionDescriptionForm',
  components: {
    ModeratableTextArea,
    TranslatableInput,
    PreviousVersionDisplay,
  },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionId: {
      required: false,
      default: null,
      validator: (prop: number | null) => typeof prop === 'number' || prop === null,
    },
  },
  setup() {
    const { params } = useContext()
    const { getOptionField } = useOptions()
    const { currentDomainIsSh } = useDomain()
    const { getOptionFieldToModerate, optionFieldPreviousVersion } = useModeration()
    const { currentLocale } = useI18n()

    const state: Partial<Record<keyof OptionModeratableFields, ShortToISOEnum>> = reactive({
      name: ShortToISOEnum[currentLocale.toUpperCase() as keyof typeof ShortToISOEnum],
    })

    const onUpdatedLocale = (event: ShortToISOEnum, field: keyof OptionModeratableFields) => {
      state[field] = event
    }
    const optionId = computed(() => parseInt(params.value.id))

    const getField = (field: string) => {
      if (field === 'name') {
        getOptionField('label', optionId.value)
      }
      return getOptionField(field, optionId.value)
    }

    return {
      getField,
      getOptionFieldToModerate,
      currentDomainIsSh,
      onUpdatedLocale,
      optionFieldPreviousVersion,
      optionsCategoriesArray,
      state,
      getOptionField,
    }
  },
})
