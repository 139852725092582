import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { getSecureHolidayEngineId } from '~/helpers';
import { ExternalPlatformTypeEnum } from '~/types/models';
export default defineComponent({
  props: {
    value: {
      type: Object,
      required: true
    },
    platformId: {
      type: String,
      default: ExternalPlatformTypeEnum.SH
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    // Required <any> here to tell TS to stfu when trying to index union types in `getInnerValueField`, should not be too important anyway.
    var innerValue = computed({
      get: function get() {
        return props.value;
      },
      set: function set(newValue) {
        return emit('input', newValue);
      }
    }); // Allows us to use v-model on nested properties without mutating VueX store.
    // Some black destructuring magic in set() to mutate the entire innerValue without mutating VueX.

    var getInnerValueField = function getInnerValueField(field) {
      return computed({
        get: function get() {
          return innerValue.value[field];
        },
        set: function set(newValue) {
          var newInnerValue = _objectSpread({}, innerValue.value);

          newInnerValue[field] = newValue;
          innerValue.value = newInnerValue;
        }
      });
    }; // When the `platformId` changes we have to reset the form to match new requirements based on the new value.


    watch(function () {
      return props.platformId;
    }, function (newValue) {
      switch (newValue) {
        case ExternalPlatformTypeEnum.KE:
          innerValue.value = {
            propertyId: ''
          };
          break;

        case ExternalPlatformTypeEnum.EVIIVO:
          innerValue.value = {
            ev_shortName: '' // eslint-disable-line camelcase

          };
          break;

        default:
          // SH
          innerValue.value = {
            engineId: getSecureHolidayEngineId(),
            establishmentId: ''
          };
          break;
      }
    });
    return {
      getInnerValueField: getInnerValueField,
      ExternalPlatformTypeEnum: ExternalPlatformTypeEnum
    };
  }
});