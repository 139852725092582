import { Booking, BookingEvent, BookingEventsEnum, IBookingTimelineItem, Money, Payment, PaymentMethodEnum, PaymentStatusEnum } from '~/types/models'
import { computed, onMounted, reactive, useContext, watch } from '@nuxtjs/composition-api'
import { sortByDateDesc } from '~/helpers/dateTime'

export interface BookingHistoryHook {
  state: BookingHistoryState
}

interface BookingHistoryState {
  bookingHistory: BookingEvent[],
  bookingPayments: Payment[],
  bookingTimeline: IBookingTimelineItem[] | null
}

function useBookingHistory(bookingId: number): BookingHistoryHook {

  const { app: { $api, $accessor } } = useContext()
  const isAdmin = computed(() => $accessor.users.currentUserIsAdmin)

  const state: BookingHistoryState = reactive<BookingHistoryState>({
    bookingHistory: [],
    bookingPayments: [],
    bookingTimeline: null,
  })

  const booking = computed(() => $accessor.bookings.getOne(bookingId))

  // Watch booking so we can refresh the timeline dynamically when the user patches the booking
  watch(
    () => booking.value,
    async() => await fetchHistory(),
    { deep: true },
  )

  onMounted(async() => {
    await fetchHistory()
  })

  const fetchHistory = async() => {
    state.bookingHistory = await $api.get(`bookings/${booking.value.id}/history`)
    state.bookingPayments = $accessor.payments.getWhereArray(payment => booking.value.payments.includes(payment.id))
    generateBookingHistory()
  }

  const generateBookingHistory = () => {
    const bookingEvents: IBookingTimelineItem[] = generateBookingEvents()
    const paymentEvents = generatePaymentEvents()
    const refundEvents = generateRefundEvents()

    state.bookingTimeline = [
      ...bookingEvents,
      ...paymentEvents,
      ...refundEvents,
    ].sort((event1, event2) => sortByDateDesc(event1.loggedAt, event2.loggedAt))
  }

  const generatePaymentEvents = (): IBookingTimelineItem[] => {
    // Filter out refunds as they are handled in another function
    const payments = state.bookingPayments.filter(payment => !payment.isRefund)
    if (!isAdmin.value) {
      return generatePaymentEventsForUserRole(payments)
    }
    return generatePaymentEventsForAdminRole(payments)
  }

  const generatePaymentEventsForAdminRole = (payments: Payment[]): IBookingTimelineItem[] => {
    return payments.filter(payment => payment.status !== PaymentStatusEnum.CREATED)
      .map(payment => ({
        id: payment.id,
        data: payment.amount,
        eventType: payment.paymentMethod === PaymentMethodEnum.GIFT_CARD ? BookingEventsEnum.GIFTCARD_PAYMENT_RECEIVED : BookingEventsEnum.PAYMENT_RECEIVED,
        giftCard: payment.paymentMethod === PaymentMethodEnum.GIFT_CARD ? payment.paymentMethodIdentifier : null,
        loggedAt: payment.executedAt,
      }))
  }

  /**
   * Concatenates all payments in a single event because Users must only see a single Payment event on the timeline
   */
  const generatePaymentEventsForUserRole = (payments: Payment[]): IBookingTimelineItem[] => {
    if (!payments.length) {
      return []
    }
    const reducedPayments = payments.reduce((acc, payment) => {
      return {
        ...acc,
        amount: {
          currency: payment.amount.currency,
          amount: acc.amount.amount + payment.amount.amount,
        },
      }
    })
    const singleEvent = {
      id: reducedPayments.id,
      data: reducedPayments.amount,
      eventType: BookingEventsEnum.PAYMENT_RECEIVED,
      loggedAt: reducedPayments.executedAt,
    }
    return [singleEvent]
  }

  const generateRefundEvents = (): IBookingTimelineItem[] => {
    const refunds = state.bookingPayments.filter(payment => payment.isRefund)
    return refunds.map(refund => {
      return {
        id: refund.id,
        data: {
          ...refund.amount,
          amount: Math.abs(refund.amount.amount),
        },
        eventType: BookingEventsEnum.REFUND_RECEIVED,
        loggedAt: refund.executedAt,
      }
    })
  }

  const generateBookingEvents = (): IBookingTimelineItem[] => {
    return state.bookingHistory
      .map(event => ({
        id: event.id,
        data: event.data as Partial<Booking> & Money,
        eventType: event.data.status ? BookingEventsEnum[event.data.status] : BookingEventsEnum.UPDATED,
        loggedAt: event.loggedAt,
      }))
  }

  return { state }
}

export default useBookingHistory
