import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { Booking } from '~/types/models'
import { resetState } from '~/helpers/store'
import { BookingMutationTypes } from './types'
import baseState, { booking } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<Booking>(booking),
  [BookingMutationTypes.EXPORT_DOWNLOAD_START](state) {
    state.isDownloadingExport = true
  },
  [BookingMutationTypes.EXPORT_DOWNLOAD_END](state) {
    state.isDownloadingExport = false
  },
  [BookingMutationTypes.SET_LASTS_FOR_DOMAIN](state, bookings: number[]) {
    state.lastBookingsForDomain = bookings
  },
  [BookingMutationTypes.SET_TODAY_FOR_DOMAIN](state, bookings: number[]) {
    state.todayBookingsForDomain = bookings
  },
  [BookingMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
})

export default mutations
