import "core-js/modules/es6.number.constructor.js";
import { headingLevelTags } from '~/helpers/constants';
export default defineComponent({
  name: 'BlockSubtitle',
  props: {
    level: {
      type: Number,
      default: 0
    }
  },
  setup: function setup(props) {
    var tag = ref(headingLevelTags[props.level]);
    return {
      tag: tag
    };
  }
});