import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api'
import { Location } from 'vue-router'
import { Values } from 'vue-i18n'
import { getTimeDiff } from '~/helpers'
import snakeCase from 'lodash/snakeCase'
import orderBy from 'lodash/orderBy'
import useDomain from './useDomain'
import useDomainNavigation from './useDomainNavigation'
import useProvider from './useProvider'

import {
  BasicBookingNotification,
  ModerationNotification,
  Notification,
  NotificationActions,
  NotificationActionTranslationKeysEnum,
  NotificationBackgroundClassesEnum,
  NotificationClassEnum,
  NotificationContentScopeEnum,
  NotificationContentScopeMapingEnum,
  NotificationDisplayInfo,
  NotificationStatusTranslationKeysEnum,
  NotificationTextClassesEnum,
  NotificationTypeEnum,
} from '@/types/models/Notification'

export interface NotificationsHook {
  fetchAll(): Promise<void>
  getNotificationActions(notification: Notification): NotificationActions
  getNotificationContent(notification: Notification): string
  getNotificationDisplayValues(notification: Notification): NotificationDisplayInfo,
  getNotificationTime(notification: Notification): string
  getNotificationType(notification: Notification): NotificationTypeEnum
  markAsRead(id: number): Promise<void>
  unreadCount: ComputedRef<number>
  unreadNotifications: ComputedRef<Notification[]>
}


function useNotifications(): NotificationsHook {
  const { route, params, app: { $accessor, router, localePath, $dateTime, i18n, $translateEntityField } } = useContext()
  const { currentProviderId } = useProvider()
  const { currentDomainId } = useDomain()
  const { switchDomain } = useDomainNavigation()

  async function fetchAll() {
    await $accessor.notifications.fetchAll()
  }

  async function markAsRead(id: number) {
    await $accessor.notifications.patchOne({ id })
  }

  const unreadNotifications = computed(() => orderBy($accessor.notifications.getUnread, 'createdAt', 'desc'))

  const unreadCount = computed(() => unreadNotifications.value.length)

  function getNotificationType({ notificationClass }: Notification) {
    switch (notificationClass) {
      case NotificationClassEnum.BOOKING_CANCELLED_PROVIDER
        || NotificationClassEnum.BOOKING_CANCELLED_UNICSTAY
        || NotificationClassEnum.INCOMPLETE_SERVICE:
        return NotificationTypeEnum.ALERT

      case NotificationClassEnum.BOOKING_UPDATED_PROVIDER
        || NotificationClassEnum.BOOKING_UPDATED_UNICSTAY:
        return NotificationTypeEnum.WARNING

      case NotificationClassEnum.BOOKING_CONFIRMED_PROVIDER
        || NotificationClassEnum.BOOKING_CONFIRMED_UNICSTAY:
        return NotificationTypeEnum.INFO

      default:
        return NotificationTypeEnum.INFO
    }
  }

  function getNotificationDisplayValues(notification: Notification): NotificationDisplayInfo {
    const notificationType = getNotificationType(notification)
    return {
      backgroundColor: NotificationBackgroundClassesEnum[notificationType],
      textColor: NotificationTextClassesEnum[notificationType],
      statusText: i18n.t(NotificationStatusTranslationKeysEnum[notificationType]),
    }
  }

  function getNotificationActions(notification: Notification): NotificationActions {
    const notificationType = getNotificationType(notification)
    return {
      label: NotificationActionTranslationKeysEnum[notificationType],
      callback: async() => {
        if (router) {
          const newRoute = getRedirectionParams(notification)
          if (newRoute.params && newRoute.params.domain !== currentDomainId.value.toString()) {
            await switchDomain(parseInt(newRoute.params.domain))
          }

          // Check if the new route is the same as the current one to avoid duplicate navigation
          // This can happen because some notifications in the dropdown could be about the same entity
          // For example => Booking 1 updated and Booking 1 cancelled resolve to the same redirect
          if (route.value.fullPath !== router.resolve(localePath(newRoute)).resolved.fullPath) {
            await router.push(localePath(newRoute))
          }
        }
      },
    }
  }

  function getModeratedEntityLabel(data: ModerationNotification) {
    switch (data.objectType) {

      case 'domain':
        return $translateEntityField($accessor.domains.getOne(data.objectId).commercialName)

      case 'service':
        return $translateEntityField($accessor.services.getOne(data.objectId).name)

      default:
        return data.label

    }
  }

  function getNotificationContent({ notificationClass, data }: Notification) {
    const content: { choice?: number | undefined, values?: Values | undefined } = {
      choice: undefined,
      values: undefined,
    }
    const contentScope = NotificationContentScopeEnum[NotificationContentScopeMapingEnum[notificationClass]]

    switch (contentScope) {

      case NotificationContentScopeEnum.FULL_BOOKING:
        content.choice = 0
        content.values = [
          data.serviceLabel,
          $dateTime.fromISO(data.startsAt as string).toLocaleString($dateTime.DATE_SHORT),
          $dateTime.fromISO(data.endsAt as string).toLocaleString($dateTime.DATE_SHORT),
          data.totalAmount.replace(' ', '&nbsp;'),
        ]
        break

      case NotificationContentScopeEnum.BASIC_BOOKING:
        content.choice = 0
        content.values = [
          data.bookingId,
          data.serviceLabel,
        ]
        break

      case NotificationContentScopeEnum.SERVICE:
        content.choice = data.missingNumber,
        content.values = [
          data.missingNumber,
          data.serviceLabel,
        ]
        break

      case NotificationContentScopeEnum.MODERATION:
        content.choice = 0,
        content.values = [
          i18n.t(`notifications.entities.${data.objectType}`),
          getModeratedEntityLabel(data),
        ]
        break

      default:
        break
    }

    const translationKey = `notifications.${snakeCase(notificationClass)}`

    return i18n.tc(translationKey, content.choice, content.values)
  }

  function getDomainIdForBookingNotification({ serviceId }: BasicBookingNotification) {
    const domain = $accessor.domains.getWhereArray(domain => domain.services.includes(serviceId))[0]
    return domain.id
  }

  function getRedirectionParams({ notificationClass, data }: Notification): Location {
    if (notificationClass.includes('Booking')) {
      return {
        name: 'booking-id',
        params: {
          provider: currentProviderId.value.toString(),
          domain: getDomainIdForBookingNotification(data).toString(),
          id: data.bookingId.toString(),
        },
      }
    }
    if (notificationClass === NotificationClassEnum.MODERATION_UPDATE) {
      switch (data.objectType) {
        case 'domain':
          return {
            name: 'home',
            params: { domain: data.objectId.toString() },
          }
        case 'option':
          return {
            name: 'option-id',
            params: {
              provider: params.value.provider,
              domain: $accessor.options.getOne(data.objectId).domain.toString(),
              id: data.objectId.toString(),
            },
          }
        case 'service':
          return {
            name: 'service-id',
            params: {
              provider: params.value.provider,
              domain: $accessor.services.getOne(data.objectId).domain.toString(),
              id: data.objectId.toString(),
            },
          }
        default:
          break
      }
    }
    return {
      name: 'service-id',
      params: {
        provider: params.value.provider,
        domain: $accessor.services.getOne(data.serviceId).domain.toString(),
        id: data.serviceId.toString(),
      },
    }
  }

  function getNotificationTime({ createdAt }: Notification) {
    return getTimeDiff(new Date(createdAt as string).toISOString(), new Date().toISOString())
  }

  return {
    fetchAll,
    getNotificationActions,
    getNotificationContent,
    getNotificationDisplayValues,
    getNotificationTime,
    getNotificationType,
    markAsRead,
    unreadCount,
    unreadNotifications,
  }
}

export default useNotifications
