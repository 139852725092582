







































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import orderBy from 'lodash/orderBy'
import { ServiceEquipmentTypeEnum } from '~/types/models'
import useServices from '~/composable/useServices'
import ServiceEquipmentCheckboxes from './ServiceEquipmentCheckboxes.vue'
import HRSwitch from '~/components/HRSwitch.vue'

export default defineComponent({
  name: 'ServiceOutdoorEquipments',
  components: { ServiceEquipmentCheckboxes, HRSwitch },
  setup() {
    const { app: { $accessor, $translateEntityField } } = useContext()
    const { getCurrentServiceField: getField } = useServices()

    const outdoorEquipments = computed(() => orderBy(
      $accessor.serviceEquipments.getWhere((equipment) => equipment.type === ServiceEquipmentTypeEnum.OUTDOORS),
      eq => $translateEntityField(eq.name),
    ))

    return {
      outdoorEquipments,
      getField,
      ServiceEquipmentTypeEnum,
    }
  },
})
