import Vue from 'vue'
import { extend } from 'vee-validate'
import { configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { NuxtAppOptions } from '@nuxt/types'
import { ValidationRule } from 'vee-validate/dist/types/types'
import { Translation } from '~/types'

export default function(ctx: NuxtAppOptions): void {
  // Configure globally the default message to use vue-i18n,
  // see https://logaretm.github.io/vee-validate/guide/localization.html#using-other-i18n-libraries
  configure({
    defaultMessage: (field, values) => {
      values._field_ = ctx.app.i18n.t(`form.fields.${field}`)
      return ctx.app.i18n.t(`form.validations.${values._rule_}`, values)
    },
  })

  // Import all vee-validate rules,
  // see https://logaretm.github.io/vee-validate/guide/rules.html#installing-all-rules
  for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, validation as ValidationRule)
  }

  extend('period', { validate: value => 'start' in value && 'end' in value })

  extend('min_length', {
    validate: (value, { length }) => value.length >= length,
    params: ['length'],
    message: (fieldName, { length }) => ctx.app.i18n.tc('form.validations.min_length', length, {
      '_field_': ctx.app.i18n.t(`form.fields.${fieldName}`),
      length,
    }),
  })

  extend('max_value', {
    validate: (value, { max }: Record<string, any>) => value <= max,
    params: ['max'],
    message: (fieldName, { max }: Record<string, any>) => ctx.app.i18n.tc('form.validations.max_or_equal_value', max, {
      '_field_': ctx.app.i18n.t(`form.fields.${fieldName}`),
      max,
    }),
  })

  extend('isSmaller', {
    validate: (value, otherValue) => {
      return value < otherValue
    },
  })

  extend('isBigger', {
    validate: (value, otherValue) => {
      return value > otherValue
    },
  })

  // Password validation
  function validate(value: any) {
    return new RegExp(/^(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=[\D]*[\d])(?=[a-zA-Z0-9]*[^(a-zA-Z0-9)]).{8,}$/).test(
      String(value),
    )
  }

  extend('password', { validate })

  // extend('ibanValidate', {
  //   validate: (value) => new RegExp(/\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b/gm).test(value),
  //   message: () => ctx.app.i18n.tc('form.validations.iban', { '_field_': ctx.app.i18n.tc('billing.account_iban') }),
  // })

  extend('translation', {
    validate: (value: Translation) => Object.values(value).some(translation => translation.trim() !== null && translation.trim().length > 0),
    message: () => ctx.app.i18n.t('form.validations.translation'),
  })

  // Register components globally, see https://logaretm.github.io/vee-validate/guide/basics.html#registering-the-validation-provider
  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)
}
