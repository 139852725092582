import { useContext } from '@nuxtjs/composition-api'

interface CustomerHook {
  fetchMany(ids: number[], options?: FetchOptions): Promise<void>
}

interface FetchOptions {
  fetchAddress?: boolean
}

function useCustomers(): CustomerHook {
  const { app: { $accessor } } = useContext()

  async function fetchMany(ids: number[], { fetchAddress = true }) {
    if (ids.length > 0) {
      const missingIds = ids.filter(id => !$accessor.customers.getAllIds().includes(id))
      if (missingIds.length > 0) {
        const customers = await $accessor.customers.fetchMany(ids)
        if (fetchAddress && customers) {
          const addressIds = customers
            .map(customer => customer.billingAddress)
            .filter(id => !$accessor.addresses.getAllIds().includes(id))
          if (addressIds.length > 0) {
            await $accessor.addresses.fetchMany(addressIds)
          }
        }
      }
    }
  }

  return {
    fetchMany,
  }
}
export default useCustomers
