import { RouteConfig } from '@nuxt/types/config/router'
import { Route } from 'vue-router'
import MarketplacePage from '~/components/pages/marketplace.vue'
import MarketplaceDomainsPage from '~/components/pages/marketplace/marketplace-domains.vue'
import MarketplaceServicesPage from '~/components/pages/marketplace/marketplace-services.vue'
import MarketplaceBookingsPage from '~/components/pages/marketplace/marketplace-bookings.vue'
import MarketplaceCommunicationsPage from '~/components/pages/marketplace/marketplace-communications.vue'
import MarketplaceCartsPage from '~/components/pages/marketplace/marketplace-carts.vue'
import MarketplaceGiftCardsPage from '~/components/pages/marketplace/marketplace-giftCards.vue'
import MarketplacePromocodesPage from '~/components/pages/marketplace/marketplace-promocodes.vue'
import GiftCardsListPage from '~/components/pages/giftCards/giftCards-list.vue'
import GiftCardsBookingsPage from '~/components/pages/giftCards/giftCards-bookings-list.vue'
import GiftCardsCheckPaymentPage from '~/components/pages/giftCards/giftCards-check-payment.vue'
import CreateGiftCard from '~/components/pages/giftCards/create-giftCard.vue'
import GiftCardId from '~/components/pages/giftCards/giftCard-id.vue'
import MarketplaceModerateDomainPage from '~/components/pages/marketplace/marketplace-moderate-domain.vue'
import MkpDomainConfiguration from '~/components/marketplace/establishment/MkpDomainConfiguration.vue'
import DomainLocation from '~/components/establishment/DomainLocation.vue'
import MkpDomainPictures from '~/components/marketplace/establishment/MkpDomainPictures.vue'
import MkpDomainServices from '~/components/marketplace/establishment/MkpDomainServices.vue'
import MKPModerationsPage from '~/components/pages/marketplace/marketplace-moderations.vue'
import MarketplaceModerateServicePage from '~/components/pages/marketplace/marketplace-moderate-service.vue'
import MkpServiceConfiguration from '~/components/marketplace/service/MkpServiceConfiguration.vue'
import MkpServicePictures from '~/components/marketplace/service/MkpServicePictures.vue'
import MkpServiceLabels from '~/components/marketplace/service/MkpServiceLabels.vue'
import MarketplaceCreateProvider from '~/components/pages/marketplace/marketplace-create-provider.vue'
import MkpDomainHlcp from '~/components/marketplace/establishment/MkpDomainHlcp.vue'
import BookingIdPage from '~/components/pages/bookings/booking-id.vue'
import CreatePromocode from '~/components/pages/promocodes/create-promocode.vue'
import PromocodeIdPage from '~/components/pages/promocodes/promocode-id.vue'

import { addRouteMeta } from '~/helpers/route'
import { RouteLike } from '~/types'

const _marketplaceRoutes: RouteConfig[] = [
  {
    path: '/marketplace',
    component: MarketplacePage,
    name: 'marketplace',
    children: [
      {
        path: '/marketplace/establishments',
        component: MarketplaceDomainsPage,
        name: 'marketplace-domains',
      },
      {
        path: '/marketplace/services',
        component: MarketplaceServicesPage,
        name: 'marketplace-services',
        meta: { featureGate: 'adminServices' },
      },
      {
        path: '/marketplace/bookings',
        component: MarketplaceBookingsPage,
        name: 'marketplace-bookings',
        meta: { featureGate: 'adminBookings' },
        children: [
          {
            path: '/marketplace/bookings/:id',
            component: BookingIdPage,
            name: 'marketplace-booking-id',
          },
        ],
      },
      {
        path: '/marketplace/communications',
        component: MarketplaceCommunicationsPage,
        name: 'marketplace-communications',
        meta: { featureGate: 'adminCommunications' },
      },
      {
        path: '/marketplace/moderation',
        component: MKPModerationsPage,
        name: 'marketplace-moderation',
        meta: { featureGate: 'adminModeration' },
      },
      {
        path: '/marketplace/carts',
        component: MarketplaceCartsPage,
        name: 'marketplace-carts',
        meta: { featureGate: 'adminCarts' },
      },
      {
        path: '/marketplace/giftcards',
        component: MarketplaceGiftCardsPage,
        name: 'marketplace-giftCards',
        meta: { featureGate: 'adminGiftCards' },
        children: [
          {
            path: '/marketplace/giftcards',
            component: GiftCardsListPage,
            name: 'marketplace-giftCards-list',
          },
          {
            path: '/marketplace/giftcards/bookings',
            component: GiftCardsBookingsPage,
            name: 'marketplace-giftCards-bookings',
          },
          {
            path: '/marketplace/giftcards/bookings/check-payment/:bookingId',
            component: GiftCardsCheckPaymentPage,
            name: 'giftCards-check-payment',
          },
        ],
      },
      {
        path: '/marketplace/promocodes',
        component: MarketplacePromocodesPage,
        name: 'promocodes',
        meta: {
          icon: 'present',
          featureGate: 'promocodes',
          isRoot: true,
        },
        children: [
          {
            path: '/marketplace/promocodes/create',
            component: CreatePromocode,
            name: 'marketplace-promocode-create',
          },
          {
            path: '/marketplace/promocodes/:id',
            component: PromocodeIdPage,
            name: 'marketplace-promocode-id',
          },
        ],
      },
    ],
  },
  {
    path: '/marketplace/giftcards/create',
    component: CreateGiftCard,
    name: 'marketplace-create-giftCard',
  },
  {
    path: '/marketplace/giftcards/:id',
    component: GiftCardId,
    name: 'marketplace-giftCard-id',
  },
  {
    path: '/marketplace/p/create',
    component: MarketplaceCreateProvider,
    name: 'marketplace-provider-create',
  },
  {
    path: '/marketplace/p/:provider/d/:domain',
    component: MarketplaceModerateDomainPage,
    name: 'marketplace-moderate-domain',
    children: [
      {
        path: '/marketplace/p/:provider/d/:domain/config',
        component: MkpDomainConfiguration,
        name: 'marketplace-moderate-domain-config',
      },
      {
        path: '/marketplace/p/:provider/d/:domain/marketplace',
        component: MkpDomainHlcp,
        name: 'marketplace-moderate-domain-marketplace',
      },
      {
        path: '/marketplace/p/:provider/d/:domain/location',
        component: DomainLocation,
        name: 'marketplace-moderate-domain-location',
      },
      {
        path: '/marketplace/p/:provider/d/:domain/pictures',
        component: MkpDomainPictures,
        name: 'marketplace-moderate-domain-pictures',
      },
      {
        path: '/marketplace/p/:provider/d/:domain/services',
        component: MkpDomainServices,
        name: 'marketplace-moderate-domain-services',
        meta: { featureGate: 'adminDomainServices' },
      },
    ],
  },
  {
    path: '/marketplace/p/:provider/d/:domain/s/:id',
    component: MarketplaceModerateServicePage,
    name: 'marketplace-moderate-service',
    meta: { isServiceDetails: true },
    children: [
      {
        path: '/marketplace/p/:provider/d/:domain/s/:id/config',
        component: MkpServiceConfiguration,
        name: 'marketplace-moderate-service-config',
        props: ({ params }: Route): any => ({ serviceId: parseInt(params.id) }),
        meta: { isServiceDetails: true },
      },
      {
        path: '/marketplace/p/:provider/d/:domain/s/:id/pictures',
        component: MkpServicePictures,
        name: 'marketplace-moderate-service-pictures',
        meta: { isServiceDetails: true },
      },
      {
        path: '/marketplace/p/:provider/d/:domain/s/:id/labels',
        component: MkpServiceLabels,
        name: 'marketplace-moderate-service-labels',
        meta: { isServiceDetails: true },
      },
    ],
  },
]

export const marketplaceRoutes = _marketplaceRoutes.map(route => addRouteMeta(route as unknown as RouteLike, { isAdminRoute: true }) as unknown as RouteConfig) as RouteConfig[]
