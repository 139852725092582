import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import { CalendarFiltersClassesEnum, CalendarFiltersEnum, CalendarGroupFiltersEnum, CalendarViewsEnum } from '~/types';
import { computed, defineComponent, reactive, useContext } from '@nuxtjs/composition-api';
import useCalendar from '~/composable/useCalendar';
import useServices from '~/composable/useServices';
import ServiceSelectDropdown from '~/components/service/ServiceSelectDropdown.vue';
export default defineComponent({
  name: 'CalendarFilters',
  components: {
    ServiceSelectDropdown: ServiceSelectDropdown
  },
  props: {
    filtersDisabled: {
      type: Boolean,
      default: false
    },
    isInline: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup() {
    var _useContext = useContext(),
        $accessor = _useContext.app.$accessor;

    var _useCalendar = useCalendar(),
        ui = _useCalendar.ui,
        view = _useCalendar.view;

    var _useDomain = useDomain(),
        currentDomain = _useDomain.currentDomain,
        currentDomainIsSh = _useDomain.currentDomainIsSh;

    var _useServices = useServices(),
        hasDefaultPricing = _useServices.hasDefaultPricing;

    function getTypeClass(type) {
      return CalendarFiltersClassesEnum[type] || '';
    }

    function isFilterVisible(type) {
      if (!currentDomainIsSh.value) {
        return true;
      } else {
        return currentDomainIsSh.value && [CalendarFiltersEnum.BOOKING, CalendarFiltersEnum.UNAVAILABILITY, CalendarFiltersEnum.ICALS].includes(type);
      }
    }

    function defaultValueRadioGroup() {
      if (ui.value.filters.ICALS || ui.value.filters.UNAVAILABILITY || ui.value.filters.BOOKING) {
        return CalendarGroupFiltersEnum.BOOKINGS_UNAVAILABILITIES_ICALS;
      }

      if (ui.value.filters.DISCOUNT) {
        return CalendarGroupFiltersEnum.DISCOUNT;
      }

      if (ui.value.filters.PRICING_CUSTOM || ui.value.filters.PRICING_DEFAULT) {
        return CalendarGroupFiltersEnum.PRICING;
      }

      if (ui.value.filters.DURATION) {
        return CalendarGroupFiltersEnum.DURATION;
      }

      return CalendarGroupFiltersEnum.BOOKINGS_UNAVAILABILITIES_ICALS;
    }

    var services = computed(function () {
      return $accessor.services.getWhereArray(function (service) {
        return currentDomain.value.services.includes(service.id);
      });
    });
    var disabledServices = computed(function () {
      return services.value.map(function (service) {
        return service.id;
      }).filter(function (id) {
        return !hasDefaultPricing(id);
      });
    });
    var state = reactive({
      expanded: false,
      availableServices: services.value,
      radioGroupFilters: defaultValueRadioGroup()
    });
    watch(function () {
      return view.value;
    }, function (newValue) {
      if (newValue === CalendarViewsEnum.TWO_MONTHS) {
        state.radioGroupFilters = defaultValueRadioGroup();
      }
    }, {
      immediate: true
    });
    watch(function () {
      return ui.value.filters;
    }, function () {
      state.radioGroupFilters = defaultValueRadioGroup();
    }, {
      deep: true
    });
    var calendarGroupFilters = computed({
      get: function get() {
        return state.radioGroupFilters;
      },
      set: function set(val) {
        state.radioGroupFilters = val;

        switch (state.radioGroupFilters) {
          case CalendarGroupFiltersEnum.BOOKINGS_UNAVAILABILITIES_ICALS:
            return $accessor.calendar.UI_TOGGLE_FILTER_GROUP([CalendarFiltersEnum.BOOKING, CalendarFiltersEnum.UNAVAILABILITY, CalendarFiltersEnum.ICALS]);

          case CalendarGroupFiltersEnum.PRICING:
            return $accessor.calendar.UI_TOGGLE_FILTER_GROUP([CalendarFiltersEnum.PRICING_DEFAULT, CalendarFiltersEnum.PRICING_CUSTOM]);

          case CalendarGroupFiltersEnum.DURATION:
            return $accessor.calendar.UI_TOGGLE_FILTER_GROUP([CalendarFiltersEnum.DURATION]);

          case CalendarGroupFiltersEnum.DISCOUNT:
            return $accessor.calendar.UI_TOGGLE_FILTER_GROUP([CalendarFiltersEnum.DISCOUNT]);
        }
      }
    });
    var calendarViews = computed({
      get: function get() {
        return view.value;
      },
      set: function set(val) {
        $accessor.calendar.UI_TOGGLE_VIEW(val);
      }
    });
    var selectedServices = computed({
      get: function get() {
        return _toConsumableArray(ui.value.servicesFilters);
      },
      set: function set(value) {
        return $accessor.calendar.UI_TOGGLE_ALL_SERVICES_IDS_FILTER(value);
      }
    });

    function onToggleFilter(type) {
      $accessor.calendar.UI_TOGGLE_FILTER(type);
    }

    return {
      ui: ui,
      view: view,
      CalendarFiltersEnum: CalendarFiltersEnum,
      CalendarFiltersClassesEnum: CalendarFiltersClassesEnum,
      CalendarGroupFiltersEnum: CalendarGroupFiltersEnum,
      CalendarViewsEnum: CalendarViewsEnum,
      calendarViews: calendarViews,
      disabledServices: disabledServices,
      isFilterVisible: isFilterVisible,
      getTypeClass: getTypeClass,
      currentDomain: currentDomain,
      selectedServices: selectedServices,
      state: state,
      calendarGroupFilters: calendarGroupFilters,
      onToggleFilter: onToggleFilter
    };
  }
});