import { RouteLike } from '~/types'

export const addRouteMeta: <T extends RouteLike>(route: T, meta: T['meta'], recursive?: boolean) => RouteLike =
  (route, meta, recursive = true) => {
    // Filter meta that are already in route/duplicated, so we can still override per route if we need to.
    const safeMeta = Object.keys(meta!).reduce((acc, metaName) => {
      if ((route.meta && metaName in route.meta) || typeof acc[metaName] !== 'undefined') {
        return acc
      }
      return {
        ...acc,
        [metaName]: meta![metaName],
      }
    }, {} as { [key: string]: any })

    const routeMeta = route.meta || {}

    const routeWithMeta = {
      ...route,
      meta: {
        ...safeMeta,
        ...routeMeta,
      },
    }

    if (route.children && route.children.length && recursive) {
      routeWithMeta.children = route.children.map(childRoute => addRouteMeta(childRoute, meta))
    }
    return routeWithMeta
  }
