import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import { computed, defineComponent, markRaw, useContext, watch } from '@nuxtjs/composition-api';
import { DateFormatEnum, defaultEmptyTranslation } from '~/helpers';
import useProvider from '~/composable/useProvider';
import useAddress from '~/composable/useAddress';
import useModeration from '~/composable/useModeration';
import PhoneField from '~/components/form/PhoneField.vue';
import ModeratableTextArea from '~/components/moderation/ModeratableTextArea.vue';
import { MINOR_AGE } from '~/helpers/constants';
import { HOURS_EVERY_15_MIN } from '~/helpers/dateConstants';
import HRSwitch from '~/components/HRSwitch.vue';
import DomainCancelPolicy from './DomainCancelPolicy.vue';
import LocationForm from '~/components/form/LocationForm.vue';
export default defineComponent({
  name: 'DomainBookingConditions',
  components: {
    ModeratableTextArea: ModeratableTextArea,
    PhoneField: PhoneField,
    HRSwitch: HRSwitch,
    DomainCancelPolicy: DomainCancelPolicy,
    LocationForm: LocationForm
  },
  setup: function setup() {
    var _useContext = useContext(),
        $dateTime = _useContext.app.$dateTime;

    var _useModeration = useModeration(),
        getDomainFieldToModerate = _useModeration.getDomainFieldToModerate,
        domainFieldPreviousVersion = _useModeration.domainFieldPreviousVersion;

    var _useProvider = useProvider(),
        getProviderField = _useProvider.getProviderField;

    var _useDomain = useDomain(),
        getDomainField = _useDomain.getDomainField,
        getDomainMetadataField = _useDomain.getDomainMetadataField,
        currentDomain = _useDomain.currentDomain;

    var _useAddress = useAddress(),
        getAddressField = _useAddress.getAddressField;

    var currentAddressId = computed(function () {
      return currentDomain.value.metadata.claimAddress;
    });
    var baseData = markRaw({
      ages: MINOR_AGE,
      printTime: HOURS_EVERY_15_MIN
    });

    var toTimeSimple = function toTimeSimple(date) {
      return $dateTime.fromISO(date).toFormat(DateFormatEnum.HOURS_MINUTES_COLUMN_SEPARATED_HOUR);
    };

    var toTimeWithSeconds = function toTimeWithSeconds(date) {
      return "".concat(date, ":00");
    }; // Setting both fields at the same value for some fields since one of them is not used but still required on Backend side ¯\_(ツ)_/¯


    var startAndEndComputed = function startAndEndComputed(field) {
      return computed({
        get: function get() {
          return toTimeSimple(getDomainField("".concat(field, "End")).value.toString());
        },
        set: function set(val) {
          getDomainField("".concat(field, "End")).value = toTimeWithSeconds(val);
          getDomainField("".concat(field, "Start")).value = toTimeWithSeconds(val);
        }
      });
    };

    var checkinTimeStart = computed({
      get: function get() {
        return toTimeSimple(getDomainField('checkinTimeStart').value);
      },
      set: function set(val) {
        getDomainField('checkinTimeStart').value = toTimeWithSeconds(val);
      }
    });
    var checkinTimeEnd = computed({
      get: function get() {
        return toTimeSimple(getDomainField('checkinTimeEnd').value);
      },
      set: function set(val) {
        getDomainField('checkinTimeEnd').value = toTimeWithSeconds(val);
      }
    });
    watch(function () {
      return getDomainField('isFlexibleCheckinout').value;
    }, function (newVal) {
      if (!newVal) {
        getDomainField('checkinTimeDetails').value = defaultEmptyTranslation();
      }
    });
    return _objectSpread(_objectSpread({}, baseData), {}, {
      checkinTimeEnd: checkinTimeEnd,
      checkinTimeStart: checkinTimeStart,
      currentAddressId: currentAddressId,
      domainFieldPreviousVersion: domainFieldPreviousVersion,
      getAddressField: getAddressField,
      getDomainField: getDomainField,
      getDomainFieldToModerate: getDomainFieldToModerate,
      getDomainMetadataField: getDomainMetadataField,
      getProviderField: getProviderField,
      startAndEndComputed: startAndEndComputed
    });
  }
});