import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import { defineComponent } from '@nuxtjs/composition-api';
import { BookingStatusClassesEnum, BookingStatusEnum } from '~/types/models';
import TableSearch from '~/components/table/TableSearch.vue';
import BookingsTable from '~/components/booking/BookingsTable.vue';
export default defineComponent({
  name: 'MarketPlaceBookingPage',
  components: {
    TableSearch: TableSearch,
    BookingsTable: BookingsTable
  },
  setup: function setup() {
    var _useContext = useContext(),
        _useContext$app = _useContext.app,
        router = _useContext$app.router,
        localePath = _useContext$app.localePath;

    var _useBookings = useBookings(),
        onToggleBookingStandby = _useBookings.onToggleBookingStandby;

    function onClickUpdateBooking(booking) {
      router === null || router === void 0 ? void 0 : router.push(localePath({
        name: 'booking-update',
        params: {
          provider: booking.provider.toString(),
          domain: booking.domain.toString(),
          id: booking.id.toString()
        }
      }));
    }

    function onClickViewBooking(booking) {
      router === null || router === void 0 ? void 0 : router.push(localePath({
        name: 'marketplace-booking-id',
        params: {
          id: booking.id.toString()
        }
      }));
    }

    return {
      onToggleBookingStandby: onToggleBookingStandby,
      onClickUpdateBooking: onClickUpdateBooking,
      onClickViewBooking: onClickViewBooking,
      BookingStatusEnum: BookingStatusEnum,
      BookingStatusClassesEnum: BookingStatusClassesEnum
    };
  }
});