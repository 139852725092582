export enum BaseColors {
  TRANSPARENT = 'transparent',
  WHITE = 'white',
  BLACK = 'black',
  CURRENT = 'current',
  BACKGROUND = 'background',
}

export enum ThemeColors {
  BLUE = 'blue',
  BORDEAUX = 'bordeaux',
  PRIMARY = 'primary',
  TURQUOISE = 'turquoise',
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
  PURPLE = 'purple',
  GRAY = 'gray',
}

