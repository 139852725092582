












import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'AppContentHeading',
  props: {
    icon: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
  },
})
