






















import { PropType, computed, defineComponent } from '@nuxtjs/composition-api'
import { Service } from '~/types/models'
import { ISOToShortEnum } from '@/types/Locale'

export default defineComponent({
  name: 'LanguagePublicationField',
  props: {
    value: {
      type: Object as PropType<Service['publishedOnMarketplace']>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const publicationMarketplaces: Array<keyof Service['publishedOnMarketplace']> = ['uscom', 'abcd']
    const pulicationLanguages = ['fr-FR', 'en-US', 'es-ES', 'nl-NL'] as const

    const model = computed({
      get: () => props.value,
      set: (value) => {
        emit('input', value)
      },
    })

    function handleCheck($event: boolean, marketplace: 'abcd' | 'uscom' | 'master', lang: 'fr-FR' | 'en-US' | 'es-ES' | 'nl-NL') {
      model.value = {
        ...model.value,
        [marketplace]: {
          ...model.value[marketplace],
          [lang]: $event,
        },
      }
    }

    return {
      handleCheck,
      model,
      pulicationLanguages,
      ISOToShortEnum,
      publicationMarketplaces,
    }
  },
})
