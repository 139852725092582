import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import MultipleDateRangePicker from '~/components/datetime/MultipleDateRangePicker.vue';
import { DateFormatEnum } from '~/helpers';
import HRSwitch from '~/components/HRSwitch.vue';
export default defineComponent({
  name: 'DomainOpeningPeriods',
  components: {
    MultipleDateRangePicker: MultipleDateRangePicker,
    HRSwitch: HRSwitch
  },
  setup: function setup() {
    var _useDomain = useDomain(),
        getDomainField = _useDomain.getDomainField;

    var _useContext = useContext(),
        params = _useContext.params,
        _useContext$app = _useContext.app,
        $dateTime = _useContext$app.$dateTime,
        $accessor = _useContext$app.$accessor;

    var defaultPeriod = {
      start: new Date(),
      end: $dateTime.fromJSDate(new Date()).plus({
        day: 1
      }).toJSDate()
    };
    var formattedPeriods = computed({
      get: function get() {
        var periods = getDomainField('openingPeriods').value;
        return periods.map(function (period) {
          return {
            start: new Date(period.start.toString()),
            end: new Date(period.end.toString())
          };
        });
      },
      set: function set(val) {
        getDomainField('openingPeriods').value = val.map(function (period) {
          return {
            start: $dateTime.fromJSDate(new Date(period.start.toString())).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
            end: $dateTime.fromJSDate(new Date(period.end.toString())).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT)
          };
        });
      }
    });
    var isOpenedAllYear = computed({
      get: function get() {
        return $accessor.domains.getOne(parseInt(params.value.domain)).openingPeriods.length === 0;
      },
      set: function set(val) {
        if (val) {
          $accessor.domains.UPDATE_FIELD({
            id: parseInt(params.value.domain),
            path: 'openingPeriods',
            value: []
          });
        } else {
          $accessor.domains.UPDATE_FIELD({
            id: parseInt(params.value.domain),
            path: 'openingPeriods',
            value: [defaultPeriod]
          });
        }
      }
    });
    return {
      getDomainField: getDomainField,
      defaultPeriod: defaultPeriod,
      isOpenedAllYear: isOpenedAllYear,
      formattedPeriods: formattedPeriods
    };
  }
});