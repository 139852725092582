import domainSustainabilityActionsMutations from './mutations'
import domainSustainabilityActionsGetters from './getters'
import domainSustainabilityActionsActions from './actions'
import domainSustainabilityActionsState from './state'

export const domainSustainabilityActions = {
  namespaced: true,
  state: domainSustainabilityActionsState,
  actions: domainSustainabilityActionsActions,
  mutations: domainSustainabilityActionsMutations,
  getters: domainSustainabilityActionsGetters,
}
