import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-multiline"},[_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"t('giftcardcode","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('giftcards.code')}},[_c('BInput',{staticClass:"ym-disable-keys",attrs:{"data-cy":"giftCardForm-code"},model:{value:(_vm.getField('code').value),callback:function ($$v) {_vm.$set(_vm.getField('code'), "value", $$v)},expression:"getField('code').value"}})],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"column is-full-desktop is-hidden-touch"}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"t('giftcardstatus","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('giftcards.status.field')}},[_c('HRSwitch',{attrs:{"label":_vm.$t(("giftcards.status." + (_vm.getField('isActive').value ? 'active' : 'inactive')))},model:{value:(_vm.getField('isActive').value),callback:function ($$v) {_vm.$set(_vm.getField('isActive'), "value", $$v)},expression:"getField('isActive').value"}})],1),_vm._v(" "),_c('BaseField',{attrs:{"label":_vm.$t('giftcards.set_active_since')}},[_c('BCheckbox',{attrs:{"disabled":!_vm.getField('isActive').value},model:{value:(_vm.getField('setActiveSince').value),callback:function ($$v) {_vm.$set(_vm.getField('setActiveSince'), "value", $$v)},expression:"getField('setActiveSince').value"}},[_vm._v("\n        "+_vm._s(_vm.$t('giftcards.set_active_since_details'))+"\n      ")])],1),_vm._v(" "),(!_vm.getField('isActive').value)?_c('BaseMessage',{staticClass:"column is-full is-full-touch",attrs:{"type":"is-info"}},[_c('span',{staticClass:"has-text-weight-bold"},[_vm._v("\n        "+_vm._s(_vm.$t('giftcards.status.help_message'))+"\n      ")])]):_vm._e()]}}])}),_vm._v(" "),_c('div',{staticClass:"column is-full-desktop is-hidden-touch"}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"t('giftcardorigin","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('giftcards.origin')}},[_c('BInput',{attrs:{"data-cy":"giftCardForm-origin"},model:{value:(_vm.getField('origin').value),callback:function ($$v) {_vm.$set(_vm.getField('origin'), "value", $$v)},expression:"getField('origin').value"}})],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"column is-full-desktop is-hidden-touch"}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"t('giftcardvalid_until","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('giftcards.valid_until')}},[_c('BDatepicker',{attrs:{"mobile-native":false,"month-names":_vm.$t('hotel_datepicker.month-names'),"day-names":_vm.$t('hotel_datepicker.day-names'),"data-cy":"giftCardForm-date"},model:{value:(_vm.getField('validUntil').value),callback:function ($$v) {_vm.$set(_vm.getField('validUntil'), "value", $$v)},expression:"getField('validUntil').value"}})],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"column is-full-desktop is-hidden-touch"}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-touch",attrs:{"name":"t('giftcardtotal_amount","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('giftcards.total_amount')}},[_c('SymbolInput',{attrs:{"data-cy":"giftCardForm-amount"},model:{value:(_vm.getField('totalAmount.amount').value),callback:function ($$v) {_vm.$set(_vm.getField('totalAmount.amount'), "value", $$v)},expression:"getField('totalAmount.amount').value"}},[_vm._v("\n        €\n      ")])],1)]}}])}),_vm._v(" "),(!_vm.isCreation)?[_c('div',{staticClass:"column is-full-desktop is-hidden-touch"}),_vm._v(" "),_c('p',{staticClass:"column is-half-desktop is-full-touch"},[_c('span',[_vm._v(_vm._s(_vm.$t('giftcards.remaining_balance'))+": ")]),_vm._v("\n      "+_vm._s(_vm.$parseAmount(_vm.getField('remainingBalance').value).toFormat('$0,0.00'))+"\n    ")])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"column is-full-desktop is-hidden-touch"}),_vm._v(" "),_c('BaseField',{staticClass:"column is-half-desktop is-full-touch",attrs:{"label":_vm.$t('giftcards.buyer')}},[_c('BInput',{attrs:{"data-cy":"giftCardForm-buyer"},model:{value:(_vm.getField('buyerFullName').value),callback:function ($$v) {_vm.$set(_vm.getField('buyerFullName'), "value", $$v)},expression:"getField('buyerFullName').value"}})],1),_vm._v(" "),_c('div',{staticClass:"column is-full-desktop is-hidden-touch"}),_vm._v(" "),_c('BaseField',{staticClass:"column is-half-desktop is-full-touch",attrs:{"label":_vm.$t('giftcards.comment')}},[_c('BInput',{attrs:{"type":"textarea","data-cy":"giftCardForm-comment"},model:{value:(_vm.getField('comment').value),callback:function ($$v) {_vm.$set(_vm.getField('comment'), "value", $$v)},expression:"getField('comment').value"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }