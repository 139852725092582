















































import { computed, defineComponent, PropType, reactive, useContext } from '@nuxtjs/composition-api'
import HRSwitch from '~/components/HRSwitch.vue'

interface State {
  checkAll: boolean
  selectedMarketplaces: number[]
}

export default defineComponent({
  name: 'MarketplacesSelectCheckboxes',
  components: { HRSwitch },
  props: {
    value: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
      description: 'Hide marketplaces if all selected',
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array as PropType<string[]>,
      default: [],
    },
    valid: {
      type: Boolean,
      default: true,
    },
    type: {
      type: Object as PropType<Record<string, boolean>>,
      required: false,
      default: () => ({ 'is-primary': true }),
    },
  },
  setup(props, { emit }) {
    const { app: { $accessor } } = useContext()
    const availableMarketplaces = computed(() => $accessor.marketplaces.getAllArray())
    const checkAll = props.value.length > 0 ? props.value.length === availableMarketplaces.value.length : props.selectAll
    const state = reactive<State>({
      checkAll,
      selectedMarketplaces: checkAll ? availableMarketplaces.value.map(marketplace => marketplace.id) : props.value,
    })
    /**
         * Handles toggling the selection of all marketplaces.
         * If true, create an array with all IDs from availableMarketplaces.
         * If false, reset the state to an empty array and emit it
         */
    function onToggleSelectAll(value: boolean) {
      if (value) {
        state.selectedMarketplaces = availableMarketplaces.value.map(marketplace => marketplace.id)
        emit('input', state.selectedMarketplaces)
      } else {
        state.selectedMarketplaces = []
        emit('input', state.selectedMarketplaces)
      }
      state.checkAll = value
    }
    /**
         * A callback executed when a marketplace is selected. We just use this to compute the new value of state.checkAll
         * The computation of the new array of IDs is handled with v-model on the checkbox itself.
         * But we still have to $emit the new value.
         */
    function onMarketplaceSelected() {
      state.checkAll = availableMarketplaces.value.length === state.selectedMarketplaces.length
      emit('input', state.selectedMarketplaces)
    }
    return {
      // Can't use ...toRefs(state) here since state.checkAll and state.selectedMarketplaces are references to props
      // We would be mutating props when toggling the switch on and off.
      state,
      onToggleSelectAll,
      availableMarketplaces,
      onMarketplaceSelected,
    }
  },
})
