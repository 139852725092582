import { ToastObject } from 'vue-toasted'
import { TranslateResult, LocaleMessages } from 'vue-i18n'
import { NuxtAppOptions } from '@nuxt/types'

const ERROR_MESSAGE = 'You must provide a valid translated string to the toast !'

export function loadingToast(message: string | TranslateResult | LocaleMessages, app: NuxtAppOptions): ToastObject | undefined {
  if (typeof message !== 'string') {
    console.error(ERROR_MESSAGE) // eslint-disable-line
  } else {
    return app.$toast.show(message, {
      icon: 'timelapse',
      duration: undefined,
    })
  }
}

export function successToast(message: string | TranslateResult | LocaleMessages, app: NuxtAppOptions): ToastObject | undefined {
  if (typeof message !== 'string') {
    console.error(ERROR_MESSAGE) // eslint-disable-line
  } else {
    return app.$toast.success(message, {
      icon: 'check',
      duration: 5000,
    })
  }
}

export function errorToast(message: string | TranslateResult | LocaleMessages, app: NuxtAppOptions): ToastObject | undefined {
  if (typeof message !== 'string') {
    console.error(ERROR_MESSAGE) // eslint-disable-line
  } else {
    return app.$toast.error(message, {
      icon: 'times',
      duration: 5000,
    })
  }
}

export function warningToast(message: string | TranslateResult | LocaleMessages, app: NuxtAppOptions): ToastObject | undefined {
  if (typeof message !== 'string') {
    console.error(ERROR_MESSAGE) // eslint-disable-line
  } else {
    return app.$toast.show(message, {
      className: 'is-warning',
      icon: 'times',
      duration: 5000,
    })
  }
}
