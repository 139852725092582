
















































import { TranslateResult } from 'vue-i18n'
import { ShortToISOEnum, MarketplaceSpecificContent } from '~/types'
import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import useI18n from '~/composable/useI18n'
import PreviousVersionDisplay from '~/components/content/PreviousVersionDisplay.vue'
import TranslatableMarketplaceTextArea from '../i18n/TranslatableMarketplaceTextArea.vue'

type ContentScopeProp = null | 'domain' | 'service' | 'provider' | 'option'

interface InputTypeProp {
  'is-danger'?: boolean
  'is-success'?: boolean
  'is-warning'?: boolean
  'is-primary'?: boolean
}

export default defineComponent({
  name: 'ModeratableMarketplaceTextArea',
  components: {
    PreviousVersionDisplay,
    TranslatableMarketplaceTextArea,
  },
  props: {
    value: {
      type: Object as PropType<MarketplaceSpecificContent>,
      required: true,
    },
    label: {
      type: String as PropType<TranslateResult | string>,
      required: true,
    },
    subtitle: {
      type: String as PropType<TranslateResult | string>,
      default: '',
    },
    type: {
      type: Object as PropType<InputTypeProp>,
      required: false,
      default: { 'is-primary': true },
    },
    errors: {
      type: Array as PropType<string[] | null>,
      default: null,
    },
    previousVersion: {
      type: Object as PropType<MarketplaceSpecificContent | null>,
      default: null,
    },
    contentScope: {
      type: String as PropType<ContentScopeProp>,
      default: null,
      validator: (prop: ContentScopeProp) => [null, 'domain', 'provider', 'service', 'option'].includes(prop),
    },
  },
  setup(props, { emit }) {
    const { currentLocale } = useI18n()

    const innerValue = computed({
      get: () => props.value,
      set: (newValue: MarketplaceSpecificContent) => emit('input', newValue),
    })

    const displayLocale = ref(ShortToISOEnum[currentLocale.toUpperCase() as keyof typeof ShortToISOEnum])

    const hasPreviousVersion = computed(() => props.previousVersion !== null)

    function onLocaleUpdate(newLocale: ShortToISOEnum) {
      displayLocale.value = newLocale
    }

    return {
      innerValue,
      displayLocale,
      onLocaleUpdate,
      hasPreviousVersion,
    }
  },
})
