

















































import { computed, defineComponent, reactive, toRefs, useContext } from '@nuxtjs/composition-api'
import {
  Notification, NotificationBackgroundClassesEnum, NotificationTextClassesEnum, NotificationTypeClassesEnum,
  NotificationTypeEnum,
} from '~/types/models'
import { sortByDateDesc } from '~/helpers/dateTime'
import useNotifications from '~/composable/useNotifications'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import NotificationItem from '~/components/notifications/NotificationItem.vue'

export default defineComponent({
  name: 'NotificationsPage',
  components: {
    AppContentHeading,
    NotificationItem,
  },
  setup() {
    const { getNotificationType } = useNotifications()
    const { app: { $accessor } } = useContext()

    const sortedNotifications = computed(() => $accessor.notifications.getAllArray().sort((notif1, notif2) => sortByDateDesc(notif1.createdAt, notif2.createdAt)))

    const filters = reactive({
      alert: true,
      warning: true,
      info: true,
    })

    const displayNotification = (notification: Notification) => $accessor.notifications.filters[getNotificationType(notification)]

    return {
      ...toRefs(filters),
      displayNotification,
      NotificationBackgroundClassesEnum,
      NotificationTextClassesEnum,
      NotificationTypeClassesEnum,
      NotificationTypeEnum,
      sortedNotifications,
    }
  },
})
