import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { Option } from '~/types/models'
import { resetState } from '~/helpers/store'
import { OptionMutationTypes } from './types'
import baseState, { baseCreateForm, option } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<Option>(option),

  [OptionMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },

  [OptionMutationTypes.UPDATE_FIELD](state, { id, ...payload }) {
    updateField(state.entities.byId[id], payload)
  },

  [OptionMutationTypes.UPDATE_CREATE_FORM_FIELD](state, payload) {
    updateField(state.createForm, payload)
  },

  [OptionMutationTypes.RESET_CREATE_FORM](state) {
    state.createForm = baseCreateForm()
  },
})

export default mutations
