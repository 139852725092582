import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"phoneValidator",attrs:{"name":"phone","slim":"","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.label,"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"rules":_vm.rules,"disabled":_vm.disabled}},[_c('vue-tel-input',{attrs:{"value":_vm.formattedValue,"auto-default-country":false,"default-country":_vm.countryCode,"preferred-countries":_vm.phonePreferredCountries,"disabled":_vm.disabled,"input-options":{
        placeholder: '',
        styleClasses: ("input " + (errors[0] ? 'is-danger' : '') + " " + (valid ? 'is-success': ''))
      },"dropdown-options":{ showFlags: true, showDialCodeInSelection: true }},on:{"input":_vm.onPhoneUpdate}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }