import { createEntity, createState } from '@kissmylabs/vuex-entitystore'
import { Service } from '~/types/models'
import { ServiceState } from './types'

export const service = createEntity<Service>('service')

export const state = (): ServiceState => ({
  ...createState<Service>(service),
  currentServices: {},
})

export default state
