import Vue from 'vue'
import classNames from 'classnames'
import sanitizeHtml from 'sanitize-html'
import { sync } from 'vuex-router-sync'
import Decimal from 'decimal.js-light'

Vue.config.productionTip = false

Vue.use({
  install(VueN) {
    VueN.prototype.$classNames = classNames
    VueN.prototype.$sanitizeHtml = sanitizeHtml
    VueN.prototype.$decimal = Decimal
  },
})

export default function(ctx) {
  sync(ctx.store, ctx.app.router)
}
