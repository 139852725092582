import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import { generateId } from '~/helpers'
import allSettled from 'promise.allsettled'
import VTooltip from 'v-tooltip'

const loggerOptions = {
  isEnabled: true,
  logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
}

Vue.use(VueLogger, loggerOptions)

Vue.use({
  install(VueN) {
    VueN.prototype.$generateId = generateId
  },
})

allSettled.shim()

Vue.use(VTooltip, {
  defaultClass: 'vue-tooltip-theme is-info',
  defaultPlacement: 'top-end',
})
