import customersActions from './actions'
import customersMutations from './mutations'
import customersGetters from './getters'
import customersState from './state'

export const customers = {
  namespaced: true,
  state: customersState,
  actions: customersActions,
  mutations: customersMutations,
  getters: customersGetters,
}
