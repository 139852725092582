import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import { useContext, computed } from '@nuxtjs/composition-api';
import { mainMenuRoutes } from '~/routes/mainMenuRoutes';
import pick from 'lodash/pick';

function useMenuRoutes() {
  var _useContext = useContext(),
      $featureToggle = _useContext.app.$featureToggle;

  var _useDomain = useDomain(),
      currentDomain = _useDomain.currentDomain,
      currentDomainIsSh = _useDomain.currentDomainIsSh;

  var filterRouteFeatureGates = function filterRouteFeatureGates(routes) {
    return routes.filter(function (route) {
      if (route.meta && route.meta.featureGate) {
        return $featureToggle.toggles[route.meta.featureGate] === true;
      }

      return true;
    });
  };

  var filterRouteShLocked = function filterRouteShLocked(routes) {
    return routes.filter(function (route) {
      if (route.meta && route.meta.isShDisable) {
        return false;
      }

      return true;
    });
  };

  var removeChildrenKeyIfEmpty = function removeChildrenKeyIfEmpty(routes) {
    var routesFiltered = routes.map(function (route) {
      var _route$children;

      if (((_route$children = route.children) === null || _route$children === void 0 ? void 0 : _route$children.length) === 0) {
        return pick(route, Object.keys(route).filter(function (k) {
          return k !== 'children';
        }));
      }

      return route;
    });
    return routesFiltered;
  };

  var availableRoutes = computed(function () {
    var routesFilteredfeaturesGates = filterRouteFeatureGates(mainMenuRoutes.map(function (route) {
      if (route.children) {
        var newChildren = filterRouteFeatureGates(route.children);
        return _objectSpread(_objectSpread({}, route), {}, {
          children: newChildren
        });
      }

      return route;
    }));

    if (currentDomain.value && currentDomainIsSh.value) {
      var routesFilteredShLocked = filterRouteShLocked(routesFilteredfeaturesGates.map(function (route) {
        if (route.children) {
          var newChildren = filterRouteShLocked(route.children);
          return _objectSpread(_objectSpread({}, route), {}, {
            children: newChildren
          });
        }

        return route;
      }));
      return removeChildrenKeyIfEmpty(routesFilteredShLocked);
    } else {
      return routesFilteredfeaturesGates;
    }
  });
  return {
    filterRouteFeatureGates: filterRouteFeatureGates,
    filterRouteShLocked: filterRouteShLocked,
    availableRoutes: availableRoutes
  };
}

export default useMenuRoutes;