import { MarketplaceSpecificContent, ShortToISOEnum, SupportedLocale, Translation } from '~/types'

export function shortLocaleToRegionLocale(locale: SupportedLocale, fallbackToEnglish = true): string | null {
  if (locale in ShortToISOEnum) {
    // Dirty cast here to be able to index enum by string
    return ShortToISOEnum[locale.toUpperCase() as keyof typeof ShortToISOEnum]
  }
  return fallbackToEnglish ? ShortToISOEnum.EN : null
}

export function mapLocaleToCountry(locale: SupportedLocale): string {
  return locale.toUpperCase()
}

export function defaultEmptyTranslation(): Translation {
  return {
    [ShortToISOEnum.FR]: '',
    [ShortToISOEnum.EN]: '',
    [ShortToISOEnum.ES]: '',
    [ShortToISOEnum.NL]: '',
  }
}

export function defaultEmptyMultiMarketplaceTranslation(): MarketplaceSpecificContent {
  return {
    abcd: defaultEmptyTranslation(),
    uscom: defaultEmptyTranslation(),
    master: defaultEmptyTranslation(),
  }
}
