





























import { defineComponent, useContext } from '@nuxtjs/composition-api'
import TableSearch from '~/components/table/TableSearch.vue'
import ServicesTable from '~/components/service/ServiceTable.vue'

export default defineComponent({
  name: 'MkpDomainServices',
  components: {
    ServicesTable,
    TableSearch,
  },
  setup() {
    const { params } = useContext()
    const baseUrl = `services?filters[domain.id]=${params.value.domain}`

    return { baseUrl }
  },
})
