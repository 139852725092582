import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { LocaleOptions } from 'luxon'
import pick from 'lodash/pick'
import { Service, ServiceCreatePayload } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({
  state,
  getters,
  mutations,
}, {
  ...createActions<Service>(),

  async fetchOne({ commit }, serviceId: number): Promise<void> {
    const service: Service = await this.app.$api.get(`services/${serviceId}`)
    if (service) {
      commit(BaseMutationTypes.CREATE, service)
    }
  },

  async fetchServiceDetailRelations(_, service: Service): Promise<void> {
    const { $accessor } = this.app

    if ($accessor.serviceTypes.getAllIds().length === 0) {
      await $accessor.serviceTypes.fetchAll()
    }

    if ($accessor.serviceUnits.getAllIds().length === 0) {
      await $accessor.serviceUnits.fetchAllForService(service.id)
    }
    if ($accessor.serviceEquipments.getAllIds().length === 0) {
      await $accessor.serviceEquipments.fetchAll()
    }
    const missingServiceDiscountsIds = service.serviceDiscounts.filter(id => !$accessor.serviceDiscounts.getAllIds().includes(id))
    if (missingServiceDiscountsIds.length > 0) {
      await $accessor.serviceDiscounts.fetchMany(missingServiceDiscountsIds)
    }
    if (!$accessor.options.getAllArray().some(option => option.domain === service.domain)) {
      await $accessor.options.fetchForDomain(service.domain)
    }
    if ($accessor.serviceThemes.getAllIds().length === 0) {
      await $accessor.serviceThemes.fetchAll()
    }
    const missingFilesIds = service.files.map(id => id).filter(id => $accessor.entityFiles.getAllIds().includes(id))
    if (missingFilesIds.length > 0) {
      await $accessor.entityFiles.fetchMany(missingFilesIds)
    }
  },

  async fetchMany({ commit }, serviceIds: number[]): Promise<void> {
    if (serviceIds.length > 0) {
      const ids = serviceIds.join()
      const res = await this.app.$api.get(`services?filters[id_in]=${ids}&limit=${serviceIds.length}`)
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },

  async fetchAll({ commit }): Promise<void> {
    const res = await this.app.$api.get('services?limit=-1')
    if (res.data.length) {
      return commit(BaseMutationTypes.CREATE_MANY, res.data)
    }
  },

  async postOne({ commit }, payload: ServiceCreatePayload): Promise<number> {
    const res: Service = await this.app.$api.post('/services/', payload)
    commit(BaseMutationTypes.CREATE, res)
    this.app.$accessor.domains.UPDATE({
      id: res.domain,
      payload: {
        ...this.app.$accessor.domains.getOne(res.domain),
        services: [
          ...this.app.$accessor.domains.getOne(res.domain).services,
          res.id,
        ],
      },
    })
    return res.id
  },

  async patchOne({ commit }, id: number): Promise<void> {
    const payload = this.app.$accessor.services.getOne(id)
    const service = {
      ...pick(payload, Object.keys(payload).filter(k => k !== 'externalId')),
      checkinEndTime: this.app.$dateTime.fromJSDate(new Date(payload.checkinEndTime.toString()))
        .toLocaleString(this.app.$dateTime.TIME_WITH_SECONDS as LocaleOptions & Intl.DateTimeFormatOptions),
      checkinStartTime: this.app.$dateTime.fromJSDate(new Date(payload.checkinStartTime.toString()))
        .toLocaleString(this.app.$dateTime.TIME_WITH_SECONDS as LocaleOptions & Intl.DateTimeFormatOptions),
      checkoutTime: this.app.$dateTime.fromJSDate(new Date(payload.checkoutTime.toString()))
        .toLocaleString(this.app.$dateTime.TIME_WITH_SECONDS as LocaleOptions & Intl.DateTimeFormatOptions),
    }
    const res = await this.app.$api.patch(`/services/${id}`, service)

    commit(BaseMutationTypes.UPDATE, {
      id,
      payload: { ...res },
    })
  },

  async reorderPictures(_, {
    providerId,
    id,
  }: { providerId: number, id: number }): Promise<void> {
    await this.app.$api.post(`providers/${providerId}/services/${id}/resetPicturesOrdering`, { 'clear_cache': false })
  },

  async moderateOne({ commit }, { id, isApproved }: { id: number, isApproved: boolean }) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.moderation_submitted'))

    try {
      const service: Service = await this.app.$api.post(`moderation/service/${id}`, { isApproved })
      commit(BaseMutationTypes.UPDATE, {
        id,
        payload: service,
      })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.moderation_success'))
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.moderation_error'))
    }
  },

  async duplicateOne({ commit }, id: number): Promise<void> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.duplicating_service'))
    try {
      const service: Service = await this.app.$api.post(`services/${id}/duplicate`)
      commit(BaseMutationTypes.CREATE, service)

      const domain = this.app.$accessor.domains.getOne(service.domain)

      this.app.$accessor.domains.UPDATE_FIELD({
        id: domain.id,
        path: 'services',
        value: [...domain.services, service.id],
      })

      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.duplicated_service'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.duplicating_service_error'))
    }
  },

  async deleteOne({ commit }, id: number): Promise<void> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.service_deleting'))
    try {
      await this.app.$api.delete(`services/${id}`)
      commit(BaseMutationTypes.DELETE, id)

      const domain = this.app.$accessor.domains.getFirstWhere(domain => domain.services.includes(id))

      if (domain) {
        this.app.$accessor.domains.UPDATE_FIELD({
          id: domain.id,
          path: 'services',
          value: [...domain.services.filter(serviceId => serviceId !== id)],
        })
      }

      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.service_deleted'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.service_delete_error'))
    }
  },
})

export default actions
