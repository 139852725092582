import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { EntityFile, IdxMap } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<EntityFile>(),

  async fetchOne({ commit }, fileId: number): Promise<void> {
    const file = await this.app.$api.get(`files/${fileId}`)
    if (file) {
      return commit(BaseMutationTypes.CREATE, file)
    }
  },

  async fetchMany({ commit }, filesIds: number[]): Promise<void> {
    if (filesIds.length > 0) {
      const ids = filesIds.join()
      const res = await this.app.$api.get(`files?filters[id_in]=${ids}&limit=${filesIds.length}`)
      if (res.data.length) {
        return commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },

  async patchOne({ commit }, { id, payload }: { id: number, payload: EntityFile }): Promise<void> {
    const toast = this.app.$loadingToast(this.$i18n.t('toast.file_updating'))
    try {
      const res: EntityFile = await this.app.$api.patch(`files/${id}`, payload)
      commit(BaseMutationTypes.CREATE, res)
      toast.goAway(0)
      this.app.$successToast(this.$i18n.t('toast.file_updated'))
    } catch (error) {
      this.app.$errorToast(this.$i18n.t('toast.file_updating_error'))
    }
  },

  async patchMany({ commit }, files: EntityFile[]): Promise<void> {
    const toast = this.app.$loadingToast(this.$i18n.t('toast.files_updating'))
    try {
      const res: EntityFile[] = await Promise.all(files.map(file => {
        return this.app.$api.patch(`files/${file.id}`, file)
      }))

      res.forEach(file => commit(BaseMutationTypes.UPDATE, { id: file.id, payload: file }))
      toast.goAway(0)
      this.app.$successToast(this.$i18n.t('toast.files_updated'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.$i18n.t('toast.files_updating_error'))
    }
  },

  async postOne({ commit }, formData: FormData): Promise<EntityFile> {
    const res: EntityFile = await this.app.$api.post(
      'files',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
    commit(BaseMutationTypes.CREATE, res)
    return res
  },

  async resetIdx(_, fileArray: EntityFile[]): Promise<any> {
    const idxMap: IdxMap[] = fileArray.map(file => {
      return { fileId: file.id, idx: file.idx }
    })

    try {
      await this.app.$api.patch('files_idx', { idxMap })
      this.app.$successToast(this.$i18n.t('toast.file_updated'))
    } catch (error) {
      this.app.$errorToast(this.$i18n.t('toast.file_updating_error'))
    }
  },

  async deleteFile(_, id: number): Promise<void> {
    const toast = this.app.$loadingToast(this.$i18n.t('toast.file_deleting'))
    try {
      await this.app.$api.delete(`files/${id}`)
      this.app.$accessor.entityFiles.DELETE(id)
      toast.goAway(0)
      this.app.$successToast(this.$i18n.t('toast.file_deleted'))
    } catch (error) {
      this.app.$errorToast(this.$i18n.t('toast.file_delete_error'))
    }
  },
})

export default actions

