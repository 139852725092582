import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api'
import { ShortToISOEnum, Translation } from '~/types'
import { translateEntityField as translateEntityFieldHelper } from '~/helpers/i18n'

export interface i18nHook {
  currentLocale: string
  currentRegionLocale: ComputedRef<string>
  isCurrentLocalefrFR: ComputedRef<boolean>
  getOrderOfDayInWeek: ComputedRef<number>
  translateEntityField: (field: Translation) => string
}

function useI18n(): i18nHook {
  const { app } = useContext()

  const currentLocale = app.i18n.locale

  const currentRegionLocale = computed(() => ShortToISOEnum[currentLocale.toUpperCase() as keyof typeof ShortToISOEnum])

  const translateEntityField = (field: Translation): string => translateEntityFieldHelper(field, currentLocale.toUpperCase())

  const isCurrentLocalefrFR = computed(() => currentRegionLocale.value === ShortToISOEnum.FR)

  const getOrderOfDayInWeek = computed(() => isCurrentLocalefrFR.value ? 1 : 0)

  return {
    currentLocale,
    currentRegionLocale,
    isCurrentLocalefrFR,
    getOrderOfDayInWeek,
    translateEntityField,
  }
}

export default useI18n
