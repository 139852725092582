import { Middleware } from '@nuxt/types'

const checkImpersonationMiddleware: Middleware = async({ route, app: { $accessor } }) => {
  const isAdminRoute = route.matched.some((r: { meta: Record<string, any> }) => !!r.meta.isAdminRoute)

  if (!isAdminRoute && $accessor.users.isImpersonating) {
    $accessor.users.stopImpersonatingProvider()
  }
}

export default checkImpersonationMiddleware
