import { Middleware } from '@nuxt/types'

const servicesListing: Middleware = async({ params, app: { $accessor } }) => {
  const domainId = parseInt(params.domain)

  if ($accessor.serviceTypes.getAllIds().length === 0) {
    await $accessor.serviceTypes.fetchAll()
  }
  if ($accessor.domains.getOne(domainId).services.length > 0) {
    const services = $accessor.services.getWhereArray((service) => service.domain === domainId)
    const serviceIds = services.map(service => service.id)
    const missingServicesIds = serviceIds.filter(id => !$accessor.services.getAllIds().includes(id))

    if (missingServicesIds.length > 0) {
      await $accessor.services.fetchMany(missingServicesIds)
    }

    const serviceFilesIds = services.reduce((acc, service) => [...acc, ...service.files], [] as number[])
    const missingFilesIds = serviceFilesIds.filter(id => !$accessor.entityFiles.getAllIds().includes(id))
    if (missingFilesIds.length > 0) {
      await $accessor.entityFiles.fetchMany(missingFilesIds)
    }
  }
}

export default servicesListing
