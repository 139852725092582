






















import { defineComponent } from '@nuxtjs/composition-api'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import CguModal from '~/components/modal/CguModal.vue'
import CgamModal from '~/components/modal/CgamModal.vue'
import Dashboard from '~/components/dashboard/Dashboard.vue'
// import AssetsPage from '~/components/pages/home/assets'

export default defineComponent({
  name: 'HomePage',
  components: {
    Dashboard,
    AppContentHeading,
    CguModal,
    CgamModal,
    // AssetsPage,
  },
  middleware: ['lastBookingsMiddleware', 'todaysBookingsMiddleware'],
  data() {
    return {
      isActiveCguModal: false,
      isActiveCgamModal: false,
    }
  },
})
