import { DateTime } from 'luxon'
import { NuxtAppOptions } from '@nuxt/types'
import { AuthCookie } from './authCookie'

export type IAutoRefreshToken = {
  start(token: AuthCookie): void
  update(token: AuthCookie): void
  stop(): void
  checkToken(): Promise<void>
  shouldRefresh(expiresAt: string): boolean
  isDebug(): boolean
  isRunning: boolean
}

const logger: (str: string) => void | null = (str: string) => process.env.NODE_ENV === 'development'
  ? console.log(`%c [AutoRefreshToken] ${str} `, 'background: #FF7600; color: #F5F5F5; padding: 2px') // eslint-disable-line
  : null

export class AutoRefreshToken implements IAutoRefreshToken {
  autoRefreshTokenInterval: number
  interval: number
  context: NuxtAppOptions
  token: AuthCookie
  isRunning: boolean

  constructor(context: NuxtAppOptions) {
    this.autoRefreshTokenInterval = 0
    this.interval = 30000
    this.context = context
    this.isRunning = false
    this.token = {
      expiresAt: '',
      refreshToken: '',
      token: '',
      user: 0,
    }
  }

  start(token: AuthCookie): void {
    if (this.isDebug()) {
      logger('starting auto refresh...')
    }
    this.token = token
    this.autoRefreshTokenInterval = window.setInterval(() => {
      this.checkToken()
    }, this.interval)
    this.isRunning = true
  }

  update(token: AuthCookie): void {
    if (this.isDebug()) {
      logger('updating token...')
    }
    this.stop()
    this.start(token)
  }

  stop(): void {
    if (this.isDebug()) {
      logger('stopping timeout')
    }
    window.clearInterval(this.autoRefreshTokenInterval)
    this.isRunning = false
  }

  async checkToken(): Promise<void> {
    // @todo Michel placer une Sentry ici pour avoir une idée du nombre de fois où ça se produit. dans le else
    if (this.token) {
      if (this.shouldRefresh(this.token.expiresAt)) {
        if (this.isDebug()) {
          logger(`Refreshing token as it will expire at ${this.token.expiresAt}`)
        }
        await this.context.$accessor.auth.refreshAuthToken(this.token.refreshToken)
      } else if (this.isDebug()) {
        logger(`No need to refresh token, expiring at ${this.token.expiresAt}`)
      }
    }
  }

  shouldRefresh(expiresAt: string): boolean {
    return DateTime.fromISO(expiresAt).toUTC().minus({ seconds: ((this.interval * 2) / 1000) }) < DateTime.utc()
  }

  isDebug(): boolean {
    return this.context.$config.debug.autoRefreshToken
  }
}
