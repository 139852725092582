import mkpJson from './marketplaces.json'

export const services = [
  'La belle nuit étoilée',
  'La petite maison dans la prairie',
  'La maison de hobbit',
  'Le love bus',
  'Cabane escapade',
  'Cabane Spa emotion',
  'La cabane au fond du jardin',
  'La nuit sous les étoiles',
  'La chambre de Merlin',
]

export const marketplaces2 = [
  {
    id: 6,
    label: 'Abracadaroom',
    shortName: 'ABCD',
    logo: '/images/marketplace/logo-abcd@2x.png',
  },
  {
    id: 27,
    label: 'Abracadaroom NL',
    shortName: 'ABCD NL',
    logo: '/images/marketplace/logo-abcd@2x.png',
  },
  {
    id: 7,
    label: 'La Cabane en l\'air',
    shortName: 'LCBL',
    logo: '/images/marketplace/logo-lcbl@2x.png',
  },
  {
    id: 8,
    label: 'Treego',
    shortName: 'Treego',
    logo: '/images/marketplace/logo-treego@2x.png',
  },
]

export const marketplaces = mkpJson

export const getMarketplaceById = id => marketplaces.find(mkp => mkp.id === Number(id))

