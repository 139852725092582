




























































import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import { ServicePricing } from '~/types/models'
import useServicePricings from '~/composable/useServicePricings'

export default defineComponent({
  name: 'ServicePricingDetails',
  props: {
    pricing: {
      type: Object as PropType<ServicePricing>,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { app: { i18n, $dateTime } } = useContext()
    const { getPricingGuestText } = useServicePricings()

    const periodText = computed(() => {
      return i18n.t('common.from_to_date', {
        start: $dateTime.fromISO(props.pricing.start!.toString())
          .setLocale(i18n.locale)
          .toLocaleString($dateTime.DATE_SHORT),
        end: $dateTime.fromISO(props.pricing.end!.toString())
          .setLocale(i18n.locale)
          .toLocaleString($dateTime.DATE_SHORT),
      })
    })

    const guestsText = computed(() => getPricingGuestText(props.pricing))
    return {
      periodText,
      guestsText,
    }
  },
})
