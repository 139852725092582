import { Middleware } from '@nuxt/types'

const customersMiddleware: Middleware = async({ route, app: { $accessor } }) => {
  const customerId = parseInt(route.params.id)

  if (!$accessor.customers.getAllIds().includes(customerId)) {
    await $accessor.customers.fetchOne(customerId)
  }
}

export default customersMiddleware
