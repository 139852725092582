































































































































































import { defineComponent, onMounted, reactive, useContext } from '@nuxtjs/composition-api'
import { GiftCardSpending } from '~/types/models'
import useGiftCards from '~/composable/useGiftCards'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import TableEmpty from '~/components/table/TableEmpty.vue'
import GiftCardsForm from '~/components/form/GiftCardsForm.vue'
import UpdateSpendingModal from '~/components/modal/UpdateSpendingModal.vue'
import AddSpendingModal from '~/components/modal/AddSpendingModal.vue'

export default defineComponent({
  name: 'GiftCardId',
  components: {
    AppContentHeading,
    TableEmpty,
    GiftCardsForm,
    UpdateSpendingModal,
    AddSpendingModal,
  },
  setup(_, ctx) {
    const { app: { i18n, $accessor, $loadingToast, $successToast, $errorToast }, params } = useContext()
    const { getGiftCardField: getField, fetchGiftCard, currentGiftCard, patchGiftCard, deleteGiftCard } = useGiftCards()

    const state = reactive({
      loading: true,
      updateModal: false,
      addModal: false,
      selectedSpending: {} as GiftCardSpending,
    })

    onMounted(async() => {
      await fetchGiftCard(parseInt(params.value.id))
      state.loading = false
    })

    function updateSpendingModal(spending: GiftCardSpending) {
      state.selectedSpending = spending
      state.updateModal = true
    }

    async function refreshGiftCard() {
      state.loading = true
      await fetchGiftCard(parseInt(params.value.id))
      state.loading = false
    }

    async function confirmDeleteGiftCard() {
      ctx.root.$buefy.dialog.confirm({
        title: i18n.t('dialog.giftcard_delete.title').toString(),
        message: i18n.t('dialog.giftcard_delete.message').toString(),
        confirmText: i18n.t('dialog.giftcard_delete.confirm_text').toString(),
        cancelText: i18n.t('dialog.giftcard_delete.cancel_text').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          await deleteGiftCard(parseInt(params.value.id))
          ctx.root.$router.push(ctx.root.localePath('marketplace-giftCards'))
        },
      })
    }

    async function confirmDeleteSpending(id: number) {
      ctx.root.$buefy.dialog.confirm({
        title: i18n.t('dialog.spending_delete.title').toString(),
        message: i18n.t('dialog.spending_delete.message').toString(),
        confirmText: i18n.t('dialog.spending_delete.confirm_text').toString(),
        cancelText: i18n.t('dialog.spending_delete.cancel_text').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          const toast = $loadingToast(i18n.t('toast.spending_deleting'))
          try {
            await $accessor.giftCardSpendings.deleteOne(id)
            await refreshGiftCard()
            toast.goAway(0)
            $successToast(i18n.t('toast.spending_deleted'))
          } catch (error) {
            toast.goAway(0)
            $errorToast(i18n.t('toast.spending_delete_error'))
          }
        },
      })
    }

    return {
      state,
      currentGiftCard,
      patchGiftCard,
      getField,
      updateSpendingModal,
      refreshGiftCard,
      confirmDeleteSpending,
      confirmDeleteGiftCard,
    }
  },
})
