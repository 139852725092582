








































































import { defineComponent, PropType, reactive, ref, toRefs } from '@nuxtjs/composition-api'
import { ValidationObserver } from 'vee-validate'
import pick from 'lodash/pick'
import { CalendarExternalSourceForm, CalendarExternalSourceSyncIntervals, CalendarExternalSourceTypesEnum } from '~/types/models'
import useCalendarExternalSources from '~/composable/useCalendarExternalSources'
import usePermissions from '~/composable/usePermissions'

export default defineComponent({
  name: 'IcalImportLine',
  props: {
    isEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    calendarExternalSource: {
      type: Object as PropType<CalendarExternalSourceForm>,
      required: false,
      default: () => ({
        label: '',
        sourceUrl: '',
        syncInterval: CalendarExternalSourceSyncIntervals['30_MIN'],
        sourceType: CalendarExternalSourceTypesEnum.ICAL,
      }),
    },
  },
  setup(props, { emit }) {
    const { getIntervalText, getDefaultForm } = useCalendarExternalSources()
    const { isAdmin } = usePermissions()

    const validator = ref<InstanceType<typeof ValidationObserver> | null>(null)

    const form = reactive<Partial<CalendarExternalSourceForm>>(pick(props.calendarExternalSource, ['label', 'sourceUrl', 'syncInterval', 'sourceType']))

    // Filter out Keys since Object.values(Enum) is kinda weird in TS => https://stackoverflow.com/questions/56044872/typescript-enum-object-values-return-value
    const intervals = Object.values(CalendarExternalSourceSyncIntervals).filter(el => typeof el === 'number')

    const onSubmit = () => {
      emit('submit', form)
      const newForm = getDefaultForm()
      validator.value!.reset()
      form.label = newForm.label
      form.sourceUrl = newForm.sourceUrl
      form.sourceType = newForm.sourceType
      form.syncInterval = newForm.syncInterval
    }

    return {
      ...toRefs(form),
      onSubmit,
      intervals,
      validator,
      getIntervalText,
      CalendarExternalSourceSyncIntervals,
      isAdmin,
    }
  },
})
