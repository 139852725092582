import { RouteConfig } from '@nuxt/types/config/router'
import AuthBasePage from '~/components/pages/auth/index.vue'
import LoginForm from '~/components/auth/LoginForm.vue'
import AuthForgotPasswordPage from '~/components/pages/auth/forgot.vue'
import AuthResetPasswordPage from '~/components/pages/auth/resetPassword.vue'

export const authRoutes: RouteConfig[] = [
  {
    path: '/auth',
    component: AuthBasePage,
    name: 'auth',
    children: [
      {
        path: '/auth/login',
        component: LoginForm,
        name: 'auth-login',
        meta: { isAuthRoute: true },
      },
      {
        path: '/auth/forgot',
        component: AuthForgotPasswordPage,
        name: 'auth-forgot',
        meta: { isAuthRoute: true },
      },
      {
        path: '/auth/reset',
        component: AuthResetPasswordPage,
        name: 'auth-reset',
        meta: { isAuthRoute: true },
      },
    ],
  },
]
