



























































import { defineComponent, onMounted, ref, nextTick } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'AppFooter',
  setup() {
    const isVisible = ref(false)

    const initToTopButton = () => {
      window.addEventListener('scroll', () => {
        document.documentElement.scrollTop > window.innerHeight ? isVisible.value = true : isVisible.value = false
      })
    }

    onMounted(() => nextTick(() => initToTopButton()))

    const backToTop = () => {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    return {
      backToTop,
      isVisible,
    }
  },
  // data() {
  //   return {
  //     isVisible: false,
  //   }
  // },
  // mounted: function() {
  //   this.$nextTick(function() {
  //     this.initToTopButton()
  //   })
  // },
  // methods: {
  //   initToTopButton: function() {
  //     window.addEventListener('scroll', function() {
  //       document.documentElement.scrollTop > window.innerHeight ? this.isVisible = true : this.isVisible = false
  //     }.bind(this))
  //   },
  //   backToTop: function() {
  //     document.documentElement.scrollTo({
  //       top: 0,
  //       left: 0,
  //       behavior: 'smooth',
  //     })
  //   },
  // },
})
