import { createEntity, createState } from '@kissmylabs/vuex-entitystore'
import { Address } from '~/types/models'
import { AddressCreateForm, AddressState } from './types'

export const address = createEntity<Address>('address')

export const baseCreateForm = (): AddressCreateForm => ({
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  postalCode: '',
  city: '',
  subdivision: '',   // Code ISO des régions (pour la France)
  subdivision2: '',  // Code ISO des départements (pour la France)
  country: '',
  lat: null,
  lng: null,
  $isDirty: false,
})

export const state = (): AddressState => ({
  ...createState<Address>(address),
  createForm: baseCreateForm(),
})

export default state
