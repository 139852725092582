






































































import { computed, defineComponent, PropType, reactive, useContext } from '@nuxtjs/composition-api'
import { Service } from '~/types/models'
import HRSwitch from '~/components/HRSwitch.vue'

interface State {
    checkAll: boolean,
    selectedUnits: number[],
    services: Record<number, Service>
}

export default defineComponent({
  name: 'ServiceUnitsSelectCheckboxes',
  components: { HRSwitch },
  props: {
    value: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => [],
    },
    selectAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
      description: 'Hide services if all selected',
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    services: {
      type: Object as PropType<Record<number, Service>>,
      required: true,
    },
    disabledServices: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { app: { $accessor } } = useContext()
    const state = reactive<State>({
      checkAll: props.selectAll,
      selectedUnits: props.value,
      services: props.services,
    })
    /**
         * Handles adding and removing all of a Service's ServiceUnits when on service is selected by the user.
         * Since this component exposes a v-model with just a list of ServiceUnit IDs, we have to compute this.
         */
    function onServiceSelected({ serviceId, value }: {
      serviceId: number;
      value: boolean;
    }) {
      const { serviceUnits } = props.services[serviceId]
      if (value) {
        state.selectedUnits = [...serviceUnits, ...props.value]
        emit('input', state.selectedUnits)
      } else {
        state.selectedUnits = state.selectedUnits.filter(id => !serviceUnits.includes(id))
        emit('input', state.selectedUnits)
      }
      const servicesArray = Object.values(props.services)
      state.checkAll = servicesArray.filter(service => hasAllUnitsSelected(service.id)).length === servicesArray.length
    }
    function onServiceUnitSelected({ serviceUnitId, value }: {
      serviceUnitId: number;
      value: boolean;
    }) {
      state.selectedUnits = value ? [serviceUnitId, ...props.value] : state.selectedUnits.filter(id => id !== serviceUnitId)
      emit('input', state.selectedUnits)
    }
    /**
         * Handles toggling the selection of all services.
         * If true, reduce all ServiceUnit arrays of each Service into a single array and emit it.
         * If false, reset the state to an empty array and emit it
         */
    function onToggleSelectAll(value: boolean) {
      if (value) {
        const services = Object.values(props.services).filter(service => !props.disabledServices.includes(service.id))
        state.selectedUnits = services.reduce((acc, service) => {
          return acc = [...acc, ...service.serviceUnits]
        }, [] as number[])
        state.checkAll = value
        emit('input', state.selectedUnits)
      } else {
        state.checkAll = value
        state.selectedUnits = []
        emit('input', state.selectedUnits)
      }
    }
    function hasAllUnitsSelected(serviceId: number) {
      if (props.services[serviceId]) {
        const { serviceUnits } = props.services[serviceId]
        return serviceUnits.every((id) => state.selectedUnits.indexOf(id) !== -1)
      }
    }
    const isUnitSelected = (serviceUnitId: number) => computed(() => {
      return state.selectedUnits.includes(serviceUnitId)
    })
    function getUnitsForService(serviceId: number) {
      const units = $accessor.serviceUnits.getWhere((serviceUnit => serviceId === serviceUnit.service))
      return units
    }
    return {
      // Can't use ...toRefs(state) here since state.checkAll and state.selectedUnits are references to props
      // We would be mutating props when toggling the switch on and off.
      state,
      onServiceSelected,
      onServiceUnitSelected,
      hasAllUnitsSelected,
      isUnitSelected,
      onToggleSelectAll,
      getUnitsForService,
    }
  },
})
