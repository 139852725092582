import each from 'lodash/each'
import { getAuthCookieFromContext, isValidAuthCookie, shortLocaleToRegionLocale } from '~/helpers/index'
import { NuxtAppOptions } from '@nuxt/types'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { AuthCookie } from './authCookie'

enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export type PatchPayload<T> = Partial<T> & { id: number, force?: boolean }

interface HttpHeaders {
  [key: string]: string
}

class HttpStatusCodeError extends TypeError {
  message
  code
  response

  constructor(message: string, code: number, response: AxiosResponse<any>) {
    super()
    this.message = message
    this.code = code
    this.response = response
  }
}

export interface PaginatedResponse<T> {
  current_page: number | null // eslint-disable-line camelcase
  data: T[]
  next_page: number | null // eslint-disable-line camelcase
  per_page: number | null // eslint-disable-line camelcase
  prev_page: number | null // eslint-disable-line camelcase
  total: number | null
}

export type ApiMethods = {
  setToken(token: string, type?: string, scopes?: string): any,
  getToken(): string | null,
  getBaseUrl(): string,
  setAuthCookie(token: string, expiresAt: string, user: number, refreshToken: string): void
  deleteCredentials(): void
  onAxiosRequest(config: AxiosRequestConfig): void
  onAxiosResponse(_: AxiosResponse<any>): void
  onAxiosError(err: AxiosError<any>): void
  onAxiosRequestError(err: AxiosError<any>): void
  onAxiosResponseError(err: AxiosError<any>): void
  get(path: string): Promise<any>
  post(path: string, data?: any, config?: Record<string, unknown>): Promise<any>
  post<T>(path: string, data?: T, config?: Record<string, unknown>): Promise<any>
  patch<T>(path: string, data?: Partial<T>, config?: Record<string, unknown>): Promise<any>
  delete(path: string): Promise<any>
}

export class Api implements ApiMethods {
  headers: HttpHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  static authCookieName = 'us_auth'

  context
  axios
  sentry
  baseUrl

  constructor(context: NuxtAppOptions) {
    this.context = context

    this.sentry = context.$sentry

    this.axios = this.context.$axios

    this.baseUrl = context.$config.axios.baseURL

    this._setHeaders(this.headers)

    this._setInterceptors()

    this._setTokenFromCookies()

  }

  private _setHeaders(headers: HttpHeaders): void {
    each(headers, (value, name) => this.axios.setHeader(name, value))
  }

  private _setTokenFromCookies(): void {
    const authCookie = getAuthCookieFromContext(this.context)
    if (authCookie) {
      if (!isValidAuthCookie(authCookie)) { // TODO anticipate expiration by refreshing
        this.deleteCredentials()
      } else {
        this.setToken(authCookie.token)
      }
    }
  }

  private _setInterceptors(): void {
    this.axios.onRequest((config) => {
      this.onAxiosRequest(config)
    })

    this.axios.onResponse((response) => {
      this.onAxiosResponse(response)
    })

    this.axios.onError((error) => {
      this.onAxiosError(error)
    })

    this.axios.onRequestError((error) => {
      this.onAxiosRequestError(error)
    })

    this.axios.onResponseError((error) => {
      this.onAxiosResponseError(error)
    })
  }

  /**
   * Add XDEBUG_SESSION_START=1 query to every URL if a XDEBUG_SESSION cookie is present.
   */
  private _forwardXdebugSession(config: AxiosRequestConfig): void {
    if (config.url && process.env.NODE_ENV === 'development' && this.context.app.$cookies.get('XDEBUG_SESSION')) {
      const xdebugQuery = `${config.url.includes('&') || config.url.includes('?') ? '&' : '?'}XDEBUG_SESSION_START=1`
      config.url += xdebugQuery
    }
  }

  private _raiseFromStatus(type: HttpMethods, response: AxiosResponse): void {
    if (
      (type === HttpMethods.GET && ![200, 202].includes(response.status)) ||
      ([HttpMethods.POST, HttpMethods.PATCH, HttpMethods.PUT].includes(type) && ![200, 201, 202, 204, 205, 206, 207].includes(response.status)) ||
      (type === HttpMethods.DELETE && ![202, 204].includes(response.status))
    ) {
      throw new HttpStatusCodeError(
        `Wrong HTTP status code received: ${response.status}`,
        response.status,
        response,
      )
    }
  }

  setToken(token: string, type = 'Bearer', scopes = 'common'): Api {
    if (token) {
      this.axios.setToken(token, type, scopes)
    }
    return this
  }

  getToken(): string | null {
    const cookie = getAuthCookieFromContext(this.context)
    return cookie.token || null
  }

  getBaseUrl(): string {
    return this.baseUrl
  }

  setAuthCookie(token: string, expiresAt: string, user: number, refreshToken: string): void {
    const cookie: AuthCookie = {
      token,
      expiresAt,
      user,
      refreshToken,
    }
    const expiration = new Date(expiresAt).getTime()
    const now = new Date().getTime()
    // Cookie duration = one hour (rounded to the next int) after token expiration
    const maxAge = Math.ceil((Math.abs(expiration - now)) / 1000) + 3600
    this.context.app.$cookies.set(Api.authCookieName, cookie, {
      path: '/',
      maxAge,
    })
  }

  deleteCredentials(): void {
    this.context.app.$cookies.remove(Api.authCookieName, { path: '/' })
    this.axios.setHeader('Authorization', undefined)
  }

  onAxiosRequest(config: AxiosRequestConfig): void {
    this._forwardXdebugSession(config)
    const longLocale = shortLocaleToRegionLocale(this.context.app.i18n.locale)

    config.headers = {
      ...config.headers,
      'Accept-Language': `${longLocale}, ${this.context.app.i18n.locale};q=0.9, en;q=0.8`,
    }

    if (process.env.NODE_ENV !== 'production' && this.context.$config.axios.logRequests) {
      console.log(`Axios debug: ${config.method} request to ${config.url}`) // eslint-disable-line
    }
  }

  onAxiosResponse(_: AxiosResponse<any>) { // eslint-disable-line
  }

  onAxiosError(err: AxiosError<any>): void {
    this.sentry.captureException(err)
  }

  onAxiosRequestError(err: AxiosError<any>): void {
    this.sentry.captureException(err)
  }

  onAxiosResponseError(err: AxiosError<any>): void {
    this.sentry.captureException(err)
  }

  async get(path: string): Promise<any> {
    this.axios.setHeader('Content-Type', '')
    const res = await this.axios.get(path)
    this._raiseFromStatus(HttpMethods.GET, res)
    return res.data
  }

  async post<T>(path: string, data?: T, config?: Record<string, unknown>): Promise<any> {
    const res = await this.axios.post(path, data, config)
    this._raiseFromStatus(HttpMethods.POST, res)
    return res.data
  }

  async patch<T>(path: string, data?: Partial<T>, config?: Record<string, unknown>): Promise<any> {
    const res = await this.axios.patch(path, data, config)
    this._raiseFromStatus(HttpMethods.PATCH, res)
    return res.data
  }

  async delete(path: string): Promise<any> {
    const res = await this.axios.delete(path)
    this._raiseFromStatus(HttpMethods.DELETE, res)
    return res.data
  }
}

