














































import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import { DateType } from '~/types'
import { BookingEventsEnum, IBookingTimelineItem } from '~/types/models'

export default defineComponent({
  name: 'BookingTimelineItem',
  props: {
    bookingEvent: {
      type: Object as PropType<IBookingTimelineItem>,
      required: true,
    },
    marketplaceName: {
      type: String,
      required: true,
    },
    bookingId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { app: { $dateTime, $accessor, router, localePath } } = useContext()

    const isAdmin = computed(() => $accessor.users.currentUserIsAdmin)
    const formatDate = (date: DateType) => $dateTime.fromJSDate(new Date(date.toString())).toLocaleString($dateTime.DATE_SHORT)

    function redirectToGiftCard(giftCardId: number) {
      router?.push(localePath({ name: 'marketplace-giftCard-id', params: {
        id: giftCardId.toString(),
      } }))

    }

    return {
      BookingEventsEnum,
      formatDate,
      isAdmin,
      redirectToGiftCard,
    }
  },
})
