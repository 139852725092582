import { defineComponent, reactive } from '@nuxtjs/composition-api';
import CallToActionButton from '~/components/buttons/CallToActionButton.vue';
import OptionModal from '~/components/option/OptionModal.vue';
export default defineComponent({
  name: 'OptionCreateButtons',
  components: {
    CallToActionButton: CallToActionButton,
    OptionModal: OptionModal
  },
  setup: function setup() {
    var _useDomain = useDomain(),
        currentDomainIsSh = _useDomain.currentDomainIsSh;

    var state = reactive({
      isAddModalActive: false,
      addModalRequired: false
    });

    function showModal() {
      var required = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      state.addModalRequired = required;
      state.isAddModalActive = true;
    }

    return {
      state: state,
      showModal: showModal,
      currentDomainIsSh: currentDomainIsSh
    };
  }
});