import { createEntity, createState } from '@kissmylabs/vuex-entitystore'
import { CurrencyEnum, GiftCard } from '~/types/models'
import { GiftCardCreateForm, GiftCardState } from './types'

export const giftCard = createEntity<GiftCard>('giftCard')

export const baseCreateForm = (): GiftCardCreateForm => ({
  code: '',
  buyerFullName: '',
  totalAmount: {
    amount: 0,
    currency: CurrencyEnum.CURRENCY_EURO,
  },
  isActive: false,
  origin: '',
  comment: '',
  validUntil: new Date(),
})

export const state = (): GiftCardState => ({
  ...createState<GiftCard>(giftCard),
  pagination: { total: 0 },
  createForm: baseCreateForm(),
  isImpersonated: false,
})

export default state


