import serviceTypesMutations from './mutations'
import serviceTypesGetters from './getters'
import serviceTypesActions from './actions'
import serviceTypesState from './state'

export const serviceTypes = {
  namespaced: true,
  state: serviceTypesState,
  actions: serviceTypesActions,
  mutations: serviceTypesMutations,
  getters: serviceTypesGetters,
}
