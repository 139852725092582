




















import { defineComponent, reactive } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ToggleContentButton',
  props: {
    contentOff: {
      type: String,
      required: true,
    },
    contentOn: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(_, { emit }) {
    const state = reactive({ isOn: false })

    function eventClick() {
      state.isOn = !state.isOn
      emit('onClick')
    }

    return {
      state,
      eventClick,
    }
  },
})
