import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { User } from '~/types/models/User'
import { resetState } from '~/helpers/store'
import { UserMutationTypes } from './types'
import baseState, { baseCreateForm, user } from '~/store/users/state'

export const mutations = mutationTree(baseState, {
  ...createMutations<User>(user),

  [UserMutationTypes.SET_CURRENT](state, payload: User) {
    state.currentUser = payload
  },

  [UserMutationTypes.SET_CURRENT_USER_ID](state, id: number) {
    state.currentUserId = id
  },

  [UserMutationTypes.SET_CURRENT_PROVIDER_ID](state, id: number) {
    state.currentProviderId = id
  },

  [UserMutationTypes.SET_IS_IMPERSONATING](state, value: boolean) {
    state.isImpersonating = value
  },

  [UserMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },

  [UserMutationTypes.UPDATE_CREATE_FORM_FIELD](state, payload) {
    updateField(state.createForm, payload)
  },

  [UserMutationTypes.RESET_CREATE_FORM](state) {
    state.createForm = baseCreateForm()
  },

})

export default mutations
