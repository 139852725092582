import { Middleware } from '@nuxt/types'

const domainMarketplaceConfigurationMiddleware: Middleware = async({ app: { $accessor }, route }) => {

  const domain = $accessor.domains.getOne(parseInt(route.params.domain))
  const missingMarketplaceConfigurationsIds = domain.marketplaceConfigurations.filter(id => !$accessor.domainMarketplaceConfigurations.getAllIds().includes(id))
  if (missingMarketplaceConfigurationsIds.length > 0) {
    await $accessor.domainMarketplaceConfigurations.fetchMany(missingMarketplaceConfigurationsIds)
  }
}
export default domainMarketplaceConfigurationMiddleware
