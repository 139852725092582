import { Context } from '@nuxt/types'
import authSubscribers from '~/store/auth/subscribers'

/**
 * A simple yet effective approach to register universal,
 * global store subscribers and define these close to store modules.
 * @param store
 */
export default function({ store }: Context): void {
  store.subscribe((mutation, state) => {
    // TODO this is just a practical PoC, do something cleaner.
    if (
      mutation.type.includes('auth/') &&
      typeof authSubscribers[mutation.type.split('auth/')[1]] === 'function'
    ) {
      authSubscribers[mutation.type.split('auth/')[1]](store, mutation, state)
    }
  })
}
