import { Middleware } from '@nuxt/types'

const domainOptionsMiddleware: Middleware = async({ route, app: { $accessor } }) => {
  const domainId = parseInt(route.params.domain)
  // TODO find a way to check if domain's options are in store
  // if ($accessor.options.getAllIds().length === 0) {
  await $accessor.options.fetchForDomain(domainId)
  // }
}

export default domainOptionsMiddleware
