







































import { computed, defineComponent, onMounted, useContext, useMeta } from '@nuxtjs/composition-api'
import { collectBrowserInfo } from '~/helpers/3ds2'
import useAuth from '~/composable/useAuth'
import useProvider from '~/composable/useProvider'
import AppHeader from '~/components/header/AppHeader.vue'
import AppFooter from '~/components/footer/AppFooter.vue'
import AppBreadcrumb from '~/components/navigation/AppBreadcrumb.vue'
import ImpersonatingLinks from '~/components/impersonate/ImpersonatingLinks.vue'
import ModerationStickyList from '~/components/moderation/ModerationStickyList.vue'
import { Api } from '~/helpers/api'
import { RootMutationTypes } from '~/store/types'

export default defineComponent({
  name: 'LayoutDefault',
  components: {
    AppHeader,
    AppFooter,
    AppBreadcrumb,
    ImpersonatingLinks,
    ModerationStickyList,
  },
  setup() {
    const { app, store, route } = useContext()
    const { isLoggedIn } = useAuth()
    const { currentProvider } = useProvider()

    const isCalendarRoute = computed(() => route.value.name?.split('___')[0] === 'calendar')

    function handleWindowClose(e: BeforeUnloadEvent) {
      e.preventDefault()
      e.returnValue = app.$accessor.ui.preventWindowClose
      return app.$accessor.ui.preventWindowClose
    }

    onMounted(() => {
      app.$accessor.SET_3DS2_CLIENT_INFO(collectBrowserInfo())

      // AutoRefreshToken is only triggered from subscribers if mutations are commited client-side,
      // as we use window.setTimeout and we don't want the auto refresh to run in SSR.
      // So we need to check if autoRefreshToken is already running, and start it otherwise.
      if (app.$accessor.auth.getIsLoggedIn && !app.$autoRefreshToken.isRunning) {
        app.$autoRefreshToken.start(store.$cookies.get(Api.authCookieName))
      }

      // Prevent closing window when critical actions are pending.
      store.subscribe(({ type }) => {
        if (type === RootMutationTypes.START_PREVENT_WINDOW_CLOSE) {
          window.addEventListener('beforeunload', handleWindowClose, { capture: true })
        } else if (type === RootMutationTypes.END_PREVENT_WINDOW_CLOSE) {
          window.removeEventListener('beforeunload', handleWindowClose, { capture: true })
        }
      })

    })
    useMeta({ htmlAttrs: { lang: app.i18n.locale } })

    // Work around Vuex bug
    if (process.env.NODE_ENV === 'development') {
      const state = computed(() => store.state)
      return {
        isLoggedIn,
        currentProvider,
        isCalendarRoute,
        state,
      }
    }
    return {
      isLoggedIn,
      currentProvider,
      isCalendarRoute,
    }
  },
  head: {}, // Required to work with Nuxt composition API's useMeta.
})
