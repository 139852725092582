import { Middleware } from '@nuxt/types'

const marketplaceFilesMiddleware: Middleware = async({ route, app: { $accessor } }) => {
  const booking = $accessor.bookings.getOne(parseInt(route.params.id))
  const missingFilesIds = $accessor.marketplaces.getOne(booking.marketplace).files.filter(id => !$accessor.entityFiles.getAllIds().includes(id))

  if (missingFilesIds.length > 0) {
    await $accessor.entityFiles.fetchMany(missingFilesIds)
  }
}
export default marketplaceFilesMiddleware
