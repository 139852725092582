import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { Option } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<Option>(),

  async fetchOne({ commit }, id: number) {
    const res = await this.app.$api.get(`options/${id}`)
    if (this.app.$accessor.options.getOne(id)) {
      commit(BaseMutationTypes.UPDATE, {
        id,
        payload: {
          ...res,
          $fullyLoaded: true,
        },
      })
    } else {
      commit(BaseMutationTypes.CREATE, {
        ...res,
        $fullyLoaded: true,
      })
    }
  },

  async fetchMany({ commit }, optionIds: number[]) {
    if (optionIds.length > 0) {
      const ids = optionIds.join()
      const res = await this.app.$api.get(`options?filters[id_in]=${ids}&limit=${optionIds.length}`)
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },

  async fetchForDomain({ commit }, domainId: number) {
    const res = await this.app.$api.get(`options?filters[domain.id]=${domainId}&limit=-1`)
    if (res.data.length) {
      commit(BaseMutationTypes.CREATE_MANY, res.data)
    }
  },

  async patchOne({ commit }, option: Option): Promise<void> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.option_updating'))
    try {
      const res: Option = await this.app.$api.patch(`options/${option.id}`, option)

      commit(BaseMutationTypes.UPDATE, {
        id: option.id,
        payload: { ...res },
      })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.option_updated'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.option_update_error'))
    }
  },

  async postOne({ commit }): Promise<void> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.option_creating'))
    try {
      const payload = this.app.$accessor.options.createForm
      const data = {
        ...payload,
        periods: payload.periods?.map((period) => {
          const newPeriod = {
            start: this.app.$dateTime.fromJSDate(new Date(period.start.toString())).toISODate(),
            end: this.app.$dateTime.fromJSDate(new Date(period.end.toString())).toISODate(),
          }
          return newPeriod
        }),
      }
      const res = await this.app.$api.post('options', data)
      commit(BaseMutationTypes.CREATE, res)
      this.app.$accessor.options.RESET_CREATE_FORM()
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.option_created'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.option_create_error'))
    }
  },

  async deleteOne({ commit }, id: number): Promise<void> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.common_deleting'))
    try {
      await this.app.$api.delete(`options/${id}`)
      commit(BaseMutationTypes.DELETE, id)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.common_deleted'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.common_deleting_error'))
    }
  },

  async moderateOne({ commit }, { id, isApproved }: { id: number, isApproved: boolean}) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.moderation_submitted'))

    try {
      const option: Option = await this.app.$api.post(`moderation/option/${id}`, { isApproved })
      commit(BaseMutationTypes.UPDATE, {
        id,
        payload: option,
      })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.moderation_success'))
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.moderation_error'))
    }
  },
})

export default actions
