






































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { providerBookingStatus, providerBookingStatusClasses } from '~/helpers/constants'

export default defineComponent({
  name: 'BookingStatusField',
  props: {
    value: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.value,
      set: value => emit('input', value),
    })

    return {
      model,
      providerBookingStatus,
      providerBookingStatusClasses,
    }
  },
})
