import { Middleware } from '@nuxt/types'

const domainFilesMiddleware: Middleware = async({ route, app: { $accessor } }) => {
  $accessor.TOGGLE_APP_IS_LOADING()
  try {
    const domainId = parseInt(route.params.domain)
    const fileIds = $accessor.domains.getOne(domainId).files
    const missingFileIds = fileIds.filter(id => !$accessor.entityFiles.getAllIds().includes(id))
    if (missingFileIds.length > 0) {
      await $accessor.entityFiles.fetchMany(missingFileIds)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  $accessor.TOGGLE_APP_IS_LOADING()
}

export default domainFilesMiddleware
