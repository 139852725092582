


























import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import BookingDetails from '~/components/booking/BookingDetails.vue'
import BookingActions from '~/components/booking/BookingActions.vue'

export default defineComponent({
  name: 'BookingDetailsPage',
  components: {
    AppContentHeading,
    BookingDetails,
    BookingActions,
  },
  middleware: [
    'singleBookingMiddleware',
    'bookingPaymentsMiddleware',
    'marketplaceFilesMiddleware',
    'bookingServiceFilesMiddelware',
    'bookingProviderMiddleware',
    'bookingOptionsMiddleware',
    'bookingEmailLogMiddleware',
    'bookingGiftCardMiddleware',
  ],

  setup() {
    const { app: { $accessor, localePath }, params, route } = useContext()
    const booking = computed(() => $accessor.bookings.getOne(parseInt(params.value.id)))

    const parentRoutePath = computed(() => {
      if ($accessor.users.currentUserIsAdmin && route.value.matched.some(r => r.name?.startsWith('marketplace'))) {
        return localePath({ name: 'marketplace-bookings' })
      }
      return localePath({ name: 'bookings' })
    })

    return {
      booking,
      parentRoutePath,
    }
  },
})
