


















































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import CustomerContactActions from '~/components/customer/CustomerContactActions.vue'
import { Booking, Customer } from '~/types/models'

export default defineComponent({
  name: 'BookingCustomer',
  components: { CustomerContactActions },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true,
    },
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },
})
