






























































































































import { TranslateResult } from 'vue-i18n'
import {
  defineComponent, onBeforeUnmount, onMounted, PropType, Ref, ref, useContext, watch,
} from '@nuxtjs/composition-api'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote, Bold, BulletList, HardBreak, History, Italic, ListItem, OrderedList, Strike, Underline,
} from 'tiptap-extensions'
import { ShortToISOEnum, Translation } from '~/types'

type ContentScopeProp = null | 'domain' | 'service' | 'provider' | 'option'

export default defineComponent({
  name: 'TranslatableTextArea',
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    value: {
      type: Object as PropType<Translation>,
      required: true,
    },
    contentScope: {
      type: String as PropType<ContentScopeProp>,
      required: false,
      default: null,
      validator: (prop: ContentScopeProp) => [null, 'domain', 'provider', 'service', 'option'].includes(prop),
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    useDefaultHeader: {
      type: Boolean,
      default: true,
    },
    heading: {
      type: String as PropType<TranslateResult | string>,
      default: '',
    },
  },
  setup(props: any, ctx: { listeners: any; emit: any }) {
    const { app } = useContext()

    const activeLocale = ref(ShortToISOEnum[app.i18n.locale.toUpperCase() as keyof typeof ShortToISOEnum])
    const disabled = ref(false)

    const customListeners = {
      ...ctx.listeners,
      input: (e: any) => ctx.emit('input', {
        ...props.value,
        [activeLocale.value]: e,
      }),
    }

    // Init Tiptap editor
    const editor: Ref<Editor | null> = ref(null)

    function onEditorUpdateValue({ getHTML }: { getHTML: () => string }) {
      const html = getHTML()
      // Avoid storing empty content as it breaks existence checks.
      if (html === '<p></p>') {
        customListeners.input('')
      } else {
        customListeners.input(html)
      }
    }

    function onLocaleChange($event: ShortToISOEnum) {
      ctx.emit('locale-change', $event)
    }

    onMounted(() => {
      editor.value = new Editor({
        content: props.value[activeLocale.value] || '',
        editable: !disabled.value,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new ListItem(),
          new OrderedList(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        onUpdate: onEditorUpdateValue,
      })

      watch(() => disabled.value, () => {
        if (editor.value) {
          editor.value.setOptions({ editable: !disabled.value })
        }
      })

      watch(() => activeLocale.value, () => {
        if (editor.value) {
          editor.value.setContent(props.value[activeLocale.value] || '')
        }
      })
    })

    onBeforeUnmount(() => {
      if (editor.value) {
        editor.value.destroy()
      }
    })

    return {
      activeLocale,
      customListeners,
      disabled,
      editor,
      onLocaleChange,
      ShortToISOEnum,
    }
  },
})
