import { CalendarEntitiesEnum, CalendarEventEntity, CalendarFiltersEnum, CalendarUnavailabilityEvent, entitiesCalendar, FormattedCalendarEvent, ServiceBoundCalendarEntity } from '~/types'
import { BookingCondition, BookingConditionCalendar, ServiceDiscount, ServiceDiscountCalendar, ServicePricing, ServicePricingCalendar, ServiceUnitUnavailabilityCalendar, Unavailability } from '~/types/models'
import { DateTime } from 'luxon'
import sortBy from 'lodash/sortBy'
import { toAPIFormat } from '~/helpers/dateTime'

export interface EventSlot {
  slot: number,
  id: number | null,
  next: number | null
}

export interface ServiceEventSlot {
  slot: number,
  serviceId: number | null,
  event: number | null,
  next: number | null
}

export function createSlots(count: number): EventSlot[] {
  const slots: EventSlot[] = []
  for (let i = 0; i < count; i++) {
    slots.push({
      slot: i,
      id: null,
      next: null,
    })
  }
  return slots
}

export function createServiceSlots(count: number): ServiceEventSlot[] {
  const slots: ServiceEventSlot[] = []
  for (let i = 0; i < count; i++) {
    slots.push({
      slot: i,
      serviceId: null,
      event: null,
      next: null,
    })
  }
  return slots
}

// @fixme won't `param is ENTITY` break if it's not the expected entity?
// It just so happens that TypeScript has something called a type guard.
// A type guard is some expression that performs a runtime check that guarantees the type in some scope.
// ""that guarantees the type in some scope"" is not compatible with type check function.
export function isBookingCondition(param: CalendarEventEntity): param is BookingCondition {
  return (param as BookingCondition).minBookingDuration !== undefined
}

// @fixme won't `param is ENTITY` break if it's not the expected entity?
// @see isBookingCondition.
export function isUnavailability(param: CalendarEventEntity): param is Unavailability {
  return (param as Unavailability).serviceUnit !== undefined
}

// @fixme won't `param is ENTITY` break if it's not the expected entity?
// @see isBookingCondition.
export function isServicePricing(param: CalendarEventEntity): param is ServicePricing {
  return (param as ServicePricing).baseRate !== undefined
}

// @fixme won't `param is ENTITY` break if it's not the expected entity?
// @see isBookingCondition.
export function isDiscount(param: CalendarEventEntity): param is ServiceDiscount {
  return (param as ServiceDiscount).discountType !== undefined
}

export function isSameDay(date1: DateTime, date2: DateTime): boolean {
  return date1.toISODate() === date2.toISODate()
}

export function showUnavailability(unavailability: CalendarUnavailabilityEvent | Unavailability, filters: Record<CalendarFiltersEnum, boolean>): boolean {
  if (unavailability.externalSource) {
    return filters[CalendarFiltersEnum.ICALS]
  }
  if (unavailability.booking) {
    return filters[CalendarFiltersEnum.BOOKING]
  }
  return filters[CalendarFiltersEnum.UNAVAILABILITY]
}

/**
 * Check whether a FormattedCalendarEvent is starting at a given date.
 *
 * @param date
 *  Day to check (DateTime).
 * @param start
 * @param currentDayEvents
 * @param event
 *  The FormattedCalendarEvent holding the entity and event metadata.
 * @param prevDayEvents
 *  Array of FormattedCalendarEvent. Used to check if the event actually applies the day before or was overwritten.
 *
 * @return boolean
 */
export function getStartsAtDate(
  date: DateTime,
  start: DateTime,
  event: FormattedCalendarEvent,
  _currentDayEvents: FormattedCalendarEvent[],
  _prevDayEvents: FormattedCalendarEvent[],
): boolean {
  if (isSameDay(
    DateTime.fromISO(event.start as string),
    date,
  )) {
    return true
  }
  return false
  // @todo We should handle this case, but it's a tricky one:
  // When viewing 2 months, say March 2022 and April 2022, the 2 trailing days (01/03/2022 and 30/04/2022) won't have
  // access to previous events, so if an event's `start` isn't the 01/03/2022 it should not start here.
  // But when we have 2 events on the 01/03/2022, the first one ending and the second one starting,
  // we don't want to set `isStartDay = false` here, as it would prevent a proper chaining.
  // @see assets/scripts/calendar.worker.ts:69 : one way would be to always fetch 1 day before and after the period,
  // so we have access to previous day events and can know if the event starts / ends this day
  // even if it's the first day of the period.

  // if (isSameDay(start, date)) {
  //   return false
  // }

  // return prevDayEvents.length === 0 || !prevDayEvents.find(e => e.entity.id === event.entity.id)
}

/**
 * Check whether a FormattedCalendarEvent is ending at a given date.
 *
 * @param date
 *  Day to check (DateTime).
 * @param end
 * @param event
 *  The FormattedCalendarEvent holding the entity and event metadata.
 * @param nextDayEvents
 *  Array of FormattedCalendarEvent. Used to check if the event actually applies the day after or was overwritten.
 *
 * @return boolean
 */
export function getEndsAtDate(
  date: DateTime,
  end: DateTime,
  event: FormattedCalendarEvent,
  _nextDayEvents: FormattedCalendarEvent[],
): boolean {

  // @todo We should handle this case, but it's a tricky one (see above, same but for end).

  if (isSameDay(
    DateTime.fromISO(event.end as string),
    date,
  )) {
    return true
  }
  return false

  // return nextDayEvents.length === 0 || !nextDayEvents.find(e => e.entity.id === event.entity.id)
}

/**
 * Set `isStartDay` and `isEndDay` on an array of events for a given date.
 *
 * @param date
 * @param start
 * @param end
 * @param currentDayEvents
 * @param prevDayEvents
 * @param nextDayEvents
 */
export function setIsStartEndDay(
  date: DateTime,
  start: DateTime,
  end: DateTime,
  currentDayEvents: FormattedCalendarEvent[],
  prevDayEvents: FormattedCalendarEvent[],
  nextDayEvents: FormattedCalendarEvent[],
): FormattedCalendarEvent[] {
  return currentDayEvents.map(event => ({
    ...event,
    isStartDay: getStartsAtDate(date, start, event, currentDayEvents, prevDayEvents),
    isEndDay: getEndsAtDate(date, end, event, nextDayEvents),
  }))
}

export function getEventsForDay(
  day: Date,
  view: CalendarEntitiesEnum,
  entities: entitiesCalendar,
  filters: Record<CalendarFiltersEnum, boolean>,
): FormattedCalendarEvent[] {
  const eventsForDay: CalendarEventEntity[] = switchEntities(view, entities, filters, day)

  if (eventsForDay.length > 0) {
    const events = eventsForDay.map((event: CalendarEventEntity) => {
      const eventWrapper: FormattedCalendarEvent = {
        id: event.id,
        isStartDay: false,
        isEndDay: false,
        serviceId: null,
        duration: 0,
        start: event.start as string,
        end: event.end as string,
        entity: event,
        entityType: getEntityTypeName(event),
        isEmpty: false,
        booking: isUnavailability(event) ? event.booking as number : null,
        externalSource: isUnavailability(event) ? event.externalSource as number : null,
      }

      function calculateDuration(e: CalendarEventEntity, addDay = false): number {
        const startDate = DateTime.fromISO(e.start as string)
        const endDate = DateTime.fromISO(e.end as string).plus({ days: addDay ? 1 : 0 })
        if (endDate && startDate) {
          return endDate.diff(startDate).as('minutes')
        }
        return 0
      }

      if (isUnavailability(event)) {
        eventWrapper.duration = calculateDuration(event)
      } else {
        eventWrapper.serviceId = event.service
        if (!event.isDefault) {
          eventWrapper.duration = calculateDuration(event, true)
          eventWrapper.end = DateTime.fromISO(event.end as string).plus({ days: 1 }).toISO()
        }
      }

      return eventWrapper
    })
    // @todo we should also sort by duration left until event end, so we can pick events that will span more days.
    return sortBy(events, (event) => event.duration).reverse()
  }
  return []
}

function switchEntities(view: CalendarEntitiesEnum, entities: entitiesCalendar, filters: Record<CalendarFiltersEnum, boolean>, day: Date) {
  let eventsForDay: CalendarEventEntity[] = []
  const formattedDay = toAPIFormat(DateTime.fromJSDate(day))
  switch (view) {
    case CalendarEntitiesEnum.UNAVAILABILITIES:
      // eslint-disable-next-line no-case-declarations
      const eventsRecieved = entities as ServiceUnitUnavailabilityCalendar[]
      eventsForDay = eventsRecieved
        .flatMap(item => item.calendar[formattedDay])
        .filter(event => event && showUnavailability(event, filters))
      break

    case CalendarEntitiesEnum.PRICING:
      // eslint-disable-next-line no-case-declarations
      const pricingsRecieved = entities as ServicePricingCalendar[]
      eventsForDay = getServiceBoundEntityEventsForDay(
        pricingsRecieved,
        day,
        event => event.isDefault ? filters[CalendarFiltersEnum.PRICING_DEFAULT] : filters[CalendarFiltersEnum.PRICING_CUSTOM],
      )
      break

    case CalendarEntitiesEnum.BOOKING_CONDITION:
      // eslint-disable-next-line no-case-declarations
      const bookingConditionsRecieved = entities as BookingConditionCalendar[]
      eventsForDay = getServiceBoundEntityEventsForDay(
        bookingConditionsRecieved,
        day,
        _event => filters[CalendarFiltersEnum.DURATION],
      )
      break

    case CalendarEntitiesEnum.DISCOUNT:
      // eslint-disable-next-line no-case-declarations
      const discountsCalendarRecieved = entities as ServiceDiscountCalendar[]
      eventsForDay = getServiceBoundEntityEventsForDay(
        discountsCalendarRecieved,
        day,
        _event => filters[CalendarFiltersEnum.DISCOUNT],
      )
      break
  }
  return eventsForDay
}

function getServiceBoundEntityEventsForDay(
  items: { calendar: Record<string, ServiceBoundCalendarEntity> }[],
  day: Date,
  isVisibleCallback: (_e: ServiceBoundCalendarEntity) => boolean,
) {
  const formattedDay = toAPIFormat(DateTime.fromJSDate(day))
  const prevDay = DateTime.fromJSDate(day).minus({ days: 1 })
  const formattedPrevDay = toAPIFormat(prevDay)

  const eventsForPrevDay = items
    .map(item => item.calendar[formattedPrevDay])
    .filter(event => event && isVisibleCallback(event))
    .filter(event => isSameDay(DateTime.fromISO(event.end as string), prevDay))

  const eventsForDayByServiceId = [
    ...items
      .map(item => item.calendar[formattedDay])
      .filter(event => event && isVisibleCallback(event)),
    ...eventsForPrevDay,
  ]
    // index by service id
    .reduce((acc, event) => {
      if (!acc[event.service]) {
        acc[event.service] = []
      }
      acc[event.service].push(event)
      return acc
    }, {} as Record<string, CalendarEventEntity[]>)

  // @todo maybe we don't need all this "index by service id" stuff as in the end we won't use this order.
  return Object.values(eventsForDayByServiceId).flatMap(u => u)
}

function getEntityTypeName(entity: ServicePricing | BookingCondition | ServiceDiscount | Unavailability): string {
  if (isServicePricing(entity)) {
    return CalendarEntitiesEnum.PRICING
  }
  if (isBookingCondition(entity)) {
    return CalendarEntitiesEnum.BOOKING_CONDITION
  }
  if (isDiscount(entity)) {
    return CalendarEntitiesEnum.DISCOUNT
  }
  if (isUnavailability(entity)) {
    return CalendarEntitiesEnum.UNAVAILABILITIES
  }
  return 'unknown'
}
