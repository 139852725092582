
































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Option } from '~/types/models'
import OptionsListItem from '~/components/option/OptionsListItem.vue'

export default defineComponent({
  name: 'OptionsList',
  components: { OptionsListItem },
  props: {
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
})
