import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import {
  ServiceDiscount, ServiceDiscountBatchCreateForm, ServiceDiscountBatchDeleteForm, ServiceDiscountBatchEditForm,
} from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { toIsoDateStringPeriod, toIsoStringPeriod } from '~/helpers'
import { ICalendarBatchParams, ICalendarBatchActionParams } from '~/types'
import { RootMutationTypes } from '~/store/types'

type IServiceDiscountCreateBatchParams = ICalendarBatchParams<ServiceDiscountBatchCreateForm | ServiceDiscountBatchEditForm>
type IServiceDiscountResetBatchParams = ICalendarBatchParams<ServiceDiscountBatchDeleteForm>

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<ServiceDiscount>(),

  async fetchAll({ commit }): Promise<void> {
    const { $api } = this.app
    const res = await $api.get('servicediscounts')
    const discounts = res.data
    if (discounts.length) {
      return commit(BaseMutationTypes.CREATE_MANY, discounts)
    }
  },

  async fetchMany({ commit }, serviceDiscountIds: number[]) {
    const { $api } = this.app
    if (serviceDiscountIds.length > 0) {
      const ids = serviceDiscountIds.join()
      const res = await $api.get(`servicediscounts?filters[id_in]=${ids}&limit=${serviceDiscountIds.length}`)
      if (res.data.length) {
        return commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },

  async createBatch(
    _,
    { domainId, payload, options }
      : IServiceDiscountCreateBatchParams,
  ): Promise<void> {
    const { i18n, $loadingToast, $errorToast, $successToast, $api, $accessor, $sentry } = this.app
    const { toastText } = options

    const toast = $loadingToast(toastText.pending)
    $accessor[RootMutationTypes.START_PREVENT_WINDOW_CLOSE](i18n.t('prevent_window_close'))

    try {
      const cleanPayload = {
        ...payload,
        entity: {
          ...payload.entity,
          isActive: true,
          isDefault: false,
        },
        periods: payload.periods.map(period => toIsoStringPeriod(period)),
      }

      await $api.post(`domains/${domainId}/calendar/batch/servicediscount/create`, cleanPayload)

      // @todo Don't have enough time to do this another way,
      //  but we should be able to fetch everything with a single HTTP request.
      await Promise.all(
        payload.services.map(
          async(serviceId) => await $accessor.calendar.fetchSingleServiceDiscounts(serviceId),
        ),
      )

      toast.goAway(0)
      $successToast(toastText.success)
    } catch (error) {
      $sentry.captureException(error)
      toast.goAway(0)
      $errorToast(toastText.error)
      throw error
    } finally {
      $accessor[RootMutationTypes.END_PREVENT_WINDOW_CLOSE]()
    }
  },

  async resetBatch(_, { domainId, payload, options }
    : IServiceDiscountResetBatchParams,
  ): Promise<void> {
    const { i18n, $loadingToast, $errorToast, $successToast, $api, $accessor, $sentry } = this.app
    const { toastText } = options

    const toast = $loadingToast(toastText.pending)
    $accessor[RootMutationTypes.START_PREVENT_WINDOW_CLOSE](i18n.t('prevent_window_close'))

    try {
      const cleanPayload = {
        ...payload,
        periods: payload.periods.map(period => toIsoStringPeriod(period)),
      }

      await $api.patch(`domains/${domainId}/calendar/batch/servicediscount/reset`, cleanPayload)

      // @todo Don't have enough time to do this another way,
      //  but we should be able to fetch everything with a single HTTP request.
      await Promise.all(
        payload.services.map(
          async(serviceId) => await $accessor.calendar.fetchSingleServiceDiscounts(serviceId),
        ),
      )
      toast.goAway(0)
      $successToast(toastText.success)
    } catch (error) {
      $sentry.captureException(error)
      toast.goAway(0)
      $errorToast(toastText.error)
      throw error
    } finally {
      $accessor[RootMutationTypes.END_PREVENT_WINDOW_CLOSE]()
    }
  },

  async postMany({ dispatch }, { domainId, payload }
    : ICalendarBatchActionParams<ServiceDiscountBatchCreateForm>,
  ): Promise<void> {
    const { i18n } = this.app

    const createBatchOptions: IServiceDiscountCreateBatchParams['options'] = {
      toastText: {
        pending: i18n.tc('toast.service_discount_creating', 2),
        success: i18n.tc('toast.service_discount_created', 2),
        error: i18n.tc('toast.service_discount_create_error', 2),
      },
    }

    await dispatch('createBatch', { domainId, payload, options: createBatchOptions })
  },

  async patchOne(_, { serviceDiscount }: { serviceDiscount: ServiceDiscount }): Promise<void> {
    const { $api, $accessor, $loadingToast, $errorToast, i18n, $successToast, $sentry } = this.app

    const toast = $loadingToast(i18n.t('toast.service_discount_updating'))
    try {
      const payload = {
        ...serviceDiscount,
        ...toIsoDateStringPeriod(serviceDiscount),
      }

      await $api.patch(
        `servicediscounts/${serviceDiscount.id}`,
        payload,
      )
      await $accessor.calendar.fetchSingleServiceDiscounts(serviceDiscount.service)
      toast.goAway(0)
      $successToast(i18n.t('toast.service_discount_updated'))
    } catch (e) {
      $sentry.captureException(e)
      toast.goAway(0)
      $errorToast(i18n.t('toast.service_discount_update_error'))
      throw e
    }
  },

  async deleteOne(_, { serviceDiscount }: { serviceDiscount: ServiceDiscount }): Promise<void> {
    const { $api, $accessor, $loadingToast, $errorToast, i18n, $successToast, $sentry } = this.app

    const toast = $loadingToast(i18n.tc('toast.service_discount_deleting', 1))
    try {
      await $api.delete(`servicediscounts/${serviceDiscount.id}`)
      await $accessor.calendar.fetchSingleServiceDiscounts(serviceDiscount.service)
      toast.goAway(0)
      $successToast(i18n.tc('toast.service_discount_deleted', 1))
    } catch (e) {
      $sentry.captureException(e)
      toast.goAway(0)
      $errorToast(i18n.tc('toast.service_discount_delete_error', 1))
      throw e
    }
  },

  async resetMany(
    { dispatch },
    { domainId, payload }: ICalendarBatchActionParams<ServiceDiscountBatchDeleteForm>)
    : Promise<void> {
    const { i18n } = this.app

    const batchOptions: IServiceDiscountResetBatchParams['options'] = {
      toastText: {
        pending: i18n.tc('toast.service_discount_deleting', 2),
        success: i18n.tc('toast.service_discount_deleted', 2),
        error: i18n.tc('toast.service_discount_delete_error', 2),
      },
    }
    await dispatch('resetBatch', { domainId, payload, options: batchOptions })
  },
})

export default actions
