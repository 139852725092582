import domainEquipmentsMutations from './mutations'
import domainEquipmentsGetters from './getters'
import domainEquipmentsActions from './actions'
import domainEquipmentsState from './state'

export const domainEquipments = {
  namespaced: true,
  state: domainEquipmentsState,
  actions: domainEquipmentsActions,
  mutations: domainEquipmentsMutations,
  getters: domainEquipmentsGetters,
}
