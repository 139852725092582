import { MarketplaceSpecificContent, ShortToISOEnum, Translation } from '~/types/Locale'
import { ModerationStatus } from './Moderation'
import { DateType } from '../DateType'
import { ExternalPlatformTypeEnum } from '~/types/models/Domain'
import { Money } from '~/types/models/Money'

export enum ServiceStatusEnum {
  DISABLED = 'DISABLED',
  MAINTENANCE = 'MAINTENANCE',
  ACTIVE = 'ACTIVE'
}

export enum ServiceStatusColorsEnum {
  DISABLED = 'red',
  MAINTENANCE = 'orange',
  ACTIVE = 'green'
}

export interface ServiceModeratableFields {
  name?: Translation
  infos?: Translation
  longDescription?: Translation
  shortDescription?: Translation
  seoMetaDesc?: Translation
  seoTitle?: Translation
  seoH1?: Translation
  specialOffers?: Translation
  included?: Translation
  notIncluded?: Translation
  petsAllowedDetail?: Translation
  otherMeals?: Translation
  additionalServices?: Translation
  bookingConditionsText?: Translation
  minimumStay?: Translation
  goodToKnow?: Translation
}

export interface Service {
  $isDirty: boolean
  additionalServices: Translation
  babyBedNumber: number
  bedroomNumber: number
  bookingConditionsText: Translation
  bunkBedNumber: number
  checkinEndTime: DateType
  checkinStartTime: DateType
  checkoutTime: DateType
  comment: string
  complementaryBedNumber: number
  createdAt: DateType
  deletedAt: DateType
  domain: number
  doubleBedNumber: number
  externalId: ServiceExternalId | null
  files: number[]
  goodToKnow: Translation
  groundSurface: number
  id: number
  included: Translation
  infos: Translation
  isActive: boolean
  isApproved: boolean
  isBreakfastIncluded: boolean
  isBreakfastInCommonSpace: boolean
  isBreakfastInService: boolean
  isExternal: boolean
  isDeleted: boolean
  isHighlighted: boolean
  isKitchenAvailable: boolean
  isNew: boolean
  isPetsAllowed: boolean
  isPublished: {
    [ShortToISOEnum.FR]: boolean,
    [ShortToISOEnum.EN]: boolean,
    [ShortToISOEnum.ES]: boolean,
    [ShortToISOEnum.NL]: boolean,
  }
  isSmokingAllowed: boolean
  isTouristicNoteEnabled: boolean
  label: string
  lastApprovedFields: ServiceModeratableFields
  longDescription: MarketplaceSpecificContent
  lowestServicePricing?: { baseRate: Money | null, additionalPricePerAdult: Money | null, guestBaseRateScope: number | null }
  maxAdultsAllowed: number
  maxChildrenAllowed: number
  maxInfantAllowed: number
  maxPeopleAllowed: number
  metadata: ServiceMetadata
  minimumAge: number
  minimumStay: Translation
  moderationStatus: ModerationStatus
  name: Translation
  notIncluded: Translation
  options: number[]
  otherMeals: Translation
  parkingDistance: number
  pendingModeration: ServiceModeratableFields
  petsAllowedDetail: Translation
  publishedOnMarketplace: {
    abcd: { 'fr-FR': boolean, 'en-US': boolean, 'es-ES': boolean, 'nl-NL': boolean, 'de-DE': boolean, 'it-IT': boolean }
    uscom: { 'fr-FR': boolean, 'en-US': boolean, 'es-ES': boolean, 'nl-NL': boolean, 'de-DE': boolean, 'it-IT': boolean }
    master: { 'fr-FR': boolean, 'en-US': boolean, 'es-ES': boolean, 'nl-NL': boolean, 'de-DE': boolean, 'it-IT': boolean }
  }
  roomNumber: number
  seoH1: Translation
  seoMetaDesc: Translation
  seoTitle: Translation
  serviceDiscounts: number[]
  serviceEquipments: number[]
  serviceLabels: number[]
  serviceThemes: number[]
  serviceType: number
  serviceUnits: number[]
  shortDescription: Translation
  simpleBedNumber: number
  slug: string
  sofaBedNumber: number
  specialOffers: Translation
  status: ServiceStatusEnum
  toiletDistance: number
  updatedAt: DateType
}

export interface ServiceSecureHolidayData {
  productId: string
  engineId: string
}

export interface ServiceEviivoData {
  ev_roomTypeId: string // eslint-disable-line camelcase
}

export interface ServiceKeBookingData {
  unitId: string
}

// Discriminating union type to allow us to enforce strict typings on the `data` property
export type ServiceExternalId = {
  platformId: ExternalPlatformTypeEnum.SH
  data: ServiceSecureHolidayData
} | {
  platformId: ExternalPlatformTypeEnum.EVIIVO
  data: ServiceEviivoData
} | {
  platformId: ExternalPlatformTypeEnum.KE
  data: ServiceKeBookingData
}

export type ServiceCreatePayload = Pick<Service,
  'label' | 'name' | 'isPublished' | 'domain' |
  'checkinStartTime' | 'checkinEndTime' | 'checkoutTime' |
  'minimumAge' | 'status' | 'additionalServices' |
  'bookingConditionsText' | 'goodToKnow' | 'included' | 'infos' | 'maxPeopleAllowed' |
  'longDescription' | 'minimumStay' | 'notIncluded' | 'otherMeals' |
  'petsAllowedDetail' | 'seoH1' | 'seoMetaDesc' | 'externalId' |
  'seoTitle' | 'shortDescription' | 'specialOffers' | 'publishedOnMarketplace'> & { serviceType: number | null }

export interface ServiceMetadata {
  stayType: 'PRIVATE_ROOM' | 'FULL_HOUSE'
}
