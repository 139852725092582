export interface User {
  id: number
  email: string
  roles: UserRoles[]
  username: string
}

export enum UserRoles {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN'
}
