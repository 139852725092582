

















import { computed, defineComponent } from '@nuxtjs/composition-api'
import Decimal from 'decimal.js-light'

export default defineComponent({
  name: 'PriceInput',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    useCents: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const computedValue = computed({
      get: () => props.useCents
        ? new Decimal(props.value).dividedBy(100).toNumber()
        : new Decimal(props.value).toNumber(),
      set: (val) => {
        if (val) {
          let formattedValue = new Decimal(val.toString().replace(',', '.')).toNumber()
          if (val < props.min) {
            formattedValue = props.min
          }
          if (props.max && val > props.max) {
            formattedValue = props.max
          }
          if (props.useCents) {
            emit('input', new Decimal(formattedValue).times(100).toNumber())
          } else {
            emit('input', formattedValue)
          }
        } else {
          emit('input', 0)
        }
      },
    })

    return { computedValue }
  },
})
