import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { Marketplace } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<Marketplace>(),

  async fetchOne({ commit }, marketplaceId: number) {
    const marketplace = await this.app.$api.get(`marketplaces/${marketplaceId}`)
    if (marketplace) {
      commit(BaseMutationTypes.CREATE, marketplace)
    }
  },

  async fetchAll({ commit }) {
    const res = await this.app.$api.get('marketplaces')
    if (res.data.length) {
      commit(BaseMutationTypes.CREATE_MANY, res.data)
    }
  },

  async fetchMany({ commit }, mkpIds: number[]) {
    if (mkpIds.length > 0) {
      const ids = mkpIds.join()
      const res = await this.app.$api.get(`marketplaces?filters[id_in]=${ids}&limit=${mkpIds.length}`)
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },
})

export default actions
