export default defineComponent({
  props: {
    value: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var innerValue = computed({
      get: function get() {
        return props.value;
      },
      set: function set(newValue) {
        return emit('input', newValue);
      }
    });
    return {
      innerValue: innerValue
    };
  }
});