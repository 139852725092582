import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import useAuth from '~/composable/useAuth';
import AppContentHeading from '~/components/content/AppContentHeading.vue';
export default defineComponent({
  name: 'NotFound',
  components: {
    AppContentHeading: AppContentHeading
  },
  setup: function setup() {
    var _useContext = useContext(),
        _useContext$app = _useContext.app,
        localePath = _useContext$app.localePath,
        $accessor = _useContext$app.$accessor;

    var _useAuth = useAuth(),
        isLoggedIn = _useAuth.isLoggedIn;

    var _useDomain = useDomain(),
        currentDomainId = _useDomain.currentDomainId;

    var isAdmin = computed(function () {
      return $accessor.users.currentUserIsAdmin;
    });
    var domain = computed(function () {
      return currentDomainId.value ? $accessor.domains.getOne(currentDomainId.value) : $accessor.domains.getFirst();
    });
    var provider = computed(function () {
      return $accessor.providers.currentProvider;
    });
    var redirectionPath = computed(function () {
      var _domain$value, _provider$value;

      if (!isLoggedIn) {
        return localePath('auth-login');
      }

      if (isAdmin.value) {
        return localePath('marketplace');
      }

      if ((_domain$value = domain.value) !== null && _domain$value !== void 0 && _domain$value.id && (_provider$value = provider.value) !== null && _provider$value !== void 0 && _provider$value.id) {
        return localePath({
          name: 'home',
          params: {
            provider: provider.value.id.toString(),
            domain: domain.value.id.toString()
          }
        });
      }

      return localePath('auth-login');
    });
    return {
      redirectionPath: redirectionPath
    };
  }
});