export type SupportedLocale = 'fr' | 'en' | 'es' | 'nl'

export type SupportedISOLocale = 'fr-FR' | 'en-US' | 'es-ES' | 'nl-NL'

export interface LocaleObject {
  code: string
  iso: string
  file: string
}

export enum ShortToRegionEnum {
  FR = 'fr_FR',
  EN = 'en_US',
  ES = 'es_ES',
  NL = 'nl_NL',
}

export enum ShortToISOEnum {
  FR = 'fr-FR',
  EN = 'en-US',
  ES = 'es-ES',
  NL = 'nl-NL',
}

export enum ISOToShortEnum {
  'fr-FR' = 'fr',
  'en-US' = 'en',
  'es-ES' = 'es',
  'nl-NL' = 'nl',
}

export interface Translation {
  [ShortToISOEnum.FR]: string | null
  [ShortToISOEnum.EN]: string | null
  [ShortToISOEnum.ES]: string | null
  [ShortToISOEnum.NL]: string | null
}

export interface LocaleStrings {
  [key: string]: string
}

export interface LocaleBooleans {
  [key: string]: boolean
}

export interface LocalizedState {
  abcd: {
    [ShortToISOEnum.FR]: boolean
    [ShortToISOEnum.EN]: boolean
    [ShortToISOEnum.ES]: boolean
    [ShortToISOEnum.NL]: boolean
  }
  uscom: {
    [ShortToISOEnum.FR]: boolean
    [ShortToISOEnum.EN]: boolean
    [ShortToISOEnum.ES]: boolean
    [ShortToISOEnum.NL]: boolean
  }
}

export interface MarketplaceSpecificContent {
  abcd: Translation
  uscom: Translation
  master: Translation
}
