






























































































































































































































































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import { BookingStatusEnum } from '~/types/models'
import useBookings from '~/composable/useBookings'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import LocationForm from '~/components/form/LocationForm.vue'
import PhoneField from '~/components/form/PhoneField.vue'
import StatusBadge from '~/components/badges/StatusBadge.vue'
import TableEmpty from '~/components/table/TableEmpty.vue'

export default defineComponent({
  name: 'CustomerDetailsPage',
  components: {
    AppContentHeading,
    LocationForm,
    PhoneField,
    StatusBadge,
    TableEmpty,
  },
  middleware: ['customersMiddleware', 'customerBookingsMiddleware', 'customerMarketplacesMiddleware', 'customerAddressMiddleware'],
  setup() {
    const { params, app: { $accessor } } = useContext()
    const { getTotalAmount, getFormattedGuests, getTranslatedStatus, getClassFromStatus, isCancelledBooking } = useBookings()

    const customer = computed(() => $accessor.customers.getOne(parseInt(params.value.id)))
    const customerAddress = computed(() => $accessor.addresses.getOne(customer.value?.billingAddress))
    const bookings = computed(() => $accessor.bookings.getWhereArray(booking => booking.customer === parseInt(params.value.id)))

    const getCustomerField = (customerId: number, field: string) => computed({
      get: () => $accessor.customers.getField(customerId)(field),
      set: value => {
        if (!$accessor.customers.getField(customerId)('$isDirty') && field !== '$isDirty') {
          $accessor.customers.UPDATE_FIELD({
            id: customerId,
            path: '$isDirty',
            value: true,
          })
        }
        return $accessor.customers.UPDATE_FIELD({
          id: customerId,
          path: field,
          value,
        })
      },
    })

    const patchCustomer = async() => {
      await $accessor.customers.patchOne({ id: customer.value.id, payload: customer.value })
    }

    return {
      customer,
      bookings,
      customerAddress,
      getCustomerField,
      patchCustomer,
      BookingStatusEnum,
      getTotalAmount,
      getFormattedGuests,
      getTranslatedStatus,
      getClassFromStatus,
      isCancelledBooking,
    }
  },
})
