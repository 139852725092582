import { Context } from '@nuxt/types'
import { actionTree, getAccessorType, mutationTree } from 'typed-vuex'
import { RootMutationTypes, RootState } from './types'
import { getAuthCookieFromContext, isValidAuthCookie } from '~/helpers'
import { modules } from './accessor-types'
import { ThreeDS2Data } from '~/types/3DS2'
import { TranslateResult } from 'vue-i18n'
import { PresenceStatus } from '~/types'
import Vue from 'vue'

export const state = (): RootState => ({
  threeDS2Data: {
    clientIPAddress: '',
  },
  isAppLoading: false,
  userPresenceStatus: {},
  currentUserPresenceStatus: PresenceStatus.ONLINE,
  ui: {
    preventWindowClose: null,
  },
})

export const mutations = mutationTree(state, {
  [RootMutationTypes.SET_3DS2_CLIENT_IP](storeState: RootState, ip: string) {
    storeState.threeDS2Data.clientIPAddress = ip
  },

  [RootMutationTypes.SET_3DS2_CLIENT_INFO](storeState: RootState, clientInfo: Omit<ThreeDS2Data, 'clientIPAddress'>) {
    storeState.threeDS2Data = {
      ...storeState.threeDS2Data,
      ...clientInfo,
      isComplete: Object.keys(clientInfo).every(key => clientInfo[key] !== ''),
    }
  },

  [RootMutationTypes.TOGGLE_APP_IS_LOADING](storeState: RootState) {
    storeState.isAppLoading = !storeState.isAppLoading
  },

  [RootMutationTypes.START_PREVENT_WINDOW_CLOSE](storeState: RootState, message: string | TranslateResult) {
    storeState.ui.preventWindowClose = message
  },

  [RootMutationTypes.END_PREVENT_WINDOW_CLOSE](storeState: RootState) {
    storeState.ui.preventWindowClose = null
  },

  [RootMutationTypes.PRESENCE_USER_CREATE](storeState: RootState, { id, providerId, status }
    : { id: number, providerId: number, status: PresenceStatus }) {
    storeState.userPresenceStatus = {
      ...storeState.userPresenceStatus,
      [id]: {
        providerId,
        status,
      },
    }
  },

  [RootMutationTypes.PRESENCE_USER_REMOVE](storeState: RootState, id: number) {
    Vue.delete(storeState.userPresenceStatus, id)
  },

  [RootMutationTypes.PRESENCE_USER_SET_STATUS](storeState: RootState, { id, status }: { id: number, status: PresenceStatus }) {
    if (storeState.userPresenceStatus[id]) {
      storeState.userPresenceStatus[id].status = status
    }
  },
  [RootMutationTypes.PRESENCE_CURRENT_USER_SET_STATUS](storeState: RootState, status: PresenceStatus) {
    storeState.currentUserPresenceStatus = status
  },
})

const logger: (str: string) => void | null = (str: string) => process.env.NODE_ENV === 'development'
  ? console.log(`%c [NuxtServerInit] ${str} `, 'background: #7f204e; color: #F5F5F5; padding: 2px') // eslint-disable-line
  : null

export const actions = actionTree({
  state,
  mutations,
}, {
  async nuxtServerInit(_, context: Context): Promise<void> {
    const authCookie = getAuthCookieFromContext(context)
    const { $accessor } = this.app

    const redirectIfLoggedOut: () => void = () => {
      if (!context.route.matched.some((r: any) => r.meta.isAuthRoute)) {
        context.redirect(`${context.app.localePath('auth-login')}?inactive=1`)
      }
    }

    if (context.$featureToggle.toggles.maintenanceMode) {
      if (!isValidAuthCookie(authCookie) || authCookie.user !== 20) {
        await $accessor.auth.logout()
        context.redirect(`${context.app.localePath('auth-login')}?maintenance=1`)
      }
    }

    let clientIP = ''
    if (context.req.headers && context.req.headers['x-real-ip']) {
      clientIP = context.req.headers['x-real-ip'] as string
      console.log(`x-real-ip is ${clientIP}`) // eslint-disable-line no-console
    } else {
      clientIP = (context.req.connection.remoteAddress || context.req.socket.remoteAddress) as string
      console.log(`Warning: no x-real-ip found, using remoteAddress: ${clientIP}`) // eslint-disable-line no-console
    }
    $accessor.SET_3DS2_CLIENT_IP(clientIP)

    try {
      if (isValidAuthCookie(authCookie)) {
        $accessor.auth.LOGIN(authCookie.user)
        await $accessor.users.fetchOne(authCookie.user)
        await $accessor.marketplaces.fetchAll()

        if ($accessor.users.currentUserIsProvider) {
          logger('Logged in as provider')
          return $accessor._fetchProviderInitialData(context)
        } else if ($accessor.users.currentUserIsAdmin) {
          logger('Logged in as admin')
          return $accessor._fetchAdminInitialData(context)
        }
      } else {
        throw new Error('No auth cookie found, redirecting to login page')
      }
    } catch (e) {
      await $accessor.auth.logout()
      // Don't redirect if route is auth route (auth/forgotPassword/resetPassword).
      redirectIfLoggedOut()
    }
  },

  async _fetchProviderAndDomain(_, providerId?: number): Promise<void> {
    if (providerId) {
      await this.app.$accessor.providers.fetchOneForCurrent(providerId)
      await this.app.$accessor.domains.fetchMany(this.app.$accessor.providers.currentProvider!.domains)
    } else {
      await Promise.all([
        this.app.$accessor.providers.fetchAll(),
        this.app.$accessor.domains.fetchAll(),
      ])
    }
  },

  async _fetchProviderInitialData(_, { route, redirect, app }: Context): Promise<void> {
    return new Promise((resolve) => {
      const routeProvider = parseInt(route.params.provider)
      const isAuthRoute = route.matched.some(r => r.meta.isAuthRoute)

      this.app.$accessor._fetchProviderAndDomain(routeProvider).then(() => {
        const routeDomain = route.params.domain || null

        const domain = routeDomain
          ? this.app.$accessor.domains.getOne(parseInt(route.params.domain))
          : this.app.$accessor.domains.getFirst()

        const { currentProvider } = this.app.$accessor.providers

        if (domain && currentProvider) {
          this.app.$accessor.domains.setActive([domain.id])
          if (domain.services.length > 0) {
            this.app.$accessor.calendar.UI_TOGGLE_ALL_SERVICES_IDS_FILTER(domain.services)
            this.app.$accessor.services.fetchMany(domain.services).then(() => {
              resolve()
            })
          } else {
            resolve()
          }
          if (!route.name || isAuthRoute) {
            redirect(app.localePath({
              name: 'home', params: {
                provider: currentProvider.id.toString(),
                domain: domain.id.toString(),
              },
            }))
          }
        }
        resolve()
      })
    })
  },

  async _fetchAdminInitialData(_, { route, redirect, app }: Context): Promise<void> {
    return new Promise((resolve) => {
      const routeProvider = route.params.provider || null
      const routeDomain = route.params.domain || null
      const isAdminRoute = route.matched.some(r => !!r.meta.isAdminRoute)
      const isAuthRoute = route.matched.some(r => r.meta.isAuthRoute)

      if (routeProvider && !isAdminRoute) {
        logger('_fetchAdminInitialData: route has provider and is not admin')
        this.app.$accessor._fetchProviderAndDomain(parseInt(routeProvider)).then(() => {
          this.app.$accessor.users.startImpersonatingProvider(parseInt(routeProvider))

          const domain = routeDomain
            ? this.app.$accessor.domains.getOne(parseInt(routeDomain))
            : this.app.$accessor.domains.getFirst()

          const { currentProvider } = this.app.$accessor.providers

          if (domain && currentProvider) {
            this.app.$accessor.domains.setActive([domain.id])
            this.app.$accessor.calendar.UI_TOGGLE_ALL_SERVICES_IDS_FILTER(domain.services)
            if (domain.services.length > 0) {
              this.app.$accessor.services.fetchMany(domain.services).then(() => {
                resolve()
              })
            } else {
              resolve()
            }
          } else {
            resolve()
          }
        })
      } else {
        logger('_fetchAdminInitialData: route has no provider provider or is not admin')
        if (!route.name || isAuthRoute) {
          logger('_fetchAdminInitialData: could not find route or route is auth and user is already logged in')
          redirect(app.localePath('marketplace'))
        }
        resolve()
      }
    })
  },
})

// This function only exist so we can enjoy typings on app.$accessor
// See https://typed-vuex.roe.dev/getting-started-nuxt#defining-the-accessor-type
export const accessorType = getAccessorType({
  state,
  mutations,
  actions,
  modules,
})
