
















import { defineComponent, onBeforeUnmount, onMounted, reactive, UnwrapRef, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'GiftCardsCheckPaymentPage',
  setup() {
    const { app: { $api, i18n, localePath, router, $loadingToast, $successToast }, params } = useContext()

    const state: UnwrapRef<{ tries: number, checkTimeout: number | null, paymentError: string | null}> = reactive({
      tries: 0,
      checkTimeout: null,
      paymentError: null,
    })

    // see https://github.com/Microsoft/TypeScript/pull/21496
    // toast has type of the return of $loadingToast.
    let toast: ReturnType<typeof $loadingToast>

    onMounted(() => {
      toast = $loadingToast(i18n.t('toast.payment_creating'))
      checkPayment()
    })
    onBeforeUnmount(() => window.clearTimeout(state.checkTimeout as number))

    async function checkPayment() {
      state.tries++
      try {
        const res = await $api.post('gcs_check', { bookingId: parseInt(params.value.bookingId) })
        if (!res.paid) {
          if (state.tries > 5) {
            handleError()
          } else {
            state.checkTimeout = window.setTimeout(checkPayment, 10000)
          }
        } else {
          router!.push(
            localePath({ name: 'marketplace-giftCards-bookings' }),
          )
          toast.goAway(0)
          $successToast(i18n.t('toast.payment_successful'))
        }
      } catch (e) {
        handleError()
      }
    }

    function handleError() {
      state.paymentError = i18n.t('giftcards.errors.payment_error') as string
    }

    return { state }
  },
})
