import { errorToast, loadingToast, successToast, warningToast } from '~/helpers/toast';
export default function (_ref, inject) {
  var app = _ref.app;
  inject('loadingToast', function (msg) {
    return loadingToast(msg, app);
  });
  inject('errorToast', function (msg) {
    return errorToast(msg, app);
  });
  inject('successToast', function (msg) {
    return successToast(msg, app);
  });
  inject('warningToast', function (msg) {
    return warningToast(msg, app);
  });
}