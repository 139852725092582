

















































































import { defineComponent } from '@nuxtjs/composition-api'
import useProvider from '~/composable/useProvider'
import { PayoutFrequencyEnum } from '~/types/models/Provider'

export default defineComponent({
  name: 'BankInfo',
  setup() {
    const { currentProviderId, getProviderField } = useProvider()

    return {
      currentProviderId,
      getProviderField,
      PayoutFrequencyEnum,
    }
  },
})
