import { Middleware } from '@nuxt/types'

const customerBookingsMiddleware: Middleware = async({ route, app: { $accessor } }) => {
  const customer = $accessor.customers.getOne(parseInt(route.params.id))
  if (customer.bookings && customer.bookings.length > 0) {
    const missingBookingsIds = customer.bookings.filter(id => !$accessor.bookings.getAllIds().includes(id))
    if (missingBookingsIds.length > 0) {
      await $accessor.bookings.fetchManyForCustomer(customer.id)
    }
  }
}

export default customerBookingsMiddleware
