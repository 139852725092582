import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es7.object.values.js";
import { PresenceStatus } from '~/types';
import { presenceStatusColors } from '~/helpers/constants';
import StatusBadge from '~/components/badges/StatusBadge.vue';
export default defineComponent({
  name: 'ProviderPresenceStatusBadge',
  components: {
    StatusBadge: StatusBadge
  },
  props: {
    providerId: {
      type: Number,
      default: null
    },
    labelAsTooltip: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var _useProvider = useProvider(),
        currentProviderId = _useProvider.currentProviderId;

    var _useContext = useContext(),
        $accessor = _useContext.app.$accessor;

    var providerId = computed(function () {
      return props.providerId || currentProviderId.value;
    });
    var presenceStatus = computed(function () {
      var userData = Object.values($accessor.userPresenceStatus).find(function (u) {
        return u.providerId === providerId.value;
      });
      return userData ? userData.status : PresenceStatus.OFFLINE;
    });
    var presenceStatusColor = computed(function () {
      return presenceStatusColors[presenceStatus.value];
    });
    return {
      presenceStatus: presenceStatus,
      presenceStatusColor: presenceStatusColor
    };
  }
});