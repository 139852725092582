import "core-js/modules/es6.regexp.replace.js";
import CancelBookingModal from '~/components/modal/CancelBookingModal.vue';
import { computed, defineComponent, reactive, useContext, onMounted } from '@nuxtjs/composition-api';
import { BookingStatusEnum } from '~/types/models';
export default defineComponent({
  name: 'BookingActions',
  components: {
    CancelBookingModal: CancelBookingModal
  },
  props: {
    booking: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var _useContext = useContext(),
        _useContext$app = _useContext.app,
        $accessor = _useContext$app.$accessor,
        router = _useContext$app.router,
        localePath = _useContext$app.localePath;

    var _useBookings = useBookings(),
        onToggleBookingStandby = _useBookings.onToggleBookingStandby;

    var state = reactive({
      cancelBookingModal: false
    });
    onMounted(function () {
      return state.cancelBookingModal = (router === null || router === void 0 ? void 0 : router.currentRoute.query.cancelBooking) === 'true';
    });
    var isActiveCancelModal = computed(function () {
      return state.cancelBookingModal;
    });
    var isAdmin = computed(function () {
      return $accessor.users.currentUserIsAdmin;
    });
    var canCancel = computed(function () {
      return isAdmin.value && props.booking.status !== BookingStatusEnum.BOOKING_CANCELLED_CUSTOMER && props.booking.status !== BookingStatusEnum.BOOKING_CANCELLED_PROVIDER;
    });

    function toggleCancelBookingModal() {
      if ((router === null || router === void 0 ? void 0 : router.currentRoute.query.cancelBooking) === 'true') {
        router === null || router === void 0 ? void 0 : router.replace(localePath({
          name: 'booking-id'
        }));
      }

      state.cancelBookingModal = !state.cancelBookingModal;
    }

    return {
      isAdmin: isAdmin,
      canCancel: canCancel,
      isActiveCancelModal: isActiveCancelModal,
      onToggleBookingStandby: onToggleBookingStandby,
      state: state,
      BookingStatusEnum: BookingStatusEnum,
      toggleCancelBookingModal: toggleCancelBookingModal
    };
  }
});