import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { resetState } from '~/helpers/store'
import { ServiceDiscount } from '~/types/models'
import baseState, { serviceDiscount } from './state'
import { DiscountsMutationTypes } from './types'

export const mutations = mutationTree(baseState, {
  ...createMutations<ServiceDiscount>(serviceDiscount),
  [DiscountsMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },

})

export default mutations
