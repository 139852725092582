





































































import { defineComponent, reactive, computed, useContext } from '@nuxtjs/composition-api'
import orderBy from 'lodash/orderBy'
import { OptionsCategoriesEnum, Option } from '~/types/models'
import useOptions from '~/composable/useOptions'
import OptionsList from '~/components/option/OptionsList.vue'
import HRSwitch from '~/components/HRSwitch.vue'

export default defineComponent({
  name: 'ServiceOptions',
  components: { OptionsList, HRSwitch },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { params, app: { $accessor } } = useContext()
    const {
      allAdditionalCosts: additionalCosts,
      availableOptionCategories,
      availableCostCategories,
      allOptions,
    } = useOptions()

    const state = reactive({
      isAddModalActive: false,
      addModalType: 'mandatory',
    })

    const getIsEnabled = (option: Option) => computed({
      get: () => option.appliesOnServices.includes(parseInt(params.value.id)),
      set: value => {
        const newOption = { ...option }
        if (value) {
          const newAppliesOnServices = [...newOption.appliesOnServices]
          newAppliesOnServices.push(parseInt(params.value.id))
          newOption.appliesOnServices = newAppliesOnServices
        } else {
          const newAppliesOnServices = newOption.appliesOnServices.filter(id => id !== parseInt(params.value.id))
          newOption.appliesOnServices = newAppliesOnServices
        }
        $accessor.options.patchOne(newOption)
      },
    })

    function showModal(type: string) {
      state.addModalType = type
      state.isAddModalActive = true
    }

    return {
      state,
      getIsEnabled,
      showModal,
      allOptions,
      additionalCosts,
      availableOptionCategories,
      OptionsCategoriesEnum,
      availableCostCategories,
      orderBy,
    }
  },
})
