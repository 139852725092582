












































































































import { defineComponent, computed, useContext } from '@nuxtjs/composition-api'
import orderBy from 'lodash/orderBy'
import { ThemeColors } from '~/types/Tailwind'
import { OptionsCategoriesEnum, Option } from '~/types/models'
import useOptions from '~/composable/useOptions'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import OptionsList from '~/components/option/OptionsList.vue'
import OptionCreateButtons from '~/components/option/OptionCreateButtons.vue'
import HRSwitch from '~/components/HRSwitch.vue'

export default defineComponent({
  name: 'OptionsListingPage',
  components: {
    AppContentHeading,
    OptionsList,
    OptionCreateButtons,
    HRSwitch,
  },
  middleware: ['domainOptionsMiddleware'],
  setup(_, { root: { $buefy, $i18n } }) {
    const { app: { $accessor } } = useContext()
    const {
      allOptions: options,
      allAdditionalCosts: additionalCosts,
      availableOptionCategories,
      availableCostCategories,
      getOptionField,
      allOptions,
      deleteOption,
    } = useOptions()

    const confirmDeleteOption = async(optionId: number) => {
      $buefy.dialog.confirm({
        title: $i18n.t('options.delete_title').toString(),
        message: $i18n.t('options.delete_message').toString(),
        confirmText: $i18n.t('options.delete_confirm').toString(),
        cancelText: $i18n.t('options.delete_cancel').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          await deleteOption(optionId)
        },
      })
    }

    const getIsEnabled= (option: Option) => computed({
      get: () => option.isActive,
      set: async(value) => {
        const newOption = {
          ...option,
          isActive: value,
        }
        await $accessor.options.patchOne(newOption)
      },
    })

    return {
      getOptionField,
      getIsEnabled,
      options,
      additionalCosts,
      availableOptionCategories,
      availableCostCategories,
      OptionsCategoriesEnum,
      orderBy,
      allOptions,
      confirmDeleteOption,
      ThemeColors,
    }
  },
})
