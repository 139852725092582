
























import { defineComponent } from '@nuxtjs/composition-api'
import TableSearch from '~/components/table/TableSearch.vue'
import ServiceTable from '~/components/service/ServiceTable.vue'

export default defineComponent({
  name: 'MarketplaceServices',
  components: {
    TableSearch,
    ServiceTable,
  },
  setup() {
    const baseUrl = 'services'

    return { baseUrl }
  },
})
