import { ServiceEvents } from '~/store/calendar/types'
import { CalendarShowDaysEnum, CalendarViewsEnum } from '~/types'
import { DateTime, Interval } from 'luxon'
import { getterTree } from 'typed-vuex'
import state from '~/store/calendar/state'
import { Period } from '~/types/models'
import { DateFormatEnum } from '~/helpers'

export const getters = getterTree(state, {
  getDateOverlapsNextMonth(state, { getCalendarDays }) {
    return getCalendarDays.some((date: DateTime) => date.month !== getCalendarDays[0].month)
  },

  getSelectedPeriods: (state): Period[] => state.commonForm.selectedPeriods,

  getMonthViewTitle: (state, { getDateOverlapsNextMonth }: { getDateOverlapsNextMonth: boolean }) => {
    return `${DateTime.fromJSDate(state.date).toLocaleString({ month: 'long' }) +
      (getDateOverlapsNextMonth
        ? ` / ${DateTime.fromJSDate(state.date).plus({ month: 1 })
          .toLocaleString({ month: 'long' })}`
        : '')} ${DateTime.fromJSDate(state.date).toLocaleString({ year: 'numeric' })}`
  },

  getCalendarPeriod: (_, getters): { start: string, end: string } => {
    let start = ''
    let end = ''
    if (getters.getCalendarDays[0]) {
      start = getters.getCalendarDays[0].toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT)
    }

    if (getters.getCalendarDays[getters.getCalendarDays.length - 1]) {
      end = getters.getCalendarDays[getters.getCalendarDays.length - 1].toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT)
    }
    return {
      start,
      end,
    }
  },

  getCalendarDays: (state) => {
    const { showDays } = state
    const stateDate = DateTime.fromJSDate(state.date)
    let start = stateDate
    let end = stateDate

    if (state.ui.views === CalendarViewsEnum.TWO_MONTHS) {
      start = stateDate.startOf('month')
      end = stateDate.plus({ months: 1 }).endOf('month')
    } else if (state.ui.views === CalendarViewsEnum.TWO_WEEKS) {
      if (showDays === 7) {
        end = stateDate.plus({ days: CalendarShowDaysEnum.TOUCH })
      } else {
        end = stateDate.plus({ days: CalendarShowDaysEnum.TWO_WEEKS })
      }
    }
    // Generate an interval from state.date to end, split it by days.
    return Interval.fromDateTimes(start, end)
      .splitBy({ days: 1 })
      .map(interval => interval.start)
  },

  getServiceEvents: (state) => (serviceId: number) => state.serviceEvents[serviceId] || {},

  getServiceEventsForDay: (_, { getServiceEvents }: { getServiceEvents: (serviceId: number) => ServiceEvents }) => (serviceId: number, day: DateTime) => getServiceEvents(serviceId)[day.toISODate()] || {},
})

export default getters
