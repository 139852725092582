








































































































































































































































































import { defineComponent } from '@nuxtjs/composition-api'
import { ServiceEquipmentTypeEnum } from '~/types/models'
import useServices from '~/composable/useServices'
import ServiceCatering from './ServiceCatering.vue'
import ServiceOutdoorEquipments from './ServiceOutdoorEquipments.vue'
import ServiceSpa from './ServiceSpa.vue'
import ServiceEquipmentTypeSelect from './ServiceEquipmentTypeSelect.vue'
import ServiceEquipmentTypeGroupSelect from './ServiceEquipmentTypeGroupSelect.vue'
import ServiceEquipmentCheckboxes from './ServiceEquipmentCheckboxes.vue'

export default defineComponent({
  name: 'ServiceEquipment',
  components: {
    ServiceCatering,
    ServiceOutdoorEquipments,
    ServiceSpa,
    ServiceEquipmentTypeSelect,
    ServiceEquipmentTypeGroupSelect,
    ServiceEquipmentCheckboxes,
  },
  beforeRouteLeave(_to, _from, next) {
    if (this.invalid) {
      this.$errorToast(this.$t('form.fix_errors'))
    } else {
      next()
    }
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    invalid: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { getCurrentServiceField: getField } = useServices()

    return {
      getField,
      ServiceEquipmentTypeEnum,
    }
  },
})
