export function sortDates(field: string) {
  return (a: Record<string, number>, b: Record<string, number>, asc: boolean): number => {
    if (asc) {
      return b[field] - a[field]
    }
    return a[field] - b[field]
  }
}

// FIXME broken since API update for amounts.
export function sortAmounts(field: string) {
  return (a: Record<string, number>, b: Record<string, number>, asc: boolean): number => {
    return asc ? (b[field] * 100) - (a[field] * 100) : (a[field] * 100) - (b[field] * 100)
  }
}

export enum FieldTypes {
  TYPE_STRING = 'TYPE_STRING',
  TYPE_BOOLEAN = 'TYPE_BOOLEAN',
  TYPE_NUMBER = 'TYPE_NUMBER',
  TYPE_DATE = 'TYPE_DATE',
  TYPE_OPTION = 'TYPE_OPTION',
  TYPE_SINGLE_CHOICE = 'TYPE_SINGLE_CHOICE',
}
