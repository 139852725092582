import { DateType } from '../DateType'

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER'
}

export interface Customer {
  id: number
  gender: GenderEnum
  firstName: string
  lastName: string
  phone: string
  email: string
  dateOfBirth: DateType
  billingAddress: number
  $isDirty?: boolean
  bookings?: number[]
  carts: number[]
  createdAt: DateType,
  isDeleted: boolean
  updatedAt: DateType
  user: number
}
