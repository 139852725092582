import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { Service } from '~/types/models'

export interface ServiceState extends BaseState<Service> {
  currentServices: Record<number, Service>
}

export enum ServicesMutationTypes {
  RESET_STATE = 'RESET_STATE',
  UPDATE_FIELD = 'UPDATE_FIELD',
  SET_CURRENT = 'SET_CURRENT'
}
