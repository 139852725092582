


































import { CalendarDrawerNameEnum } from '~/types/Calendar'
import { defineComponent, PropType, ref, useContext } from '@nuxtjs/composition-api'
import ClickOutside from 'vue-click-outside'
import useCalendar from '~/composable/useCalendar'

export default defineComponent({
  name: 'CalendarDrawer',
  directives: {
    ClickOutside,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    isClickOutside: {
      type: Boolean,
      default: true,
    },
    backgroundTitle: {
      type: String as PropType<CalendarDrawerNameEnum>,
      default: CalendarDrawerNameEnum.CREATE,
    },
  },
  setup(props,{ emit }) {
    const { app: { $accessor } } = useContext()
    const isActiveState = ref(props.isActive)
    const { ui } = useCalendar()

    function onClose() {
      $accessor.calendar.RESET_UI_DRAWER()
      emit('close')
    }

    function onClickOutside() {
      if (props.isClickOutside && props.isActive && isActiveState.value) {
        $accessor.calendar.RESET_UI_DRAWER()
        emit('click-outside')
      }
    }

    function getTitleClass(backgroundTitle: CalendarDrawerNameEnum) {
      switch (backgroundTitle) {
        case CalendarDrawerNameEnum.UNAVAILABILITY:
          return 'has-background-danger'
        case CalendarDrawerNameEnum.DELETE:
          return 'has-background-danger'
        case CalendarDrawerNameEnum.PRICING:
          return 'has-background-black'
        case CalendarDrawerNameEnum.BOOKING_CONDITION:
          return 'has-background-success'
        case CalendarDrawerNameEnum.BOOKING_CONDITION_DEFAULT:
          return 'CustomDurationDrawer'
        case CalendarDrawerNameEnum.DISCOUNT:
          return 'DiscountDrawer'
        case CalendarDrawerNameEnum.BOOKING:
          return 'has-background-blue'
        case CalendarDrawerNameEnum.ICALS:
          return 'has-background-grey'
        default:
          return 'has-background-primary'
      }
    }

    return {
      isActiveState,
      onClose,
      onClickOutside,
      getTitleClass,
      ui,
    }
  },
})
