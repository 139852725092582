import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { EmailLog } from '~/types/models'
import { resetState } from '~/helpers/store'
import { EmailLogMutationTypes } from './types'
import baseState, { emailLog } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<EmailLog>(emailLog),

  [EmailLogMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
})

export default mutations
