import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"}},[_c('BasePageSection',{staticClass:"is-size-5 has-text-primary has-text-weight-bold",attrs:{"aria-id":_vm.$t('billing.the_bank_transfer_settings'),"can-toggle":true}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("\n      "+_vm._s(_vm.$t('billing.the_bank_transfer_settings'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-full"},[_vm._v("\n        "+_vm._s(_vm.$t('billing.bank_transfer_frequency'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"column is-full"},[_c('BaseField',[_c('BRadio',{attrs:{"native-value":_vm.PayoutFrequencyEnum.WEEKLY,"size":"is-small"},model:{value:(_vm.getProviderField('payoutFrequency', _vm.currentProviderId).value),callback:function ($$v) {_vm.$set(_vm.getProviderField('payoutFrequency', _vm.currentProviderId), "value", $$v)},expression:"getProviderField('payoutFrequency', currentProviderId).value"}},[_vm._v("\n            "+_vm._s(_vm.$t('billing.frequency_weekly'))+"\n          ")]),_vm._v(" "),_c('BRadio',{attrs:{"native-value":_vm.PayoutFrequencyEnum.MONTHLY,"size":"is-small"},model:{value:(_vm.getProviderField('payoutFrequency', _vm.currentProviderId).value),callback:function ($$v) {_vm.$set(_vm.getProviderField('payoutFrequency', _vm.currentProviderId), "value", $$v)},expression:"getProviderField('payoutFrequency', currentProviderId).value"}},[_vm._v("\n            "+_vm._s(_vm.$t('billing.frequency_monthly'))+"\n          ")])],1)],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-full",attrs:{"name":"account_bic","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('billing.account_bic'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"id":"IBAN","valid":valid},model:{value:(_vm.getProviderField('bic', _vm.currentProviderId).value),callback:function ($$v) {_vm.$set(_vm.getProviderField('bic', _vm.currentProviderId), "value", $$v)},expression:"getProviderField('bic', currentProviderId).value"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-full",attrs:{"name":"account_iban","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('billing.account_iban'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"id":"IBAN","placeholder":"XXXX XXXX XXXX XXXX XXXX","size":"is-medium","type":"tel","valid":valid},model:{value:(_vm.getProviderField('iban', _vm.currentProviderId).value),callback:function ($$v) {_vm.$set(_vm.getProviderField('iban', _vm.currentProviderId), "value", $$v)},expression:"getProviderField('iban', currentProviderId).value"}})],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }