





















import { defineComponent, useContext } from '@nuxtjs/composition-api'
import DashboardWidget from '~/components/dashboard/DashboardWidget.vue'
import LastBookings from '~/components/dashboard/widgets/LastBookings.vue'
import TodayBookings from '~/components/dashboard/widgets/TodayBookings.vue'

export default defineComponent({
  name: 'Dashboard',
  components: {
    TodayBookings,
    LastBookings,
    DashboardWidget,
  },
  setup() {
    const { params, app: { router, localePath } } = useContext()

    const onRedirect = (redirection: 'last-bookings' |  'today-bookings') => {
      if (router) {
        const name = redirection === 'last-bookings' ? 'bookings' : 'booking-arrivals'
        router.push(localePath({ name, params: { provider: params.value.provider, domain: params.value.domain } }))
      }
    }

    return { onRedirect }
  },
})
