import { computed, ComputedRef, getCurrentInstance, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import orderBy from 'lodash/orderBy'
import { decode } from 'universal-base64'
import { Domain, DomainExternalId, DomainMetadata, EntityFile, EntityFileTypeEnum, ExternalPlatformTypeEnum } from '~/types/models'
import useI18n from '~/composable/useI18n'
import useFiles from '~/composable/useFiles'

interface FetchOptions {
  fetchServices?: boolean
  fetchAddress?: boolean
}

interface DomainHook {
  addCurrentDomainFile(file: EntityFile): void
  currentDomain: ComputedRef<Domain>
  currentDomainId: ComputedRef<number>
  currentDomainIsSh: ComputedRef<boolean>
  currentDomainPhotos: WritableComputedRef<EntityFile[]>
  deleteDomainPicture: (fileId: number) => Promise<void>
  deleteManyDomainPictures: (fileArray: EntityFile[]) => Promise<void>
  fetchMany(ids: number[], options?: FetchOptions): Promise<void>
  fetchOne(id: number, options?: FetchOptions): Promise<void>
  getCurrentDomainFile: (id: number) => WritableComputedRef<EntityFile>
  getDomainMetadataField: (field: keyof DomainMetadata) => WritableComputedRef<any>
  getDomainExternalIdObject: ComputedRef<DomainExternalId | null>
  getDomainField: (field: string) => WritableComputedRef<any>
  getDomainFormExternalIdField: (field: string, index: number) => WritableComputedRef<any>
  getDomainFormItemField: (field: string, index: number) => WritableComputedRef<any>
  getDomainTranslation: (field: string, locale?: string | undefined) => WritableComputedRef<string>
  orderableDomainPhotos: WritableComputedRef<EntityFile[]>
}

function useDomain(): DomainHook {
  const { app: { $accessor, i18n }, params } = useContext()
  const currentInstance = getCurrentInstance()

  const currentDomainId = computed(() => parseInt(params.value.domain))
  const currentDomain = computed(() => $accessor.domains.getOne(currentDomainId.value))
  const currentDomainIsSh = computed(() => getDomainExternalIdObject.value?.platformId === ExternalPlatformTypeEnum.SH)
  const { currentLocale } = useI18n()
  const { getFileField } = useFiles()

  async function fetchOne(id: number, { fetchServices = true, fetchAddress = false }) {
    await $accessor.domains.fetchOne(id)

    if (fetchServices && $accessor.domains.getOne(id).services.length > 0) {
      await $accessor.services.fetchMany($accessor.domains.getOne(id).services)
    }
    if (fetchAddress && $accessor.domains.getOne(id).address) {
      await $accessor.addresses.fetchOne($accessor.domains.getOne(id).address)
    }
  }

  async function fetchMany(ids: number[], { fetchServices = false, fetchAddress = false }) {
    if (ids.length > 0) {
      await $accessor.domains.fetchMany(ids)

      const domains = $accessor.domains.getWhereArray(domain => ids.includes(domain.id))
      const addressIds = fetchAddress ? domains.reduce((acc, domain) => [...acc, domain.address], [] as number[]) : []
      const serviceIds = fetchServices ? domains.reduce((acc, domain) => [...acc, ...domain.services], [] as number[]) : []

      await Promise.all([
        addressIds.length > 0 ?? $accessor.addresses.fetchMany(addressIds),
        serviceIds.length > 0 ?? $accessor.services.fetchMany(serviceIds),
      ])
    }
  }

  const getDomainField = (field: string) => computed({
    get: () => $accessor.domains.getField(currentDomainId.value)(field),
    set: (value) => {
      if (!$accessor.domains.getField(currentDomainId.value)('$isDirty') && field !== '$isDirty') {
        $accessor.domains.UPDATE_FIELD({
          id: currentDomainId.value,
          path: '$isDirty',
          value: true,
        })
      }
      return $accessor.domains.UPDATE_FIELD({
        id: currentDomainId.value,
        path: field,
        value,
      })
    },
  })

  function addCurrentDomainFile(file: EntityFile) {
    $accessor.entityFiles.CREATE(file)
    getDomainField('files').value = [
      ...getDomainField('files').value,
      file.id,
    ]
  }

  const getDomainTranslation: (field: string, locale?: string) => WritableComputedRef<string> = (field: string, locale: string = currentLocale) => computed({
    get: () => getDomainField(`${field}.${locale}-${locale.toUpperCase()}`).value,
    set: value => getDomainField(`${field}.${locale.toUpperCase()}`).value = value,
  })

  const getDomainFileByDomainId: (fileId: number, domainId: number) => WritableComputedRef<EntityFile> = (fileId: number, _domainId: number) => computed({
    get: () => $accessor.entityFiles.getOne(fileId),
    set: (val) => {
      if (val && Number.isNaN(val)) {
        return $accessor.entityFiles.UPDATE({
          id: fileId,
          payload: val,
        })
      }
    },
  })

  const getCurrentDomainFile: (id: number) => WritableComputedRef<EntityFile> = (id: number) => computed({
    get: () => getDomainFileByDomainId(id, currentDomainId.value).value,
    set: (val) => getDomainFileByDomainId(id, currentDomainId.value).value = {
      ...val,
      $isDirty: true,
    },
  })

  async function deleteDomainPicture(fileId: number): Promise<void> {
    if (currentInstance) {
      currentInstance.$buefy.dialog.confirm({
        title: i18n.t('dialog.picture_delete.title').toString(),
        message: i18n.t('dialog.picture_delete.message').toString(),
        confirmText: i18n.t('dialog.picture_delete.confirmText').toString(),
        cancelText: i18n.t('dialog.picture_delete.cancelText').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          await deleteDomainFile(fileId)
          if (currentDomainPhotos.value.length > 0) {
            await $accessor.entityFiles.resetIdx(currentDomainPhotos.value)
          }
        },
      })
    }
  }

  async function deleteManyDomainPictures(fileArray: EntityFile[]): Promise<void> {
    if (currentInstance) {
      currentInstance.$buefy.dialog.confirm({
        title: i18n.t('dialog.pictures_delete_many.title').toString(),
        message: i18n.t('dialog.pictures_delete_many.message').toString(),
        confirmText: i18n.t('dialog.pictures_delete_many.confirmText').toString(),
        cancelText: i18n.t('dialog.pictures_delete_many.cancelText').toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          await Promise.all(fileArray.map(file => deleteDomainFile(file.id)))
          if (currentDomainPhotos.value.length > 0) {
            await $accessor.entityFiles.resetIdx(currentDomainPhotos.value)
          }
        },
      })
    }
  }

  const getDomainFormItemField = (field: string, index: number) => computed({
    get: () => $accessor.domains.getCreateFormField(index)(field),
    set: value => $accessor.domains.UPDATE_CREATE_FORM_FIELD({
      index,
      path: field,
      value,
    }),
  })

  const getDomainFormExternalIdField = (field: string, index: number) => computed({
    get: () => $accessor.domains.getCreateFormExternalIdField(index)(field),
    set: value => {
      $accessor.domains.UPDATE_CREATE_FORM_EXTERNAL_ID_FIELD({
        index,
        path: field,
        value,
      })
    },
  })

  async function deleteDomainFile(id: number) {
    await $accessor.entityFiles.deleteFile(id)
    const domainFiles: number[] = getDomainField('files').value
    getDomainField('files').value = domainFiles.filter((file: number) => file !== id)
  }

  const currentDomainPhotos: WritableComputedRef<EntityFile[]> = computed({
    get: () => orderBy($accessor.entityFiles.getWhereArray((file) => file.domain === parseInt(params.value.domain) && file.type === EntityFileTypeEnum.DOMAIN_PICTURE), 'idx'),
    set: (values: EntityFile[]) => {
      const valuesWithIdxOrdered = values.map((item, index) => ({
        ...item,
        $isDirty: true,
        idx: index,
      }))
      valuesWithIdxOrdered.map(file => getFileField(file.id, 'idx').value = file.idx)
    },
  })

  /**
   * This looks a lot like currentDomainPhotos, the only difference is that is does not flag the file as '$dirty'
   * when setting a value. Its used to prevent enabling patching the Domain when re-ordering file, since this is
   * done in a separate request and should not trigger a PATCH of the file itself.
   */
  const orderableDomainPhotos = computed({
    get: () => orderBy($accessor.entityFiles.getWhereArray((file) => file.domain === parseInt(params.value.domain) && file.type === EntityFileTypeEnum.DOMAIN_PICTURE), 'idx'),
    set: (values: EntityFile[]) => {
      const valuesWithIdxOrdered = values.map((item, index) => ({
        ...item,
        idx: index,
      }))
      valuesWithIdxOrdered.forEach(file => $accessor.entityFiles.UPDATE_FIELD({
        id: file.id,
        path: 'idx',
        value: file.idx,
      }))
    },
  })

  const getDomainExternalIdObject = computed(() => {
    if (getDomainField('isExternal').value && getDomainField('externalId').value) {
      return JSON.parse(decode(getDomainField('externalId').value)) as DomainExternalId
    }
    return null
  })

  const getDomainMetadataField = (field: keyof DomainMetadata) => computed({
    get: () => getDomainField('metadata').value[field],
    set: (value) => {
      if (!$accessor.domains.getField(currentDomainId.value)('$isDirty')) {
        $accessor.domains.UPDATE_FIELD({
          id: currentDomainId.value,
          path: '$isDirty',
          value: true,
        })
      }
      return $accessor.domains.UPDATE_FIELD({
        id: currentDomainId.value,
        path: `metadata.${field}`,
        value,
      })
    },
  })

  return {
    addCurrentDomainFile,
    currentDomain,
    currentDomainId,
    currentDomainIsSh,
    currentDomainPhotos,
    deleteDomainPicture,
    deleteManyDomainPictures,
    fetchMany,
    fetchOne,
    getCurrentDomainFile,
    getDomainMetadataField,
    getDomainExternalIdObject,
    getDomainField,
    getDomainFormExternalIdField,
    getDomainFormItemField,
    getDomainTranslation,
    orderableDomainPhotos,
  }
}

export default useDomain
