







































import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api'
export default defineComponent({
  name: 'Modale',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, slots }) {
    const isModalActive = ref(props.isActive)
    const hasSlotHeader = computed(() => slots.title)
    watch(() => props.isActive, newValue => isModalActive.value = newValue)

    function close() {
      isModalActive.value = false
      emit('close', false)
    }
    return {
      close,
      hasSlotHeader,
      isModalActive,
    }
  },
})
