import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { Address, Provider, StructureStatusEnum } from '~/types/models'

export enum ProviderMutationTypes {
  SET_CURRENT = 'SET_CURRENT',
  RESET_STATE = 'RESET_STATE',
  UPDATE_FIELD = 'UPDATE_FIELD',
  UPDATE_CREATE_FORM_FIELD = 'UPDATE_CREATE_FORM_FIELD',
  RESET_CREATE_FORM = 'RESET_CREATE_FORM',
  SET_PAGINATION = 'SET_PAGINATION',
  START_IMPERSONATING = 'START_IMPERSONATING',
  STOP_IMPERSONATING = 'STOP_IMPERSONATING',
  ADD_PROVIDER_FILE = 'ADD_PROVIDER_FILE',
}

export interface ProviderCreateForm {
  bic: string | null,
  iban: string | null,
  cancelPolicy: string | null,
  email: string,
  hlcpId: string | null,
  hlcpPassword: string | null,
  legalContactTitle: string | null,
  legalContactFirstname: string | null,
  legalContactLastname: string | null,
  legalContactBirthDate: string | null,
  legalContactNationality: string | null,
  legalContactCountryOfResidence: string | null,
  mybeezboxId: string | null,
  mybeezboxPassword: string | null,
  mybeezboxUrl: string | null,
  payoutFrequency: string | null,
  phone: string | null,
  status: string | null,
  structureStatus: StructureStatusEnum,
  structureLegalForm?: string | null,
  structureCompanyName?: string | null,
  structureRCSNumber?: string | null,
  structureAPENAFCode?: string | null,
  unicstayContactTitle: string | null,
  unicstayContactFirstname: string | null,
  unicstayContactLastname: string | null,
  unicstayContactBirthdate: string | null,
  unicstayContactPhone: string | null,
  unicstayContactEmail: string | null,
  unicstayContactAddress: Address | null,
  users: number[] | [],
}

export interface ProviderState extends BaseState<Provider> {
  currentProvider: Provider | null,
  createForm: ProviderCreateForm
}
