const middleware = {}

middleware['bookingEmailLogMiddleware'] = require('../middleware/bookingEmailLogMiddleware.ts')
middleware['bookingEmailLogMiddleware'] = middleware['bookingEmailLogMiddleware'].default || middleware['bookingEmailLogMiddleware']

middleware['bookingGiftCardMiddleware'] = require('../middleware/bookingGiftCardMiddleware.ts')
middleware['bookingGiftCardMiddleware'] = middleware['bookingGiftCardMiddleware'].default || middleware['bookingGiftCardMiddleware']

middleware['bookingOptionsMiddleware'] = require('../middleware/bookingOptionsMiddleware.ts')
middleware['bookingOptionsMiddleware'] = middleware['bookingOptionsMiddleware'].default || middleware['bookingOptionsMiddleware']

middleware['bookingPaymentsMiddleware'] = require('../middleware/bookingPaymentsMiddleware.ts')
middleware['bookingPaymentsMiddleware'] = middleware['bookingPaymentsMiddleware'].default || middleware['bookingPaymentsMiddleware']

middleware['bookingProviderMiddleware'] = require('../middleware/bookingProviderMiddleware.ts')
middleware['bookingProviderMiddleware'] = middleware['bookingProviderMiddleware'].default || middleware['bookingProviderMiddleware']

middleware['bookingServiceFilesMiddelware'] = require('../middleware/bookingServiceFilesMiddelware.ts')
middleware['bookingServiceFilesMiddelware'] = middleware['bookingServiceFilesMiddelware'].default || middleware['bookingServiceFilesMiddelware']

middleware['calendarExternalSourcesMiddleware'] = require('../middleware/calendarExternalSourcesMiddleware.ts')
middleware['calendarExternalSourcesMiddleware'] = middleware['calendarExternalSourcesMiddleware'].default || middleware['calendarExternalSourcesMiddleware']

middleware['checkImpersonationMiddleware'] = require('../middleware/checkImpersonationMiddleware.ts')
middleware['checkImpersonationMiddleware'] = middleware['checkImpersonationMiddleware'].default || middleware['checkImpersonationMiddleware']

middleware['customerAddressMiddleware'] = require('../middleware/customerAddressMiddleware.ts')
middleware['customerAddressMiddleware'] = middleware['customerAddressMiddleware'].default || middleware['customerAddressMiddleware']

middleware['customerBookingsMiddleware'] = require('../middleware/customerBookingsMiddleware.ts')
middleware['customerBookingsMiddleware'] = middleware['customerBookingsMiddleware'].default || middleware['customerBookingsMiddleware']

middleware['customerMarketplacesMiddleware'] = require('../middleware/customerMarketplacesMiddleware.ts')
middleware['customerMarketplacesMiddleware'] = middleware['customerMarketplacesMiddleware'].default || middleware['customerMarketplacesMiddleware']

middleware['customersMiddleware'] = require('../middleware/customersMiddleware.ts')
middleware['customersMiddleware'] = middleware['customersMiddleware'].default || middleware['customersMiddleware']

middleware['defaultServicePricingsMiddleware'] = require('../middleware/defaultServicePricingsMiddleware.ts')
middleware['defaultServicePricingsMiddleware'] = middleware['defaultServicePricingsMiddleware'].default || middleware['defaultServicePricingsMiddleware']

middleware['domainActivitiesMiddleware'] = require('../middleware/domainActivitiesMiddleware.ts')
middleware['domainActivitiesMiddleware'] = middleware['domainActivitiesMiddleware'].default || middleware['domainActivitiesMiddleware']

middleware['domainAddressMiddleware'] = require('../middleware/domainAddressMiddleware.ts')
middleware['domainAddressMiddleware'] = middleware['domainAddressMiddleware'].default || middleware['domainAddressMiddleware']

middleware['domainEquipmentsMiddleware'] = require('../middleware/domainEquipmentsMiddleware.ts')
middleware['domainEquipmentsMiddleware'] = middleware['domainEquipmentsMiddleware'].default || middleware['domainEquipmentsMiddleware']

middleware['domainFilesMiddleware'] = require('../middleware/domainFilesMiddleware.ts')
middleware['domainFilesMiddleware'] = middleware['domainFilesMiddleware'].default || middleware['domainFilesMiddleware']

middleware['domainMarketplaceConfigurationMiddleware'] = require('../middleware/domainMarketplaceConfigurationMiddleware.ts')
middleware['domainMarketplaceConfigurationMiddleware'] = middleware['domainMarketplaceConfigurationMiddleware'].default || middleware['domainMarketplaceConfigurationMiddleware']

middleware['domainMiddleware'] = require('../middleware/domainMiddleware.ts')
middleware['domainMiddleware'] = middleware['domainMiddleware'].default || middleware['domainMiddleware']

middleware['domainOptionsMiddleware'] = require('../middleware/domainOptionsMiddleware.ts')
middleware['domainOptionsMiddleware'] = middleware['domainOptionsMiddleware'].default || middleware['domainOptionsMiddleware']

middleware['domainSustainabilityActionsMiddleware'] = require('../middleware/domainSustainabilityActionsMiddleware.ts')
middleware['domainSustainabilityActionsMiddleware'] = middleware['domainSustainabilityActionsMiddleware'].default || middleware['domainSustainabilityActionsMiddleware']

middleware['healthSafetyMeasuresMiddleware'] = require('../middleware/healthSafetyMeasuresMiddleware.ts')
middleware['healthSafetyMeasuresMiddleware'] = middleware['healthSafetyMeasuresMiddleware'].default || middleware['healthSafetyMeasuresMiddleware']

middleware['lastBookingsMiddleware'] = require('../middleware/lastBookingsMiddleware.ts')
middleware['lastBookingsMiddleware'] = middleware['lastBookingsMiddleware'].default || middleware['lastBookingsMiddleware']

middleware['marketplaceFilesMiddleware'] = require('../middleware/marketplaceFilesMiddleware.ts')
middleware['marketplaceFilesMiddleware'] = middleware['marketplaceFilesMiddleware'].default || middleware['marketplaceFilesMiddleware']

middleware['optionMiddleware'] = require('../middleware/optionMiddleware.ts')
middleware['optionMiddleware'] = middleware['optionMiddleware'].default || middleware['optionMiddleware']

middleware['promocodeMiddleware'] = require('../middleware/promocodeMiddleware.ts')
middleware['promocodeMiddleware'] = middleware['promocodeMiddleware'].default || middleware['promocodeMiddleware']

middleware['providerAddressMiddleware'] = require('../middleware/providerAddressMiddleware.ts')
middleware['providerAddressMiddleware'] = middleware['providerAddressMiddleware'].default || middleware['providerAddressMiddleware']

middleware['providerMiddleware'] = require('../middleware/providerMiddleware.ts')
middleware['providerMiddleware'] = middleware['providerMiddleware'].default || middleware['providerMiddleware']

middleware['serviceDetailMiddleware'] = require('../middleware/serviceDetailMiddleware.ts')
middleware['serviceDetailMiddleware'] = middleware['serviceDetailMiddleware'].default || middleware['serviceDetailMiddleware']

middleware['serviceFilesMiddleware'] = require('../middleware/serviceFilesMiddleware.ts')
middleware['serviceFilesMiddleware'] = middleware['serviceFilesMiddleware'].default || middleware['serviceFilesMiddleware']

middleware['serviceLabelsMiddleware'] = require('../middleware/serviceLabelsMiddleware.ts')
middleware['serviceLabelsMiddleware'] = middleware['serviceLabelsMiddleware'].default || middleware['serviceLabelsMiddleware']

middleware['servicesListing'] = require('../middleware/servicesListing.ts')
middleware['servicesListing'] = middleware['servicesListing'].default || middleware['servicesListing']

middleware['servicesMiddleware'] = require('../middleware/servicesMiddleware.ts')
middleware['servicesMiddleware'] = middleware['servicesMiddleware'].default || middleware['servicesMiddleware']

middleware['serviceUnitsMiddleware'] = require('../middleware/serviceUnitsMiddleware.ts')
middleware['serviceUnitsMiddleware'] = middleware['serviceUnitsMiddleware'].default || middleware['serviceUnitsMiddleware']

middleware['singleBookingMiddleware'] = require('../middleware/singleBookingMiddleware.ts')
middleware['singleBookingMiddleware'] = middleware['singleBookingMiddleware'].default || middleware['singleBookingMiddleware']

middleware['todaysBookingsMiddleware'] = require('../middleware/todaysBookingsMiddleware.ts')
middleware['todaysBookingsMiddleware'] = middleware['todaysBookingsMiddleware'].default || middleware['todaysBookingsMiddleware']

export default middleware
