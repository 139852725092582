
















































































import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import useImage from '~/composable/useImage'
import { Booking, BookingStatusClassesEnum, BookingStatusEnum } from '~/types/models/Booking'
import { EntityFileTypeEnum } from '~/types/models/EntityFile'
import { MarketplaceLabelAffilaeEnum } from '~/types/models/Marketplace'

export default defineComponent({
  name: 'BookingMarketplace',
  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },
  setup(props) {
    const { app: { i18n, $accessor } } = useContext()
    const { generateSignedUrl } = useImage()
    const isAdmin = computed(() => $accessor.users.currentUserIsAdmin)

    const marketplace = computed(() => $accessor.marketplaces.getOne(props.booking.marketplace))

    const displayMarketPlace = computed(() => {
      const isAffiliateMarketplace = Object.values(MarketplaceLabelAffilaeEnum).includes(marketplace.value.label as MarketplaceLabelAffilaeEnum)
      return isAffiliateMarketplace ||isAdmin.value
    })


    const giftCards = computed(() => $accessor.giftCards.getWhereArray(giftcard => props.booking.bookingGiftCards.some(bookingGiftCard => bookingGiftCard.giftCard === giftcard.id)))
    const payments = computed(() => $accessor.payments.getWhere((payment) => props.booking.payments.includes(payment.id)))
    const displayGiftCardInfo = computed(() => props.booking.status === BookingStatusEnum.BOOKING_CONFIRMED && $accessor.users.currentUserIsAdmin && props.booking.bookingGiftCards.length > 0)
    const marketplaceLogo = computed(() => $accessor.entityFiles.getFirstWhere(file => marketplace.value.files.includes(file.id) && file.type === EntityFileTypeEnum.MARKETPLACE_LOGO))
    const promocodes = computed(() => $accessor.promocodes.getWhereArray(promocode => props.booking.promocodes.includes(promocode.id)))

    const statusText = computed(() => {
      if (displayGiftCardInfo.value) {
        return i18n.t('booking.payment_method.giftcard_payment_received')
      }
      return i18n.t(`booking.status_${props.booking.status.toLowerCase()}`)
    })

    const priceBoxClasses = computed(() => {
      switch (props.booking.status) {
        case BookingStatusEnum.BOOKING_CONFIRMED:
          return 'has-text-success has-border-success'
        case BookingStatusEnum.BOOKING_STANDBY:
          return 'has-text-warning has-border-warning'
        case BookingStatusEnum.BOOKING_PENDING:
          return 'has-text-warning has-border-warning'
        default:
          return 'has-text-danger has-border-danger'
      }
    })

    const marketplaceImg = computed(() => {
      if (marketplaceLogo.value) {
        return generateSignedUrl(marketplaceLogo.value.url, 300, 300, {
          resizingType: 'fit',
        })
      }
    })

    return {
      marketplace,
      priceBoxClasses,
      marketplaceLogo,
      giftCards,
      payments,
      BookingStatusClassesEnum,
      BookingStatusEnum,
      statusText,
      displayGiftCardInfo,
      marketplaceImg,
      promocodes,
      isAdmin,
      displayMarketPlace,
    }
  },
})
