import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { Booking, BookingStatusEnum, filterBookingsStatusCompleteAccepted } from '~/types/models'
import { PatchPayload } from '~/helpers/api'
import { BookingMutationTypes } from '~/store/bookings/types'
import { state } from './state'
import { mutations } from './mutations'

interface CancelBookingPayload {
  bookingId: number
  amount: number
  currency: string
}

export const actions = actionTree({ state, mutations }, {
  ...createActions<Booking>(),
  async patchOne({ commit }, payload: PatchPayload<Booking>): Promise<Booking | undefined> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.domain_updating'))
    try {
      const res: Booking = await this.app.$api.patch<Booking>(`/bookings/${payload.id}`, payload)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.booking_updated'))
      commit(BaseMutationTypes.UPDATE, {
        id: payload.id,
        payload: res,
      })
      return res
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.booking_update_error'))
    }
  },

  async fetchOne({ commit }, bookingId: number) {
    try {
      const { $accessor, $api } = this.app
      const res: Booking = await $api.get(`/bookings/${bookingId}`)
      commit(BaseMutationTypes.CREATE, res)
      if (!$accessor.services.getAllIds().includes(res.service)) {
        await $accessor.services.fetchOne(res.service)
      }
      if (!$accessor.marketplaces.getAllIds().includes(res.marketplace)) {
        await $accessor.marketplaces.fetchOne(res.marketplace)
      }
      if (!$accessor.customers.getAllIds().includes(res.customer)) {
        await $accessor.customers.fetchOne(res.customer)
      }
      const optionForBookingsIds = res.options.map(option => option.option)
      const missingOptionsIds = optionForBookingsIds.filter(id => !$accessor.options.getAllIds().includes(id))
      if (missingOptionsIds.length) {
        await $accessor.options.fetchMany(missingOptionsIds)
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) // eslint-disable-line
      }
    }
  },

  async fetchManyForCustomer({ commit }, customerId: number) {
    const wantedStatuses = filterBookingsStatusCompleteAccepted()

    const res = await this.app.$api.get(`bookings?filters[customer.id]=${customerId}
    &filters[status_in]=${wantedStatuses}`)
    if (res.data.length) {
      await this.app.$accessor.bookings.fetchBookingsServices(res.data)
      commit(BaseMutationTypes.CREATE_MANY, res.data)
    }
  },

  async fetchManyWithServicesById({ commit }, bookingIds: number[]) {
    if (bookingIds.length) {
      const ids = bookingIds.join()
      try {
        const res = await this.app.$api.get(`bookings?filters[id_in]=${ids}&limit=${bookingIds.length}`)
        const bookings: Booking[] = res.data
        if (bookings.length) {
          await this.app.$accessor.bookings.fetchBookingsServices(bookings)
          commit(BaseMutationTypes.CREATE_MANY, bookings)
        }
      } catch (error) { } //eslint-disable-line
    }
  },

  async fetchMany({ commit }, bookingIds: number[]) {
    if (bookingIds.length) {
      const ids = bookingIds.join()
      const res = await this.app.$api.get(`bookings?filters[id_in]=${ids}&limit=${bookingIds.length}`)
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },

  async fetchManySoftDeletedIncluded({ commit }, bookingIds: number[]) {
    if (bookingIds.length) {
      const ids = bookingIds.join()
      const res = await this.app.$api.get(`bookings?filters[id_in]=${ids}&limit=${bookingIds.length}&includeSoftDeleted=true`)
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },

  async fetchBookingsServices(_, bookings: Booking[]) {
    const servicesIds = bookings.reduce((acc, booking) => [...acc, booking.service], [] as number[])
    const missingsServicesIds = servicesIds.filter(id => !this.app.$accessor.services.getAllIds().includes(id))
    if (missingsServicesIds.length) {
      await this.app.$accessor.services.fetchMany(missingsServicesIds)
    }
  },

  async fetchBookingsMarketplaces(_, bookings: Booking[]) {
    const marketplacesIds = bookings.reduce((acc, booking) => [...acc, booking.marketplace], [] as number[])
    const missingsMarketplaceIds = marketplacesIds.filter(id => !this.app.$accessor.marketplaces.getAllIds().includes(id))
    if (missingsMarketplaceIds.length) {
      await this.app.$accessor.marketplaces.fetchMany(missingsMarketplaceIds)
    }
  },

  async fetchBookingsPayments(_, bookings: Booking[]) {
    const paymentIds = bookings.reduce((acc, booking) => [...acc, ...booking.payments], [] as number[])
    const missingPaymentsIds = paymentIds.filter(id => !this.app.$accessor.payments.getAllIds().includes(id))
    if (missingPaymentsIds.length) {
      await this.app.$accessor.payments.fetchMany(missingPaymentsIds)
    }
  },

  async fetchBookingsCustomers(_, bookings: Booking[]) {
    const customersIds = bookings.map(booking => booking.customer)
    const missingCustomersIds = customersIds.filter(id => !this.app.$accessor.customers.getAllIds().includes(id))
    if (missingCustomersIds.length) {
      await this.app.$accessor.customers.fetchMany(missingCustomersIds)
    }
  },

  async fetchBookingsSpendings(_, bookings: Booking[]) {
    const giftCardSpendingIds = bookings.reduce((acc, booking) => [...acc, ...booking.giftCardSpendings], [] as number[])
    const missingSpendingsIds = giftCardSpendingIds.filter(id => !this.app.$accessor.giftCardSpendings.getAllIds().includes(id))
    if (missingSpendingsIds.length) {
      await this.app.$accessor.giftCardSpendings.fetchMany(missingSpendingsIds)
    }
  },

  async fetchBookingsDomains(_, bookings: Booking[]) {
    const domainIds = bookings.reduce((acc, booking) => [...acc, booking.domain], [] as number[])
    const missingDomainIds = domainIds.filter(id => !this.app.$accessor.domains.getAllIds().includes(id))
    if (missingDomainIds.length) {
      await this.app.$accessor.domains.fetchMany(missingDomainIds)
    }
  },

  async downloadCsvExport(_, domainId: number) {
    const toast = this.app.$loadingToast(this.app.i18n.t('booking.messages.export_csv_started'))
    try {
      const res = await this.app.$api.get(`bookings/export?domainId=${domainId}`)
      const data = new Blob([res])
      const a = document.createElement('a')
      a.download = 'bookings.csv'
      a.type = 'text/csv'
      a.target = '_blank'
      a.href = URL.createObjectURL(data)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      toast.goAway(0)
    } catch (e) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('booking.messages.export_csv_error'))
    }
  },

  async toggleBookingStandby({ commit }, { id, status }: { id: number, status: BookingStatusEnum }): Promise<Booking | undefined> {
    const toast = this.app.$loadingToast(this.app.i18n.t(status === BookingStatusEnum.BOOKING_STANDBY ? 'toast.booking_in_standby_loading' : 'toast.booking_resume_loading'))
    try {
      const booking = await this.app.$api.patch(`/bookings/${id}`, { status, force: true })
      commit(BaseMutationTypes.UPDATE, {
        id: booking.id, payload: booking,
      })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t(status === BookingStatusEnum.BOOKING_STANDBY ? 'toast.booking_in_standby' : 'toast.booking_resume'))
      return booking
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t(status === BookingStatusEnum.BOOKING_STANDBY ? 'toast.booking_in_standby_error' : 'toast.booking_resume_error'))
    }
  },


  async cancelBooking({ commit }, { bookingId, amount, currency }: CancelBookingPayload): Promise<Booking | undefined> {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.booking_canceled_loading'))
    try {
      const booking: Booking = await this.app.$api.post(
        `bookings/${bookingId}/cancel`,
        {
          refund: {
            amount,
            currency,
          },
        },
      )
      commit(BaseMutationTypes.UPDATE, {
        id: bookingId, payload: booking,
      })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.booking_canceled'))
      return booking
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.booking_canceled_error'))
    }
  },

  async fetchLastBookingsForDomain({ commit }, id: number): Promise<void> {
    try {
      const wantedStatuses = filterBookingsStatusCompleteAccepted()

      const res = await this.app.$api.get(`domains/${id}/bookings?limit=3&orderBy=createdAt&
      filters[status_in]=${wantedStatuses}`)
      const bookings: Booking[] = res.data
      if (bookings.length) {
        commit(BaseMutationTypes.CREATE_MANY, bookings)
        await this.app.$accessor.bookings.fetchBookingsServices(bookings)
        await this.app.$accessor.bookings.fetchBookingsMarketplaces(bookings)
        await this.app.$accessor.bookings.fetchBookingsCustomers(bookings)

        const bookingIds = bookings.map(booking => booking.id)
        commit(BookingMutationTypes.SET_LASTS_FOR_DOMAIN, bookingIds)
      }
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.booking_fetching'))
    }
  },

  async fetchTodayBookingsForDomain({ commit }, id: number): Promise<void> {
    try {
      const wantedStatuses = filterBookingsStatusCompleteAccepted()

      const today = new Date()
      today.setUTCHours(0, 0, 0, 0)
      const res = await this.app.$api.get(`domains/${id}/bookings?limit=3&orderBy=createdAt&filters[start]=${today.toISOString()}
      &filters[status_in]=${wantedStatuses}`)
      const bookings: Booking[] = res.data
      if (bookings.length) {
        commit(BaseMutationTypes.CREATE_MANY, bookings)
        await this.app.$accessor.bookings.fetchBookingsCustomers(bookings)
        const bookingIds: number[] = bookings.map(booking => booking.id)
        this.app.$accessor.bookings.SET_TODAY_FOR_DOMAIN(bookingIds)
      }
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.booking_fetching'))
    }
  },
})

export default actions
