import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { DateFormatEnum } from '~/helpers';
export default defineComponent({
  name: 'TodayBookings',
  setup: function setup() {
    var _useDomain = useDomain(),
        currentDomain = _useDomain.currentDomain;

    var _useContext = useContext(),
        _useContext$app = _useContext.app,
        $accessor = _useContext$app.$accessor,
        $dateTime = _useContext$app.$dateTime;

    var bookingFromId = function bookingFromId(id) {
      return $accessor.bookings.getOne(id);
    };

    var customerFromBookingId = function customerFromBookingId(id) {
      return $accessor.customers.getOne(bookingFromId(id).customer);
    };

    var serviceFromBookingId = function serviceFromBookingId(id) {
      return $accessor.services.getOne(bookingFromId(id).service);
    };

    var formatDateHour = function formatDateHour(date) {
      return $dateTime.fromISO(date).toFormat(DateFormatEnum.HOURS_MINUTES_H_SEPARATED_HOUR);
    };

    return {
      bookingFromId: bookingFromId,
      customerFromBookingId: customerFromBookingId,
      serviceFromBookingId: serviceFromBookingId,
      currentDomain: currentDomain.value,
      formatDateHour: formatDateHour
    };
  }
});