import { defineComponent } from '@nuxtjs/composition-api';
import TableEmpty from '~/components/table/TableEmpty.vue';
import StatusBadge from '~/components/badges/StatusBadge.vue';
export default defineComponent({
  name: 'ReviewsListPage',
  components: {
    TableEmpty: TableEmpty,
    StatusBadge: StatusBadge
  },
  middleware: ['servicesListing'],
  setup: function setup() {
    var _useDomain = useDomain(),
        currentDomainId = _useDomain.currentDomainId;

    var STATUS_MAP = {
      'NO_REPLY': 'Non',
      'REPLY_PENDING': 'En cours',
      'REPLY_ACCEPTED': 'Oui'
    };

    var _useReviews = useReviews(),
        getReviewersName = _useReviews.getReviewersName;

    var baseUrl = "/domains/".concat(currentDomainId.value, "/verifiedreviews");

    var _useTable = useTable(baseUrl, undefined, {
      orderBy: 'reviewDate'
    }),
        tableState = _useTable.state,
        eventSearchTable = _useTable.eventSearchTable,
        eventSortTable = _useTable.eventSortTable,
        refreshTable = _useTable.refreshTable;

    function getAnswerStatus(_ref) {
      var status = _ref.status;
      return STATUS_MAP[status];
    }

    return {
      tableState: tableState,
      getAnswerStatus: getAnswerStatus,
      eventSearchTable: eventSearchTable,
      eventSortTable: eventSortTable,
      refreshTable: refreshTable,
      getReviewersName: getReviewersName
    };
  }
});