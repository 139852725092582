import { actionTree } from 'typed-vuex'
import {
  BookingCondition, BookingConditionCalendar, ServiceDiscount, ServiceDiscountCalendar, ServicePricing,
  ServicePricingCalendar, ServiceUnitUnavailabilityCalendar, Unavailability,
} from '~/types/models'
import state from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { CalendarMutationTypes } from './types'
import uniqBy from 'lodash/uniqBy'

interface CalendarDataEntitiesByService {
  bookingConditions: Record<string, BookingCondition>,
  serviceDiscounts: Record<string, ServiceDiscount>,
  servicePricings: Record<string, ServicePricing>,
  unavailabilities: Record<number, Record<string, Unavailability[]>>
}

type CalendarAPIResponse = Record<number, CalendarDataEntitiesByService>

export const actions = actionTree({ state, getters, mutations }, {

  async fetchCalendarData({ commit, getters }, domainId: number): Promise<void> {
    commit(CalendarMutationTypes.UI_LOADING_START)

    // DO NOT REMOVE Start a console timer to monitor Calendar performances
    console.time('calendar_loading') // eslint-disable-line

    const { start, end } = getters.getCalendarPeriod
    const services = this.app.$accessor.domains.getOne(domainId)?.services
    if (domainId && services.length > 0) {
      // typing to illustrate the structure of the response
      const res: CalendarAPIResponse = await this.app.$api.get(`domains/${domainId}/calendar?start=${start}&end=${end}`)

      // creation of empty arrays in which we will merge each entity independently of each service id
      let unavailCalendarArray: Record<string, Unavailability[]>[] = []
      let calendarBookings: Record<string, BookingCondition>[] = []
      let calendarDiscounts: Record<string, ServiceDiscount>[] = []
      let calendarPricings: Record<string, ServicePricing>[] = []
      const formatedPricingsCalendarArray: ServicePricingCalendar[] = []
      const formatedConditionsCalendarArray: BookingConditionCalendar[] = []
      const formatedDiscountsCalendarArray: ServiceDiscountCalendar[] = []
      let formatedUnavailsCalendar: ServiceUnitUnavailabilityCalendar[] = []

      Object.keys(res).forEach(serviceId => {
        // recovery of each entity by service received in the request
        const bookingConditionsCalendar = res[parseInt(serviceId)].bookingConditions
        const pricingCalendar = res[parseInt(serviceId)].servicePricings
        const discountCalendar = res[parseInt(serviceId)].serviceDiscounts
        const resUnavail = res[parseInt(serviceId)].unavailabilities

        calendarDiscounts = [...calendarDiscounts, ...[discountCalendar]]
        calendarPricings = [...calendarPricings, ...[pricingCalendar]]
        calendarBookings = [...calendarBookings, ...[bookingConditionsCalendar]]

        // formatting constants for each entity for the calendar specific store
        const formattedPricing: ServicePricingCalendar = {
          service: parseInt(serviceId),
          calendar: pricingCalendar,
        }
        formatedPricingsCalendarArray.push(formattedPricing)

        const formattedBookingConditions: BookingConditionCalendar = {
          calendar: bookingConditionsCalendar,
          service: parseInt(serviceId),
        }
        formatedConditionsCalendarArray.push(formattedBookingConditions)

        const formattedDiscounts: ServiceDiscountCalendar = {
          calendar: discountCalendar,
          service: parseInt(serviceId),
        }
        formatedDiscountsCalendarArray.push(formattedDiscounts)

        const unavailsCalendarByUnit = Object.entries(resUnavail).map(entry => {
          const [serviceUnitId, unavailabilities] = entry
          return {
            calendar: unavailabilities,
            serviceUnit: parseInt(serviceUnitId),
          }
        })
        formatedUnavailsCalendar = [...formatedUnavailsCalendar, ...unavailsCalendarByUnit]

        // formatting unavails to add them to the calendar store
        const unavailsToCommit = unavailsCalendarByUnit.reduce((acc, unavail) => ({ ...acc, ...unavail }), []) as unknown as { calendar: any }

        // we merge all unavails together independently of the unit id to transform them into Unavailability[]
        unavailCalendarArray = [...unavailCalendarArray, ...[unavailsToCommit.calendar]]
      })

      commit(CalendarMutationTypes.SET_MANY_ALL_ENTITIES, {
        pricingsCalendar: formatedPricingsCalendarArray,
        bookingConditionCalendar: formatedConditionsCalendarArray,
        discountsCalendar: formatedDiscountsCalendarArray,
        unavailabilitiesCalendar: formatedUnavailsCalendar,
      })

      // formatting unvailsCalendar to transform them into Unavailability[]
      const unavailsReduced = unavailCalendarArray
        .map(serviceUnitUnavailCalendar => Object.values(serviceUnitUnavailCalendar)
          .reduce((acc, el) => [...acc, ...el]))

      const formatingUnavails = unavailsReduced.reduce((acc, UnavailArray) => [...acc, ...UnavailArray])

      //remove duplicates to put them in the unavails store
      const uniqUnavails = uniqBy(formatingUnavails, 'id')
      this.app.$accessor.unavailabilities.RESET_STATE()
      this.app.$accessor.unavailabilities.createManyUnavails(uniqUnavails)

      // calendarPricings : Record<string, ServicePricing>[] formating => servicePricings[]
      // retrive the object ServicePricing to commit them in servicePricing store
      // retrieve the object (Record<string, ServicePricing>) recover the object without the key (number) to access the pricing afterward
      const servicePricingsCalendar = Object.values(calendarPricings)
        .map(servicePricingCalendar => Object.values(servicePricingCalendar))

      // reduce servicePricingsCalendar to have ServicePricing[]
      const servicePrings = servicePricingsCalendar.reduce((acc, pricing) => [...acc, ...pricing])

      //removed duplicates servicePricings before commit them.
      const uniqPricings = uniqBy(servicePrings, 'id')

      // need to do same thing with discount
      const serviceDiscountsCalendar = Object.values(calendarDiscounts)
        .map(serviceDiscountCalendar => Object.values(serviceDiscountCalendar))

      const serviceDiscounts = serviceDiscountsCalendar.reduce((acc, discount) => [...acc, ...discount])

      const uniqDiscounts = uniqBy(serviceDiscounts, 'id')

      // need to do same thing with bookings conditions
      const bookingConditionsCalendar = Object.values(calendarBookings)
        .map(bookingConditionCalendar => Object.values(bookingConditionCalendar))

      const bookingConditions = bookingConditionsCalendar.reduce((acc, condition) => [...acc, ...condition])

      const uniqConditions = uniqBy(bookingConditions, 'id')


      // fetch bookings in unavails
      const bookingsIds = uniqUnavails.filter(unavail => unavail.booking)
        .map(unavail => unavail.booking) as unknown as number[]
      const missingBookingIds = [...new Set(bookingsIds.filter(id => !this.app.$accessor.bookings.getAllIds()
        .includes(id)))]
      if (missingBookingIds.length > 0) {
        await this.app.$accessor.bookings.fetchManySoftDeletedIncluded(missingBookingIds)
      }

      //fetchCustomers
      const customerIds = bookingsIds.map(id => this.app.$accessor.bookings.getOne(id).customer)
      const missingsCustomerIds = customerIds.filter(id => !this.app.$accessor.customers.getAllIds().includes(id))
      if (missingsCustomerIds.length > 0) {
        await this.app.$accessor.customers.fetchMany(missingsCustomerIds)
      }

      if (uniqPricings.length > 0) {
        this.app.$accessor.servicePricings.CREATE_MANY(uniqPricings)
      }

      if (uniqConditions.length > 0) {
        this.app.$accessor.bookingConditions.CREATE_MANY(uniqConditions)
      }

      if (uniqDiscounts.length > 0) {
        this.app.$accessor.serviceDiscounts.CREATE_MANY(uniqDiscounts)
      }

    }
    // DO NOT REMOVE End the console timer
    console.timeEnd('calendar_loading') // eslint-disable-line
    commit(CalendarMutationTypes.UI_LOADING_END)
  },

  async fetchSingleUnitUnavailabilities({ commit, getters }, {
    serviceId, serviceUnitId,
  }: { serviceId: number, serviceUnitId: number }): Promise<void> {
    const { start, end } = getters.getCalendarPeriod

    const unavailabilityCalendar: Record<string, Unavailability[]> = await this.app.$api.get(`services/${serviceId}/units/${serviceUnitId}/unavailabilitycalendar?start=${start}&end=${end}`)

    const unavails = Object.values(unavailabilityCalendar).reduce((acc, unavail) => [...acc, ...unavail])
    const uniqUnavail = uniqBy(unavails, 'id')
    this.app.$accessor.unavailabilities.createMany(uniqUnavail)

    commit(CalendarMutationTypes.UPDATE_UNAVAILABILITY_CALENDAR, {
      payload: unavailabilityCalendar, serviceUnit: serviceUnitId,
    })
  },

  async fetchSingleServiceBookingConditions({ commit, getters }, serviceId: number): Promise<void> {
    commit(CalendarMutationTypes.UI_LOADING_START)

    const { start, end } = getters.getCalendarPeriod

    const bookingConditionsCalendar: Record<string, BookingCondition> = await this.app.$api.get(`services/${serviceId}/conditioncalendar?start=${start}&end=${end}`)
    const formattedBookingConditions: BookingConditionCalendar = {
      calendar: bookingConditionsCalendar,
      service: serviceId,
    }

    const uniqConditions = uniqBy(Object.values(bookingConditionsCalendar), 'id')
    this.app.$accessor.bookingConditions.createMany(uniqConditions)

    commit(CalendarMutationTypes.SET_BOOKING_CONDITIONS_CALENDAR, formattedBookingConditions)
    commit(CalendarMutationTypes.UI_LOADING_END)
  },

  async fetchSingleServicePricings({ commit, getters }, serviceId: number): Promise<void> {
    commit(CalendarMutationTypes.UI_LOADING_START)

    const { start, end } = getters.getCalendarPeriod

    const pricingCalendar: Record<string, ServicePricing> = await this.app.$api.get(`services/${serviceId}/pricingcalendar?start=${start}&end=${end}`)
    const formattedPricing: ServicePricingCalendar = {
      calendar: pricingCalendar,
      service: serviceId,
    }

    const uniqPricings = uniqBy(Object.values(pricingCalendar), 'id')
    this.app.$accessor.servicePricings.createMany(uniqPricings)
    commit(CalendarMutationTypes.SET_SERVICE_PRICINGS_CALENDAR, formattedPricing)
    commit(CalendarMutationTypes.UI_LOADING_END)
  },

  async fetchSingleServiceDiscounts({ commit, getters }, serviceId: number): Promise<void> {
    commit(CalendarMutationTypes.UI_LOADING_START)

    const { start, end } = getters.getCalendarPeriod

    const discountCalendar: Record<string, ServiceDiscount> = await this.app.$api.get(`services/${serviceId}/discountcalendar?start=${start}&end=${end}`)
    const formattedDiscounts: ServiceDiscountCalendar = {
      calendar: discountCalendar,
      service: serviceId,
    }

    const uniqDiscounts = uniqBy(Object.values(discountCalendar), 'id')
    this.app.$accessor.serviceDiscounts.createMany(uniqDiscounts)

    commit(CalendarMutationTypes.SET_DISCOUNTS_CALENDAR, formattedDiscounts)
    commit(CalendarMutationTypes.UI_LOADING_END)
  },
})

export default actions
