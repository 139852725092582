










































































































































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import {
  DomainEquipmentEntry,
  DomainActivity,
  DomainActivityEntry,
  NewDomainActivityEntry,
  NewDomainEquipmentEntry,
  DomainEquipment,
} from '~/types/models'
import { PatchPayload } from '~/helpers/api'
import useDomainEquipments from '~/composable/useDomainEquipments'
import useDomainActivities from '~/composable/useDomainActivities'
import DomainEquipmentsOrActivities from '~/components/establishment/DomainEquipmentsOrActivities.vue'
import EstablishmentHealthSafetyMeasures from '~/components/establishment/EstablishmentHealthSafetyMeasures.vue'
import DomainAmenitiesModal from './DomainAmenitiesModal.vue'

export default defineComponent({
  name: 'DomainEquipment',
  components: {
    DomainEquipmentsOrActivities,
    EstablishmentHealthSafetyMeasures,
    DomainAmenitiesModal,
  },
  middleware: [
    'domainEquipmentsMiddleware',
    'domainActivitiesMiddleware',
    'healthSafetyMeasuresMiddleware',
  ],
  setup() {
    const {
      params,
      app: { $accessor },
    } = useContext()
    const {
      availableEquipments,
      createEquipmentEntry,
      deleteEquipmentEntry,
      equipmentEntries,
      isEquipmentEntry,
      patchEquipmentEntry,
    } = useDomainEquipments()
    const {
      nearbyActivities,
      availableOnsiteActivities,
      nearbyEntries,
      onsiteEntries,
      deleteActivityEntry,
      createActivityEntry,
      patchActivityEntry,
    } = useDomainActivities()

    const availableForDomain = computed(() => [...availableEquipments.value, ...availableOnsiteActivities.value])
    const currentDomainEquipments = computed(() => [...equipmentEntries.value, ...onsiteEntries.value])
    const domainId = computed(() => parseInt(params.value.domain))

    const healthSafetyMeasures = computed(() => Object.values($accessor.healthSafetyMeasures.entities.byId))

    // here we determine type of item to delete with his composition
    const onDeleteEquipmentOrActivityEntry = async(item: DomainEquipmentEntry | DomainActivityEntry) => {
      if (item.id) {
        if (isEquipmentEntry(item)) {
          await deleteEquipmentEntry(item.id)
          return
        }
        await deleteActivityEntry(item.id)
      }
    }

    const onSelectItemEntry = async(item: DomainEquipment | DomainActivity) => {
      if (item.id) {
        // item.type => DomainActivity
        if ('type' in item) {
          await onSelectActivityEntry(item)
          // domainEquipment
        } else {
          const payload: NewDomainEquipmentEntry = {
            domainEquipment: item.id,
            domain: domainId.value,
            isFree: true,
          }
          await createEquipmentEntry(payload)
        }
      }
    }

    const onUpdateEquipmentPricing = async({ item, newValue }: { item: DomainEquipmentEntry, newValue: boolean }) => {
      const payload: PatchPayload<DomainEquipmentEntry> = {
        domain: domainId.value,
        isFree: newValue,
        id: item.id,
      }
      await patchEquipmentEntry(payload)
    }

    const onUpdateActivityPricing = async({ item, newValue }: { item: DomainActivityEntry, newValue: boolean }) => {
      const payload: PatchPayload<DomainActivityEntry> = {
        domain: domainId.value,
        isFree: newValue,
        id: item.id,
      }
      await patchActivityEntry(payload)
    }

    const onUpdateEntry = async({ item, newValue }: { item: DomainEquipmentEntry | DomainActivityEntry, newValue: boolean }) => {
      if (isEquipmentEntry(item)) {
        await onUpdateEquipmentPricing({ item, newValue })
        return
      }
      await onUpdateActivityPricing({ item, newValue })
    }

    const onDeleteActivityEntry = async(item: DomainActivity) => await deleteActivityEntry(item.id)

    const onSelectActivityEntry = async(item: DomainActivity) => {
      const payload: NewDomainActivityEntry = {
        domainActivity: item.id,
        domain: domainId.value,
        isFree: false,
      }
      await createActivityEntry(payload)
    }

    return {
      availableEquipments,
      onUpdateEquipmentPricing,
      onDeleteActivityEntry,
      onUpdateActivityPricing,
      onSelectActivityEntry,
      healthSafetyMeasures,
      equipmentEntries,
      nearbyEntries,
      nearbyActivities,
      onsiteEntries,
      currentDomainEquipments,
      onDeleteEquipmentOrActivityEntry,
      onSelectItemEntry,
      onUpdateEntry,
      availableForDomain,
    }
  },
})
