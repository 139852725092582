import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { DomainEquipment, DomainEquipmentEntry, NewDomainEquipmentEntry } from '~/types/models'
import { PatchPayload } from '~/helpers/api'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<DomainEquipment>(),

  async deleteOne({ commit }, { id, domainId }: { id: number, domainId: number }) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.common_deleting'))
    try {
      await this.app.$api.delete(`domains/${domainId}/amenities/${id}`)
      commit(BaseMutationTypes.DELETE, id)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.common_deleted'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.common_deleting_error'))
    }
  },

  async postOne({ commit }, payload: NewDomainEquipmentEntry) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.common_creating'))
    try {
      const res = await this.app.$api.post(`domains/${payload.domain}/amenities/`, payload)
      commit(BaseMutationTypes.CREATE, res)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.common_created'))

    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.common_creating_error'))
    }
  },

  async patchOne({ commit }, payload: PatchPayload<DomainEquipmentEntry>) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.common_updating'))
    try {
      const res = await this.app.$api.patch(`domains/${payload.domain}/amenities/${payload.id}`, payload)
      commit(BaseMutationTypes.UPDATE, { id: payload.id, payload: res })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.common_updated'))

    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.common_updating_error'))
    }
  },
})

export default actions
