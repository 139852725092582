
















































import { defineComponent, useContext } from '@nuxtjs/composition-api'
import CallToActionButton from '~/components/buttons/CallToActionButton.vue'
import OptionDescriptionForm from '~/components/option/OptionDescriptionForm.vue'
import OptionPriceForm from '~/components/option/OptionPriceForm.vue'
import OptionConditionsForm from '~/components/option/OptionConditionsForm.vue'
import OptionApplicableServicesForm from './OptionApplicableServicesForm.vue'

export default defineComponent({
  name: 'OptionModal',
  components: {
    CallToActionButton,
    OptionDescriptionForm,
    OptionPriceForm,
    OptionConditionsForm,
    OptionApplicableServicesForm,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionId: {
      required: false,
      default: null,
      validator: (prop: number | null) => typeof prop === 'number' || prop === null,
    },
  },
  setup(_, { emit }) {
    const { app: { $accessor }, params } = useContext()
    async function submit() {
      $accessor.options.UPDATE_CREATE_FORM_FIELD({
        path: 'domain',
        value: parseInt(params.value.domain),
      })
      await $accessor.options.postOne()
      emit('update:active', false)
    }

    return { submit }
  },
})
