













































































































import { defineComponent } from '@nuxtjs/composition-api'
import { serviceStatus } from '~/helpers/constants'
import useServices from '~/composable/useServices'
import StatusField from '~/components/form/StatusField.vue'
import LanguagePublicationField from '~/components/form/LanguagePublicationField.vue'
import SymbolField from '~/components/content/SymbolField.vue'
import ExternalConnectivityServiceInfo from '~/components/service/ExternalConnectivityServiceInfo.vue'
import HRSwitch from '~/components/HRSwitch.vue'

export default defineComponent({
  name: 'MkpServiceConfiguration',
  components: {
    ExternalConnectivityServiceInfo,
    StatusField,
    LanguagePublicationField,
    SymbolField,
    HRSwitch,
  },
  props: {
    serviceId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { getCurrentServiceField: getField } = useServices()

    return {
      serviceStatus,
      getField,
    }
  },
})
