import { computed, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import { EntityFile } from '~/types/models'
import useI18n from './useI18n'

export interface FileHook {
  toBase64: (file: Blob) => Promise<unknown>
  getCurrentFileTranslation: (id: number, field: string) => WritableComputedRef<string>
  getFileField: (id: number, field: string) => WritableComputedRef<any>
  postOne: (formData: FormData) => Promise<EntityFile>
  patchOne: (file: EntityFile) => Promise<void>
  deleteOne: (id: number) => Promise<void>
}

function useFiles(): FileHook {
  const { app: { $accessor, $translateEntityField } } = useContext()
  const { currentLocale } = useI18n()

  const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const postOne = async(formData: FormData) => $accessor.entityFiles.postOne(formData)

  const patchOne = async(file: EntityFile) => $accessor.entityFiles.patchOne({ id: file.id, payload: file })

  const deleteOne = async(id: number) => $accessor.entityFiles.deleteFile(id)

  const getFileField: (id: number, field: string) => WritableComputedRef<any> = (id: number, field: string) => computed({
    get: () => $accessor.entityFiles.getField(id)(field),
    set: val => {
      if (!$accessor.entityFiles.getField(id)('$isDirty') && field !== '$isDirty') {
        $accessor.entityFiles.UPDATE_FIELD({
          id,
          path: '$isDirty',
          value: true,
        })
      }
      return $accessor.entityFiles.UPDATE_FIELD({
        id,
        path: field,
        value: val,
      })
    },
  })

  const getCurrentFileTranslation: (id: number, field: string) => WritableComputedRef<string> = (id: number, field: string) => computed({
    get: () => $translateEntityField($accessor.entityFiles.getField(id)(field)),
    set: val => getFileField(id, `${field}.${currentLocale}-${currentLocale.toUpperCase()}`).value = val,
  })

  return {
    toBase64,
    getCurrentFileTranslation,
    getFileField,
    deleteOne,
    postOne,
    patchOne,
  }
}

export default useFiles
