import { WithId } from '@kissmylabs/vuex-entitystore'
import { CalendarBatchCreateServiceBoundPayload, CalendarBatchDeleteServiceBoundPayload, DateType } from '~/types'
import { DegressivityRate } from './DegressivityRate'
import { WithTimestamps } from '~/types/models/types'
import { PeriodDate, WithPeriods } from '~/types/models/Period'

export enum ServiceDiscountPerimeterEnum {
  BASE = 'BASE',
  FINAL = 'FINAL'
}

export enum ServiceDiscountTypeEnum {
  ADDITIONAL = 'ADDITIONAL',
  GLOBAL = 'GLOBAL'
}

interface IServiceDiscount {
  label: string
  service: number
  start: DateType
  end: DateType
  discountPerimeter: ServiceDiscountPerimeterEnum
  discountType: ServiceDiscountTypeEnum
  degressivityRates: DegressivityRate[]
}

export interface NewServiceDiscount extends IServiceDiscount {
  id?: number
}

export type ServiceDiscount = NewServiceDiscount & WithId & WithTimestamps & {
  isActive: boolean
  isDefault: boolean
}

// Gives some flexibility to avoid having to set isActive/isDefault (which we don't use anymore),
// but still allow setting these defaults before hitting the API.
export type ServiceDiscountCreateForm = Omit<NewServiceDiscount, 'start' | 'end' | 'service' & {
  isActive?: boolean
  isDefault?: boolean
}>

export type ServiceDiscountEditForm = Omit<ServiceDiscount, 'start' | 'end'> & WithPeriods<PeriodDate>

export type ServiceDiscountBatchCreateForm = CalendarBatchCreateServiceBoundPayload<ServiceDiscountCreateForm>
export type ServiceDiscountBatchEditForm = CalendarBatchCreateServiceBoundPayload<ServiceDiscountEditForm>
export type ServiceDiscountBatchDeleteForm = CalendarBatchDeleteServiceBoundPayload
