/**
 * @file Logic to "mimic" calendar.worker.ts for easier debugging during development.
 *
 * ====================
 * !!!!! WARNING !!!!!
 * ====================
 * This is used only when calendar.worker algorithm needs to be debugged.
 * For now it's easier to keep some functions (eg getFirstServiceBoundEntitiesForDay) here and not in ~/helpers/calendar,
 * as these functions use the `currentSlot` / `currentSlotsByService` variables that are global to the script.
 *
 * YOU NEED TO MAKE SURE THESE FUNCTIONS MATCH THE VERSION IN calendar.worker.ts.
 *
 * IF YOU'RE NOT SURE YOU CAN SAFELY OVERWRITE EVERYTHING: copy calendar.worker.ts contents from after the listener
 * (self.addEventListener closing parenthesis) to the end of the file, then paste it here to overwrite everything
 * after `doLikeCalendarWorkerDoes` closing bracket.
 *
 * WHEN YOU ARE DONE DEBUGGING MAKE SURE TO DO THE OPPOSITE IN ORDER TO UPDATE THE WORKER.
 *
 * We'll probably find a better way to do this, but with Nuxt 3 / Vite getting closer,
 * we won't spend time to work on that right now.
 *
 * @todo instead of using current slots, pass the slots when calling the function first, return filled slots, pass to next day...
 *
 */

import { DateTime } from 'luxon'
import { toAPIFormat } from '~/helpers/dateTime'
import { CalendarEntitiesEnum, CalendarFiltersEnum, DataReceivedWorker, entitiesCalendar, FormattedCalendarEvent } from '~/types/Calendar'
import { createServiceSlots, createSlots, getEventsForDay, setIsStartEndDay } from '~/helpers/calendar'

let currentSlots = createSlots(3)

let currentSlotsByService = createServiceSlots(3)

// in worker
// self.addEventListener('message', (event: MessageEvent<DataReceivedWorker>) => {
export function doLikeCalendarWorkerDoes(data: DataReceivedWorker): any {
  console.time('debugWorkerLoading') // eslint-disable-line

  const {
    view,
    calendarUnavails,
    calendarPricings,
    calendarBookingCondition,
    calendarDiscount,
    calendarFilters,
    from,
    to,
    // in worker
    // } = event.data
  } = data

  const eventsMap: null | Record<string, any> = {}
  const start = DateTime.fromJSDate(from)
  const end = DateTime.fromJSDate(to)

  let entities: entitiesCalendar = []
  switch (view) {
    case CalendarEntitiesEnum.UNAVAILABILITIES:
      entities = calendarUnavails
      break

    case CalendarEntitiesEnum.PRICING:
      entities = calendarPricings
      break

    case CalendarEntitiesEnum.BOOKING_CONDITION:
      entities = calendarBookingCondition
      break

    case CalendarEntitiesEnum.DISCOUNT:
      entities = calendarDiscount
      break
  }

  let currentDay = start
  // Lookup dict for events per date.Used to store the result of getEventsForDay() for each date to avoid recalculating it.
  const eventsByDate: Record<string, FormattedCalendarEvent[]> = {}

  while (currentDay.valueOf() <= end.valueOf()) {
    const prevDay = currentDay.minus({ day: 1 })
    const nextDay = currentDay.plus({ day: 1 })

    // Don't remove for now plz.
    // if (currentDay.toISODate() === '2021-12-01') {
    //   debugger
    // }

    let prevDayEvents

    // Get events for prev day, unless current day is first in period.
    // @todo maybe we don't want this 'unless' condition: we should fetch 1 day before and 1 after the period, so we know if the event actually starts/ends at a period boundary.
    if (eventsByDate[prevDay.toISODate()]) {
      prevDayEvents = eventsByDate[prevDay.toISODate()]
    } else {
      prevDayEvents = currentDay.valueOf() === start.valueOf()
        ? []
        : getEventsForDay(prevDay.toJSDate(), view, entities, calendarFilters)
    }

    // Get events for next day, unless current day is last in period.
    // @todo maybe we don't want this 'unless' condition: we should fetch 1 day before and 1 after the period, so we know if the event actually starts/ends at a period boundary.
    const nextDayEvents = currentDay.valueOf() === end.valueOf()
      ? []
      : getEventsForDay(nextDay.toJSDate(), view, entities, calendarFilters)

    if (typeof eventsByDate[currentDay.toISODate()] === 'undefined') {
      // Don't forget to call setIsStartEndDay() on the cached values, as we don't call it when computing nextDayEvents.
      // Get events first to store them in the lookup dict without calling setIsStartEndDay, as context will change.
      eventsByDate[currentDay.toISODate()] = getEventsForDay(currentDay.toJSDate(), view, entities, calendarFilters)
    }

    const currentDayEvents = setIsStartEndDay(
      currentDay,
      start,
      end,
      eventsByDate[currentDay.toISODate()],
      prevDayEvents,
      nextDayEvents,
    )

    // Store current and next day events in the lookup dict, so we don't call getEventsForDay twice for the same date.
    eventsByDate[nextDay.toISODate()] = nextDayEvents

    eventsMap[toAPIFormat(currentDay)] = getFirstEventsForDayWrapper(
      view,
      calendarFilters,
      currentDayEvents,
      prevDayEvents,
      nextDayEvents,
    )

    currentDay = currentDay.plus({ day: 1 })
  }

  // in worker
  // postMessage(eventsMap)

  // Reset global variables.
  currentSlots = createSlots(3)
  currentSlotsByService = createServiceSlots(3)

  console.timeEnd('debugWorkerLoading')// eslint-disable-line
  return eventsMap
}

function getFirstEventsForDayWrapper(
  view: CalendarEntitiesEnum,
  filters: Record<CalendarFiltersEnum, boolean>,
  dayEvents: FormattedCalendarEvent[],
  prevDayEvents: FormattedCalendarEvent[],
  nextDayEvents: FormattedCalendarEvent[],
) {
  if (view === CalendarEntitiesEnum.UNAVAILABILITIES) {
    return getFirstUnavailabilitiesForDay(view, filters, dayEvents, prevDayEvents, nextDayEvents)
  }
  return getFirstServiceBoundEntitiesForDay(view, filters, dayEvents, prevDayEvents, nextDayEvents)
}

function getFirstServiceBoundEntitiesForDay(
  view: CalendarEntitiesEnum,
  filters: Record<CalendarFiltersEnum, boolean>,
  dayEvents: FormattedCalendarEvent[],
  prevDayEvents: FormattedCalendarEvent[],
  nextDayEvents: FormattedCalendarEvent[],
) {
  // Check existing previous day slots so they keep their space.
  for (const slot of currentSlotsByService) {
    // Skip empty slot.
    if (slot.event === null) {
      continue
    }

    // If this slot has a next id OR its event doesn't exist the current day,
    // place the next id at its index.
    if (slot.next !== null) {
      currentSlotsByService[currentSlotsByService.indexOf(slot)].event = slot.next
      currentSlotsByService[currentSlotsByService.indexOf(slot)].next = null
    }
  }

  // Find either free slots, or slots where .next is null (maybe ending).
  const freeOrMaybeEndingSlots = currentSlotsByService.filter(s => s.event === null || s.next === null)

  for (const event of dayEvents) {
    // If there are no more slots available, stop checking the dayEvents.
    if (freeOrMaybeEndingSlots.length === 0) {
      break
    }

    // Get first slot in freeOrMaybeEndingSlots, removing it so we can't use it again.
    const slot = freeOrMaybeEndingSlots.shift()

    // This should not happen, but TS won't stop whining about it.
    if (!slot) {
      continue
    }

    if (slot.event === null) {
      currentSlotsByService[currentSlotsByService.indexOf(slot)].event = event.id
      currentSlotsByService[currentSlotsByService.indexOf(slot)].serviceId = event.serviceId
    }

    // Check if this event also exist the next day.
    const isThereNextDay = nextDayEvents.find(e => e.id === slot.event)

    // If it's not there next day, find the next event for this service id.
    const nextEvent = isThereNextDay ? null : nextDayEvents.find(e => e.serviceId === slot.serviceId)

    // Use next day event as slot next.
    if (nextEvent) {
      currentSlotsByService[currentSlotsByService.indexOf(slot)].next = nextEvent.id
    }
  }

  const finalSlots = []
  for (const slot of currentSlotsByService) {
    if (slot.event === null && slot.next === null) {
      finalSlots.push([{
        id: Math.random(),
        isEmpty: true,
      }])
    } else {
      const dayRet = []
      const e = dayEvents.find(e => e.id === slot.event)
      const eNext = dayEvents.find(e => e.id === slot.next)
      if (e) {
        dayRet.push(e)
      }
      if (eNext) {
        dayRet.push({
          ...eNext,
          // It's the second event on the same line so it's necessarily starting and not ending.
          isStartDay: true,
          isEndDay: false,
        })
      }
      finalSlots.push(dayRet)
    }
  }

  return { slots: finalSlots, allEventsForDay: dayEvents }
}

// @todo we should give priority to events that span on next day.
function getFirstUnavailabilitiesForDay(
  view: CalendarEntitiesEnum,
  filters: Record<CalendarFiltersEnum, boolean>,
  dayEvents: FormattedCalendarEvent[],
  _prevDayEvents: FormattedCalendarEvent[],
  _nextDayEvents: FormattedCalendarEvent[],
) {
  // Holds the IDs already added to the slots to avoid duplicates.
  const alreadySeenEvents: number[] = []

  // Check existing previous day slots so they keep their space and remove refs to missing events.
  for (const slot of currentSlots) {
    // Skip empty slot.
    if (slot.id === null) {
      continue
    }

    // Events that were next in the previous iteration now become current.
    if (slot.next !== null) {
      currentSlots[currentSlots.indexOf(slot)].id = slot.next
      currentSlots[currentSlots.indexOf(slot)].next = null
    }

    // Events referenced but not in `events` are reset.
    // @todo log something here as it shouldn't happen (?)
    if (!dayEvents.find(e => e.id === slot.id)) {
      currentSlots[currentSlots.indexOf(slot)].id = null
      currentSlots[currentSlots.indexOf(slot)].next = null
    }

    if (currentSlots[currentSlots.indexOf(slot)].id !== null) {
      alreadySeenEvents.push(currentSlots[currentSlots.indexOf(slot)].id as number)
    }
  }

  for (const event of dayEvents) {
    // Find either free slots, or slots where .next is null (maybe ending).
    const freeOrMaybeEndingSlots = currentSlots.filter(s => s.id === null || s.next === null)

    // If there are no more slots available, stop checking the dayEvents.
    if (freeOrMaybeEndingSlots.length === 0) {
      break
    }

    // Skip this event if we've already seen it.
    if (alreadySeenEvents.includes(event.id)) {
      continue
    }

    for (const slot of freeOrMaybeEndingSlots) {
      if (!alreadySeenEvents.includes(event.id)) {
        if (slot.id === null) {
          // Fill slot with current event.
          currentSlots[currentSlots.indexOf(slot)].id = event.id
          alreadySeenEvents.push(event.id)
        } else {
          // If this slot has an event ID but no next, and it ends today, add current event as next for this slot.
          // Find the event to check if it ends today.
          const slotEvent = dayEvents.find(e => e.id === slot.id)
          // Only add the event as next if it doesn't also end today, otherwise this could get messy.
          // @todo this is not perfect as in the last day of a 2 month period, we won't have any event so it will always seem to be ending.
          if (slotEvent && !event.isEndDay && slotEvent.isEndDay) {
            currentSlots[currentSlots.indexOf(slot)].next = event.id
            alreadySeenEvents.push(event.id)
          }
        }
      }
    }
  }

  const finalSlots = []
  for (const slot of currentSlots) {
    if (slot.id === null && slot.next === null) {
      finalSlots.push([{
        id: Math.random(),
        isEmpty: true,
      }])
    } else {
      const dayRet = []
      const e = dayEvents.find(e => e.id === slot.id)
      const eNext = dayEvents.find(e => e.id === slot.next)
      if (e) {
        dayRet.push(e)
      }
      if (eNext) {
        dayRet.push(eNext)
      }
      finalSlots.push(dayRet)
    }
  }

  return { slots: finalSlots, allEventsForDay: dayEvents }
}
