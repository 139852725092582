import servicesMutations from './mutations'
import servicesGetters from './getters'
import servicesActions from './actions'
import servicesState from './state'

export const services = {
  namespaced: true,
  state: servicesState,
  actions: servicesActions,
  mutations: servicesMutations,
  getters: servicesGetters,
}
