import { createGetters } from '@kissmylabs/vuex-entitystore'
import { getterTree } from 'typed-vuex'
import { getField } from 'vuex-map-fields'
import { DomainEquipmentEntry } from '~/types/models'
import { state } from './state'

export const getters = getterTree(state, {
  ...createGetters<DomainEquipmentEntry>(),
  getField: (state) => (id: number): any => getField(state.entities.byId[id]),
  getAllEquipmentIds(_, { getAllArray }): number[] {
    return getAllArray().map((entry: DomainEquipmentEntry) => entry.domainEquipment)
  },
})

export default getters
