import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { Promocode } from '~/types/models'
import { PromocodeMutationTypes } from './types'
import state, { baseCreateForm, promocode } from './state'

export const mutations = mutationTree(state, {
  ...createMutations<Promocode>(promocode),

  [PromocodeMutationTypes.UPDATE_FIELD](state, { id, ...payload }) {
    updateField(state.entities.byId[id], payload)
  },

  [PromocodeMutationTypes.UPDATE_CREATE_FORM_FIELD](state, payload) {
    updateField(state.createForm, payload)
  },

  [PromocodeMutationTypes.RESET_CREATE_FORM](state) {
    state.createForm = baseCreateForm()
  },
})

export default mutations
