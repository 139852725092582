import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { defineComponent, reactive } from '@nuxtjs/composition-api';
import { WeekDaysEnumArray } from '~/helpers';
export default defineComponent({
  name: 'WeekdayCheckboxes',
  props: {
    value: {
      type: Array,
      required: true
    },
    selectedDays: {
      type: Array,
      default: function _default() {
        return WeekDaysEnumArray;
      }
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var state = reactive({
      selectedDays: props.value
    });
    var innerValue = computed({
      get: function get() {
        return props.value;
      },
      set: function set(val) {
        return emit('input', val);
      }
    });
    watch(function () {
      return props.selectedDays;
    }, function (newValue) {
      innerValue.value = newValue.filter(function (day) {
        return props.selectedDays.includes(day);
      });
    }, {
      deep: true
    });
    return {
      state: state,
      WeekDaysEnumArray: WeekDaysEnumArray,
      innerValue: innerValue
    };
  }
});