import { createEntity, createState } from '@kissmylabs/vuex-entitystore'
import { ProviderCreateForm, ProviderState } from './types'
import { Provider, ProviderStatusEnum, StructureStatusEnum } from '~/types/models'

export const provider = createEntity<Provider>('provider')

export const baseCreateForm = (): ProviderCreateForm => ({
  bic: null,
  iban: null,
  cancelPolicy: null,
  email: '',
  hlcpId: null,
  hlcpPassword: null,
  legalContactTitle: null,
  legalContactFirstname: null,
  legalContactLastname: null,
  legalContactBirthDate: null,
  legalContactNationality: null,
  legalContactCountryOfResidence: null,
  mybeezboxId: null,
  mybeezboxPassword: null,
  mybeezboxUrl: null,
  payoutFrequency: null,
  phone: null,
  status: ProviderStatusEnum.MAINTENANCE,
  unicstayContactTitle: null,
  unicstayContactFirstname: null,
  unicstayContactLastname: null,
  unicstayContactBirthdate: null,
  unicstayContactPhone: null,
  unicstayContactEmail: null,
  unicstayContactAddress: null,
  users: [],
  structureStatus: StructureStatusEnum.COMPANY,
})

export const state = (): ProviderState => ({
  ...createState<Provider>(provider),
  currentProvider: null,
  createForm: baseCreateForm(),
})

export default state
