import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { Promocode } from '~/types/models'
import { PromocodeMutationTypes } from './types'
import state from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { DateFormatEnum } from '~/helpers'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<Promocode>(),

  async postOne({ commit }) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.promocode_creating'))
    try {
      const { createForm } = this.app.$accessor.promocodes
      const payload = {
        ...createForm,
        periods: createForm.periods.map(period => ({
          start: this.app.$dateTime.fromJSDate(period.start as Date).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
          end: this.app.$dateTime.fromJSDate(period.end as Date).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
        })),
      }
      await this.app.$api.post('promocodes', payload)
      commit(PromocodeMutationTypes.RESET_CREATE_FORM)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.promocode_created'))
      return true
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.promocode_create_error'))
      return false
    }
  },

  async fetchOne({ commit }, id: number) {
    try {
      const res: Promocode = await this.app.$api.get(`promocodes/${id}`)
      const promocode: Promocode = {
        ...res,
        periods: res.periods.map(period => ({
          start: new Date(period.start as string),
          end: new Date(period.end as string),
        })),
      }
      if (promocode) {
        commit(BaseMutationTypes.CREATE, promocode)
      }
    } catch (error) {
      this.app.$errorToast('COULD NOT GET')
    }
  },

  async patchOne({ commit }, { id, payload }: { id: number, payload: Partial<Promocode> }) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.promocode_updating'))
    try {
      let formattedPayload = payload
      if (payload.periods) {
        formattedPayload = {
          ...payload,
          periods: payload.periods.map(period => ({
            start: this.app.$dateTime.fromJSDate(period.start as Date).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
            end: this.app.$dateTime.fromJSDate(period.end as Date).toFormat(DateFormatEnum.YEAR_MONTH_DAY_SHORT),
          })),
        }
      }
      const res: Promocode = await this.app.$api.patch(`promocodes/${id}`, formattedPayload)
      const promocode: Promocode = {
        ...res,
        periods: res.periods.map(period => ({
          start: new Date(period.start as string),
          end: new Date(period.end as string),
        })),
      }
      commit(BaseMutationTypes.UPDATE, { id, payload: promocode })
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.promocode_updated'))
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.promocode_update_error'))
    }
  },
})

export default actions
