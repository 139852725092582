




























import { defineComponent, useContext, computed, ComputedRef } from '@nuxtjs/composition-api'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import TabsNavigation from '~/components/navigation/TabsNavigation.vue'
import useProvider from '~/composable/useProvider'
import useAddress from '~/composable/useAddress'
import useTabsNavigation from '~/composable/useTabsNavigation'
import accountRoutes from '~/routes/accountRoutes'
import useFeatureToggle from '~/composable/useFeatureToggle'

export default defineComponent({
  name: 'Legalpage',
  components: {
    AppContentHeading,
    TabsNavigation,
  },
  middleware: [
    'providerAddressMiddleware',
  ],
  setup() {
    const { app: { $accessor }, params } = useContext()
    const { currentProvider, currentProviderId, patchCurrentProvider, getProviderField } = useProvider()
    const { getAddressField } = useAddress()
    const { isEnabledRoute } = useFeatureToggle()
    const domainId = computed(() => parseInt(params.value.domain))

    const childRoutes = accountRoutes.find(route => route.name === 'legal')!.children!
    const enabledRoutes = childRoutes.filter(route => isEnabledRoute(route as any))

    const { currentIndex } = useTabsNavigation({
      childRoutes: enabledRoutes as any,
      root: 'legal',
      defaultRoute: 'legalInfos',
      routeParams: {
        provider: currentProviderId.value.toString(),
        domain: domainId.value.toString(),
      },
    })

    const currentProviderIsDirty = computed(() => currentProvider.value && currentProvider.value.$isDirty)
    const addressCreateFormIsDirty: ComputedRef<boolean> = computed(() => $accessor.addresses.getCreateFormField('$isDirty'))
    const currentProvderAddressIsDirty: ComputedRef<boolean> = computed(() => currentProvider.value?.unicstayContactAddress && getAddressField('$isDirty', currentProvider.value?.unicstayContactAddress).value)

    const canPatch = computed(() => currentProviderIsDirty.value||addressCreateFormIsDirty.value||currentProvderAddressIsDirty.value)

    const onSubmit = async() => {
      if (currentProvderAddressIsDirty.value && currentProvider.value?.unicstayContactAddress) {
        await $accessor.addresses.patchOne({ id: currentProvider.value.unicstayContactAddress })

      } else if (addressCreateFormIsDirty.value) {
        const address = await $accessor.addresses.postOne()
        if (address) {
          getProviderField('unicstayContactAddress', currentProviderId.value).value = address.id
        }
      }
      await patchCurrentProvider()
    }

    return {
      onSubmit,
      canPatch,
      currentIndex,
      enabledRoutes,
    }
  },
})
