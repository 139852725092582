import { defineComponent } from '@nuxtjs/composition-api';
import { ExternalPlatformTypeEnum } from '~/types/models';
import useServices from '~/composable/useServices';
export default defineComponent({
  name: 'ExternalConnectivityServiceInfo',
  setup: function setup() {
    var _useServices = useServices(),
        getServiceExternalIdObject = _useServices.getServiceExternalIdObject;

    var platformId = computed(function () {
      var _getServiceExternalId;

      return (_getServiceExternalId = getServiceExternalIdObject.value) === null || _getServiceExternalId === void 0 ? void 0 : _getServiceExternalId.platformId;
    });
    return {
      ExternalPlatformTypeEnum: ExternalPlatformTypeEnum,
      getServiceExternalIdObject: getServiceExternalIdObject,
      platformId: platformId
    };
  }
});