import { Translation } from '~/types/Locale'
import { MarketplaceFrontConfig } from './MarketplaceFrontConfig'

export interface MarketplaceAffilaeTrackingIds {
  [key: string]: string[]
}

export enum MarketplaceLabelAffilaeEnum {
  MHI = 'MHI',
  LCBL = 'LCBL',
  ABCD = 'AbracadaRoom',
  ABCDNL = 'AbracadaRoom NL'
}

export interface Marketplace {
  id: number
  label: string
  website: string
  slug: string
  files: number[]
  commissionAmount: number
  originId: number
  originLabel: string
  phoneNumber: string
  email: string
  name: Translation
  googleTagId: string
  affiliateTrackingId: string
  contactMethods: any
  paymentMethods: any
  hostname: string
  frontConfig: MarketplaceFrontConfig
}
