import { createMutations } from '@kissmylabs/vuex-entitystore'
import { updateField } from 'vuex-map-fields'
import { mutationTree } from 'typed-vuex'
import { Domain, DomainExternalId } from '~/types/models'
import { resetState } from '~/helpers/store'
import baseState, { baseCreateForm, domain } from './state'
import { DomainMutationTypes } from './types'

export const mutations = mutationTree(baseState, {
  ...createMutations<Domain>(domain),
  [DomainMutationTypes.UPDATE_FIELD](state, { id, path, value }: { id: number, path: string, value: any }) {
    updateField(state.entities.byId[id], { path, value })
  },

  [DomainMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },

  [DomainMutationTypes.RESET_CREATE_FORM](state) {
    state.createForm = [baseCreateForm()]
  },

  [DomainMutationTypes.ADD_CREATE_FORM_ITEM](state, externalId?: DomainExternalId) {
    state.createForm.push(baseCreateForm(externalId))
  },

  [DomainMutationTypes.REMOVE_CREATE_FORM_ITEM](state, { index }: { index: number }) {
    state.createForm.splice(index, 1)
  },

  [DomainMutationTypes.UPDATE_CREATE_FORM_FIELD](state, { index, path, value }: { index: number, path: string, value: any }) {
    updateField(state.createForm[index], { path, value })
  },
  [DomainMutationTypes.UPDATE_CREATE_FORM_EXTERNAL_ID_FIELD](state, { index, path, value }: { index: number, path: string, value: any }) {
    updateField(state.createForm[index].externalId, { path, value })
  },
})

export default mutations
