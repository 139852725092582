import notificationsMutations from './mutations'
import notificationsGetters from './getters'
import notificationsActions from './actions'
import notificationsState from './state'

export const notifications = {
  namespaced: true,
  state: notificationsState,
  actions: notificationsActions,
  mutations: notificationsMutations,
  getters: notificationsGetters,
}
