var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Timeline"},[_c('BasePageSection',{attrs:{"title":_vm.$t('booking.the_timeline'),"aria-id":"booking-timeline","can-toggle":""}},[_c('div',{staticClass:"timeline is-centered is-size-6 is-size-5-desktop"},_vm._l((_vm.bookingTimelineItems),function(bookingEvent,index){return _c('div',{key:index,staticClass:"timeline-item is-primary"},[_c('div',{class:[
            'timeline-marker is-image is-flex justify-center align-items-center',
            {'is-64x64': _vm.isWide},
            {'is-32x32': _vm.isTouch},
            ("" + (_vm.getClassFromStatus(bookingEvent.eventType))) ]},[_c('i',{class:[
              'usi has-text-white',
              {'is-size-2': _vm.isWide},
              {'is-size-7': _vm.isTouch},
              ("" + (_vm.getIconFromStatus(bookingEvent.eventType)))
            ]})]),_vm._v(" "),_c('div',{staticClass:"timeline-content"},[_c('div',{staticClass:"timeline-heading has-text-weight-bold has-text-primary"},[_c('div',[_vm._v("\n              "+_vm._s(_vm.$dateTime.fromJSDate(new Date(bookingEvent.loggedAt.toString())).toLocaleString(_vm.$dateTime.DATE_SHORT))+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("\n              "+_vm._s(_vm.$dateTime.fromJSDate(new Date(bookingEvent.loggedAt.toString())).toLocaleString(_vm.$dateTime.TIME_24_SIMPLE))+"\n            ")])]),_vm._v(" "),_c('BookingTimelineItem',{attrs:{"booking-event":bookingEvent,"marketplace-name":_vm.marketplace.label,"booking-id":_vm.booking.id}}),_vm._v(" "),_c('feature-toggle',{attrs:{"name":"bookingEventLinks","value":true}},[(_vm.getLogForEvent(bookingEvent))?_c('div',{staticClass:"has-text-right"},[(_vm.getLogForEvent(bookingEvent))?[_c('BaseLink',{attrs:{"icon-pack":"usi","icon-left":"eye"},on:{"click":function($event){return _vm.toggleDisplayMailModal(bookingEvent)}}},[_vm._v("\n                  "+_vm._s(_vm.$t('actions.display'))+"\n                ")]),_vm._v(" "),(_vm.$accessor.users.currentUserIsAdmin)?_c('BaseLink',{attrs:{"icon-pack":"usi","icon-left":"refresh"},on:{"click":function($event){return _vm.onResendEmail(bookingEvent)}}},[_vm._v("\n                  "+_vm._s(_vm.$t('actions.resend'))+"\n                ")]):_vm._e()]:_c('span',[_vm._v(_vm._s(_vm.$t('booking.no_email_log')))])],2):_vm._e()])],1)])}),0)]),_vm._v(" "),_c('BaseModal',{attrs:{"active":_vm.displayMailModal},on:{"update:active":function($event){_vm.displayMailModal=$event}}},[_c('div',{staticClass:"py-2 px-2 card has-padding-30"},[(_vm.displayMailModal)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.modalContent)}}):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }