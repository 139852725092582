import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.number.max-safe-integer.js";
import { computed, defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'DistanceInput',
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var unit = ref('KM');
    var computedValue = computed({
      get: function get() {
        return props.value;
      },
      set: function set(val) {
        return emit('input', val);
      }
    });
    watch(function () {
      return unit.value;
    }, function (newValue) {
      if (newValue === 'KM') {
        computedValue.value = computedValue.value / 1000;
      } else {
        computedValue.value = computedValue.value * 1000;
      }
    });
    return {
      computedValue: computedValue,
      unit: unit
    };
  }
});