import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'EstablishmentHealthSafetyMeasures',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup: function setup() {
    var _useDomain = useDomain(),
        getDomainField = _useDomain.getDomainField;

    return {
      getDomainField: getDomainField
    };
  }
});