import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{staticClass:"block",attrs:{"name":"unavail_periods","rules":"period","skip-if-empty":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('DateRangePicker',{attrs:{"label":_vm.$t('calendar.unavailability_period'),"month-names":_vm.$t('hotel_datepicker.month-names'),"day-names":_vm.$t('hotel_datepicker.day-names'),"first-day-of-week":_vm.getOrderOfDayInWeek,"locale":_vm.currentRegionLocale,"range-item-formatter":_vm.calendarDatesFormatter,"mobile-native":false},on:{"input":function($event){return validate($event)}},model:{value:(_vm.periods[0]),callback:function ($$v) {_vm.$set(_vm.periods, 0, $$v)},expression:"periods[0]"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"unavailability_reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('calendar.unavailability_reason'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{attrs:{"data-cy":"UnavailabilityEditFormInputComment"},on:{"focus":_vm.onCommentFocus},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-full has-text-centered"},[_c('BButton',{staticClass:"is-primary is-size-5 is-uppercase has-text-weight-bold",attrs:{"size":"is-large","loading":_vm.loading,"disabled":invalid,"data-cy":"UnavailabilityEditFormButtonSave"},on:{"click":_vm.onSubmit}},[_vm._v("\n          "+_vm._s(_vm.$t('actions.save'))+"\n        ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }