






























































































import { computed, defineComponent, PropType, reactive, toRefs, useContext } from '@nuxtjs/composition-api'
import BookingTimelineItem from '~/components/booking/BookingTimelineItem.vue'
import useBookings from '~/composable/useBookings'
import { Booking, IBookingTimelineItem, BookingActionEnum, BookingEventsEnum } from '~/types/models'

export default defineComponent({
  name: 'BookingTimeline',
  components: { BookingTimelineItem },
  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
    bookingTimeline: {
      type: Array as PropType<IBookingTimelineItem[]>,
      required: true,
    },
  },
  setup(props) {
    const { app: { $accessor } } = useContext()
    const { getClassFromStatus, getIconFromStatus } = useBookings()

    const state = reactive({
      displayMailModal: false,
      modalContent: '',
    })

    const bookingTimelineItems = computed(() => props.bookingTimeline.filter(item => item.data.amount !== 0))

    const marketplace = computed(() => $accessor.marketplaces.getOne(props.booking.marketplace))
    const customer = computed(() => $accessor.customers.getOne(props.booking.customer))

    const getLogForEvent = (bookingEvent: IBookingTimelineItem) => $accessor.emailLogs.getFirstWhere(emailLog => {
      return emailLog.changesetId === bookingEvent.id && emailLog.notificationClass.includes('Customer')
    })

    const onResendEmail = async(bookingEvent: IBookingTimelineItem) => {
      const emailLog = getLogForEvent(bookingEvent)
      if (emailLog) {
        await $accessor.emailLogs.sendMail(emailLog.id)
      }
    }

    const displayActions = (bookingEvent: IBookingTimelineItem) => {
      return [
        BookingEventsEnum.CREATED,
        BookingEventsEnum.BOOKING_CANCELLED_CUSTOMER,
        BookingEventsEnum.BOOKING_CANCELLED_PROVIDER,
      ].includes(bookingEvent.eventType)
    }

    const toggleDisplayMailModal = (bookingEvent: IBookingTimelineItem) => {
      state.displayMailModal = !state.displayMailModal
      const log = getLogForEvent(bookingEvent)
      state.modalContent = log ? log.html : ''
    }


    return {
      ...toRefs(state),
      BookingActionEnum,
      BookingEventsEnum,
      bookingTimelineItems,
      customer,
      displayActions,
      getClassFromStatus,
      getIconFromStatus,
      getLogForEvent,
      marketplace,
      onResendEmail,
      state,
      toggleDisplayMailModal,
    }
  },
})
