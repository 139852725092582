import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { Translation } from '~/types'
import { Option, OptionFeeTypeEnum, OptionsCategoriesEnum, Period } from '~/types/models'

export enum OptionMutationTypes {
  RESET_STATE = 'RESET_STATE',
  UPDATE_FIELD = 'UPDATE_FIELD',
  UPDATE_CREATE_FORM_FIELD = 'UPDATE_CREATE_FORM_FIELD',
  RESET_CREATE_FORM = 'RESET_CREATE_FORM',
}

export interface OptionCreateForm {
  isActive?: boolean,
  category?: OptionsCategoriesEnum,
  periods?: Period[],
  isRecurringPeriod?: boolean,
  isMandatory?: boolean,
  feeType?: OptionFeeTypeEnum,
  fee?: {
    amount: number,
    currency: string,
  },
  appliesOnServices?: number[],
  name?: Translation,
  description?: Translation,
  min?: number,
  max?: number,
  label?: string,
  domain?: number,
  specialOffers?: string,
  minPreBookingDays?: number,
}

export interface OptionState extends BaseState<Option> {
  createForm: OptionCreateForm,
}
