/**
 * @function collectBrowserInfo
 *
 * @desc Collects available frontend browser info and store it in the properties dictated by the EMVCo spec
 * (3DS_Spec_protocolAndCoreFny_v2-1_Oct2017.pdf)
 * see https://github.com/Adyen/adyen-3ds2-js-utils/blob/master/browser/index.js
 *
 * @example const browserInfo = collectBrowserInfo();
 * const userAgent = browserInfo.userAgent;
 *
 * @returns {Object} - browserInfo an object containing the retrieved browser properties
 */
import { ThreeDS2Data } from '~/types'

export const collectBrowserInfo: () => Omit<ThreeDS2Data, 'clientIPAddress'> = () => {

  const screenWidth = window && window.screen ? window.screen.width : ''
  const screenHeight = window && window.screen ? window.screen.height : ''
  const colorDepth = window && window.screen ? window.screen.colorDepth : ''
  const userAgent = window && window.navigator ? window.navigator.userAgent : ''
  const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false
  const javaScriptEnabled = true
  const acceptHeader = 'application/json'

  let language = ''
  if (window && window.navigator) {
    language = window.navigator.language
      ? window.navigator.language
      : (window.navigator as Navigator & { browserLanguage: string }).browserLanguage // Else is for IE <+ 10
  }

  const d = new Date()
  const timeZoneOffset = d.getTimezoneOffset().toString()

  return {
    screenWidth,
    screenHeight,
    colorDepth,
    userAgent,
    timeZoneOffset,
    language,
    javaEnabled,
    javaScriptEnabled,
    acceptHeader,
  }
}
