import { Translation } from '~/types/Locale'
import { Money } from './Money'
import { DateType } from '../DateType'
import { Period } from './Period'
import { ModerationStatusEnum } from './Moderation'

export enum OptionFeeTypeEnum {
  NIGHT = 'NIGHT',
  GUEST = 'GUEST',
  NIGHT_GUEST = 'NIGHT_GUEST',
  FLAT = 'FLAT'
}

export enum OptionsCategoriesEnum {
  GENERAL = 'GENERAL',
  CATERING = 'CATERING',
  ACTIVITY = 'ACTIVITY',
  UNKNOWN = 'UNKNOWN',
}
export const optionFeeTypes = [
  OptionFeeTypeEnum.FLAT,
  OptionFeeTypeEnum.GUEST,
  OptionFeeTypeEnum.NIGHT,
  OptionFeeTypeEnum.NIGHT_GUEST,
]

export const optionsCategoriesArray = [
  OptionsCategoriesEnum.GENERAL,
  OptionsCategoriesEnum.CATERING,
  OptionsCategoriesEnum.ACTIVITY,
  OptionsCategoriesEnum.UNKNOWN,
]

export interface OptionModeratableFields {
  name?: Translation
  description?: Translation
}

export interface Option {
  id: number
  domain: number
  label: string
  name: Translation
  description: Translation
  min: number
  max: number
  isMandatory: boolean
  periods: Period[]
  isRecurringPeriod: boolean
  feeType: OptionFeeTypeEnum
  fee: Money
  minPreBookingDays?: number,
  $fullyLoaded?: boolean
  $isDirty: boolean
  appliesOnServices: number[]
  category: OptionsCategoriesEnum
  createdAT: DateType
  deletedAt: DateType
  updatedAt: DateType
  externalId: number
  externalLevel: number
  isActive: boolean
  isApproved: boolean
  isDeleted: boolean
  isExternal: boolean
  isPersonType: boolean
  lastApprovedFields: OptionModeratableFields
  moderationStatus: ModerationStatusEnum
  pendingModeration: OptionModeratableFields
}
