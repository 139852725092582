import { Middleware } from '@nuxt/types'

const promocodeMiddleware: Middleware = async({ app: { $accessor }, route }) => {
  const id = parseInt(route.params.id)
  const promocode = $accessor.promocodes.getOne(id)
  if (!promocode) {
    await $accessor.promocodes.fetchOne(id)
  }
}

export default promocodeMiddleware
