import { RouteConfig } from '@nuxt/types/config/router'
import AccountingPage from '~/components/pages/accounting.vue'
import ArrivalsPage from '~/components/pages/arrivals.vue'
import BookingsListPage from '~/components/pages/bookings/BookingsList.vue'
import BookingsPage from '~/components/pages/bookings.vue'
import CalendarCreateMany from '~/components/pages/calendar/CalendarCreateMany.vue'
import CalendarDeleteMany from '~/components/pages/calendar/CalendarDeleteMany.vue'
import CalendarPage from '~/components/pages/calendar/calendar.vue'
import CustomersPage from '~/components/pages/customers.vue'
import DomainBookingConditions from '~/components/establishment/DomainBookingConditions.vue'
import DomainEquipment from '~/components/establishment/DomainEquipment.vue'
import DomainGeneralInfo from '~/components/establishment/DomainGeneralInfo.vue'
import DomainLocation from '~/components/establishment/DomainLocation.vue'
import DomainOpeningPeriods from '~/components/establishment/DomainOpeningPeriods.vue'
import DomainPhotos from '~/components/establishment/DomainPhotos.vue'
import EstablishmentPage from '~/components/pages/offer/establishment.vue'
import GiftVoucher from '~/components/pages/bookings/gift-voucher.vue'
import HomePage from '~/components/pages/home.vue'
import IcalsPage from '~/components/pages/calendar/icals.vue'
import ListingPage from '~/components/pages/services/listing.vue'
import OptionsListingPage from '~/components/pages/options/options-listing.vue'
import PromotionsPage from '~/components/pages/promotions.vue'
import ReviewsPage from '~/components/pages/reviews/reviews.vue'
import ReviewsListPage from '~/components/pages/reviews/ReviewsList.vue'
import ReviewsDetailsPage from '~/components/pages/reviews/ReviewsDetails.vue'
import StatsPage from '~/components/pages/stats.vue'

export const mainMenuRoutes: RouteConfig[] = [
  {
    path: '/p/:provider/d/:domain/home',
    component: HomePage,
    name: 'home',
    meta: { icon: 'home' },
  },
  {
    path: '/p/:provider/d/:domain/establishment',
    component: EstablishmentPage,
    name: 'establishment',
    meta: { icon: 'castle' },
    children: [
      {
        path: '/p/:provider/d/:domain/establishment/general',
        name: 'domain-generalinfo',
        component: DomainGeneralInfo,
        props: true,
      },
      {
        path: '/p/:provider/d/:domain/establishment/location',
        name: 'domain-location',
        component: DomainLocation,
        props: true,
      },
      {
        path: '/p/:provider/d/:domain/establishment/opening-periods',
        name: 'domain-opening-periods',
        component: DomainOpeningPeriods,
        props: true,
      },
      {
        path: '/p/:provider/d/:domain/establishment/booking-conditions',
        name: 'domain-booking-conditions',
        component: DomainBookingConditions,
        props: true,
      },
      {
        path: '/p/:provider/d/:domain/establishment/equipment',
        name: 'domain-equipment',
        component: DomainEquipment,
        props: true,
      },
      {
        path: '/p/:provider/d/:domain/establishment/medias',
        name: 'domain-photos',
        component: DomainPhotos,
        props: true,
      },
    ],
  },
  {
    path: '/p/:provider/d/:domain/services/listing',
    component: ListingPage,
    meta: { icon: 'tipi' },
    name: 'listing',
  },
  {
    path: '/p/:provider/d/:domain/options',
    component: OptionsListingPage,
    name: 'options',
    meta: {
      featureGate: 'domainOptions',
      icon: 'breakfast',
    },
  },
  {
    path: '/p/:provider/d/:domain/bookings',
    component: BookingsPage,
    name: 'bookings',
    meta: {
      icon: 'keys',
    },
    children: [
      {
        path: '/p/:provider/d/:domain/bookings/list',
        component: BookingsListPage,
        name: 'bookings-list',
        meta: { featureGate: 'todaysArrival' },
      },
      {
        path: '/p/:provider/d/:domain/bookings/today-arrivals',
        component: ArrivalsPage,
        name: 'booking-arrivals',
        meta: { featureGate: 'todaysArrival' },
      },
      {
        path: '/p/:provider/d/:domain/bookings/gift-voucher',
        component: GiftVoucher,
        name: 'gift-voucher',
        meta: { featureGate: 'voucherService' },
      },
      {
        path: '/p/:provider/d/:domain/bookings/customers',
        component: CustomersPage,
        name: 'customers',
        meta: { icon: 'clients' },
      },
    ],
  },
  {
    path: '/p/:provider/d/:domain/calendar',
    component: CalendarPage,
    name: 'calendar',
    meta: {
      icon: 'calendar',
      isRoot: true,
    },
    children: [
      {
        path: '/p/:provider/d/:domain/calendar/create-many',
        component: CalendarCreateMany,
        name: 'calendar-create-many',
        meta: {
          featureGate: 'create-many',
          isShDisable: true,
        },
      },
      {
        path: '/p/:provider/d/:domain/calendar/delete-many',
        component: CalendarDeleteMany,
        name: 'calendar-delete-many',
        meta: {
          featureGate: 'delete-many',
          isShDisable: true,
        },
      },
      {
        path: '/p/:provider/d/:domain/calendar/icals',
        component: IcalsPage,
        name: 'icals',
        meta: {
          featureGate: 'icals',
          isShDisable: true,
        },
      },
    ],
  },
  {
    path: '/p/:provider/promotions',
    component: PromotionsPage,
    name: 'promotions',
    meta: {
      icon: 'present',
      featureGate: 'promotions',
    },
  },
  {
    path: '/p/:provider/stats',
    component: StatsPage,
    name: 'stats',
    meta: {
      icon: 'statistics',
      featureGate: 'stats',
    },
  },
  {
    path: '/p/:provider/d/:domain/reviews',
    component: ReviewsPage,
    name: 'reviews',
    meta: {
      icon: 'stars',
      featureGate: 'reviews',
    },
    children: [
      {
        path: '/p/:provider/d/:domain/reviews/list',
        component: ReviewsListPage,
        name: 'reviews-list',
        meta: { featureGate: 'reviews' },
      },
      {
        path: '/p/:provider/d/:domain/reviews/:id',
        component: ReviewsDetailsPage,
        name: 'reviews-details',
        meta: { featureGate: 'reviews' },
      },
    ],
  },
  {
    path: '/p/:provider/d/:domain/accounting',
    component: AccountingPage,
    name: 'accounting',
    meta: {
      icon: 'bill',
      featureGate: 'paymentService',
    },
  },
]
