









































































import { defineComponent, onMounted, Ref, ref, useContext } from '@nuxtjs/composition-api'
import useNotifications from '@/composable/useNotifications'
import NotificationItem from '~/components/notifications/NotificationItem.vue'

export default defineComponent({
  name: 'NotificationsDropdown',
  components: { NotificationItem },
  setup() {
    const { params, app: { $accessor, router, localePath } } = useContext()
    const { fetchAll, unreadCount, unreadNotifications } = useNotifications()

    onMounted(async() => fetchAll())

    // Weird typings because Buefy doesnt export Components instance typings, only install functions
    const dropdown: Ref<{ toggle: () => void } | null> = ref(null)

    const onSeeAll = () => {
      if (dropdown.value) {
        dropdown.value.toggle()
      }
      if (router && $accessor.users.currentUserId) {
        router.push(localePath({
          name: 'notifications',
          params: {
            provider: params.value.provider,
            id: $accessor.users.currentUserId.toString(),
          },
        }))
      }
    }

    const onItemClick = () => {
      if (dropdown.value) {
        dropdown.value.toggle()
      }
    }

    return {
      dropdown,
      onItemClick,
      onSeeAll,
      unreadCount,
      unreadNotifications,
    }
  },
})
