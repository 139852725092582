import serviceLabelsMutations from './mutations'
import serviceLabelsGetters from './getters'
import serviceLabelsActions from './actions'
import serviceLabelsState from './state'

export const serviceLabels = {
  namespaced: true,
  state: serviceLabelsState,
  actions: serviceLabelsActions,
  mutations: serviceLabelsMutations,
  getters: serviceLabelsGetters,
}
