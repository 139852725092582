import { DateType } from '../DateType'
import { TranslateResult } from 'vue-i18n'

export enum NotificationClassEnum {
  BOOKING_CANCELLED_PROVIDER = 'BookingCancelledProviderNotification',
  BOOKING_CANCELLED_UNICSTAY = 'BookingCancelledUnicstayNotification',
  BOOKING_CREATED_PROVIDER = 'BookingCreatedProviderNotification',
  BOOKING_CREATED_UNICSTAY = 'BookingCreatedUnicstayNotification',
  BOOKING_UPDATED_PROVIDER = 'BookingUpdatedProviderNotification',
  BOOKING_UPDATED_UNICSTAY = 'BookingUpdatedUnicstayNotification',
  BOOKING_CONFIRMED_PROVIDER = 'BookingConfirmedProviderNotification',
  BOOKING_CONFIRMED_UNICSTAY = 'BookingConfirmedUnicstayNotification',
  INCOMPLETE_SERVICE = 'IncompleteServiceNotification',
  MODERATION_UPDATE = 'ModerationUpdateNotification'
}

export enum NotificationContentScopeEnum {
  'BASIC_BOOKING' = 'BASIC_BOOKING',
  'FULL_BOOKING' = 'FULL_BOOKING',
  'SERVICE' = 'SERVICE',
  'MODERATION' = 'MODERATION'
}

export enum NotificationContentScopeMapingEnum {
  'BookingCancelledProviderNotification' = 'BASIC_BOOKING',
  'BookingCancelledUnicstayNotification' = 'BASIC_BOOKING',
  'BookingCreatedProviderNotification' = 'FULL_BOOKING',
  'BookingCreatedUnicstayNotification' = 'FULL_BOOKING',
  'BookingUpdatedProviderNotification' = 'FULL_BOOKING',
  'BookingUpdatedUnicstayNotification' = 'FULL_BOOKING',
  'BookingConfirmedProviderNotification' = 'FULL_BOOKING',
  'BookingConfirmedUnicstayNotification' = 'FULL_BOOKING',
  'IncompleteServiceNotification' = 'SERVICE',
  'ModerationUpdateNotification' = 'MODERATION'
}

export enum NotificationTypeEnum {
  ALERT = 'ALERT',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum NotificationActionTranslationKeysEnum {
  'ALERT' = 'notifications.read',
  'WARNING' = 'notifications.read',
  'INFO' = 'notifications.read',
}

export enum NotificationTextClassesEnum {
  'ALERT' = 'has-text-danger',
  'WARNING' = 'has-text-warning',
  'INFO' = 'has-text-success',
}

export enum NotificationTypeClassesEnum {
  'ALERT' = 'is-danger',
  'WARNING' = 'is-warning',
  'INFO' = 'is-success',
}

export enum NotificationBackgroundClassesEnum {
  'ALERT' = 'has-background-danger',
  'WARNING' = 'has-background-warning',
  'INFO' = 'has-background-success',
}

export enum NotificationStatusTranslationKeysEnum {
  'ALERT' = 'notifications.alert_label',
  'WARNING' = 'notifications.warning_label',
  'INFO' = 'notifications.info_label',
}

export interface NotificationDisplayInfo {
  backgroundColor: NotificationBackgroundClassesEnum
  textColor: NotificationTextClassesEnum
  statusText: TranslateResult
}

export interface NotificationActions {
  label: string
  callback: () => void
}

export interface Notification {
  id: number
  user: number
  notificationClass: NotificationClassEnum
  data: ServiceNotification & BasicBookingNotification & FullBookingNotification & ModerationNotification
  readAt?: DateType | null
  createdAt: DateType
  updatedAt: DateType
}

export interface ServiceNotification {
  serviceId: number
  serviceLabel: string
  missingNumber: number
}

export interface BasicBookingNotification {
  serviceId: number
  bookingId: number
  serviceLabel: string
}

export interface FullBookingNotification extends BasicBookingNotification {
  startsAt: DateType
  endsAt: DateType
  totalAmount: string
}

export interface ModerationNotification {
  objectType: 'domain' | 'option' | 'service'
  objectId: number
  label: string
  hasBeenModified: boolean
}
