import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { ServiceType } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<ServiceType>(),

  async fetchOne({ commit }, serviceTypeId: number): Promise<void> {
    const type = await this.app.$api.get(`servicetypes/${serviceTypeId}`)
    if (type) {
      return commit(BaseMutationTypes.CREATE, type)
    }
  },

  async fetchAll({ commit }): Promise<void> {
    const res = await this.app.$api.get('servicetypes?limit=-1')
    const servicesTypes = res.data
    if (servicesTypes.length) {
      return commit(BaseMutationTypes.CREATE_MANY, servicesTypes)
    }
  },

})

export default actions
