import { parsePhoneNumber, CountryCode } from 'libphonenumber-js'

export function getFormattedInternationalPhoneNumber(number: string | null): string {
  if (!number) {
    return ''
  }
  return parsePhoneNumber(number).formatInternational().replaceAll(' ', '')
}

export function getFormattedNationalPhoneNumber(number: string | null): string {
  if (!number) {
    return ''
  }
  return parsePhoneNumber(number).formatNational()
}

export function getCountryCodeFromNumber(number: string | null): CountryCode | undefined {
  if (!number) {
    return
  }
  return parsePhoneNumber(number).country
}
