import { DateTime, Duration } from 'luxon'
import { DateType } from '~/types'
import { AnyPeriod, Period, PeriodDate, PeriodDateTime, PeriodString } from '~/types/models'
import { ONE_DAY_MILLISECOND } from './dateConstants'
import { isSameDay } from '~/helpers/calendar'
import isDate from 'lodash/isDate'

export enum WeekDayNamesEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum WeekDaysEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const WeekDaysEnumArray = [
  WeekDaysEnum.MONDAY,
  WeekDaysEnum.TUESDAY,
  WeekDaysEnum.WEDNESDAY,
  WeekDaysEnum.THURSDAY,
  WeekDaysEnum.FRIDAY,
  WeekDaysEnum.SATURDAY,
  WeekDaysEnum.SUNDAY,
]

export const LuxonDayValue: LuxonDayValueMap = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
}

export type LuxonDayValueMap = {
  [key in WeekDaysEnum]: number
}

export const weekDayNamesArray = [
  WeekDayNamesEnum.MONDAY,
  WeekDayNamesEnum.TUESDAY,
  WeekDayNamesEnum.WEDNESDAY,
  WeekDayNamesEnum.THURSDAY,
  WeekDayNamesEnum.FRIDAY,
  WeekDayNamesEnum.SATURDAY,
  WeekDayNamesEnum.SUNDAY,
]

export function orderByDayOfWeek(days: WeekDaysEnum[]): WeekDaysEnum[] {
  return [...days].sort((a, b) => {
    const day1 = a
    const day2 = b
    return getIndexOfDay(day1) - getIndexOfDay(day2)
  })
}

export function getIndexOfDay(day: WeekDaysEnum): number {
  return WeekDaysEnumArray.findIndex(dayName => dayName === day)
}

export enum DateFormatEnum {
  YEAR_MONTH_DAY_SHORT = 'yyyy-LL-dd', // 1994/04/26
  HOURS_MINUTES_H_SEPARATED_HOUR = 'HH\'H\'mm', // 12h00
  HOURS_MINUTES_SECONDES = 'TT', //12:00:12
  HOURS_MINUTES_COLUMN_SEPARATED_HOUR = 'T', // 12:00
}

/**
 * Converts a DateTime to a 'yyyy-mm-dd' formatted string.
 * This is used to comply with some date formatting from the API
 * @param dateTime The DateTime object you want to format
 * @returns A string represeting the date in a 'yyyy-mm-dd' format

 */
export const toAPIFormat = (dateTime: DateTime): string => {
  // We have to do this formating on days and month because DateTime.month returns a format from 1 to 12 instead of 01 to 12
  return dateTime.toISODate()
}

export const isDateTime = (date: DateType): date is DateTime => {
  return 'isLuxonDate' in (date as DateTime)
}

export const isDayInSelectedDays = (day: DateTime, dayToApply: WeekDaysEnum[]): boolean => {
  const weekDayAsNumber = dayToApply.map(day => LuxonDayValue[day])
  return weekDayAsNumber.includes(day.weekday)
}

export function transformDateTimePeriodsToJsDate(periods: Period[]): Period[] {
  return periods.map(period => {
    const { start, end } = period

    if (isDateTime(start) && isDateTime(end)) {
      return {
        start: start.toJSDate(),
        end: end.toJSDate(),
      }
    }

    if (typeof start === 'string' && typeof end === 'string') {
      return {
        start: new Date(start),
        end: new Date(end),
      }
    }
    return {
      start,
      end,
    }
  })
}

export function toDateTime(date: DateType): DateTime {
  if (DateTime.isDateTime(date)) {
    return date
  }
  if (isDate(date)) {
    return DateTime.fromJSDate(date)
  }
  const fromIso = DateTime.fromISO(date)
  if (fromIso.isValid) {
    return fromIso
  }
  const parsed = DateTime.fromJSDate(new Date(date))
  if (parsed.isValid) {
    return parsed
  }
  throw new Error(`Unable to parse date: ${String(date)}`)
}

export function toJsDatePeriod(period: AnyPeriod): PeriodDate {
  return {
    start: toDateTime(period.start).toJSDate(),
    end: toDateTime(period.end).toJSDate(),
  }
}

export function toDateTimePeriod(period: AnyPeriod): PeriodDateTime {
  return {
    start: toDateTime(period.start),
    end: toDateTime(period.end),
  }
}

export function toIsoStringPeriod(period: AnyPeriod): PeriodString {
  return {
    start: toDateTime(period.start).toISO(),
    end: toDateTime(period.end).toISO(),
  }
}

export function toIsoDateStringPeriod(period: AnyPeriod): PeriodString {
  return {
    start: toDateTime(period.start).toISODate(),
    end: toDateTime(period.end).toISODate(),
  }
}

export function toApiFormatPeriod(period: Period): PeriodString {
  return {
    start: toAPIFormat(toDateTime(period.start)),
    end: toAPIFormat(toDateTime(period.end)),
  }
}

export function addDayToSameDayPeriodDateTimeStartEnd(period: Period): PeriodDateTime {
  const start = toDateTime(period.start)
  const end = toDateTime(period.end)
  return {
    start,
    end: isSameDay(start, end) ? end.plus({ day: 1 }) : end,
  }
}

export function transformPeriodsInDateTime(periods: Period[]): PeriodDateTime[] {
  return periods.map(period => ({
    start: DateTime.fromJSDate(period.start as Date),
    end: DateTime.fromJSDate(period.end as Date),
  }))
}

export function firstPeriodToStartEnd(periods: Period[]): PeriodDateTime {
  return {
    start: toDateTime(periods[0].start),
    end: toDateTime(periods[0].end),
  }
}

function humanizeDuration(time: Duration): string {
  if (time.months > 0) {
    return `${time.months}mois et ${time.days} j`
  }
  if (time.days > 0) {
    return `${time.days}j`
  }
  if (time.hours > 0) {
    return `${time.hours}h`
  }
  if (time.minutes > 0) {
    return `${Math.round(time.minutes)}min`
  }
  return '0m'
}

export const getTimeDiff = (start: string, end: string): string => {
  const startTime = DateTime.fromISO(start)
  const endTime = DateTime.fromISO(end)
  return humanizeDuration(endTime.diff(startTime, ['months', 'days', 'hours', 'minutes']))
}

export function dateDiff(date1: string, date2: string): number {
  const dt1 = new Date(date1)
  const dt2 = new Date(date2)
  return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (ONE_DAY_MILLISECOND))
}

/**
 * Sorts two dates based on which one is closer to the current date (new Date())
 * @param date1 The first date you want to sort
 * @param date2 The second date you want to sort
 * @returns A number indicating which date is the most recent
 */
export const sortByDateDesc = (date1: DateType, date2: DateType): number => {
  if (date1 && date2) {
    const dateTime1 = DateTime.fromISO(date1.toString())
    const dateTime2 = DateTime.fromISO(date2.toString())

    if (dateTime1 > dateTime2) {
      return -1
    }
    if (dateTime1 < dateTime2) {
      return 1
    }
    return 0
  }
  return 0
}

/**
 * Sorts two dates based on which one is further from the current date (new Date())
 * @param date1 The first date you want to sort
 * @param date2 The second date you want to sort
 * @returns A number indicating which date is the oldest
 */
export const sortByDateAsc = (date1: DateType, date2: DateType): number => {
  const dateTime1 = DateTime.fromISO(date1.toString())
  const dateTime2 = DateTime.fromISO(date2.toString())

  if (dateTime1 > dateTime2) {
    return 1
  }
  if (dateTime1 < dateTime2) {
    return -1
  }
  return 0
}
