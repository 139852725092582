import { render, staticRenderFns } from "./TodayBookings.vue?vue&type=template&id=7252c25b&scoped=true&"
import script from "./TodayBookings.vue?vue&type=script&lang=ts&"
export * from "./TodayBookings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7252c25b",
  null
  
)

export default component.exports