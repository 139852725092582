import { computed, useContext } from '@nuxtjs/composition-api';

function useModeration() {
  var _useContext = useContext(),
      $accessor = _useContext.app.$accessor,
      params = _useContext.params;

  var _useDomain = useDomain(),
      currentDomainId = _useDomain.currentDomainId;

  var getDomainFieldToModerate = function getDomainFieldToModerate(field) {
    return computed(function () {
      var _$accessor$domains$ge;

      return (_$accessor$domains$ge = $accessor.domains.getOne(currentDomainId.value)) === null || _$accessor$domains$ge === void 0 ? void 0 : _$accessor$domains$ge.pendingModeration[field];
    });
  };

  var getServiceFieldToModerate = function getServiceFieldToModerate(field) {
    return computed(function () {
      var _$accessor$services$g;

      return (_$accessor$services$g = $accessor.services.getOne(parseInt(params.value.id))) === null || _$accessor$services$g === void 0 ? void 0 : _$accessor$services$g.pendingModeration[field];
    });
  };

  var getOptionFieldToModerate = function getOptionFieldToModerate(field) {
    return computed(function () {
      var _$accessor$options$ge;

      return (_$accessor$options$ge = $accessor.options.getOne(parseInt(params.value.id))) === null || _$accessor$options$ge === void 0 ? void 0 : _$accessor$options$ge.pendingModeration[field];
    });
  };

  var getFileFieldToModerate = function getFileFieldToModerate(field, id) {
    return computed(function () {
      var _$accessor$entityFile;

      return (_$accessor$entityFile = $accessor.entityFiles.getOne(id)) === null || _$accessor$entityFile === void 0 ? void 0 : _$accessor$entityFile.pendingModeration[field];
    });
  };

  var domainFieldPreviousVersion = function domainFieldPreviousVersion(field) {
    return computed(function () {
      var _$accessor$domains$ge2;

      return (_$accessor$domains$ge2 = $accessor.domains.getOne(currentDomainId.value)) === null || _$accessor$domains$ge2 === void 0 ? void 0 : _$accessor$domains$ge2.lastApprovedFields[field];
    });
  };

  var serviceFieldPreviousVersion = function serviceFieldPreviousVersion(field) {
    return computed(function () {
      var _$accessor$services$g2;

      return (_$accessor$services$g2 = $accessor.services.getOne(parseInt(params.value.id))) === null || _$accessor$services$g2 === void 0 ? void 0 : _$accessor$services$g2.lastApprovedFields[field];
    });
  };

  var optionFieldPreviousVersion = function optionFieldPreviousVersion(field) {
    return computed(function () {
      var _$accessor$options$ge2;

      return (_$accessor$options$ge2 = $accessor.options.getOne(parseInt(params.value.id))) === null || _$accessor$options$ge2 === void 0 ? void 0 : _$accessor$options$ge2.lastApprovedFields[field];
    });
  };

  return {
    domainFieldPreviousVersion: domainFieldPreviousVersion,
    getDomainFieldToModerate: getDomainFieldToModerate,
    getFileFieldToModerate: getFileFieldToModerate,
    getOptionFieldToModerate: getOptionFieldToModerate,
    getServiceFieldToModerate: getServiceFieldToModerate,
    optionFieldPreviousVersion: optionFieldPreviousVersion,
    serviceFieldPreviousVersion: serviceFieldPreviousVersion
  };
}

export default useModeration;