export enum CurrencyEnum {
  CURRENCY_EURO = 'EUR'
}

export enum CurrencyToSymbolEnum {
  'EUR' = '€'
}
export interface Money {
  amount: number ,
  currency: CurrencyEnum,
  sign?: { prefix: string, suffix: string }
}
