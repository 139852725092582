































import { defineComponent, useContext } from '@nuxtjs/composition-api'
import useOptions from '~/composable/useOptions'
import useDomain from '~/composable/useDomain'

export default defineComponent({
  name: 'OptionConditionsForm',
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionId: {
      required: false,
      default: null,
      validator: (prop: number | null) => typeof prop === 'number' || prop === null,
    },
  },
  setup() {
    const { params } = useContext()
    const {
      getOptionField,
      getOptionTranslationField,
    } = useOptions()
    const { currentDomainIsSh } = useDomain()

    function getField(field: string) {
      return getOptionField(field, parseInt(params.value.id))
    }

    function getTranslationField(field: string) {
      getOptionTranslationField(field, parseInt(params.value.id))
    }


    return {
      getField,
      getTranslationField,
      currentDomainIsSh,
    }
  },
})
