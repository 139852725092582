














































































































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { EmailLog } from '~/types/models'
import useTable from '~/composable/useTable'
import TableSearch from '~/components/table/TableSearch.vue'
import TableEmpty from '~/components/table/TableEmpty.vue'

export default defineComponent({
  name: 'MarketPlaceCommunicationsPage',
  components: {
    TableSearch,
    TableEmpty,
  },
  setup() {
    const baseUrl = 'emaillogs'
    const { app: { $accessor, $translateEntityField } } = useContext()

    const fetchRelations = async(items: EmailLog[]) => {
      const bookingsIds = [...new Set(items.map(emailLog => emailLog.booking))]
      const missingBookingsIds = bookingsIds.filter(id => !$accessor.bookings.getAllIds().includes(id))

      if (missingBookingsIds.length > 0) {
        await $accessor.bookings.fetchMany(missingBookingsIds)
      }

      const domainIds = [...new Set(bookingsIds.map(id => {
        const booking = $accessor.bookings.getOne(id)
        if (booking) {
          return booking.domain
        }
      }))]

      const missingDomainIds = domainIds.filter(id => id !== undefined && !$accessor.domains.getAllIds().includes(id))

      if (missingDomainIds.length > 0) {
        await $accessor.domains.fetchMany(missingDomainIds as number[])
      }

    }
    const { state: tableState, eventSearchTable, eventSortTable } = useTable(baseUrl, fetchRelations)
    const isModalOpen = ref(false)
    const modalContent = ref('')

    const getDomainNameByEmailLog = (communication: EmailLog) => {
      const booking = $accessor.bookings.getOne(communication.booking)
      if (booking) {
        return $translateEntityField($accessor.domains.getOne(booking.domain).commercialName)
      }
      return 'N/A'
    }

    const isSoftDeletedBooking = (id: number) => $accessor.bookings.getOne(id) === undefined

    const resendMail = async(id: number) => {
      await $accessor.emailLogs.sendMail(id)
    }

    function openModal(communication: EmailLog) {
      isModalOpen.value = true
      modalContent.value = communication.html
    }

    return {
      tableState,
      eventSearchTable,
      eventSortTable,
      openModal,
      isModalOpen,
      isSoftDeletedBooking,
      modalContent,
      getDomainNameByEmailLog,
      resendMail,
    }
  },
})
