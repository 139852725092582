import { Context, NuxtAppOptions } from '@nuxt/types'
import { DateTime } from 'luxon'
import { Api } from '~/helpers/api'

export interface AuthCookie {
  token: string,
  expiresAt: string,
  user: number,
  refreshToken: string
}

export const getAuthCookieFromContext = (context: Context | NuxtAppOptions): AuthCookie => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const cookie = require('cookie')
  let authCookie: AuthCookie = {
    token: '',
    expiresAt: '',
    user: 0,
    refreshToken: '',
  }
  if (process.server && context.req.headers.cookie) {
    const reqCookies = cookie.parse(context.req.headers.cookie)
    if (reqCookies[Api.authCookieName]) {
      authCookie = JSON.parse(reqCookies[Api.authCookieName])
    }
  } else if (process.client) {
    const resCookie = context.app.$cookies.get(Api.authCookieName, { fromRes: true })
    if (resCookie) {
      authCookie = JSON.parse(resCookie)
    }
  }
  return authCookie
}

export const isValidAuthCookie = (authCookie: AuthCookie): boolean => {
  const now = DateTime.local()
    .setZone('Europe/Paris')
  const expiresAt = DateTime.fromISO(authCookie.expiresAt)

  return expiresAt > now
    && authCookie.user > 0
    && authCookie.refreshToken.length > 0
    && authCookie.token.length > 0
}
