import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePageSection',{attrs:{"title":_vm.$t('establishment.homelocpay'),"aria-id":"marketplaceEstablishmentHomelocPay","can-toggle":""}},[_c('BaseMessage',{attrs:{"type":"is-info","closable":""}},[_c('p',[_vm._v(_vm._s(_vm.$t('marketplace.hlcp.warn_sandbox_keys_1')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('marketplace.hlcp.warn_sandbox_keys_2')))])]),_vm._v(" "),_c('div',{staticClass:"columns is-mobile is-multiline is-full-width"},_vm._l((_vm.marketplaces),function(marketplace){return _c('div',{key:marketplace.id,staticClass:"column is-half-touch is-one-quarter-desktop"},[_c('BCheckbox',{attrs:{"value":_vm.getMkpConfigField(marketplace.id, 'isEnabled').value,"native-value":marketplace.id},on:{"input":function($event){return _vm.onCheck(marketplace.id)}}},[_vm._v("\n        "+_vm._s(marketplace.label)+"\n      ")]),_vm._v(" "),(_vm.configOrFormEnable(marketplace.id).value)?_c('div',[_c('ValidationObserver',{ref:"observer",refInFor:true,attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"name":"apikey","rules":'required',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label":_vm.$t('marketplace.apiKey')}},[_c('BInput',{attrs:{"aria-required":"true","disabled":!_vm.getMkpConfigField(marketplace.id, 'isEnabled').value},model:{value:(_vm.getMkpConfigField(marketplace.id, 'homelocApiKey').value),callback:function ($$v) {_vm.$set(_vm.getMkpConfigField(marketplace.id, 'homelocApiKey'), "value", $$v)},expression:"getMkpConfigField(marketplace.id, 'homelocApiKey').value"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"apipassword","rules":'required',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label":_vm.$t('marketplace.apiPassword')}},[_c('BInput',{attrs:{"aria-required":"true","disabled":!_vm.getMkpConfigField(marketplace.id, 'isEnabled').value},model:{value:(_vm.getMkpConfigField(marketplace.id,'homelocApiPassword').value),callback:function ($$v) {_vm.$set(_vm.getMkpConfigField(marketplace.id,'homelocApiPassword'), "value", $$v)},expression:"getMkpConfigField(marketplace.id,'homelocApiPassword').value"}})],1)]}}],null,true)}),_vm._v(" "),(_vm.getMkpConfigField(marketplace.id,'$isDirty').value)?_c('BaseButton',{attrs:{"disabled":invalid},on:{"click":function($event){return _vm.onSubmit(marketplace.id)}}},[_vm._v("\n            "+_vm._s(_vm.$t('actions.save'))+"\n          ")]):_vm._e()]}}],null,true)})],1):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }