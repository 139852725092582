import paymentsMutations from './mutations'
import paymentsGetters from './getters'
import paymentsActions from './actions'
import paymentsState from './state'

export const payments = {
  namespaced: true,
  state: paymentsState,
  actions: paymentsActions,
  mutations: paymentsMutations,
  getters: paymentsGetters,
}
