import { createGetters } from '@kissmylabs/vuex-entitystore'
import { getField } from 'vuex-map-fields'
import { getterTree } from 'typed-vuex'
import { GiftCard } from '~/types/models'
import { state } from './state'

export const getters = getterTree(state, {
  ...createGetters<GiftCard>(),
  getField: state => (id: number) => getField(state.entities.byId[id]),
  getCreateFormField: state => getField(state.createForm),
  getCreateForm: state => state.createForm,
})

export default getters
