import { AuthMutationTypes, AuthState } from '~/store/auth/types'
import { MutationPayload, Store } from 'vuex'
import { Context } from '@nuxt/types'
import { Api } from '~/helpers/api'

type StoreSubscriber = (_store: Store<any>, mutation: MutationPayload, state: any) => void

interface StoreSubscribers {
  [key: string]: StoreSubscriber
}

const subscribers: StoreSubscribers = {
  [AuthMutationTypes.LOGIN]: (store: Context['store'], _mutation: MutationPayload, _state: AuthState) => {
    if (process.client) {
      store.$autoRefreshToken.start(store.$cookies.get(Api.authCookieName))
    }
  },
  [AuthMutationTypes.LOGOUT]: (store: Context['store'], _mutation: MutationPayload, _state: AuthState) => {
    if (process.client) {
      store.$autoRefreshToken.stop()
    }
  },
}

export default subscribers
