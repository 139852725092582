import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { GiftCard, GiftCardSpending } from '~/types/models'
import { GiftCardCreateForm, GiftCardMutationTypes } from './types'
import { state } from './state'
import { getters } from './getters'

export const actions = actionTree({ state, getters }, {
  ...createActions<GiftCard>(),

  async deleteOne(_, id: number) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.giftcard_deleting'))
    try {
      await this.app.$api.delete(`giftcards/${id}`)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.giftcard_deleted'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.giftcard_delete_error'))
    }
  },

  async fetchMany({ commit }, giftCardIds: number[]) {
    if (giftCardIds.length > 0) {
      const ids = giftCardIds.join()
      const res = await this.app.$api.get(`giftcards?filters[id_in]=${ids}&limit=${giftCardIds.length}`)
      if (res.data.length > 0) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },

  async fetchOne({ commit }, id: number) {
    const res = await this.app.$api.get(`giftcards/${id}`)

    const spendings: GiftCardSpending[] = await this.app.$accessor.giftCardSpendings.fetchMany(res.spendings)

    const formatedData = {
      ...res,
      spendings,
      validUntil: new Date(res.validUntil),
      totalAmount: this.app.$fromCent(res.totalAmount),
    }

    return commit(BaseMutationTypes.CREATE, formatedData)
  },

  async postOne({ commit }, payload: GiftCardCreateForm) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.giftcard_creating'))
    try {
      await this.app.$api.post('giftcards', payload)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.giftcard_created'))
      commit(GiftCardMutationTypes.RESET_CREATE_FORM)
    } catch (error: any) {
      toast.goAway(0)
      error.response.data.code === '23000' ?
        this.app.$errorToast(this.app.i18n.t('toast.giftcard_duplicate_error'))
        : this.app.$errorToast(this.app.i18n.t('toast.giftcard_create_error'))
    }
  },

  async patchOne({ commit, getters }, id: number) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.giftcard_updating'))
    const giftCard = getters.getOne(id)

    const formatedPayload = {
      code: giftCard.code,
      buyerFullName: giftCard.buyerFullname,
      totalAmount: this.app.$toCent(giftCard.totalAmount),
      isActive: giftCard.isActive,
      comment: giftCard.comment,
      validUntil: this.app.$dateTime.fromJSDate(giftCard.validUntil as Date).toISODate(),
      origin: giftCard.origin,
      setActiveSince: giftCard.setActiveSince === true,
    }
    try {
      const res = await this.app.$api.patch(`giftcards/${id}`, formatedPayload)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.giftcard_updated'))

      const spendingsIds = res.spendings
      let spendings: GiftCardSpending[] = []
      if (spendingsIds.length) {
        const spendingRes = await this.app.$accessor.giftCardSpendings.fetchMany(spendingsIds)
        spendings = spendingRes.data

      }
      const formatedData = {
        ...res,
        spendings,
        validUntil: new Date(res.validUntil),
        isActive: res.isActive,
        totalAmount: this.app.$fromCent(res.totalAmount),
        remainingBalance: this.app.$fromCent(res.remainingBalance),
      }
      return commit(BaseMutationTypes.UPDATE, {
        id,
        payload: formatedData,
      })
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.giftcard_update_error'))
    }
  },

  async downloadCsvExport() {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.export_giftcard_csv_started'))
    try {
      const res = await this.app.$api.get('giftcards_export')
      const data = new Blob([res])
      const a = document.createElement('a')
      a.download = 'cartes-cadeau.csv'
      a.type = 'text/csv'
      a.target = '_blank'
      a.href = URL.createObjectURL(data)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      toast.goAway(0)
    } catch (e) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.export_giftcard_csv_error'))
    }
  },

})

export default actions
