import { SupportedISOLocale } from '~/types'

export enum CartStatusEnum {
  CART_OPEN = 'CART_OPEN',
  CART_AWAITING_PAYMENT = 'CART_AWAITING_PAYMENT',
  CART_PAID = 'CART_PAID',
  CART_CLOSED = 'CART_CLOSED',
  CART_FAILED = 'CART_FAILED',
  CART_ABANDONNED = 'CART_ABANDONNED',
}

/**
 * @deprecated. Use `CartStatusColorsEnum` instead.
 */
export enum CartStatusClassesEnum {
  CART_OPEN = 'is-blue',
  CART_AWAITING_PAYMENT = 'is-warning',
  CART_PAID = 'is-success',
  CART_CLOSED = 'is-purple',
  CART_ABANDONNED = 'is-danger',
  CART_FAILED = 'is-danger'
}

/**
 * @todo we cannot use computed values from ThemeColors. Maybe this should be computed from TW config file and not a type.
 */
export enum CartStatusColorsEnum {
  CART_OPEN = 'blue',
  CART_AWAITING_PAYMENT = 'orange',
  CART_PAID = 'green',
  CART_CLOSED = 'purple',
  CART_ABANDONNED = 'red',
  CART_FAILED = 'red'
}
export interface Cart {
  id: number
  sessionId: string
  originId: number
  customer?: number
  service: number
  marketplace: number
  booking: number
  lang: SupportedISOLocale
  start: Date
  end: Date
  promocode: number
  options: number[]
  giftCards: number[]
  guests: number[]
  status: CartStatusEnum
}
