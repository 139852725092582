





















































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { Notification } from '@/types/models'
import useNotifications from '~/composable/useNotifications'

export default defineComponent({
  name: 'NotificationItem',
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      getNotificationTime,
      getNotificationDisplayValues,
      getNotificationActions,
      getNotificationContent,
      markAsRead,
    } = useNotifications()

    const notificationDisplayValues = computed(() => getNotificationDisplayValues(props.notification))

    const notificationActions = computed(() => getNotificationActions(props.notification))

    const notificationContent = computed(() => getNotificationContent(props.notification))

    const notificationTime = computed(() => getNotificationTime(props.notification))

    const displayMarkAsRead = computed(() => !props.notification.readAt)

    const onMarkAsRead = async(id: number) => markAsRead(id)

    const onLabelClick = () => {
      notificationActions.value.callback()
      emit('click')
    }

    return {
      displayMarkAsRead,
      notificationTime,
      notificationActions,
      notificationContent,
      notificationDisplayValues,
      onLabelClick,
      onMarkAsRead,
    }
  },
})
