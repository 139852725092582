import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { DomainAmenityEntry } from '~/types/models'
import { resetState } from '~/helpers/store'
import baseState, { domainAmenityEntry } from './state'
import { DomainAmenityEntryMutationTypes } from './types'

export const mutations = mutationTree(baseState, {
  ...createMutations<DomainAmenityEntry>(domainAmenityEntry),
  [DomainAmenityEntryMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
  [DomainAmenityEntryMutationTypes.UPDATE_FIELD]: (state, { id, path, value }: { id: number, path: string, value: any }) => updateField(state.entities.byId[id], { path, value }),

})

export default mutations
