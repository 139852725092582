








import { defineComponent } from '@nuxtjs/composition-api'
import AppContentHeading from '~/components/content/AppContentHeading.vue'

export default defineComponent({
  name: 'StatsPage',
  components: { AppContentHeading },
})
