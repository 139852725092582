import serviceDiscountsMutations from './mutations'
import serviceDiscountsGetters from './getters'
import serviceDiscountsActions from './actions'
import serviceDiscountsState from './state'

export const serviceDiscounts = {
  namespaced: true,
  state: serviceDiscountsState,
  actions: serviceDiscountsActions,
  mutations: serviceDiscountsMutations,
  getters: serviceDiscountsGetters,
}
