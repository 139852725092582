import { createGetters } from '@kissmylabs/vuex-entitystore'
import { getterTree } from 'typed-vuex'
import { getField } from 'vuex-map-fields'
import { EmailLog } from '~/types/models'
import { state } from './state'

export const getters = getterTree(state, {
  ...createGetters<EmailLog>(),
  getField: (state) => (id: number): any => getField(state.entities.byId[id]),
})

export default getters
