import servicePricingsMutations from './mutations'
import servicePricingsGetters from './getters'
import servicePricingsActions from './actions'
import servicePricingsState from './state'

export const servicePricings = {
  namespaced: true,
  state: servicePricingsState,
  actions: servicePricingsActions,
  mutations: servicePricingsMutations,
  getters: servicePricingsGetters,
}
