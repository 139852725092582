






























import { defineComponent, onMounted, useContext } from '@nuxtjs/composition-api'
import usePromocodes from '~/composable/usePromocodes'
import PromocodeForm from '~/components/promocodes/PromocodeForm.vue'

export default defineComponent({
  name: 'CreatePromocode',
  components: { PromocodeForm },
  setup() {
    const { app: { $accessor, router, localePath } } = useContext()
    const { getCreatePromocodeFormField, postOne } = usePromocodes()

    onMounted(() => {
      if (getCreatePromocodeFormField('marketplaces').value.length === 0) {
        getCreatePromocodeFormField('marketplaces').value = $accessor.marketplaces.getAllIds()
      }
    })

    const onSubmit = async() => {
      const success = await postOne()
      if (success) {
        router!.push(localePath('promocodes'))
      }
    }

    return {
      onSubmit,
      getCreatePromocodeFormField,
    }
  },
})
