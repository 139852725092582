


































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Service } from '~/types/models'
import ServiceOfferDetails from '~/components/service/ServiceOfferDetails.vue'

export default defineComponent({
  name: 'ServiceOffer',
  components: { ServiceOfferDetails },
  props: {
    service: {
      type: Object as PropType<Service>,
      required: true,
    },
  },
})
