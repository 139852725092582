














import { defineComponent } from '@nuxtjs/composition-api'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import TermsAndCondition from '~/components/account/TermsAndCondition.vue'
import TermsAndConditionOfCancellation from '~/components/account/TermsAndConditionOfCancellation.vue'

export default defineComponent({
  name: 'ContractsPage',
  components: {
    AppContentHeading,
    TermsAndCondition,
    TermsAndConditionOfCancellation,
  },
})
