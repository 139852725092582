import { Middleware } from '@nuxt/types'

const bookingPaymentsMiddleware: Middleware = async({ app: { $accessor }, route }) => {
  const { payments } = $accessor.bookings.getOne(parseInt(route.params.id))
  const missingPaymentIds = payments.filter(id => !$accessor.payments.getAllIds().includes(id))

  if (missingPaymentIds.length > 0) {
    await $accessor.payments.fetchMany(missingPaymentIds)
  }
}

export default bookingPaymentsMiddleware
