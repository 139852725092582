







































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import AppContentHeading from '~/components/content/AppContentHeading.vue'

export default defineComponent({
  name: 'AuthPage',
  components: { AppContentHeading },
  setup() {
    const { route, app: { i18n } } = useContext()

    const pageTitle = computed(() => {
      if (route.value.name?.includes('forgot')) {
        return i18n.t('routes.auth.forgot_title')
      } else if (route.value.name?.includes('reset')) {
        return i18n.t('routes.auth.reset_title')
      }
      return i18n.t('routes.auth.page_title')
    })
    return { pageTitle }
  },
})
