import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { CalendarExternalSource } from '~/types/models'
import { CalendarExternalSourceMutationTypes } from './types'
import baseState, { calendarExternalSource } from './state'
import { resetState } from '~/helpers/store'

export const mutations = mutationTree(baseState, {
  ...createMutations<CalendarExternalSource>(calendarExternalSource),
  [CalendarExternalSourceMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
  [CalendarExternalSourceMutationTypes.UPDATE_FIELD]: (state, { id, path, value }: { id: number, path: string, value: any }) =>  updateField(state.entities.byId[id], { path, value }),

})

export default mutations
