import getBaseState from '~/store/auth/state'
import { resetState } from '~/helpers/store'
import { mutationTree } from 'typed-vuex'
import { state } from './state'
import { AuthMutationTypes } from './types'

export const mutations = mutationTree(state,{
  [AuthMutationTypes.LOGIN](state, _userId: number) {
    state.isLoggedIn = true
  },

  [AuthMutationTypes.LOGOUT](state) {
    state.isLoggedIn = false
  },

  [AuthMutationTypes.RESET_STATE](state) {
    resetState(state, getBaseState)
  },
})

export default mutations
