import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.filter.js";
import { defineComponent, useContext, computed } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'MkpServiceLabels',
  components: {},
  middleware: ['serviceLabelsMiddleware'],
  setup: function setup() {
    var _useContext = useContext(),
        $accessor = _useContext.app.$accessor,
        params = _useContext.params;

    var _useServices = useServices(),
        getField = _useServices.getCurrentServiceField,
        currentServiceId = _useServices.currentServiceId;

    var service = computed(function () {
      return $accessor.services.getOne(parseInt(params.value.id));
    });

    var serviceLabelsValue = function serviceLabelsValue(id) {
      return computed({
        get: function get() {
          return getField('serviceLabels').value.includes(id);
        },
        set: function set(_val) {
          if (getField('serviceLabels').value.includes(id)) {
            return getField('serviceLabels').value = getField('serviceLabels').value.filter(function (label) {
              return label !== id;
            });
          }

          return getField('serviceLabels').value = [].concat(_toConsumableArray(getField('serviceLabels').value), [id]);
        }
      });
    };

    return {
      service: service,
      getField: getField,
      serviceLabelsValue: serviceLabelsValue,
      currentServiceId: currentServiceId
    };
  }
});