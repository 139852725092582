import { VueTelInput } from 'vue-tel-input';
import { mapLocaleToCountry, getFormattedNationalPhoneNumber, getCountryCodeFromNumber } from '~/helpers';
import { phonePreferredCountries } from '~/helpers/constants';
import 'vue-tel-input/dist/vue-tel-input.css';
export default defineComponent({
  components: {
    VueTelInput: VueTelInput
  },
  props: {
    value: {
      // Type null by default since its nullable
      type: null,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object],
      default: ''
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useContext = useContext(),
        i18n = _useContext.app.i18n;

    var phoneValidator = ref(null);
    var formattedValue = computed(function () {
      return getFormattedNationalPhoneNumber(props.value);
    });
    var countryCode = computed(function () {
      return getCountryCodeFromNumber(props.value) || mapLocaleToCountry(i18n.locale);
    }); // We don't use the actual number but the formatted value that the `phoneObject` gives us so we don't have to work with parsing it later

    function onPhoneUpdate(_number, phoneObject) {
      if (phoneObject.number) {
        if (phoneValidator.value) {
          phoneValidator.value.reset();

          if (phoneValidator.value && !phoneObject.valid) {
            phoneValidator.value.setErrors([i18n.t('form.validations.phone')]);
          }
        }

        emit('input', phoneObject.number);
      }
    }

    return {
      countryCode: countryCode,
      formattedValue: formattedValue,
      mapLocaleToCountry: mapLocaleToCountry,
      onPhoneUpdate: onPhoneUpdate,
      phonePreferredCountries: phonePreferredCountries,
      phoneValidator: phoneValidator
    };
  }
});