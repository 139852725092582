import { CurrencyEnum } from './Money'
import { WithId } from '@kissmylabs/vuex-entitystore'
import { CalendarBatchDeleteServiceBoundPayload } from '~/types'

export enum DiscountTypeEnum {
  BOOKING_GLOBAL = 'BOOKING_GLOBAL',
  BOOKING_SERVICE = 'BOOKING_SERVICE'
}

interface IBookingDiscount {
  discountType: DiscountTypeEnum
  currency: CurrencyEnum
  amount?: number
  percent?: number
}

export interface NewBookingDiscount extends IBookingDiscount {
  id?: number
}

export type BookingDiscount = NewBookingDiscount & WithId

export type BatchNewBookingDiscount = Omit<NewBookingDiscount, 'start' | 'end' | 'service'>

export type BookingDiscountBatchDeleteForm = CalendarBatchDeleteServiceBoundPayload
