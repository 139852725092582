import healthSafetyMeasuresMutations from './mutations'
import healthSafetyMeasuresGetters from './getters'
import healthSafetyMeasuresActions from './actions'
import healthSafetyMeasuresState from './state'

export const healthSafetyMeasures = {
  namespaced: true,
  state: healthSafetyMeasuresState,
  actions: healthSafetyMeasuresActions,
  mutations: healthSafetyMeasuresMutations,
  getters: healthSafetyMeasuresGetters,
}
