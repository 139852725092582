import { Middleware } from '@nuxt/types'

const singleBookingMiddleware: Middleware = async({ app: { $accessor }, params }) => {
  const bookingId = parseInt(params.id)

  if (!$accessor.bookings.getAllIds().includes(bookingId)) {
    await $accessor.bookings.fetchOne(bookingId)
  }
  // Handle when loaded from admin, so we probably don't have any domain/service loaded.
  const booking = $accessor.bookings.getOne(bookingId)
  if (!$accessor.services.getAllIds().includes(booking.service)) {
    await $accessor.services.fetchOne(booking.service)
  }
  if (!$accessor.domains.getAllIds().includes(booking.domain)) {
    await $accessor.domains.fetchOne(booking.domain)
  }
  const service = $accessor.services.getOne(booking.service)

  await Promise.all([
    new Promise((resolve) => {
      if (!$accessor.customers.getAllIds().includes(booking.customer)) {
        $accessor.customers.fetchOne(booking.customer).then((r) => resolve(r))
      } else {
        resolve(null)
      }
    }),
    new Promise((resolve) => {
      if (!$accessor.domains.getAllIds().includes(service.domain)) {
        $accessor.domains.fetchOne(service.domain).then((r) => resolve(r))
      } else {
        resolve(null)
      }
    }),
  ])
}

export default singleBookingMiddleware
