import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { Customer } from '~/types/models'
import { resetState } from '~/helpers/store'
import baseState, { customer } from './state'
import { CustomerMutationTypes } from './types'

export const mutations = mutationTree(baseState,{
  ...createMutations<Customer>(customer),
  [CustomerMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },

  [CustomerMutationTypes.UPDATE_FIELD](state, { id, path, value }: { id: number, path: string, value: any }) {
    updateField(state.entities.byId[id], { path, value })
  },
})

export default mutations
