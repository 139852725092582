import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { DefaultServicePricingCreateForm, NewDefaultServicePricing, ServicePricing, ServicePricingBatchCreateForm, ServicePricingBatchDeleteForm, ServicePricingBatchEditForm, ServicePricingCreateForm } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { toIsoDateStringPeriod, toIsoStringPeriod } from '~/helpers'
import { formatPricingRates } from '~/helpers/servicePricing'
import { ICalendarBatchActionParams, ICalendarBatchParams } from '~/types'
import { RootMutationTypes } from '~/store/types'

type IServicePricingCreateBatchParams = ICalendarBatchParams<ServicePricingBatchCreateForm | ServicePricingBatchEditForm>
type IServicePricingResetBatchParams = ICalendarBatchParams<ServicePricingBatchDeleteForm>

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<ServicePricing>(),

  async createBatch(
    _,
    { domainId, payload, options }
      : IServicePricingCreateBatchParams,
  ): Promise<void> {
    const { i18n, $loadingToast, $errorToast, $successToast, $api, $accessor, $sentry } = this.app
    const { toastText } = options

    const toast = $loadingToast(toastText.pending)
    $accessor[RootMutationTypes.START_PREVENT_WINDOW_CLOSE](i18n.t('prevent_window_close'))

    try {
      const cleanPayload = {
        ...payload,
        periods: payload.periods.map(period => toIsoStringPeriod(period)),
        entity: {
          ...payload.entity,
          ...formatPricingRates(payload.entity),
        },
      }
      await $api.post(`domains/${domainId}/calendar/batch/servicepricing/create`, cleanPayload)

      // @todo Don't have enough time to do this another way,
      //  but we should be able to fetch everything with a single HTTP request.
      await Promise.all(
        payload.services.map(
          async(serviceId) => await $accessor.calendar.fetchSingleServicePricings(serviceId),
        ),
      )
      toast.goAway(0)
      $successToast(toastText.success)
    } catch (error) {
      $sentry.captureException(error)
      toast.goAway(0)
      $errorToast(toastText.error)
      throw error
    } finally {
      $accessor[RootMutationTypes.END_PREVENT_WINDOW_CLOSE]()
    }
  },

  async resetBatch(_, { domainId, payload, options }
    : IServicePricingResetBatchParams,
  ): Promise<void> {
    const { i18n, $loadingToast, $errorToast, $successToast, $api, $accessor, $sentry } = this.app
    const { toastText } = options

    const toast = $loadingToast(toastText.pending)
    $accessor[RootMutationTypes.START_PREVENT_WINDOW_CLOSE](i18n.t('prevent_window_close'))

    try {
      const cleanPayload = {
        ...payload,
        periods: payload.periods.map(period => toIsoStringPeriod(period)),
      }
      await $api.patch(`domains/${domainId}/calendar/batch/servicepricing/reset`, cleanPayload)

      // @todo Don't have enough time to do this another way,
      //  but we should be able to fetch everything with a single HTTP request.
      await Promise.all(
        payload.services.map(
          async(serviceId) => await $accessor.calendar.fetchSingleServicePricings(serviceId),
        ),
      )
      toast.goAway(0)
      $successToast(toastText.success)
    } catch (error) {
      $sentry.captureException(error)
      toast.goAway(0)
      $errorToast(toastText.error)
      throw error
    } finally {
      $accessor[RootMutationTypes.END_PREVENT_WINDOW_CLOSE]()
    }
  },

  async postOne(
    _,
    { servicePricing, isDefault }
      : { servicePricing: ServicePricingCreateForm | DefaultServicePricingCreateForm, isDefault: boolean },
  ): Promise<void> {
    const { $loadingToast, $errorToast, $successToast, $api, $accessor, $sentry, i18n } = this.app

    const toast = $loadingToast(i18n.tc('toast.service_pricing_creating', 1))

    try {
      const preparedPayload: typeof servicePricing = {
        ...servicePricing,
        ...formatPricingRates(servicePricing),
        isDefault,
      }
      await $api.post(`services/${servicePricing.service}/pricings`, preparedPayload)
      await $accessor.calendar.fetchSingleServicePricings(servicePricing.service)
      toast.goAway(0)
      $successToast(i18n.tc('toast.service_pricing_created', 1))
    } catch (error) {
      $sentry.captureException(error)
      toast.goAway(0)
      $errorToast(i18n.tc('toast.service_pricing_create_error', 1))
    }
  },

  async postMany({ dispatch }, { domainId, payload }: ICalendarBatchActionParams<ServicePricingBatchCreateForm>,
  ): Promise<void> {
    const { i18n } = this.app

    const createBatchOptions: IServicePricingCreateBatchParams['options'] = {
      toastText: {
        pending: i18n.tc('toast.service_pricing_creating', 2),
        success: i18n.tc('toast.service_pricing_created', 2),
        error: i18n.tc('toast.service_pricing_create_error', 2),
      },
    }
    await dispatch('createBatch', { domainId, payload, options: createBatchOptions })
  },

  async patchOne(
    _,
    { servicePricing }: { servicePricing: ServicePricing | NewDefaultServicePricing },
  ): Promise<void> {
    const { $api, $accessor, $loadingToast, $errorToast, i18n, $successToast, $sentry } = this.app

    const toast = $loadingToast(i18n.t('toast.service_pricing_updating'))
    try {
      let payload = {
        ...servicePricing,
        ...formatPricingRates(servicePricing),
      }
      if (!servicePricing.isDefault) {
        payload = {
          ...payload,
          ...toIsoDateStringPeriod(servicePricing as ServicePricing),
        }
      }

      await $api.patch(
        `services/${servicePricing.service}/pricings/${servicePricing.id}`,
        payload,
      )
      await $accessor.calendar.fetchSingleServicePricings(servicePricing.service)
      toast.goAway(0)
      $successToast(i18n.t('toast.service_pricing_updated'))
    } catch (e) {
      $sentry.captureException(e)
      toast.goAway(0)
      $errorToast(i18n.t('toast.service_pricing_update_error'))
      throw e
    }
  },

  async deleteOne(_, { servicePricing }: { servicePricing: ServicePricing }): Promise<void> {
    const { $api, $accessor, $loadingToast, $errorToast, i18n, $successToast, $sentry } = this.app

    const toast = $loadingToast(i18n.tc('toast.service_pricing_deleting', 1))
    try {
      await $api.delete(`services/${servicePricing.service}/pricings/${servicePricing.id}`)
      await $accessor.calendar.fetchSingleServicePricings(servicePricing.service)
      toast.goAway(0)
      $successToast(i18n.tc('toast.service_pricing_deleted', 1))
    } catch (e) {
      $sentry.captureException(e)
      toast.goAway(0)
      $errorToast(i18n.tc('toast.service_pricing_delete_error', 1))
      throw e
    }
  },

  async resetMany(
    { dispatch },
    { domainId, payload }: ICalendarBatchActionParams<ServicePricingBatchDeleteForm>)
    : Promise<void> {
    const { i18n } = this.app

    const batchOptions: IServicePricingResetBatchParams['options'] = {
      toastText: {
        pending: i18n.tc('toast.service_pricing_deleting', 2),
        success: i18n.tc('toast.service_pricing_deleted', 2),
        error: i18n.tc('toast.service_pricing_delete_error', 2),
      },
    }
    await dispatch('resetBatch', { domainId, payload, options: batchOptions })
  },

  async fetchDefaultForService({ commit }, serviceId: number): Promise<void | boolean> {
    const { $api } = this.app
    const pricing = await $api.get(`services/${serviceId}/pricings?filters[isDefault]=1`)
    return pricing.data.length > 0 && commit(BaseMutationTypes.CREATE, pricing.data[0])
  },
})

export default actions
