






































import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import orderBy from 'lodash/orderBy'
import { ServiceEquipmentTypeEnum } from '~/types/models'
import useServices from '~/composable/useServices'

export default defineComponent({
  name: 'ServiceEquipmentTypeSelect',
  props: {
    equipmentType: {
      type: String as PropType<ServiceEquipmentTypeEnum>,
      required: true,
      validator: (prop: ServiceEquipmentTypeEnum) => Object.values(ServiceEquipmentTypeEnum).includes(prop),
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      getCurrentServiceField: getField,
      getCurrentServiceUniqueEquipmentByType: getUniqueEquipment,
    } = useServices()
    const { app: { $accessor } } = useContext()
    const equipmentOptions = computed(() => orderBy(
      $accessor.serviceEquipments.getWhere((equipement) => equipement.type === props.equipmentType)))

    return {
      getField,
      getUniqueEquipment,
      equipmentOptions,
    }
  },
})
