import bookingConditionsActions from './actions'
import bookingConditionsMutations from './mutations'
import bookingConditionsGetters from './getters'
import bookingConditionsState from './state'

export const bookingConditions = {
  namespaced: true,
  state: bookingConditionsState,
  actions: bookingConditionsActions,
  mutations: bookingConditionsMutations,
  getters: bookingConditionsGetters,
}
