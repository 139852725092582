




























import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SymbolField',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    symbol: {
      type: String,
      required: true,
    },
  },
})
