import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { ServiceEquipment } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<ServiceEquipment>(),

  async fetchAll({ commit }): Promise<void> {
    const res = await this.app.$api.get('serviceequipments?limit=-1')
    if (res.data.length) {
      return commit(BaseMutationTypes.CREATE_MANY, res.data)
    }
  },
})

export default actions
