import { computed, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import { Promocode } from '~/types/models'

interface PromocodesHook {
  getPromocodeField: (field: string) => WritableComputedRef<any>
  getCreatePromocodeFormField: (field: string) => WritableComputedRef<any>
  postOne: () => Promise<boolean>
  patchOne: (params: {
    id: number;
    payload: Partial<Promocode>;
  }) => Promise<void>
}

function usePromocodes(): PromocodesHook {
  const { params, app: { $accessor } } = useContext()

  const currentPromocode = computed(() => $accessor.promocodes.getOne(parseInt(params.value.id)))

  const getPromocodeField = (field: string) => computed<any>({
    get: () => $accessor.promocodes.getField(currentPromocode.value.id)(field),
    set: value => {
      if (!$accessor.promocodes.getField(currentPromocode.value.id)('$isDirty') && field !== '$isDirty') {
        $accessor.promocodes.UPDATE_FIELD({
          id: currentPromocode.value.id,
          path: '$isDirty',
          value: true,
        })
      }
      if (field === 'code') {
        value = (value as string).toUpperCase()
      }
      return $accessor.promocodes.UPDATE_FIELD({
        id: currentPromocode.value.id,
        path: field,
        value,
      })
    },
  })

  const getCreatePromocodeFormField = (field: string) => computed<any>({
    get: () => $accessor.promocodes.getCreateFormField(field),
    set: value => {
      if (field === 'code') {
        value = (value as string).toUpperCase()
      }
      return $accessor.promocodes.UPDATE_CREATE_FORM_FIELD({
        path: field,
        value,
      })
    },
  })

  const postOne = async() => await $accessor.promocodes.postOne()

  const patchOne = async(params: { id: number, payload: Partial<Promocode> }) => await $accessor.promocodes.patchOne(params)

  return {
    getCreatePromocodeFormField,
    getPromocodeField,
    postOne,
    patchOne,
  }
}

export default usePromocodes
