import { Middleware } from '@nuxt/types'

const healthSafetyMeasuresMiddleware: Middleware = async({ app: { $api, $accessor } }) => {
  if ($accessor.healthSafetyMeasures.getAllIds().length === 0) {
    const res = await $api.get('domainhealthsafetymeasures')
    if (res.length) {
      $accessor.healthSafetyMeasures.CREATE_MANY(res)
    }
  }
}

export default healthSafetyMeasuresMiddleware
