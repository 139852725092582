import domainActivitiesMutations from './mutations'
import domainActivitiesGetters from './getters'
import domainActivitiesActions from './actions'
import domainActivitiesState from './state'

export const domainActivities = {
  namespaced: true,
  state: domainActivitiesState,
  actions: domainActivitiesActions,
  mutations: domainActivitiesMutations,
  getters: domainActivitiesGetters,
}
