import domainMarketplaceConfigurationMutations from './mutations'
import domainMarketplaceConfigurationActions from './actions'
import domainMarketplaceConfigurationState from './state'
import domainMarketplaceConfigurationGetters from './getters'

export const domainMarketplaceConfigurations = {
  namespaced: true,
  state: domainMarketplaceConfigurationState,
  actions: domainMarketplaceConfigurationActions,
  mutations: domainMarketplaceConfigurationMutations,
  getters: domainMarketplaceConfigurationGetters,
}
