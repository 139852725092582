import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import baseState, { serviceType } from './state'
import { ServiceType } from '~/types/models'
import { ServicesTypeMutationTypes } from './types'
import { resetState } from '~/helpers/store'

export const mutations = mutationTree(baseState, {
  ...createMutations<ServiceType>(serviceType),
  [ServicesTypeMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
  [ServicesTypeMutationTypes.UPDATE_FIELD]: (state, { id, path, value }: { id: number, path: string, value: any }) => updateField(state.entities.byId[id], { path, value }),

})

export default mutations
