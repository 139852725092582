












































import { DrawerDisplayModeEnum } from '~/store/calendar/types'
import { CalendarDrawerNameEnum, CalendarDrawerOptions, CalendarEntitiesEnum } from '~/types/Calendar'
import { CalendarEvent } from '~/types'
import { Service, ServiceDiscount, ServiceDiscountCalendar } from '~/types/models'
import { computed, ComputedRef, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import { DateTime } from 'luxon'
import { toAPIFormat } from '~/helpers'
import useCalendar from '~/composable/useCalendar'

export default defineComponent({
  name: 'CalendarServiceDiscountLine',
  props: {
    service: {
      type: Object as PropType<Service>,
      required: true,
    },
  },
  setup(props) {
    const { app: { $accessor, i18n } } = useContext()
    const {
      getCalendarDays, getCalendarPushEvents, lineWidth, lineRef, showDiscount, onDiscountClicked,
    } = useCalendar()

    const serviceDiscounts: ComputedRef<ServiceDiscountCalendar | null> = computed(() => {
      return $accessor.calendar.serviceDiscountCalendars.find((discount) => discount.service === props.service.id) || null
    })

    function getServiceDiscountForDay(day: DateTime) {
      if (serviceDiscounts.value) {
        return serviceDiscounts.value.calendar[toAPIFormat(day)]
      }
      return null
    }

    const onDayClicked = (day: DateTime) => {
      getServiceDiscountForDay(day)
      $accessor.calendar.UDPATE_SELECTED_PERIODS([{
        start: day.toJSDate(),
        end: day.toJSDate(),
      }])

      const drawerOpts: CalendarDrawerOptions = {
        drawer: CalendarDrawerNameEnum.CREATE,
        mode: DrawerDisplayModeEnum.CREATE,
        data: {
          service: props.service.id,
        },
      }
      if ($accessor.calendar.ui.isDrawerOpened) {
        $accessor.calendar.RESET_UI_DRAWER()
      }

      $accessor.calendar.UI_OPEN_DRAWER(drawerOpts)
      if ($accessor.calendar.ui.drawer === CalendarDrawerNameEnum.CREATE) {
        $accessor.calendar.UPDATE_SELECTED_SERVICES([props.service.id])
        $accessor.calendar.UPDATE_ENTITY_FORM_OPENED(CalendarEntitiesEnum.DISCOUNT)
      }
    }

    const events: ComputedRef<Record<number, CalendarEvent<ServiceDiscount> & { label: string, displayKey: number }>> = computed(() => {
      const discounts = serviceDiscounts.value
      if (lineWidth.value && discounts) {
        let key = 0
        return (getCalendarPushEvents<ServiceDiscount>(discounts.calendar, lineWidth.value))
          .reduce((acc, event) => {
            key++
            return {
              ...acc,
              [key]: {
                displayKey: key,
                label: $accessor.serviceDiscounts.getOne(event.id)?.label,
                ...event,
              },
            }
          }
          , {})
      }
      return []
    })

    function getTooltipContent(entity: ServiceDiscount) {
      let content = `${entity.degressivityRates[0].percentDiscount} % ${i18n.t('discounts.from_label')} ${entity.degressivityRates[0].nightNumber} ${i18n.t('common.nights')}`
      for (let i = 1; i < entity.degressivityRates.length; i++) {
        content += `,  ${entity.degressivityRates[i].percentDiscount} %  ${i18n.t('discounts.from_label')} ${entity.degressivityRates[i].nightNumber} ${i18n.t('common.nights')}`
      }
      return content
    }

    function getLineContent(entity: ServiceDiscount) {
      return `${entity.degressivityRates[0].percentDiscount} % ${i18n.t('discounts.from_label')} ${entity.degressivityRates[0].nightNumber} ${i18n.t('common.nights')} ${entity.degressivityRates.length > 1 ? '...' : ''}`
    }

    return {
      lineRef,
      getCalendarDays,
      getLineContent,
      getTooltipContent,
      showDiscount,
      onDiscountClicked,
      events,
      onDayClicked,
    }
  },
})
