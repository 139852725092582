import { useContext } from '@nuxtjs/composition-api'
import { CartStatusColorsEnum, CartStatusEnum } from '~/types/models/Cart'

interface CartHook {
  getTranslatedStatus(status: CartStatusEnum): string
  getClassFromStatus(status: CartStatusEnum): CartStatusColorsEnum
}

function useCart(): CartHook {
  const { app: { i18n } } = useContext()

  function getTranslatedStatus(status: CartStatusEnum) {
    const key = CartStatusEnum[status].toLowerCase() as keyof typeof CartStatusEnum
    return i18n.t(`cart.status.${key}`).toString()
  }

  function getClassFromStatus(status: CartStatusEnum) {
    return CartStatusColorsEnum[status]
  }

  return {
    getTranslatedStatus,
    getClassFromStatus,
  }
}

export default useCart
