import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseMessage',{attrs:{"type":"is-info","has-icon":"","closable":false}},[_c('div',{staticClass:"has-text-centered has-text-weight-bold"},[_vm._v("\n      "+_vm._s(_vm.$t('rental.rental_help_text'))+"\n    ")])]),_vm._v(" "),(_vm.allOptions)?_c('BasePageSection',{attrs:{"aria-id":"serviceOptions","card-class":"is-paddingless"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("\n      "+_vm._s(_vm.$t('rental.the_options'))+"\n    ")]),_vm._v(" "),_vm._l((_vm.availableOptionCategories),function(category){return _c('OptionsList',{key:category,attrs:{"name":_vm.$t(("options.categories." + (category.toLowerCase()))).toString(),"options":_vm.orderBy(_vm.allOptions.filter(function (o) { return o.category === category || (!o.category && category === _vm.OptionsCategoriesEnum.GENERAL); }), 'category', 'desc')},scopedSlots:_vm._u([{key:"optional",fn:function(ref){
var option = ref.option;
return [_c('span',{class:['circle', option.isActive ? 'is-success' : 'is-danger']}),_vm._v(" "),_c('span',[_vm._v("\n          "+_vm._s(option.isActive ? _vm.$t('options.active_for_provider') : _vm.$t('options.inactive_for_provider'))+"\n        ")])]}},{key:"default",fn:function(ref){
var option = ref.option;
return [_c('HRSwitch',{attrs:{"label":_vm.getIsEnabled(option).value ? _vm.$t('options.disable_for_rental') : _vm.$t('options.enable_for_rental'),"disabled":!option.isActive},model:{value:(_vm.getIsEnabled(option).value),callback:function ($$v) {_vm.$set(_vm.getIsEnabled(option), "value", $$v)},expression:"getIsEnabled(option).value"}})]}}],null,true)})})],2):_vm._e(),_vm._v(" "),(_vm.additionalCosts)?_c('BasePageSection',{attrs:{"aria-id":"serviceAdditionalCosts","card-class":"is-paddingless"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("\n      "+_vm._s(_vm.$t('rental.the_additional_costs'))+"\n    ")]),_vm._v(" "),_vm._l((_vm.availableCostCategories),function(category){return _c('OptionsList',{key:category,attrs:{"name":_vm.$t(("options.categories." + (category.toLowerCase()))).toString(),"options":_vm.orderBy(_vm.additionalCosts.filter(function (o) { return o.category === category || (!o.category && category === _vm.OptionsCategoriesEnum.GENERAL); }), 'category', 'asc')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('HRSwitch',{attrs:{"label":_vm.getIsEnabled(option).value ? _vm.$t('options.disable_for_rental') : _vm.$t('options.enable_for_rental')},model:{value:(_vm.getIsEnabled(option).value),callback:function ($$v) {_vm.$set(_vm.getIsEnabled(option), "value", $$v)},expression:"getIsEnabled(option).value"}})]}}],null,true)})})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }