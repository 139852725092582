import { createGetters } from '@kissmylabs/vuex-entitystore'
import { getterTree } from 'typed-vuex'
import { getField } from 'vuex-map-fields'
import { DomainActivityEntry } from '~/types/models'
import { state } from './state'

export const getters = getterTree(state, {
  ...createGetters<DomainActivityEntry>(),
  getField: (state) => (id: number): any => getField(state.entities.byId[id]),
  getAllActivityIds(_, { getAllArray }): number[] {
    return getAllArray().map((entry: DomainActivityEntry) => entry.domainActivity)
  },
})

export default getters
