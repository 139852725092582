







import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { LocaleMessages, TranslateResult } from 'vue-i18n'
import { TooltipPlacements } from '~/types'
import { ThemeColors } from '~/types/Tailwind'

export default defineComponent({
  name: 'StatusBadge',
  props: {
    label: {
      type: String as PropType<string | TranslateResult | LocaleMessages>,
      default: '',
    },
    position: {
      type: String as PropType<TooltipPlacements>,
      default: TooltipPlacements.RIGHT,
      validator: (prop: string) => (Object.values(TooltipPlacements) as string[]).includes(prop),
    },
    color: {
      type: String as PropType<ThemeColors>,
      default: ThemeColors.GRAY,
    },
  },
})
