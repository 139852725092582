import unavailabilitiesMutations from './mutations'
import unavailabilitiesGetters from './getters'
import unavailabilitiesActions from './actions'
import unavailabilitiesState from './state'

export const unavailabilities = {
  namespaced: true,
  state: unavailabilitiesState,
  actions: unavailabilitiesActions,
  mutations: unavailabilitiesMutations,
  getters: unavailabilitiesGetters,
}
