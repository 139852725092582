import { createGetters } from '@kissmylabs/vuex-entitystore'
import { getterTree } from 'typed-vuex'
import { getField } from 'vuex-map-fields'
import { Customer } from '~/types/models'
import { state } from './state'

export const getters = getterTree(state, {
  ...createGetters<Customer>(),
  getField: state => (id: number) => getField(state.entities.byId[id]),
})

export default getters
