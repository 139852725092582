export default {
  locales: [
    // {
    //   code: 'en',
    //   iso: 'en-US',
    //   file: 'en-full.js',
    // },
    {
      code: 'fr',
      iso: 'fr-FR',
      file: 'fr-full.js',
    },
    // {
    //   code: 'es',
    //   iso: 'es-ES',
    //   file: 'es-full.js',
    // },
  ],
  defaultLocale: 'fr',
  lazy: true,
  langDir: 'locale/',
  strategy: 'prefix_and_default',
  rootRedirect: 'fr/',
  vuex: {
    syncLocale: true,
    syncRouteParams: true,
  },
  vueI18n: { fallbackLocale: 'fr' },
}
