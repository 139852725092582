function useReviews() {
  var _useContext = useContext(),
      i18n = _useContext.app.i18n;

  function getReviewersName(_ref) {
    var firstName = _ref.firstName,
        lastName = _ref.lastName;

    if (firstName === 'anonymous') {
      return i18n.t('reviews.common.anonymous');
    }

    return "".concat(firstName, " ").concat(lastName);
  }

  return {
    getReviewersName: getReviewersName
  };
}

export default useReviews;