














































































































































































import { defineComponent, Ref, ref, useContext } from '@nuxtjs/composition-api'
import { ThreeDS2Data } from '~/types'
import { PaymentMethodEnum } from '~/types/models'

export default defineComponent({
  name: 'GiftCardPaymentModal',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    bookingId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { app: { $accessor, $api, $featureToggle, i18n, $errorToast, $loadingToast, $successToast } } = useContext()

    const getBaseForm = () => ({
      bookingId: props.bookingId,
      type: PaymentMethodEnum.CREDIT_CARD,
      cardNumber: '',
      month: '',
      year: '',
      ccv: '',
      cardHolderName: '',
    })

    const form = ref(getBaseForm())

    function resetForm() {
      form.value = getBaseForm()
    }

    const paymentErrors: Ref<string[]> = ref([])

    async function submitPayment() {
      const toast = $loadingToast(i18n.t('toast.payment_creating'))
      try {
        paymentErrors.value = []
        let payload
        if ($featureToggle.toggles.threeDS2) {
          if ($accessor.threeDS2Data.isComplete) {
            payload = {
              ...form.value,
              year: `20${form.value.year}`,
              threeDS2Data: $accessor.threeDS2Data as ThreeDS2Data,
            }
          } else {
            console.error('3DS2 Data incomplete!!! Something went wrong!!!') // eslint-disable-line
            // TODO Sentry here.
          }
        }
        // If threeDS2 enabled but it's not complete, fallback to vanilla payment.
        if (typeof payload === 'undefined') {
          payload = {
            ...form.value,
            year: `20${form.value.year}`,
          }
        }

        const res = await $api.post('gcs_pay', payload)
        toast.goAway(0)

        if (res.isSuccess) {
          if (res.paymentRedirect !== null) {
            window.location = res.paymentRedirect
          } else {
            $successToast(i18n.t('toast.payment_successful'))
            emit('payment-succeeded')
            emit('update:active')
            resetForm()
          }
        } else {
          for (const err of res.errors) {
            paymentErrors.value.push(i18n.t(`giftcards.errors.homelocpay.${err}`) as string)
          }
        }
      } catch (error) {
        $errorToast(i18n.t('toast.error_generic'))
        toast.goAway(0)
      }
    }

    return {
      form,
      submitPayment,
      paymentErrors,
    }
  },
})
