import giftCardsMutations from './mutations'
import giftCardsGetters from './getters'
import giftCardsActions from './actions'
import giftCardsState from './state'

export const giftCards = {
  namespaced: true,
  state: giftCardsState,
  actions: giftCardsActions,
  mutations: giftCardsMutations,
  getters: giftCardsGetters,
}
