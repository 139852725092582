import domainsMutations from './mutations'
import domainsActions from './actions'
import domainsState from './state'
import domainsGetters from './getters'

export const domains = {
  namespaced: true,
  state: domainsState,
  actions: domainsActions,
  mutations: domainsMutations,
  getters: domainsGetters,
}
