












































































import { defineComponent, reactive, useContext, watch } from '@nuxtjs/composition-api'

interface State {
  file: File | null
  uploadErrors: { message: string, line: string}[]
}

export default defineComponent({
  name: 'UploadCsvModal',
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  setup(_, { emit }) {
    const { app: { i18n, $api, $loadingToast, $errorToast, $successToast } } = useContext()

    const state = reactive<State>({
      file: null,
      uploadErrors: [],
    })

    watch(
      () => state.file,
      () => state.uploadErrors = [],
    )

    async function uploadFile() {
      if (state.file) {
        const toast = $loadingToast(i18n.t('toast.uploading'))
        try {
          const formData = new FormData()
          formData.append('file', state.file)

          const res = await $api.post(
            'giftcards_import',
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } })
          toast.goAway(0)

          // Status is always 200 OK but it can contain parsing errors in the response so we check for those
          if (res.length >= 1) {
            res.map((error: { message: string, line: string}) => state.uploadErrors.push(error))

          // If no errors in response then everything uploaded fine, reset the form and close the modal
          } else {
            $successToast(i18n.t('toast.uploaded'))
            state.file = null
            state.uploadErrors = []
            emit('update:active', false)
          }
        } catch (error) {
          toast.goAway(0)
          $errorToast(i18n.t('toast.error_generic'))
        } finally {
          emit('refresh')
        }
      }
    }

    const onToggleActive = (val: boolean) => {
      emit('refresh')
      emit('update:active', val)
    }

    return {
      state,
      uploadFile,
      onToggleActive,
    }
  },
})
