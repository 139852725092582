













import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'TabsNavigation',
  props: {
    value: {
      type: Number,
      required: true,
    },
    routes: {
      type: Array,
      required: true,
    },
  },
})
