import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-full is-one-quarter-desktop"},[(_vm.platformId === _vm.ExternalPlatformTypeEnum.SH)?[_c('ValidationProvider',{attrs:{"name":"domainName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('form.fields.sh_establishment_id'),"message":errors,"data-cy":"DomainShIdInput"}},[_c('BInput',{model:{value:(_vm.getInnerValueField('establishmentId').value),callback:function ($$v) {_vm.$set(_vm.getInnerValueField('establishmentId'), "value", $$v)},expression:"getInnerValueField('establishmentId').value"}})],1)]}}],null,false,3179336477)}),_vm._v(" "),_c('BaseField',{attrs:{"label":_vm.$t('form.fields.sh_engine_id'),"data-cy":"DomainShEngineInput"}},[_c('BInput',{attrs:{"value":_vm.getInnerValueField('engineId').value,"disabled":""}})],1)]:_vm._e(),_vm._v(" "),(_vm.platformId === _vm.ExternalPlatformTypeEnum.EVIIVO)?_c('ValidationProvider',{attrs:{"data-cy":"EviivoShortnameInput","name":"domainName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('form.fields.eviivo_shortname'),"message":errors}},[_c('BInput',{model:{value:(_vm.getInnerValueField('ev_shortName').value),callback:function ($$v) {_vm.$set(_vm.getInnerValueField('ev_shortName'), "value", $$v)},expression:"getInnerValueField('ev_shortName').value"}})],1)]}}],null,false,3686316878)}):_vm._e(),_vm._v(" "),(_vm.platformId === _vm.ExternalPlatformTypeEnum.KE)?_c('ValidationProvider',{attrs:{"data-cy":"KePropertyIdInput","name":"domainName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('form.fields.ke_property_id'),"message":errors}},[_c('BInput',{model:{value:(_vm.getInnerValueField('propertyId').value),callback:function ($$v) {_vm.$set(_vm.getInnerValueField('propertyId'), "value", $$v)},expression:"getInnerValueField('propertyId').value"}})],1)]}}],null,false,1308827353)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }