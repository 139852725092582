import serviceEquipmentsMutations from './mutations'
import serviceEquipmentsGetters from './getters'
import serviceEquipmentsActions from './actions'
import serviceEquipmentsState from './state'

export const serviceEquipments = {
  namespaced: true,
  state: serviceEquipmentsState,
  actions: serviceEquipmentsActions,
  mutations: serviceEquipmentsMutations,
  getters: serviceEquipmentsGetters,
}
