import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { ServicePricing } from '~/types/models'
import { resetState } from '~/helpers/store'
import { ServicesPricingMutationTypes } from './types'
import baseState, { servicePricing } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<ServicePricing>(servicePricing),
  [ServicesPricingMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
})

export default mutations
