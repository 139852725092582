import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es7.object.values.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
export default defineComponent({
  name: 'FilteredServiceUnitsSelectCheckboxes',
  props: {
    value: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    services: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useContext = useContext(),
        $accessor = _useContext.app.$accessor;

    watch(function () {
      return props.services;
    }, function (newValue) {
      var newInnerValue = []; // Compute a new inner value based on services received as props

      Object.values(newValue).forEach(function (service) {
        if (hasMultipleUnits(service)) {
          var unitsAlreadyInState = service.serviceUnits.filter(function (id) {
            return innerValue.value.includes(id);
          });
          var shouldPreserveState = unitsAlreadyInState.length !== service.serviceUnits.length && unitsAlreadyInState.length > 0;

          if (shouldPreserveState) {
            newInnerValue = [].concat(_toConsumableArray(newInnerValue), _toConsumableArray(unitsAlreadyInState));
            return;
          }

          newInnerValue = [].concat(_toConsumableArray(newInnerValue), _toConsumableArray(service.serviceUnits));
          return;
        }

        newInnerValue = [].concat(_toConsumableArray(newInnerValue), _toConsumableArray(service.serviceUnits));
      });
      innerValue.value = newInnerValue;
    }, {
      deep: true
    });
    var innerValue = computed({
      get: function get() {
        return props.value;
      },
      set: function set(newValue) {
        emit('input', newValue);
      }
    });
    var servicesWithMultipleUnits = computed(function () {
      return Object.values(props.services).filter(function (service) {
        return service.serviceUnits.length > 1;
      });
    });

    function getUnitsForService(serviceId) {
      return $accessor.serviceUnits.getWhere(function (serviceUnit) {
        return serviceId === serviceUnit.service;
      });
    }

    function hasMultipleUnits(service) {
      return service.serviceUnits.length > 1;
    }

    return {
      innerValue: innerValue,
      getUnitsForService: getUnitsForService,
      servicesWithMultipleUnits: servicesWithMultipleUnits,
      hasMultipleUnits: hasMultipleUnits
    };
  }
});