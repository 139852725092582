import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { HealthSafetyMeasure } from '~/types/models'
import { resetState } from '~/helpers/store'
import baseState, { healthSafetyMeasure } from './state'
import { HealthSafetyMeasuresMutationTypes } from './types'

export const mutations = mutationTree(baseState, {
  ...createMutations<HealthSafetyMeasure>(healthSafetyMeasure),
  [HealthSafetyMeasuresMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
  [HealthSafetyMeasuresMutationTypes.UPDATE_FIELD]: (state, { id, path, value }: { id: number, path: string, value: any }) =>  updateField(state.entities.byId[id], { path, value }),

})

export default mutations
