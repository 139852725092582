import { DomainExternalId, ExternalPlatformTypeEnum, SecureHolidayEngineEnum, ServiceExternalId } from '~/types/models'

export function getSecureHolidayEngineId(): SecureHolidayEngineEnum {
  return process.env.NODE_ENV === 'production' ? SecureHolidayEngineEnum.ENGINE_PRODUCTION : SecureHolidayEngineEnum.ENGINE_SANDBOX
}

export function getDefaultDomainExternalId(): DomainExternalId {
  return {
    platformId: ExternalPlatformTypeEnum.SH,
    data: {
      engineId: getSecureHolidayEngineId(),
      establishmentId: '',
    },
  }
}

export function getDefaultServiceExternalIdByPlatformId(platformId: ExternalPlatformTypeEnum): ServiceExternalId {
  switch (platformId) {
    case ExternalPlatformTypeEnum.KE:
      return {
        platformId,
        data: {
          unitId: '',
        },
      }

    case ExternalPlatformTypeEnum.EVIIVO:
      return {
        platformId,
        data: {
          ev_roomTypeId: '', // eslint-disable-line camelcase
        },
      }

    case ExternalPlatformTypeEnum.SH:
    default: //SH
      return {
        platformId,
        data: {
          productId: '',
          engineId: getSecureHolidayEngineId(),
        },
      }
  }
}

export function getDefaultExternalIdByPlatformId(platformId: ExternalPlatformTypeEnum): DomainExternalId {
  switch (platformId) {
    case ExternalPlatformTypeEnum.KE:
      return {
        platformId,
        data: {
          propertyId: '',
        },
      }
    case ExternalPlatformTypeEnum.EVIIVO:
      return {
        platformId,
        data: {
          ev_shortName: '', // eslint-disable-line camelcase
        },
      }
    case ExternalPlatformTypeEnum.SH:
    default: // SH
      return getDefaultDomainExternalId()
  }
}
