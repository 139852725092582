





































































import { defineComponent, reactive, ref, watch } from '@nuxtjs/composition-api'
import useImage from '~/composable/useImage'

export default defineComponent({
  name: 'EditableImage',
  props: {
    file: {
      type: Object,
      required: true,
    },
    featured: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { generateSignedUrl } = useImage()
    // see https://github.com/alexjoverm/VLazyImage/issues/29
    const lazyImage = ref(null)

    const state = reactive({
      currentTab: 'main',
      placeholderUrl: props.featured
        ? require('~/assets/img/placeholder-540x300.jpg')
        : require('~/assets/img/placeholder-300x300-new.jpg'),
      thumbnailCroppa: {} as any,
      featuredCroppa: {},
      imageEl: null as any,
      isEditing: false,
      isEditingLoaded: false,
      valignPct: 'valign_pct' in props.file.metadata ? props.file.metadata.valign_pct : 50,
    })
    watch(() => state.isEditing, (val, prevVal, onInvalidate) => {
      if (val && !prevVal && !state.isEditingLoaded) {
        state.imageEl = new Image()
        state.imageEl.crossOrigin = 'anonymous'
        state.imageEl.src = generateSignedUrl(props.file.url)
        state.imageEl.decode()
          .then(() => {
            state.isEditingLoaded = true
            // TODO responsive
            // const imgWidth = state.imageEl.width
            // state.modalSize.width = imgWidth > 780 ? 780 : imgWidth
            // state.modalSize.height = state.modalSize.width * 0.7
          })
        onInvalidate(() => {
          if (!state.isEditingLoaded) {
            state.imageEl = null
          }
        })
      }
    })

    function onStartEditing() {
      state.isEditing = true
    }

    function saveImage() {
      emit('onPictureUpdate', {
        ...props.file,
        $isDirty: true,
        metadata: {
          ...props.file.metadata,
          valign_pct: parseInt(state.valignPct), // eslint-disable-line camelcase
          // ...state.thumbnailCroppa.getMetadata(),
          // featuredMeta: state.featuredCroppa.getMetadata(),
        },
      })
      state.isEditing = false
    }

    return {
      lazyImage,
      state,
      onStartEditing,
      saveImage,
      generateSignedUrl,
    }
  },
})
