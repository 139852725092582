

















import { defineComponent, reactive, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'HelpLinks',
  props: {
    previewUrl: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { app } = useContext()

    const state = reactive({
      showPreviewModal: false,
      previewIframeUrl: null,
    })

    async function onCLickPreview() {
      const res = await app.$axios.get(props.previewUrl)
      window.open(res.data.url, '_blank')
    }

    return {
      state,
      onCLickPreview,
    }
  },
})
