import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { resetState } from '~/helpers/store'
import { Provider } from '~/types/models'
import { ProviderMutationTypes } from './types'
import baseState, { baseCreateForm, provider } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<Provider>(provider),

  [ProviderMutationTypes.SET_CURRENT](state, payload: Provider) {
    state.currentProvider = payload
  },

  [ProviderMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },

  [ProviderMutationTypes.UPDATE_FIELD](state, { path, value, id }: { path: string, value: any, id: number }) {
    updateField(state.entities.byId[id], { path, value })
  },

  [ProviderMutationTypes.ADD_PROVIDER_FILE](state, { file }: { file: any }) {
    state.currentProvider?.files.push(file)
  },

  [ProviderMutationTypes.UPDATE_CREATE_FORM_FIELD](state, payload) {
    updateField(state.createForm, payload)
  },

  [ProviderMutationTypes.RESET_CREATE_FORM](state) {
    state.createForm = baseCreateForm()
  },
})

export default mutations
