import { Middleware } from '@nuxt/types'

const bookingGiftCardMiddleware: Middleware = async({ app: { $accessor }, route }) => {
  const isAdmin = $accessor.users.currentUserIsAdmin
  if (isAdmin) {
    const booking = $accessor.bookings.getOne(parseInt(route.params.id))
    const missingGiftCardIds = booking.bookingGiftCards.map(bookingGiftCard => {
      if (!$accessor.giftCards.getAllIds().includes(bookingGiftCard.giftCard)) {
        return bookingGiftCard.giftCard
      }
    })
    if (missingGiftCardIds.length > 0) {
      const filteredIds = missingGiftCardIds.filter(id => id !== undefined)
      await $accessor.giftCards.fetchMany(filteredIds as number[])
      const giftCards = $accessor.giftCards.getWhereArray(card => missingGiftCardIds.includes(card.id))

      const spendingsIds = giftCards.reduce((acc, card) => [...acc, ...card.spendings], [] as number[])
      const missingSpendingIds = spendingsIds.length > 0 ? spendingsIds.filter(id => !$accessor.giftCardSpendings.getAllIds().includes(id)) : []
      if (missingSpendingIds.length > 0) {
        await $accessor.giftCardSpendings.fetchMany(missingSpendingIds)
      }
    }
  }
}

export default bookingGiftCardMiddleware
