




































import { defineComponent } from '@nuxtjs/composition-api'
import useGiftCards from '~/composable/useGiftCards'
import AppContentHeading from '~/components/content/AppContentHeading.vue'
import GiftCardsForm from '~/components/form/GiftCardsForm.vue'

export default defineComponent({
  name: 'CreateGiftCard',
  components: {
    AppContentHeading,
    GiftCardsForm,
  },
  setup() {
    const { getCreateGiftCardFormField: getField, postGiftCard } = useGiftCards()

    return {
      getField,
      postGiftCard,
    }
  },
})
