































import { computed, ComputedRef, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import orderBy from 'lodash/orderBy'
import { ServiceEquipment, ServiceEquipmentTypeEnum } from '~/types/models'
import useServices from '~/composable/useServices'

interface PropEquipmentType {
  type: ServiceEquipmentTypeEnum
  label: string
}

interface EquipmentOptionsType {
  label: string,
  items: ServiceEquipment[]
}

export default defineComponent({
  name: 'ServiceEquipmentTypeGroupSelect',
  props: {
    equipmentTypes: {
      type: Array as PropType<PropEquipmentType[]>,
      required: true,
    },
  },
  setup(props) {
    const { app: { $accessor, $translateEntityField } } = useContext()
    const { getCurrentServiceField: getField, getCurrentServiceEquipmentMutex } = useServices()

    const equipmentOptions: ComputedRef<Record<ServiceEquipmentTypeEnum, EquipmentOptionsType>> = computed(() => props.equipmentTypes.reduce((acc, types) => {
      acc[types.type] = {
        label: types.label,
        items: orderBy(
          $accessor.serviceEquipments.getWhere((equipment) => equipment.type === types.type),
          eq => $translateEntityField(eq.name),
        ),
      }
      return acc
    }, {} as Record<ServiceEquipmentTypeEnum, EquipmentOptionsType>))

    return {
      getField,
      equipmentOptions,
      getCurrentServiceEquipmentMutex,
    }
  },
})
