import { defineComponent } from '@nuxtjs/composition-api';
import useDomain from '~/composable/useDomain';
export default defineComponent({
  name: 'DomainCancelPolicy',
  setup: function setup() {
    var _useContext = useContext(),
        i18n = _useContext.app.i18n;

    var _useDomain = useDomain(),
        getDomainMetadataField = _useDomain.getDomainMetadataField;

    var selectedPolicy = ref(getDomainMetadataField('cancelPolicy').value);
    var value = ref('MODERATE');

    function updateValue(val) {
      selectedPolicy.value = val;
    }

    watch(selectedPolicy, function (newVal) {
      return getDomainMetadataField('cancelPolicy').value = newVal;
    });
    var policies = [{
      name: 'FLEXIBLE',
      cancel: i18n.t('establishment.cancel_policy.policies.FLEXIBLE.cancel_text'),
      modify: i18n.t('establishment.cancel_policy.policies.FLEXIBLE.modify_text'),
      recommended: false
    }, {
      name: 'MODERATE',
      cancel: i18n.t('establishment.cancel_policy.policies.MODERATE.cancel_text'),
      modify: i18n.t('establishment.cancel_policy.policies.MODERATE.modify_text'),
      recommended: true
    }, {
      name: 'STRICT',
      cancel: i18n.t('establishment.cancel_policy.policies.STRICT.cancel_text'),
      modify: i18n.t('establishment.cancel_policy.policies.STRICT.modify_text'),
      recommended: false
    }, {
      name: 'NONE',
      text: i18n.t('establishment.cancel_policy.policies.NONE.text'),
      recommended: false
    }];
    return {
      policies: policies,
      updateValue: updateValue,
      selectedPolicy: selectedPolicy,
      value: value
    };
  }
});