import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { Service } from '~/types/models'
import { resetState } from '~/helpers/store'
import { ServicesMutationTypes } from './types'
import baseState, { service } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<Service>(service),
  [ServicesMutationTypes.RESET_STATE](state): void {
    resetState(state, baseState)
  },
  [ServicesMutationTypes.UPDATE_FIELD](state, { id, path, value }: { id: number, path: string, value: any }) {
    updateField(state.entities.byId[id], { path, value })
  },
})

export default mutations
