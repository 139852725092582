import _typeof from "@babel/runtime/helpers/esm/typeof";
import Idle from '@hackmd/idle-js';
import { PresenceStatus } from '~/types';
import { RootMutationTypes } from '~/store/types';
import { AuthMutationTypes } from '~/store/auth/types';
export default function (_ref, inject) {
  var $accessor = _ref.app.$accessor,
      $config = _ref.$config,
      store = _ref.store;

  function shouldUpdateIdleState() {
    if (!window.echoChat) {
      console.log('window.echoChat is not defined'); // eslint-disable-line
    }

    return _typeof(window.echoChat) && $accessor.users.currentUserIsProvider;
  }

  var createIdle = function createIdle() {
    var idle = new Idle({
      awayTimeout: $config.idleTimeout
    });

    idle.onAway = function () {
      console.log("idle.onAway, shouldUpdateIdleState is ".concat(shouldUpdateIdleState())); // eslint-disable-line

      if (shouldUpdateIdleState()) {
        var _window$echoChat;

        store.commit(RootMutationTypes.PRESENCE_CURRENT_USER_SET_STATUS, PresenceStatus.IDLE);
        (_window$echoChat = window.echoChat) === null || _window$echoChat === void 0 ? void 0 : _window$echoChat.whisper('changeState', {
          id: $accessor.users.currentUserId,
          status: PresenceStatus.IDLE
        });
      }
    };

    idle.onAwayBack = function () {
      console.log("idle.onAwayBack, shouldUpdateIdleState is ".concat(shouldUpdateIdleState())); // eslint-disable-line

      if (shouldUpdateIdleState()) {
        var _window$echoChat2;

        store.commit(RootMutationTypes.PRESENCE_CURRENT_USER_SET_STATUS, PresenceStatus.ONLINE);
        (_window$echoChat2 = window.echoChat) === null || _window$echoChat2 === void 0 ? void 0 : _window$echoChat2.whisper('changeState', {
          id: $accessor.users.currentUserId,
          status: PresenceStatus.ONLINE
        });
      }
    };

    return idle;
  };

  var idle = null;

  if ($accessor.auth.getIsLoggedIn) {
    console.log('starting idle check'); // eslint-disable-line

    idle = createIdle();
    idle.start();
  } // start/stop idle when logging in/out.


  store.subscribe(function (mutation) {
    var _idle;

    switch (mutation.type) {
      case "auth/".concat(AuthMutationTypes.LOGIN):
        console.log('starting idle check'); // eslint-disable-line

        idle = createIdle();
        idle.start();
        break;

      case "auth/".concat(AuthMutationTypes.LOGOUT):
        console.log('stopping idle check'); // eslint-disable-line

        (_idle = idle) === null || _idle === void 0 ? void 0 : _idle.stop();
        idle = null;
        break;

      default:
        break;
    }
  });
  inject('idle', idle);
}