import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api'
import { DomainEquipmentEntry, DomainEquipment, NewDomainEquipmentEntry } from '~/types/models'
import { PatchPayload } from '~/helpers/api'
import useI18n from '~/composable/useI18n'

interface DomainEquipmentsHook {
  availableEquipments: ComputedRef<DomainEquipment[]>
  createEquipmentEntry: (payload: NewDomainEquipmentEntry) => Promise<void>
  deleteEquipmentEntry: (id: number) => Promise<void>
  equipmentEntries: ComputedRef<DomainEquipmentEntry[]>
  isEquipmentEntry(entry: any): entry is DomainEquipmentEntry
  patchEquipmentEntry: (payload: PatchPayload<DomainEquipmentEntry>) => Promise<void>
}

function useDomainEquipments(): DomainEquipmentsHook {
  const {
    params,
    app: { $accessor },
  } = useContext()
  const domainId = computed(() => parseInt(params.value.domain))
  const { currentRegionLocale } = useI18n()

  const collator = new Intl.Collator()
  const sortEquipments = (a: DomainEquipment, b: DomainEquipment) => collator.compare(a.name[currentRegionLocale.value as never], b.name[currentRegionLocale.value as never])

  const availableEquipments = computed(() =>
    $accessor.domainEquipments.getAllArray().filter(
      equipment => !$accessor.domainEquipmentsEntries.getAllEquipmentIds.includes(equipment.id),
    )
      .sort(sortEquipments),
  )

  const equipmentEntries = computed(() => $accessor.domainEquipmentsEntries.getAllArray().filter(
    (entry: DomainEquipmentEntry) => $accessor.domainEquipments.getWhereArray(equipment =>
      equipment.id === entry.domainEquipment
      && entry.domain === domainId.value,
    ).length !== 0,
  ))

  const deleteEquipmentEntry = async(id: number) => {
    await $accessor.domainEquipmentsEntries.deleteOne({
      id,
      domainId: domainId.value,
    })
  }

  const createEquipmentEntry = async(payload: NewDomainEquipmentEntry) => {
    await $accessor.domainEquipmentsEntries.postOne(payload)
  }

  const patchEquipmentEntry = async(payload: PatchPayload<DomainEquipmentEntry>) => {
    await $accessor.domainEquipmentsEntries.patchOne(payload)
  }


  function isEquipmentEntry(entry: any): entry is DomainEquipmentEntry {
    return 'domainEquipment' in entry
  }


  return {
    availableEquipments,
    createEquipmentEntry,
    deleteEquipmentEntry,
    equipmentEntries,
    isEquipmentEntry,
    patchEquipmentEntry,
  }
}

export default useDomainEquipments
