import marketplacesMutations from './mutations'
import marketplacesGetters from './getters'
import marketplacesActions from './actions'
import marketplacesState from './state'

export const marketplaces = {
  namespaced: true,
  state: marketplacesState,
  actions: marketplacesActions,
  mutations: marketplacesMutations,
  getters: marketplacesGetters,
}
