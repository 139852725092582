import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { ServiceTheme } from '~/types/models'
import { resetState } from '~/helpers/store'
import { ServiceThemeMutationTypes } from './types'
import getBaseState, { serviceTheme } from './state'

export const mutations = mutationTree(getBaseState, {
  ...createMutations<ServiceTheme>(serviceTheme),
  [ServiceThemeMutationTypes.RESET_STATE](state): void {
    resetState(state, getBaseState)
  },
})

export default mutations
