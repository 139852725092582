import { Translation } from '~/types/Locale'
import { DateType } from '~/types'
import { ModerationStatusEnum } from './Moderation'

export interface EntityFileMetadata {
  idx: number,
  type: string,
  embed: string,
  width: number,
  yt_id: string, // eslint-disable-line camelcase
  height: number,
  valign: string,
  valign_pct: number // eslint-disable-line camelcase
}

export interface IdxMap {
  fileId: number,
  idx: number,
}

export interface FileModeratableFields {
  alt?: Translation

}

export interface FilesLastApprovedFields extends FileModeratableFields {
  path: string | null
}

export enum EntityFileTypeEnum {
  SERVICE_PICTURE = 'SERVICE_PICTURE',
  MARKETPLACE_LOGO = 'MARKETPLACE_LOGO',
  MARKETPLACE_LEGAL_TERMS = 'MARKETPLACE_LEGAL_TERMS',
  SERVICE_LEGAL_TERMS = 'SERVICE_LEGAL_TERMS',
  DOMAIN_PICTURE = 'DOMAIN_PICTURE',
  DOMAIN_OWNERS_PICTURE = 'DOMAIN_OWNERS_PICTURE',
  DOMAIN_LOGO = 'DOMAIN_LOGO',
  DOMAIN_LEGAL_TERMS = 'DOMAIN_LEGAL_TERMS',
  PROVIDER_LEGAL_TERMS = 'PROVIDER_LEGAL_TERMS',
}

export enum ModerateFileTypeEnum {
  SERVICE_PICTURE = 'SERVICE_PICTURE',
  MARKETPLACE_LOGO = 'MARKETPLACE_LOGO',
  DOMAIN_PICTURE = 'DOMAIN_PICTURE',
  DOMAIN_LOGO = 'DOMAIN_LOGO',
}


export interface EntityFile {
  id: number,
  idx: number,
  domain: number | null,
  type: EntityFileTypeEnum,
  filename: string,
  isApproved: boolean,
  isDeleted: boolean,
  isPrivate: boolean,
  lastApprovedFields: FilesLastApprovedFields,
  marketplace: number | null
  mime: string,
  name: Translation,
  path: string,
  pendingModeration: FileModeratableFields,
  provider: number | null,
  service: number | null,
  description: Translation,
  alt: Translation,
  metadata: EntityFileMetadata,
  createdAt: DateType,
  updatedAt: DateType,
  deletedAt: DateType | null,
  moderationStatus: ModerationStatusEnum,
  url: string
  rejectReason: string | null,
  $isDirty?: boolean,
}
