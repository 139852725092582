import { useContext } from '@nuxtjs/composition-api'
import {
  CalendarExternalSource,
  CalendarExternalSourceForm,
  CalendarExternalSourceSyncIntervals,
  CalendarExternalSourceTypesEnum,
  ExternalSourceEntity,
} from '@/types/models'

interface CalendarExternalSourceHook {
  deleteOne: (id: number) => Promise<void>
  getDefaultForm: () => CalendarExternalSourceForm
  getIntervalText: (interval: number) => string
  patchOne: (form: CalendarExternalSourceForm, entity: ExternalSourceEntity, entityId: number, id: number) => Promise<void>
  postOne: (form: CalendarExternalSourceForm, entity: ExternalSourceEntity, id: number) => Promise<void>
  syncIcal: (id: number) => Promise<void>
}


function useCalendarExternalSources(): CalendarExternalSourceHook {

  const { app: { $accessor } } = useContext()

  const formatPayload = (form: CalendarExternalSourceForm, entity: ExternalSourceEntity, id: number): Partial<CalendarExternalSource> => {
    return entity === 'service'
      ? {
        ...form,
        service: id,
      }
      : {
        ...form,
        serviceUnit: id,
      }
  }

  const getIntervalText = (interval: number) => {
    if (interval === CalendarExternalSourceSyncIntervals['30_MIN']) {
      return '1/2H'
    }
    return `${interval / 60}H`
  }

  const postOne = async(form: CalendarExternalSourceForm, entity: ExternalSourceEntity, id: number) => {
    const payload = formatPayload(form, entity, id)
    await $accessor.calendarExternalSources.postOne(payload)
  }

  const patchOne = async(form: CalendarExternalSourceForm, entity: ExternalSourceEntity, entityId: number, id: number) => {
    const payload = formatPayload(form, entity, entityId)
    await $accessor.calendarExternalSources.patchOne({
      ...payload,
      id,
    })
  }

  const deleteOne = async(id: number) => {
    await $accessor.calendarExternalSources.deleteOne(id)
  }

  const getDefaultForm = (): CalendarExternalSourceForm => ({
    label: '',
    sourceUrl: '',
    syncInterval: CalendarExternalSourceSyncIntervals['30_MIN'],
    sourceType: CalendarExternalSourceTypesEnum.ICAL,
  })

  const syncIcal = async(id: number) => {
    await $accessor.calendarExternalSources.syncOne(id)
  }

  return {
    deleteOne,
    getDefaultForm,
    getIntervalText,
    patchOne,
    postOne,
    syncIcal,
  }
}

export default useCalendarExternalSources
