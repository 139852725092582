





















































































































































import { optionFeeTypes } from '~/types/models/Option'
import { Period } from '~/types/models/Period'
import { computed, defineComponent, reactive, ref, useContext } from '@nuxtjs/composition-api'
import Decimal from 'decimal.js-light'
import useOptions from '~/composable/useOptions'
import useDomain from '~/composable/useDomain'
import SymbolField from '~/components/content/SymbolField.vue'
import HRSwitch from '~/components/HRSwitch.vue'
import MultipleDateRangePicker from '~/components/datetime/MultipleDateRangePicker.vue'

export default defineComponent({
  name: 'OptionPriceForm',
  components: {
    SymbolField,
    MultipleDateRangePicker,
    HRSwitch,
  },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionId: {
      required: false,
      default: null,
      validator: (prop: number | null) => typeof prop === 'number' || prop === null,
    },
  },
  setup(props) {
    const { app: { $dateTime, $accessor }, params } = useContext()
    const { getOptionField, getOptionTranslationField } = useOptions()
    const { currentDomainIsSh } = useDomain()
    const periodsValidation = ref(null)

    const state = reactive({ datesOverlap: false })

    function getField(field: string) {
      return getOptionField(field, parseInt(params.value.id))
    }

    function getTranslationField(field: string) {
      getOptionTranslationField(field, parseInt(params.value.id))
    }

    const defaultPeriod: Period = {
      start: new Date(),
      end: $dateTime.fromJSDate(new Date()).plus({ day: 1 }).toJSDate(),
    }

    const fee = computed({
      get: () => {
        if (props.required) {
          if (getField('min').value === 0) {
            getField('min').value = 1
          }
          return new Decimal(Math.max(getField('fee.amount').value, 100)).dividedBy(100).toNumber()
        }
        return new Decimal(getField('fee.amount').value).dividedBy(100).toNumber()
      },
      set: value => {
        if (value) {
          if (props.required) {
            getField('fee.amount').value = new Decimal(Math.max(value, 1)).times(100).toNumber()
          } else {
            getField('fee.amount').value = new Decimal(value).times(100).toNumber()
          }
        }
      },
    })

    const periods = computed({
      get: () => {
        if (!params.value.id) {
          return [defaultPeriod]
        } else {
          return $accessor.options.getOne(parseInt(params.value.id)).periods.map((period) => {
            const newPeriod = {
              start: $dateTime.fromISO(period.start as string).toJSDate(),
              end: $dateTime.fromISO(period.end as string).toJSDate(),
            }
            return newPeriod
          })
        }
      },
      set: (value) => {
        if (value) {
          const formatedValue = value.map(period => {
            const formatedPeriod = {
              start: $dateTime.fromJSDate(period.start as Date).toISODate(),
              end: $dateTime.fromJSDate(period.end as Date).toISODate(),
            }
            return formatedPeriod
          })
          if (params.value.id) {
            return $accessor.options.UPDATE_FIELD({
              id: parseInt(params.value.id),
              path: 'periods',
              value: formatedValue,
            })
          } else {
            return $accessor.options.UPDATE_CREATE_FORM_FIELD({
              path: 'periods',
              value: formatedValue,
            })
          }
        }
      },
    })

    const allYear = computed({
      get: () => {
        if (params.value.id) {
          return $accessor.options.getOne(parseInt(params.value.id)).periods.length === 0
        } else {
          return $accessor.options.getCreateFormField('periods').length === 0
        }
      },
      set: (val: boolean) => {
        if (params.value.id) {
          return $accessor.options.UPDATE_FIELD({
            id: parseInt(params.value.id),
            path: 'periods',
            value: val ? [] : [defaultPeriod],
          })
        } else {
          return $accessor.options.UPDATE_CREATE_FORM_FIELD({
            path: 'periods',
            value: val ? [] : [defaultPeriod],
          })
        }
      },
    })

    return {
      state,
      fee,
      allYear,
      defaultPeriod,
      optionFeeTypes,
      getField,
      getTranslationField,
      periodsValidation,
      periods,
      currentDomainIsSh,
    }
  },
})
