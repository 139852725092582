








import { defineComponent, PropType } from '@nuxtjs/composition-api'
import ToggleContentButton from '~/components/buttons/ToggleContentButton.vue'

type ContactMethod = 'phone' | 'email'

export default defineComponent({
  name: 'ContactToggleButton',
  components: { ToggleContentButton },
  props: {
    type: {
      type: String as PropType<ContactMethod>,
      validator: (value: ContactMethod) => ['phone', 'email'].includes(value),
      required: true,
    },
    contact: {
      type: String,
      required: true,
    },
    openContact: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    function callPhoneNumber() {
      window.open(`tel:${props.contact}`)
    }

    function openMailClient() {
      window.open(`mailto:${props.contact}`)
    }

    function eventClick() {
      switch (props.type) {
        case 'phone':
          return callPhoneNumber()
        case 'email':
          return openMailClient()
      }
    }

    return {
      callPhoneNumber,
      openMailClient,
      eventClick,
    }
  },
})
