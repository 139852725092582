






































































import { defineComponent, reactive } from '@nuxtjs/composition-api'
import pick from 'lodash/pick'
import useAuth from '~/composable/useAuth'
import HRSwitch from '~/components/HRSwitch.vue'

export default defineComponent({
  name: 'LoginPage',
  components: { HRSwitch },
  setup() {
    const { login } = useAuth()
    const state = reactive({
      email: '',
      password: '',
      remember: true,
      isSubmitting: false,
      hasSucceeded: false,
    })

    async function submitForm() {
      state.isSubmitting = true
      await login(pick(state, ['email', 'password', 'remember']))
      state.isSubmitting = false
    }

    return {
      state,
      submitForm,
    }
  },
})
