import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es7.object.values.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import { MarketplaceLabelAffilaeEnum, BookingStatusEnum, BookingStatusClassesEnum, EntityFileTypeEnum } from '~/types/models';
import { DrawerDisplayModeEnum } from '~/store/calendar/types';
import { CalendarDrawerNameEnum } from '~/types/Calendar';
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import useCalendar from '~/composable/useCalendar';
import CalendarDrawer from '~/components/calendar/drawers/CalendarDrawer.vue';
import CustomerContactActions from '~/components/customer/CustomerContactActions.vue';
import useImage from '~/composable/useImage';
import useBookings from '~/composable/useBookings';
import { dateDiff } from '~/helpers';
export default defineComponent({
  name: 'CalendarBookingDrawer',
  components: {
    CalendarDrawer: CalendarDrawer,
    CustomerContactActions: CustomerContactActions
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(_, _ref) {
    var emit = _ref.emit;

    var _useContext = useContext(),
        params = _useContext.params,
        _useContext$app = _useContext.app,
        $accessor = _useContext$app.$accessor,
        router = _useContext$app.router,
        localePath = _useContext$app.localePath;

    var _useDomain = useDomain(),
        currentDomainIsSh = _useDomain.currentDomainIsSh;

    var _useCalendar = useCalendar(),
        ui = _useCalendar.ui,
        isCalendarUserEnabled = _useCalendar.isCalendarUserEnabled;

    var _useImage = useImage(),
        generateSignedUrl = _useImage.generateSignedUrl;

    var _useBookings = useBookings(),
        getFormattedGuests = _useBookings.getFormattedGuests;

    var hasActions = computed(function () {
      return isCalendarUserEnabled.value || !currentDomainIsSh.value;
    });
    var isAdmin = computed(function () {
      return $accessor.users.currentUserIsAdmin;
    });
    var isDrawerBookingOpen = computed(function () {
      return booking.value && ui.value.drawer === CalendarDrawerNameEnum.BOOKING;
    });
    var booking = computed(function () {
      if (ui.value.drawerDisplayMode !== DrawerDisplayModeEnum.CREATE) {
        return $accessor.bookings.getFirstActive();
      }
    });
    var service = computed(function () {
      if (booking.value) {
        var _booking$value;

        return $accessor.services.getOne((_booking$value = booking.value) === null || _booking$value === void 0 ? void 0 : _booking$value.service);
      }
    });
    var customer = computed(function () {
      if (booking.value) {
        return $accessor.customers.getOne(booking.value.customer);
      }
    });
    var marketplace = computed(function () {
      if (booking.value) {
        return $accessor.marketplaces.getOne(booking.value.marketplace);
      }
    });
    var displayMarketPlace = computed(function () {
      var _marketplace$value;

      var isAffiliateMarketplace = Object.values(MarketplaceLabelAffilaeEnum).includes((_marketplace$value = marketplace.value) === null || _marketplace$value === void 0 ? void 0 : _marketplace$value.label);
      return isAffiliateMarketplace || isAdmin.value;
    });
    var priceBoxClasses = computed(function () {
      var _booking$value2;

      switch ((_booking$value2 = booking.value) === null || _booking$value2 === void 0 ? void 0 : _booking$value2.status) {
        case BookingStatusEnum.BOOKING_CONFIRMED:
          return 'has-text-success has-border-success';

        case BookingStatusEnum.BOOKING_STANDBY:
          return 'has-text-warning has-border-warning';

        case BookingStatusEnum.BOOKING_PENDING:
          return 'has-text-warning has-border-warning';

        default:
          return 'has-text-danger has-border-danger';
      }
    });
    var rentalImg = computed(function () {
      if (service.value) {
        var _service$value$files;

        if (((_service$value$files = service.value.files) === null || _service$value$files === void 0 ? void 0 : _service$value$files.length) > 0) {
          var serviceImg = $accessor.entityFiles.getWhereArray(function (file) {
            return file.type === EntityFileTypeEnum.SERVICE_PICTURE && service.value.files.includes(file.id);
          });

          if (serviceImg.length > 0) {
            return generateSignedUrl(serviceImg[0].url);
          }
        }
      }
    });

    function onClose() {
      emit('on-close');
      $accessor.calendar.RESET_UI_DRAWER();
      $accessor.unavailabilities.RESET_ACTIVE();
    }

    function onClickOutside() {
      onClose();
      emit('on-click-outside');
    }

    function redirectBookingPage() {
      $accessor.calendar.RESET_UI_DRAWER();

      if (router && booking.value) {
        router.push(localePath({
          name: 'booking-id',
          params: {
            provider: params.value.provider.toString(),
            domain: params.value.domain.toString(),
            id: booking.value.id.toString()
          }
        }));
      }
    }

    function redirectBookingUpdatePage() {
      $accessor.calendar.RESET_UI_DRAWER();

      if (router && booking.value) {
        router.push(localePath({
          name: 'booking-update',
          params: {
            provider: params.value.provider.toString(),
            domain: params.value.domain.toString(),
            id: booking.value.id.toString()
          }
        }));
      }
    }

    function redirectBookingCancelPage() {
      $accessor.calendar.RESET_UI_DRAWER();

      if (router && booking.value) {
        router.push(localePath({
          name: 'booking-id',
          params: {
            provider: params.value.provider.toString(),
            domain: params.value.domain.toString(),
            id: booking.value.id.toString()
          },
          query: {
            cancelBooking: 'true'
          }
        }));
      }
    }

    return {
      booking: booking,
      BookingStatusClassesEnum: BookingStatusClassesEnum,
      CalendarDrawerNameEnum: CalendarDrawerNameEnum,
      currentDomainIsSh: currentDomainIsSh,
      customer: customer,
      dateDiff: dateDiff,
      displayMarketPlace: displayMarketPlace,
      DrawerDisplayModeEnum: DrawerDisplayModeEnum,
      getFormattedGuests: getFormattedGuests,
      hasActions: hasActions,
      isAdmin: isAdmin,
      isCalendarUserEnabled: isCalendarUserEnabled,
      isDrawerBookingOpen: isDrawerBookingOpen,
      marketplace: marketplace,
      onClickOutside: onClickOutside,
      onClose: onClose,
      priceBoxClasses: priceBoxClasses,
      redirectBookingCancelPage: redirectBookingCancelPage,
      redirectBookingPage: redirectBookingPage,
      redirectBookingUpdatePage: redirectBookingUpdatePage,
      rentalImg: rentalImg,
      service: service,
      ui: ui
    };
  }
});