






import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'

type AlignOptions = 'left' | 'center' | 'right'

export default defineComponent({
  name: 'ContentTopLinks',
  props: {
    align: {
      type: String as PropType<AlignOptions>,
      default: 'right',
      validator: (prop: AlignOptions) => ['left', 'center', 'right'].includes(prop),
    },
  },
  setup(props) {
    const alignmentClasses = {
      'left': 'flex-start',
      'center': 'flex-center',
      'right': 'flex-end',
    }
    const classes = ref(alignmentClasses[props.align])

    return { classes }
  },
})
