




















































































































import { defineComponent, useContext } from '@nuxtjs/composition-api'
import { DateType } from '~/types'
import { Promocode } from '~/types/models'
import useTable from '~/composable/useTable'
import TableEmpty from '~/components/table/TableEmpty.vue'
import TableSearch from '~/components/table/TableSearch.vue'

export default defineComponent({
  name: 'PromocodesPage',
  components: {
    TableEmpty,
    TableSearch,
  },
  setup() {
    const { app: { $dateTime, $accessor } } = useContext()
    const baseUrl = 'promocodes'

    const fetchRelations = async(promocodes: Promocode[]) => {
      const uniqueIds = [...new Set(promocodes.map(promocode => promocode.marketplaces).flat())]
      const missingIds = uniqueIds.filter(id => !$accessor.marketplaces.getOne(id))
      if (missingIds.length > 0) {
        await $accessor.marketplaces.fetchMany(missingIds)
      }
    }

    const { state: tableState, eventSortTable, eventSearchTable } = useTable<Promocode>(baseUrl, fetchRelations)

    const formatDate = (date: DateType) => {
      const jsDate = new Date(date.toString())
      return $dateTime.fromJSDate(jsDate).toLocaleString($dateTime.DATE_SHORT)
    }

    return {
      tableState,
      eventSortTable,
      eventSearchTable,
      formatDate,
    }
  },
})
