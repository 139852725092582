import { State as BaseState } from '@kissmylabs/vuex-entitystore'
import { DateType } from '~/types'
import { GiftCard, Money } from '~/types/models'
import { PaginationState } from '~/store/types'

export enum GiftCardMutationTypes {
  UPDATE_FIELD = 'UPDATE_FIELD',
  UPDATE_CREATE_FORM_FIELD = 'UPDATE_CREATE_FORM_FIELD',
  RESET_CREATE_FORM = 'RESET_CREATE_FORM',
}

export interface GiftCardCreateForm {
  buyerFullName: string
  code: string
  comment: string
  origin: string
  isActive: boolean
  totalAmount: Money
  validUntil: DateType
}

export interface GiftCardState extends BaseState<GiftCard> {
  createForm: GiftCardCreateForm,
  isImpersonated: boolean,
  pagination: PaginationState,
}
