import { Middleware } from '@nuxt/types'

const domainMiddleware: Middleware = async({ params, app: { $accessor } }) => {
  const domainId = parseInt(params.domain)
  if (!$accessor.domains.getAllIds().includes(domainId)) {
    await $accessor.domains.fetchOne(domainId)
  }
  const addressId = $accessor.domains.getOne(domainId).address
  if (addressId && !$accessor.addresses.getOne(addressId)) {
    await $accessor.addresses.fetchOne(addressId)
  }
}

export default domainMiddleware
