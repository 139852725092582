


















































































import { defineComponent } from '@nuxtjs/composition-api'
import AppContentHeading from '~/components/content/AppContentHeading.vue'

export default defineComponent({
  name: 'PromotionsPage',
  components: { AppContentHeading },
  data() {
    return {
      promotions: [
        {
          id: 1,
          name: 'PROMOTION HIVER',
          discount: '10',
          promotionType: 'Classique',
          availableFrom: '01/01/2020',
          availableTo: '04/05/2020',
          publishedFrom: '01/01/2020',
          publishedTo: '04/05/2020',
        },
        {
          id: 2,
          name: 'PROMOTION ÉTÉ',
          discount: '10',
          promotionType: 'Classique',
          availableFrom: '01/01/2020',
          availableTo: '04/05/2020',
          publishedFrom: '01/01/2020',
          publishedTo: '04/05/2020',
        },
        {
          id: 3,
          name: 'PROMOTION HIVER',
          discount: '10',
          promotionType: 'Classique',
          availableFrom: '01/01/2020',
          availableTo: '04/05/2020',
          publishedFrom: '01/01/2020',
          publishedTo: '04/05/2020',
        },
        {
          id: 4,
          name: 'PROMOTION HIVER',
          discount: '10',
          promotionType: 'Classique',
          availableFrom: '01/01/2020',
          availableTo: '04/05/2020',
          publishedFrom: '01/01/2020',
          publishedTo: '04/05/2020',
        },
        {
          id: 5,
          name: 'PROMOTION ÉTÉ',
          discount: '10',
          promotionType: 'Classique',
          availableFrom: '01/01/2020',
          availableTo: '04/05/2020',
          publishedFrom: '01/01/2020',
          publishedTo: '04/05/2020',
        },
      ],
    }
  },
})
