




















































































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import useDomainMarketplaceConfiguration from '~/composable/useDomainMarketplaceConfiguration'

export default defineComponent({
  name: 'MkpDomainHlcp',
  middleware: ['domainMarketplaceConfigurationMiddleware'],
  setup(_, { root: { $buefy, $i18n } }) {
    const { app: { $accessor }, params } = useContext()
    const { getDomainMarketplaceConfigurationFieldById: getField, getCreateFormField, patchOne, postOne } = useDomainMarketplaceConfiguration()
    const currentDomain = computed(() => $accessor.domains.getOne(parseInt(params.value.domain)))
    const marketplaces = computed(() => Object.values($accessor.marketplaces.entities.byId))

    const getMkpConfigField = (marketplaceId: number, field: string) => computed({
      get: () => {
        const mkpConfigId = $accessor.domainMarketplaceConfigurations.getWhereArray((mkpConfig) => mkpConfig.marketplace === marketplaceId && mkpConfig.domain === currentDomain.value.id)[0]?.id
        if (mkpConfigId) {
          return getField(mkpConfigId, field).value
        }
        return $accessor.domainMarketplaceConfigurations.getCreateForm(marketplaceId) ? getCreateFormField(marketplaceId, field).value : false
      },
      set: val => {
        const mkpConfigId = $accessor.domainMarketplaceConfigurations.getWhereArray((mkpConfig) => mkpConfig.marketplace === marketplaceId && mkpConfig.domain === currentDomain.value.id)[0]?.id
        return mkpConfigId ? getField(mkpConfigId, field).value = val : getCreateFormField(marketplaceId, field).value = val
      },
    })

    const configOrFormEnable = (marketplaceId: number) => computed(() => !!(($accessor.domainMarketplaceConfigurations.getOneByMkpId(marketplaceId, currentDomain.value.id)||$accessor.domainMarketplaceConfigurations.getCreateForm(marketplaceId))))

    const onSubmit = async(marketplaceId: number) => {
      const isEnable: boolean = getMkpConfigField(marketplaceId, 'isEnabled').value
      $buefy.dialog.confirm({
        title: $i18n.t(`marketplace.configuration_${isEnable ? 'activation' : 'inactivation'}`).toString(),
        message: $i18n.t('marketplace.configuration_valid_api_infos_message').toString(),
        confirmText: $i18n.t(`marketplace.configuration_${isEnable ? 'activation' : 'inactivation'}_confirm`).toString(),
        cancelText: $i18n.t(`marketplace.configuration_${isEnable ? 'activation' : 'inactivation'}_cancel`).toString(),
        hasIcon: true,
        type: 'is-danger',
        async onConfirm() {
          await saveMkpConfig(marketplaceId)
        },
      })
    }

    async function onCheck(marketplaceId: number) {
      const mkpConfigId = $accessor.domainMarketplaceConfigurations.getWhereArray((mkpConfig) => mkpConfig.marketplace === marketplaceId && mkpConfig.domain === currentDomain.value.id)[0]?.id

      // if mkpConfigId => patch status changes
      if (mkpConfigId) {
        getField(mkpConfigId, 'isEnabled').value = !getField(mkpConfigId, 'isEnabled').value
        const mkpConfig = $accessor.domainMarketplaceConfigurations.getOne(mkpConfigId)
        $buefy.dialog.confirm({
          title: $i18n.t(`marketplace.configuration_${mkpConfig.isEnabled ? 'activation' : 'inactivation'}`).toString(),
          message: $i18n.t(`marketplace.configuration_${mkpConfig.isEnabled ? 'activation' : 'inactivation'}_confirm_message`).toString(),
          confirmText: $i18n.t(`marketplace.configuration_${mkpConfig.isEnabled ? 'activation' : 'inactivation'}_confirm`).toString(),
          cancelText: $i18n.t(`marketplace.configuration_${mkpConfig.isEnabled ? 'activation' : 'inactivation'}_cancel`).toString(),
          hasIcon: true,
          type: 'is-danger',
          async onConfirm() {
            await patchOne(mkpConfig, true)
          },
          onCancel() {
            getField(mkpConfigId, 'isEnabled').value = !getField(mkpConfigId, 'isEnabled').value
          },
        })
      // else create a form to create MkpConfig
      } else if (!$accessor.domainMarketplaceConfigurations.getCreateForm(marketplaceId) || !getCreateFormField(marketplaceId, 'isEnabled').value) {
        $accessor.domainMarketplaceConfigurations.ADD_CREATE_FORM_ITEM({ marketplaceId, domainId: currentDomain.value.id })
        getCreateFormField(marketplaceId, 'isEnabled').value = true
      } else {
        getCreateFormField(marketplaceId, 'isEnabled').value = false
        $accessor.domainMarketplaceConfigurations.REMOVE_CREATE_FORM_ITEM({ marketplaceId, domainId: currentDomain.value.id })
      }
    }

    async function saveMkpConfig(marketplaceId: number) {
      const mkpConfigId = $accessor.domainMarketplaceConfigurations.getWhereArray((mkpConfig) => mkpConfig.marketplace === marketplaceId && mkpConfig.domain === currentDomain.value.id)[0]?.id
      if (mkpConfigId) {
        const mkpConfig = $accessor.domainMarketplaceConfigurations.getOne(mkpConfigId)
        await patchOne(mkpConfig, false)
      } else {
        const newMkpConfig = $accessor.domainMarketplaceConfigurations.getCreateForm(marketplaceId)
        await postOne(newMkpConfig)
      }
    }

    return {
      getField,
      marketplaces,
      getMkpConfigField,
      onCheck,
      saveMkpConfig,
      onSubmit,
      currentDomain,
      configOrFormEnable,
    }
  },
})
