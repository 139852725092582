import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{attrs:{"active":_vm.active,"card":""},on:{"update:active":function (val) { return _vm.$emit('update:active', val); }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n    "+_vm._s(_vm.$t('refund_modal.cancel_booking'))+"\n  ")]},proxy:true}])},[_vm._v(" "),_c('div',{staticClass:"modal-card-body"},[(!_vm.booking.bookingCharge)?_c('BaseMessage',{attrs:{"type":"is-info","has-icon":"","closable":false}},[_vm._v("\n      "+_vm._s(_vm.$t('refund_modal.no_refund'))+"\n    ")]):(!_vm.canCancelBooking && !_vm.$accessor.users.currentUserIsAdmin)?_c('div',[_c('BaseMessage',{attrs:{"type":"is-danger","has-icon":""}},[_vm._v("\n        "+_vm._s(_vm.$t('booking.cannot_cancel', { minHoursBeforeBooking: _vm.domainForBooking.minHoursBeforeBooking }))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"column is-full has-text-centered"},[_c('BButton',{staticClass:"is-danger is-size-5 is-uppercase has-text-weight-bold",attrs:{"size":"is-large"},on:{"click":function (val) { return _vm.$emit('update:active', val); }}},[_vm._v("\n          "+_vm._s(_vm.$t('actions.back'))+"\n        ")])],1)],1):_c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h2',{staticClass:"title is-2"},[_vm._v("\n        "+_vm._s(_vm.$t('refund_modal.refund_amount'))+"\n      ")]),_vm._v(" "),_c('section',{staticClass:"columns"},[_c('ValidationProvider',{staticClass:"column is-half",attrs:{"name":"refund_amount","rules":("max_value:" + (_vm.refundableAmount.amount / 100)),"skip-if-empty":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('PriceInput',{attrs:{"min":0},model:{value:(_vm.refundAmount),callback:function ($$v) {_vm.refundAmount=$$v},expression:"refundAmount"}},[_vm._v("\n              "+_vm._s(_vm.refundableAmount.currency)+"\n            ")])],1)]}}],null,true)}),_vm._v(" "),_c('BaseMessage',{staticClass:"column is-half",attrs:{"type":"is-info","has-icon":"","closable":false}},[_vm._v("\n          "+_vm._s(_vm.$t('refund_modal.refund_info'))+"\n        ")])],1),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('refund_modal.refund_partial', {
            amount: _vm.refundAmount,
            total: _vm.$fromCent(_vm.refundableAmount).amount,
            currency: _vm.CurrencyToSymbolEnum[_vm.refundableAmount.currency]
          }))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"column is-full has-text-centered"},[_c('BButton',{staticClass:"is-primary is-size-5 is-uppercase has-text-weight-bold",attrs:{"disabled":(!_vm.canCancelBooking && !_vm.$accessor.users.currentUserIsAdmin) || invalid,"size":"is-large"},on:{"click":_vm.onSubmit}},[_vm._v("\n          "+_vm._s(_vm.$t('actions.validate'))+"\n        ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }