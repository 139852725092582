import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-multiline"},[_c('ValidationProvider',{staticClass:"column is-one-third-desktop is-full-touch",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('promocodes.name')}},[_c('BInput',{model:{value:(_vm.getField('name').value),callback:function ($$v) {_vm.$set(_vm.getField('name'), "value", $$v)},expression:"getField('name').value"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-one-third-desktop is-full-touch",attrs:{"name":"t('giftcardcode","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('promocodes.code')}},[_c('BInput',{model:{value:(_vm.getField('code').value),callback:function ($$v) {_vm.$set(_vm.getField('code'), "value", $$v)},expression:"getField('code').value"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-one-third-desktop is-full-touch",attrs:{"name":"booking_discount_amount","rules":"required|max_value:20","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('promocodes.value')}},[_c('PriceInput',{model:{value:(_vm.getField('percentDiscount').value),callback:function ($$v) {_vm.$set(_vm.getField('percentDiscount'), "value", $$v)},expression:"getField('percentDiscount').value"}},[_vm._v("\n        %\n      ")])],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half",attrs:{"name":"description","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('promocodes.description')}},[_c('BInput',{attrs:{"type":"textarea"},model:{value:(_vm.getField('description').value),callback:function ($$v) {_vm.$set(_vm.getField('description'), "value", $$v)},expression:"getField('description').value"}})],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"column is-full"}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-half",attrs:{"name":"periods","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('promocodes.periods')}},[_c('MultipleDateRangePicker',{attrs:{"delete-label":_vm.$t('actions.delete'),"default-period":_vm.defaultPeriod},model:{value:(_vm.getField('periods').value),callback:function ($$v) {_vm.$set(_vm.getField('periods'), "value", $$v)},expression:"getField('periods').value"}})],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"column is-full"},[_c('HRSwitch',{attrs:{"reverse":"","label":_vm.$t('promocodes.unlimited')},model:{value:(_vm.isIllimited),callback:function ($$v) {_vm.isIllimited=$$v},expression:"isIllimited"}})],1),_vm._v(" "),(!_vm.isIllimited)?_c('ValidationProvider',{staticClass:"column is-one-third",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"label":_vm.$t('promocodes.max_uses')}},[_c('BNumberinput',{attrs:{"step":1,"min":0,"size":"is-medium","icon-pack":"usi","type":"is-primary"},model:{value:(_vm.getField('maxUses').value),callback:function ($$v) {_vm.$set(_vm.getField('maxUses'), "value", $$v)},expression:"getField('maxUses').value"}})],1)]}}],null,false,563748359)}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-full",attrs:{"name":"marketplaces","rules":"required","tag":"div","skip-if-empty":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('MarketplacesSelectCheckboxes',{attrs:{"label":_vm.$t('form.fields.apply_to'),"type":{ 'is-danger': errors[0] },"errors":errors,"valid":valid,"select-all":"","compact":""},model:{value:(_vm.getField('marketplaces').value),callback:function ($$v) {_vm.$set(_vm.getField('marketplaces'), "value", $$v)},expression:"getField('marketplaces').value"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }