


















































import { computed, defineComponent, reactive, useContext } from '@nuxtjs/composition-api'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import deburr from 'lodash/deburr'
import usePermissions from '~/composable/usePermissions'
import useServices from '~/composable/useServices'

export default defineComponent({
  name: 'ServiceThemeSelector',
  setup() {
    const { app: { $accessor, $translateEntityField } } = useContext()
    const { canEditCriticalFields } = usePermissions()
    const { getServiceFieldByServiceId } = useServices()
    const state = reactive({
      accomodationThemeGroup: [],
      expanded: false,
      disabled: !canEditCriticalFields('service').value,
      // Prepare the themes:
      // 1. sort themes by name ASC
      // 2. group by theme's name first character
      themes: computed(() => groupBy(
        sortBy($accessor.serviceThemes.getAllArray(), theme => deburr($translateEntityField(theme.name))),
        theme => deburr($translateEntityField(theme.name)[0]),
      )),
    })

    return {
      state,
      getServiceFieldByServiceId,
    }
  },
})
