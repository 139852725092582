




















































import { computed, defineComponent, PropType, ref, useContext, watch } from '@nuxtjs/composition-api'
import HRSwitch from '~/components/HRSwitch.vue'

export default defineComponent({
  name: 'ServiceSelectCheckboxes',
  components: { HRSwitch },
  props: {
    value: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
      description: 'Hide services if all selected',
    },
    label: {
      type: String,
      default: '',
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledServices: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    ignoreDisabledServices: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    valid: {
      type: Boolean,
      default: true,
    },
    type: {
      type: Object,
      default: () => ({ 'is-primary': true }),
    },
    isInColumn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { app: { $accessor }, params } = useContext()
    const availableServices = computed(() => {
      return $accessor.services.getWhereArray(service => {
        return $accessor.domains.getOne(parseInt(params.value.domain)).services.includes(service.id)
      })
    })
    const checkAll = ref(props.selectAll
      ? props.selectAll
      : props.value.length === availableServices.value.length)
    const innerValue = computed({
      get: () => props.value,
      set: newValue => {
        const servicesArray = Object.values(availableServices.value).filter(service => !props.disabledServices.includes(service.id))
        checkAll.value = servicesArray.length === newValue.length
        emit('input', newValue)
      },
    })
    /**
     * If the disabledServices value changes, compute a new innerValue excluding all new disabledServices to prevent from
     * keeping some disabled services selected.
     */
    watch(() => props.disabledServices, (newValue) => innerValue.value = innerValue.value.filter(id => !newValue.includes(id)))
    /**
     * Handles toggling the selection of all services.
     * If true, create an array with all IDs from.
     * If false, reset the value to an empty array.
     */
    function onToggleSelectAll(value: boolean) {
      if (value) {
        const services = Object.values(availableServices.value).filter(service => !props.disabledServices.includes(service.id))
        innerValue.value = services.map(service => service.id)
      } else {
        innerValue.value = []
      }
    }
    return {
      checkAll,
      innerValue,
      onToggleSelectAll,
      availableServices,
    }
  },
})
