import { Middleware } from '@nuxt/types'

const serviceLabelsMiddleware: Middleware = async({ app: { $accessor, $api } }) => {
  if ($accessor.serviceLabels.getAllIds().length === 0) {
    const allServiceLabels = await $api.get('servicelabels?limit=-1')
    $accessor.serviceLabels.CREATE_MANY(allServiceLabels.data)
  }
}

export default serviceLabelsMiddleware
