







































































import { defineComponent } from '@nuxtjs/composition-api'
import TableSearch from '~/components/table/TableSearch.vue'
import TableEmpty from '~/components/table/TableEmpty.vue'
import useTable from '~/composable/useTable'
import useAdmin from '~/composable/useAdmin'
import { Domain, ModerationStatusEnum } from '~/types/models'

export default defineComponent({
  name: 'DomainTableModeration',
  components: {
    TableSearch,
    TableEmpty,
  },
  setup() {
    const { impersonateProvider, state: impersonateState } = useAdmin()
    const moderationfilters = {
      moderationStatus: ModerationStatusEnum.PENDING,
    }
    const domainUrl = 'domains'
    const {
      state: tableState, eventSortTable, eventSearchTable } = useTable<Domain>(domainUrl)
    tableState.filters = moderationfilters

    function getNumberOfPendingModerations(domain: Domain) {
      return Object.keys(domain.pendingModeration).filter(key => key !== 'moderationStatus').length
    }

    return {
      getNumberOfPendingModerations,
      tableState,
      eventSortTable,
      eventSearchTable,
      impersonateProvider,
      impersonateState,
    }
  },

})
