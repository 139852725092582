var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CalendarDayHeader tw-w-full tw-flex"},_vm._l((_vm.getCalendarDays),function(day){return _c('div',{key:((day.month) + "-" + (day.day)),staticClass:"tw-flex-grow",class:[
      'CalendarDayHeader-day',
      'has-text-primary',
      'has-text-centered',
      { 'has-text-weight-bold': [6, 7].includes(Number(day.plus({day: 0}).toFormat('c'))) }
    ]},[_c('div',{staticClass:"tw-py-1 tw-border-b-2 tw-border-gray-light"},[_vm._v("\n      "+_vm._s(day.plus({ day: 0 })
          .toFormat('ccc')
          .substr(0, 2))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"py-1"},[_c('div',[_vm._v(_vm._s(day.day))]),_vm._v(" "),_c('div',{staticClass:"sep is-hidden-mobile"},[_vm._v("\n        -\n      ")]),_vm._v(" "),_c('div',{staticClass:"is-hidden-mobile"},[_vm._v("\n        "+_vm._s(day.month)+"\n      ")])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }