import {
  Unavailability,
  UnavailabilityBatchCreateForm, UnavailabilityBatchDeleteForm, UnavailabilityBatchEditForm, UnavailabilityEditForm,
} from '~/types/models'
import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { addDayToSameDayPeriodDateTimeStartEnd, toIsoDateStringPeriod, toIsoStringPeriod } from '~/helpers'
import { ICalendarBatchActionParams, ICalendarBatchParams } from '~/types'
import { RootMutationTypes } from '~/store/types'

type IUnavailabilityCreateBatchParams = ICalendarBatchParams<UnavailabilityBatchCreateForm | UnavailabilityBatchEditForm>
type IUnavailabilityResetBatchParams = ICalendarBatchParams<UnavailabilityBatchDeleteForm>

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<Unavailability>(),

  createManyFromCalendar({ commit }, calendar: Record<string, Unavailability[]>) {
    const unavailabilities = Object.values(calendar).flat()
    if (unavailabilities.length) {
      commit(BaseMutationTypes.CREATE_MANY, unavailabilities)
    }
  },

  createManyUnavails({ commit }, unavailabilities: Unavailability[]) {
    if (unavailabilities.length > 0) {
      commit(BaseMutationTypes.CREATE_MANY, unavailabilities)
    }
  },

  async createBatch(
    _,
    { domainId, payload, options }
      : IUnavailabilityCreateBatchParams,
  ): Promise<void> {
    const { i18n, $accessor, $loadingToast, $errorToast, $successToast, $api, $sentry } = this.app
    const { toastText } = options

    const toast = $loadingToast(toastText.pending)
    $accessor[RootMutationTypes.START_PREVENT_WINDOW_CLOSE](i18n.t('prevent_window_close'))

    try {
      const cleanPayload = {
        ...payload,
        periods: payload.periods.map(period => toIsoStringPeriod(addDayToSameDayPeriodDateTimeStartEnd(period))),
      }

      await $api.post(`domains/${domainId}/calendar/batch/unavailability/create`, cleanPayload)

      // @todo Don't have enough time to do this another way,
      //  but we should be able to fetch everything with a single HTTP request.
      await Promise.all(
        payload.serviceUnits.map(async(serviceUnitId) => {
          const serviceId = this.app.$accessor.serviceUnits.getOne(serviceUnitId).service
          await $accessor.calendar.fetchSingleUnitUnavailabilities({
            serviceUnitId,
            serviceId,
          })
        }),
      )
      toast.goAway(0)
      $successToast(toastText.success)
    } catch (error) {
      $sentry.captureException(error)
      toast.goAway(0)
      $errorToast(toastText.error)
      throw error
    } finally {
      $accessor[RootMutationTypes.END_PREVENT_WINDOW_CLOSE]()
    }
  },

  async resetBatch(_, { domainId, payload, options }
    : IUnavailabilityResetBatchParams,
  ): Promise<void> {
    const { i18n, $accessor, $loadingToast, $errorToast, $successToast, $api, $sentry } = this.app
    const { toastText } = options

    const toast = $loadingToast(toastText.pending)
    $accessor[RootMutationTypes.START_PREVENT_WINDOW_CLOSE](i18n.t('prevent_window_close'))

    try {
      const cleanPayload = {
        ...payload,
        periods: payload.periods.map(period => toIsoStringPeriod(addDayToSameDayPeriodDateTimeStartEnd(period))),
      }

      await $api.patch(`domains/${domainId}/calendar/batch/unavailability/reset`, cleanPayload)

      // @todo Don't have enough time to do this another way,
      //  but we should be able to fetch everything with a single HTTP request.
      await Promise.all(
        payload.serviceUnits.map(async(serviceUnitId) => {
          const serviceId = $accessor.serviceUnits.getOne(serviceUnitId).service
          await $accessor.calendar.fetchSingleUnitUnavailabilities({
            serviceUnitId,
            serviceId,
          })
        }),
      )
      toast.goAway(0)
      $successToast(toastText.success)
    } catch (error) {
      $sentry.captureException(error)
      toast.goAway(0)
      $errorToast(toastText.error)
      throw error
    } finally {
      $accessor[RootMutationTypes.END_PREVENT_WINDOW_CLOSE]()
    }
  },

  async postMany({ dispatch }, { domainId, payload, isIcalDescriptionEnabled }:
      ICalendarBatchActionParams<UnavailabilityBatchCreateForm> & {
      isIcalDescriptionEnabled: boolean
    },
  ): Promise<void> {
    const { i18n } = this.app

    const cleanPayload: UnavailabilityBatchCreateForm = {
      ...payload,
      entity: {
        ...payload.entity,
        label: 'placeholder',
        icalDescription: isIcalDescriptionEnabled ? payload.entity.icalDescription : null,
      },
    }

    const createBatchOptions: IUnavailabilityCreateBatchParams['options'] = {
      toastText: {
        pending: i18n.tc('toast.unavailability_creating', 2),
        success: i18n.tc('toast.unavailability_created', 2),
        error: i18n.tc('toast.unavailability_create_error', 2),
      },
    }

    await dispatch('createBatch', { domainId, payload: cleanPayload, options: createBatchOptions })
  },

  async patchOne(_, { unavailability }: { unavailability: UnavailabilityEditForm }): Promise<void> {
    const { $accessor, $api, i18n, $errorToast, $loadingToast, $successToast, $sentry } = this.app

    const toast = $loadingToast(i18n.t('toast.unavailability_updating'))

    try {
      const payload = {
        ...unavailability,
        ...toIsoDateStringPeriod(addDayToSameDayPeriodDateTimeStartEnd(unavailability.periods[0])),
        label: 'placeholder',
      }

      await $api.patch(`unavailabilities/${unavailability.id}`, payload)

      const serviceId = $accessor.serviceUnits.getOne(unavailability.serviceUnit).service

      await $accessor.calendar.fetchSingleUnitUnavailabilities({
        serviceUnitId: unavailability.serviceUnit,
        serviceId,
      })

      toast.goAway(0)
      $successToast(i18n.t('toast.unavailability_updated'))
    } catch (e) {
      $sentry.captureException(e)
      toast.goAway(0)
      $errorToast(i18n.t('toast.unavailability_update_error'))
    }
  },

  async deleteOne(_, { unavailability }: { unavailability: Unavailability }): Promise<void> {
    const { $api, $accessor, $loadingToast, $errorToast, i18n, $successToast, $sentry } = this.app

    const toast = $loadingToast(i18n.tc('toast.unavailability_deleting', 1))
    try {
      await $api.delete(`unavailabilities/${unavailability.id}`)

      const serviceUnit = $accessor.serviceUnits.getOne(unavailability.serviceUnit)

      await $accessor.calendar.fetchSingleUnitUnavailabilities({
        serviceUnitId: unavailability.serviceUnit,
        serviceId: serviceUnit.service,
      })

      toast.goAway(0)
      $successToast(i18n.tc('toast.unavailability_deleted', 1))
    } catch (e) {
      $sentry.captureException(e)
      toast.goAway(0)
      $errorToast(i18n.tc('toast.unavailability_delete_error', 1))
    }
  },

  async resetMany(
    { dispatch },
    { domainId, payload }: ICalendarBatchActionParams<UnavailabilityBatchDeleteForm>): Promise<void> {
    const { i18n } = this.app

    const batchOptions: IUnavailabilityResetBatchParams['options'] = {
      toastText: {
        pending: i18n.tc('toast.unavailability_deleting', 2),
        success: i18n.tc('toast.unavailability_deleted', 2),
        error: i18n.tc('toast.unavailability_delete_error', 2),
      },
    }
    await dispatch('resetBatch', { domainId, payload, options: batchOptions })
  },
})

export default actions
