import emailLogsMutations from './mutations'
import emailLogsActions from './actions'
import emailLogsState from './state'
import emailLogsGetters from './getters'

export const emailLogs = {
  namespaced: true,
  state: emailLogsState,
  actions: emailLogsActions,
  mutations: emailLogsMutations,
  getters: emailLogsGetters,
}
