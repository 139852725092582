import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import { defineComponent, reactive, useContext } from '@nuxtjs/composition-api';
import { ThemeColors } from '~/types/Tailwind';
export default defineComponent({
  name: 'MultipleDateRangePicker',
  props: {
    value: {
      type: Array,
      required: true
    },
    defaultPeriod: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    deleteLabel: {
      type: String,
      required: true
    },
    format: {
      type: String,
      required: false,
      default: 'dd/LL'
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    itemClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minDuration: {
      type: Number,
      default: 0
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useContext = useContext(),
        i18n = _useContext.app.i18n;

    var state = reactive({
      // Immutable array here to avoir mutating any Vuex state when mutating the prop
      periods: _toConsumableArray(props.value)
    });

    var _useI18n = useI18n(),
        currentRegionLocale = _useI18n.currentRegionLocale,
        getOrderOfDayInWeek = _useI18n.getOrderOfDayInWeek;

    function rangeItemFormatter(dateTime) {
      return dateTime.setLocale(i18n.locale).toFormat(props.format);
    }

    function onUpdate(_ref2) {
      var index = _ref2.index,
          values = _ref2.values;
      // Immutable method to avoid mutating any Vuex state when mutating the prop
      var periods = state.periods.map(function (period, currentIndex) {
        return currentIndex === index ? values : period;
      });
      state.periods = periods;
      emit('input', state.periods);
    }

    function onAddPeriod() {
      // Immutable method to avoid mutating any Vuex state when mutating the prop
      state.periods = [].concat(_toConsumableArray(state.periods), [props.defaultPeriod]);
      emit('input', state.periods);
    }

    function onRemovePeriod(index) {
      // Immutable method to avoid mutating any Vuex state when mutating the prop
      state.periods = state.periods.filter(function (_, idx) {
        return idx !== index;
      });
      emit('input', state.periods);
    }

    return {
      currentRegionLocale: currentRegionLocale,
      getOrderOfDayInWeek: getOrderOfDayInWeek,
      onAddPeriod: onAddPeriod,
      onRemovePeriod: onRemovePeriod,
      onUpdate: onUpdate,
      rangeItemFormatter: rangeItemFormatter,
      state: state,
      ThemeColors: ThemeColors
    };
  }
});