import { createEntity, createState } from '@kissmylabs/vuex-entitystore'
import { DomainMarketplaceConfiguration, DomainMarketplaceConfigurationCreateForm } from '~/types/models'
import { DomainMarketplaceConfigurationState } from './types'

export const domainMarketplaceConfiguration = createEntity<DomainMarketplaceConfiguration>('domainMarketplaceConfiguration')

export const baseCreateForm = (): DomainMarketplaceConfigurationCreateForm => ({
  domain: 0,
  marketplace: 0,
  homelocApiKey: '',
  homelocApiPassword: '',
  isEnabled: false,
  $isDirty: false,
})

export const state = (): DomainMarketplaceConfigurationState => ({
  ...createState<DomainMarketplaceConfiguration>(domainMarketplaceConfiguration),
  createForm: [],
})
export default state
