import { Translation } from '../Locale'

export const DomainAmenitiesTypes = ['CATERING', 'FACILITY'] as const

export interface DomainAmenity {
  id: number
  slug: string
  name: Translation
  type: typeof DomainAmenitiesTypes[number]
}
