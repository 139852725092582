






































































































import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import { DateTime } from 'luxon'
import useCalendar from '~/composable/useCalendar'
import useServices from '~/composable/useServices'
import { ServiceUnitUnavailabilityEvent } from '~/types'
import { BookingCondition, Money, ServiceDiscount, ServicePricing, Unavailability } from '~/types/models'

export default defineComponent({
  name: 'CalendarEventsModal',
  props: {
    events: {
      type: Array as PropType<ServiceUnitUnavailabilityEvent[] |ServicePricing[] | BookingCondition[] | ServiceDiscount[]>,
      required: true,
    },
    day: {
      type: DateTime,
      required: true,
    },
  },
  setup(props) {
    const { app: { $dateTime, i18n, $accessor, $translateEntityField, $parseAmount } } = useContext()
    const { ui,
      isSameDay,
      isDiscount,
      isServicePricing,
      isUnavailability,
      isBookingCondition,
      onUnavailClick,
      onBookingConditionClick,
      onClickPricing,
      onDiscountClicked,
    } = useCalendar()
    const { getServiceIdByUnitId } = useServices()

    function getUnavailRightTriangleColor(event: Unavailability) {
      if (event.externalSource) {
        return 'right-border-grey'
      } else if (event.booking) {
        return 'right-border-blue'
      }
      return 'right-border-danger'
    }

    function getUnavailLeftTriangleColor(event: Unavailability) {
      if (event.externalSource) {
        return 'left-border-grey'
      } else if (event.booking) {
        return 'left-border-blue'
      }
      return 'left-border-danger'
    }

    function getUnavailLineClass(unavailability: Unavailability) {
      if (unavailability.externalSource) {
        return 'has-background-grey'
      } else if (unavailability.booking) {
        return 'tw-bg-blue BookingCalendar'
      }
      return 'tw-bg-red'
    }

    function getUnavailBorderClasses(event: ServiceUnitUnavailabilityEvent) {
      if (event.isStartDay) {
        return 'RoundedStart'
      }
      if (event.isEndDay) {
        return 'RoundedEnd'
      }
      return ''
    }

    function getEventsRightTriangleColor(event: ServicePricing | ServiceDiscount | BookingCondition) {
      if (isServicePricing(event)) {
        return 'right-border-black'
      } else if (isDiscount(event)) {
        return 'right-border-purple'
      } else if (isBookingCondition(event)) {
        return 'right-border-green'
      }
      return ''
    }

    function getEventsLeftTriangleColor(event: ServicePricing | ServiceDiscount | BookingCondition) {
      if (isServicePricing(event)) {
        return 'left-border-black'
      } else if (isDiscount(event)) {
        return 'left-border-purple'
      } else if (isBookingCondition(event)) {
        return 'left-border-green'
      }
      return ''
    }

    function getEventBorderClass(event: ServicePricing | ServiceDiscount | BookingCondition) {
      if (event.start && isSameDay($dateTime.fromISO(event.start.toString()), props.day)) {
        return 'RoundedStart'
      } else if (event.end && isSameDay($dateTime.fromISO(event.end.toString()), props.day)) {
        return 'RoundedEnd'
      }
      return ''
    }

    function getEventLabel(event: ServiceDiscount | BookingCondition | Unavailability | ServicePricing) {
      if (isUnavailability(event)) {
        let serviceLabel = ''
        const service = $accessor.services.getFirstWhere(service => service.serviceUnits.includes(event.serviceUnit))
        if (service && service.serviceUnits.length > 1) {
          serviceLabel = $accessor.serviceUnits.getOne(event.serviceUnit).label
        }
        if (service) {
          serviceLabel = $translateEntityField(service.name)
        }
        if (event.externalSource) {
          return serviceLabel
        }
        if (event.booking) {
          const customer  = $accessor.customers.getFirstWhere(customer => customer.bookings?.includes(event.booking!)!)
          if (service && customer) {
            const serviceName = $translateEntityField(service.name)
            return `${serviceName} - n°${event.booking} - ${customer.lastName}`
          }
        }
        return serviceLabel
      } else {
        const service = $accessor.services.getOne(event.service)
        const serviceLabel = $translateEntityField(service.name)
        if (isDiscount(event)) {
          return `${serviceLabel} ~ ${event.degressivityRates[0].percentDiscount} % ${event.degressivityRates[0].nightNumber} ${i18n.t('discounts.from_label')} ${i18n.t('common.nights')}`
        }
        if (isServicePricing(event)) {
          return `${serviceLabel} ~ ${$parseAmount(event.baseRate as Money).toFormat('$0,0.00')}`
        }
        if (isBookingCondition(event)) {
          return `${serviceLabel} ~ ${event.minBookingDuration} ${i18n.t('common.nights')}/min - ${event.maxBookingDuration} ${i18n.t('common.nights')}/min`
        }
        return ''
      }
    }


    return {
      getEventBorderClass,
      getEventLabel,
      getEventsLeftTriangleColor,
      getEventsRightTriangleColor,
      getServiceIdByUnitId,
      getUnavailBorderClasses,
      getUnavailLeftTriangleColor,
      getUnavailLineClass,
      getUnavailRightTriangleColor,
      isSameDay,
      onBookingConditionClick,
      onClickPricing,
      onDiscountClicked,
      onUnavailClick,
      ui,
    }
  },
})
