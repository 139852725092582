import { DateType } from '../DateType'
import { Money } from './Money'

export enum PaymentMethodEnum {
  CREDIT_CARD = 'CREDIT_CARD',
  GIFT_CARD = 'GIFT_CARD',
  BCMC = 'BCMC',
  IDEAL = 'IDEAL'
}

export enum PaymentStatusEnum {
  CREATED = 'CREATED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED'
}

export interface Payment {
  id: number
  isRefund: boolean
  booking?: number
  cart?: number
  paymentMethod: PaymentMethodEnum
  paymentMethodIdentifier: number
  amount: Money
  status: PaymentStatusEnum
  executedAt: DateType
}
