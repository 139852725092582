import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'HRSwitch',
  props: {
    label: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: Boolean,
      required: true
    },
    reverse: {
      type: Boolean,
      default: false,
      required: false
    },
    isMarginless: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var innerValue = computed({
      get: function get() {
        return props.value;
      },
      set: function set(newValue) {
        return emit('input', newValue);
      }
    });
    return {
      innerValue: innerValue
    };
  }
});