import { Middleware } from '@nuxt/types'
import { CalendarExternalSource } from '~/types/models'


const calendarExternalSourcesMiddleware: Middleware = async({ params, app: { $accessor, $api } }) => {
  const domainId = parseInt(params.domain)
  const services = $accessor.services.getWhereArray(service => service.domain === domainId)
  const serviceIds = services.map(service => service.id)
  const serviceUnitsIds = services.filter(service => service.serviceUnits.length !== 0).flatMap(service => service.serviceUnits)

  const res = await $api.get(`calendarexternalsources?asDomain=${domainId}`)
  let externalSources: CalendarExternalSource[] | null = null
  if ($accessor.users.currentUserIsAdmin) {
    externalSources = res
  } else {
    externalSources = res.data
  }

  if (externalSources && externalSources.length > 0) {
    const calendarExternalSources = externalSources.filter(calendarExternalSource => {
      if (calendarExternalSource.service) {
        return serviceIds.includes(calendarExternalSource.service)
      }
      // Can't be undefined since Service and ServiceUnits are nullable only because they are mutually exclusive
      return serviceUnitsIds.includes(calendarExternalSource.serviceUnit!)
    })
    $accessor.calendarExternalSources.CREATE_MANY(calendarExternalSources)
  }

}

export default calendarExternalSourcesMiddleware
