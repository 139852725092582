import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { CalendarEntitiesEnum, CalendarRecurrenceEnum, CalendarRecurrenceValueEnum, CalendarViewsEnum } from '~/types/Calendar';
import { WeekDaysEnum } from '~/helpers';
import CalendarFilters from '~/components/calendar/CalendarFilters.vue';
import CalendarAccordion from '~/components/calendar/CalendarAccordion.vue';
import MultipleDateRangePicker from '~/components/datetime/MultipleDateRangePicker.vue';
import WeekdayCheckboxes from '~/components/datetime/WeekdayCheckboxes.vue';
import ServiceSelectCheckboxes from '~/components/service/ServiceSelectCheckboxes.vue';
import CalendarAccordionEntityForms from '~/components/calendar/CalendarAccordionEntityForms.vue';
import CalendarAccordionDeleteManyForms from '~/components/calendar/CalendarAccordionDeleteManyForms.vue';
import CalendarTwoMonthView from '~/components/calendar/CalendarTwoMonthView.vue';
import { DateTime } from 'luxon';
export default defineComponent({
  name: 'CalendarFullScreenModal',
  components: {
    CalendarFilters: CalendarFilters,
    CalendarAccordion: CalendarAccordion,
    MultipleDateRangePicker: MultipleDateRangePicker,
    WeekdayCheckboxes: WeekdayCheckboxes,
    ServiceSelectCheckboxes: ServiceSelectCheckboxes,
    CalendarAccordionEntityForms: CalendarAccordionEntityForms,
    CalendarAccordionDeleteManyForms: CalendarAccordionDeleteManyForms,
    CalendarTwoMonthView: CalendarTwoMonthView
  },
  props: {
    backgroundTitle: {
      type: String,
      default: 'has-background-primary'
    },
    title: {
      type: String,
      required: true
    }
  },
  setup: function setup(_, _ref) {
    var emit = _ref.emit;

    var _useContext = useContext(),
        route = _useContext.route,
        $accessor = _useContext.app.$accessor;

    var _useCalendar = useCalendar(),
        navigateToToday = _useCalendar.navigateToToday,
        navigateToNextYear = _useCalendar.navigateToNextYear,
        getSelectedPeriods = _useCalendar.getSelectedPeriods,
        getSelectedDays = _useCalendar.getSelectedDays,
        getSelectedServices = _useCalendar.getSelectedServices,
        getSelectedRecurrence = _useCalendar.getSelectedRecurrence,
        ui = _useCalendar.ui,
        navigateToJsDate = _useCalendar.navigateToJsDate;

    var _useI18n = useI18n(),
        currentRegionLocale = _useI18n.currentRegionLocale,
        getOrderOfDayInWeek = _useI18n.getOrderOfDayInWeek;

    var _useDomain = useDomain(),
        currentDomain = _useDomain.currentDomain;

    var _useServices = useServices(),
        disabledServices = _useServices.getCalendarDisabledServices;

    var isCreationModal = computed(function () {
      var _route$value$name;

      return ((_route$value$name = route.value.name) === null || _route$value$name === void 0 ? void 0 : _route$value$name.split('__')[0]) === 'calendar-create-many';
    });
    var services = computed(function () {
      return $accessor.services.getWhereArray(function (service) {
        return currentDomain.value.services.includes(service.id);
      }).map(function (service) {
        return service.id;
      });
    }); // Initial values for the form

    var start = $accessor.calendar.date;
    var end = DateTime.fromJSDate($accessor.calendar.date).plus({
      day: 1
    }).toJSDate();
    var defaultPeriod = {
      start: start,
      end: end
    };
    var selectedDay = ref({
      start: null,
      end: null
    });

    function onMoveNextYear() {
      navigateToNextYear();
    }

    function onMoveToday() {
      navigateToToday();
    }

    function onClose() {
      $accessor.calendar.RESET_UI_DRAWER();
      $accessor.calendar.RESET_COMMON_FORM();
      emit('close');
    }

    if (ui.value.views !== CalendarViewsEnum.TWO_MONTHS) {
      $accessor.calendar.UI_TOGGLE_VIEW(CalendarViewsEnum.TWO_MONTHS);
    }

    watch(function () {
      return ui.value.filters;
    }, function (newValue) {
      if (newValue.UNAVAILABILITY || newValue.ICALS || newValue.BOOKING) {
        $accessor.calendar.UPDATE_ENTITY_FORM_OPENED(CalendarEntitiesEnum.UNAVAILABILITIES);
      } else if (newValue.PRICING_DEFAULT || newValue.PRICING_CUSTOM) {
        $accessor.calendar.UPDATE_ENTITY_FORM_OPENED(CalendarEntitiesEnum.PRICING);
      } else if (newValue.DURATION) {
        $accessor.calendar.UPDATE_ENTITY_FORM_OPENED(CalendarEntitiesEnum.BOOKING_CONDITION);
      } else if (newValue.DISCOUNT) {
        $accessor.calendar.UPDATE_ENTITY_FORM_OPENED(CalendarEntitiesEnum.DISCOUNT);
      } else {
        $accessor.calendar.UPDATE_ENTITY_FORM_OPENED(CalendarEntitiesEnum.UNAVAILABILITIES);
      }
    }, {
      deep: true
    });
    watch(function () {
      return [selectedDay.value.start, selectedDay.value.end];
    }, function (newValue) {
      var startDate = newValue[0];
      var endDate = newValue[1];

      if (startDate) {
        var period = {
          start: startDate,
          end: $accessor.calendar.commonForm.selectedPeriods[0].end
        };
        $accessor.calendar.UDPATE_SELECTED_PERIODS([period]);
      }

      if (endDate) {
        var _period = {
          start: $accessor.calendar.commonForm.selectedPeriods[0].start,
          end: endDate
        };
        $accessor.calendar.UDPATE_SELECTED_PERIODS([_period]);
      }
    });
    return {
      CalendarRecurrenceEnum: CalendarRecurrenceEnum,
      CalendarRecurrenceValueEnum: CalendarRecurrenceValueEnum,
      currentRegionLocale: currentRegionLocale,
      defaultPeriod: defaultPeriod,
      disabledServices: disabledServices,
      getOrderOfDayInWeek: getOrderOfDayInWeek,
      getSelectedDays: getSelectedDays,
      getSelectedPeriods: getSelectedPeriods,
      getSelectedRecurrence: getSelectedRecurrence,
      getSelectedServices: getSelectedServices,
      isCreationModal: isCreationModal,
      navigateToJsDate: navigateToJsDate,
      onClose: onClose,
      onMoveNextYear: onMoveNextYear,
      onMoveToday: onMoveToday,
      services: services,
      WeekDaysEnum: WeekDaysEnum
    };
  }
});