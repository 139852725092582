import { defineComponent } from '@nuxtjs/composition-api';
import { ExternalPlatformTypeEnum } from '~/types/models';
export default defineComponent({
  name: 'ExternalConnectivityDomainInfo',
  setup: function setup() {
    var _useDomain = useDomain(),
        getDomainExternalIdObject = _useDomain.getDomainExternalIdObject;

    var platformId = computed(function () {
      var _getDomainExternalIdO;

      return (_getDomainExternalIdO = getDomainExternalIdObject.value) === null || _getDomainExternalIdO === void 0 ? void 0 : _getDomainExternalIdO.platformId;
    });
    return {
      ExternalPlatformTypeEnum: ExternalPlatformTypeEnum,
      getDomainExternalIdObject: getDomainExternalIdObject,
      platformId: platformId
    };
  }
});