import domainEquipmentsEntriesMutations from './mutations'
import domainEquipmentsEntriesGetters from './getters'
import domainEquipmentsEntriesActions from './actions'
import domainEquipmentsEntriesState from './state'

export const domainEquipmentsEntries = {
  namespaced: true,
  state: domainEquipmentsEntriesState,
  actions: domainEquipmentsEntriesActions,
  mutations: domainEquipmentsEntriesMutations,
  getters: domainEquipmentsEntriesGetters,
}
