import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import "core-js/modules/es7.array.includes.js";
import { defineComponent, onBeforeUnmount, onMounted, ref, useContext, watch } from '@nuxtjs/composition-api';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import { Blockquote, Bold, BulletList, HardBreak, History, Italic, ListItem, OrderedList, Strike, Underline } from 'tiptap-extensions';
import { ShortToISOEnum } from '~/types';
export default defineComponent({
  name: 'TranslatableMarketplaceTextArea',
  components: {
    EditorContent: EditorContent,
    EditorMenuBar: EditorMenuBar
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    contentScope: {
      type: String,
      required: false,
      default: null,
      validator: function validator(prop) {
        return [null, 'domain', 'provider', 'service', 'option'].includes(prop);
      }
    },
    isNew: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props, ctx) {
    var _useContext = useContext(),
        app = _useContext.app;

    var _usePermissions = usePermissions(),
        isAdmin = _usePermissions.isAdmin;

    var activeLocale = ref(ShortToISOEnum[app.i18n.locale.toUpperCase()]);
    var selectedContent = ref('master');
    var isDisabled = computed(function () {
      return !isAdmin.value && selectedContent.value !== 'master';
    });
    var innerValue = computed(function () {
      return props.value[selectedContent.value][activeLocale.value] || '';
    });

    var customListeners = _objectSpread(_objectSpread({}, ctx.listeners), {}, {
      input: function input(e) {
        return ctx.emit('input', _objectSpread(_objectSpread({}, props.value), {}, _defineProperty({}, selectedContent.value, _objectSpread(_objectSpread({}, props.value[selectedContent.value]), {}, _defineProperty({}, activeLocale.value, e)))));
      }
    }); // Init Tiptap editor


    var editor = ref(null);

    function onEditorUpdateValue(_ref) {
      var getHTML = _ref.getHTML;
      var html = getHTML(); // Avoid storing empty content as it breaks existence checks.

      if (html === '<p></p>') {
        customListeners.input('');
      } else {
        customListeners.input(html);
      }
    }

    function onLocaleChange($event) {
      ctx.emit('locale-change', $event);
    }

    function onDuplicateContent() {
      ctx.emit('input', _objectSpread(_objectSpread({}, props.value), {}, {
        abcd: props.value.master,
        uscom: props.value.master
      }));
    }

    onMounted(function () {
      editor.value = new Editor({
        content: props.value[activeLocale.value] || '',
        editable: !isDisabled.value,
        extensions: [new Blockquote(), new BulletList(), new HardBreak(), new ListItem(), new OrderedList(), new Bold(), new Italic(), new Strike(), new Underline(), new History()],
        onUpdate: onEditorUpdateValue
      });

      if (editor.value) {
        editor.value.setContent(innerValue.value);
      }

      watch(function () {
        return isDisabled.value;
      }, function () {
        if (editor.value) {
          editor.value.setOptions({
            editable: !isDisabled.value
          });
        }
      });
      watch(function () {
        return [activeLocale.value, selectedContent.value];
      }, function () {
        if (editor.value) {
          editor.value.setContent(innerValue.value);
        }
      });
    });
    onBeforeUnmount(function () {
      if (editor.value) {
        editor.value.destroy();
      }
    });
    return {
      activeLocale: activeLocale,
      customListeners: customListeners,
      isDisabled: isDisabled,
      editor: editor,
      onLocaleChange: onLocaleChange,
      ShortToISOEnum: ShortToISOEnum,
      selectedContent: selectedContent,
      onDuplicateContent: onDuplicateContent,
      innerValue: innerValue,
      isAdmin: isAdmin
    };
  }
});