import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.name.startsWith('marketplace-booking-id'))?_c('RouterView'):_c('section',{staticClass:"is-full is-relative"},[_c('BookingsTable',{attrs:{"base-url":"bookings","exclude-from-search":['customer.phone']},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var tableSearch = ref.tableSearch;
var tableState = ref.tableState;
return [_c('TableSearch',{staticClass:"level-left",attrs:{"value":tableState.query},on:{"input":function($event){return tableSearch($event)}}})]}},{key:"actions",fn:function(ref){
var booking = ref.booking;
var fetchPage = ref.fetchPage;
return [_c('div',{staticClass:"level"},[_c('BaseLink',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(_vm.$t('options.update_booking')),expression:"$t('options.update_booking')",modifiers:{"top-end":true}}],attrs:{"icon-only":"","icon-left":"pencil","icon-pack":"usi"},on:{"click":function($event){return _vm.onClickUpdateBooking(booking)}}}),_vm._v(" "),_c('BaseLink',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(booking.status === _vm.BookingStatusEnum.BOOKING_STANDBY ? _vm.$t('options.resume_booking') : _vm.$t('options.pause_booking')),expression:"booking.status === BookingStatusEnum.BOOKING_STANDBY ? $t('options.resume_booking') : $t('options.pause_booking')",modifiers:{"top-end":true}}],attrs:{"icon-only":"","icon-left":"chrono","icon-pack":"usi"},on:{"click":function($event){return _vm.onToggleBookingStandby(booking, fetchPage)}}}),_vm._v(" "),_c('BaseLink',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(_vm.$t('actions.view')),expression:"$t('actions.view')",modifiers:{"top-end":true}}],attrs:{"icon-only":"","icon-left":"eye-outline","icon-pack":"mdi"},on:{"click":function($event){return _vm.onClickViewBooking(booking)}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }