import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{attrs:{"active":_vm.active,"card":"","data-cy":"CreateServiceModal"},on:{"update:active":function($event){return _vm.$emit('update:active', $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n    "+_vm._s(_vm.$t('rental.create_new'))+"\n  ")]},proxy:true}])},[_vm._v(" "),_c('div',{staticClass:"card has-padding-45"},[_c('ValidationObserver',{staticClass:"tw-flex tw-flex-col",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.createService.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{staticClass:"column is-half-desktop is-full-mobile",attrs:{"name":"accommodation_type","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BField',{attrs:{"label":_vm.$t('form.fields.accommodation_type'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"data-cy":"CreateServiceTypeSelect"}},[_c('BSelect',{attrs:{"placeholder":_vm.$t('common.select'),"size":"is-medium"},model:{value:(_vm.state.newService.serviceType),callback:function ($$v) {_vm.$set(_vm.state.newService, "serviceType", $$v)},expression:"state.newService.serviceType"}},_vm._l((_vm.getServiceTypeCategories()),function(category){return _c('optgroup',{key:category,attrs:{"label":_vm.$t(("service.type_categories." + category))}},_vm._l((_vm.getServiceTypesByCategory(category)),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v("\n                "+_vm._s(_vm.$translateEntityField(item.name))+"\n              ")])}),0)}),0)],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"column is-full",attrs:{"name":"service_name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BField',{attrs:{"label":_vm.$t('form.fields.service_name'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"data-cy":"CreateServiceNameInput"}},[_c('BInput',{attrs:{"size":"is-medium"},model:{value:(_vm.state.newService.label),callback:function ($$v) {_vm.$set(_vm.state.newService, "label", $$v)},expression:"state.newService.label"}})],1),_vm._v(" "),_c('BaseMessage',{attrs:{"type":"is-danger","has-icon":"","closable":false,"data-cy":"CreateServiceNameMessage"}},[_c('p',[_vm._v(_vm._s(_vm.$t('rental.create_new_label_msg')))])])]}}],null,true)}),_vm._v(" "),(_vm.domainExternalId && _vm.state.newService.externalId)?[_c('ServiceExternalPlatformDataInput',{attrs:{"platform-id":_vm.domainExternalId.platformId},model:{value:(_vm.state.newService.externalId.data),callback:function ($$v) {_vm.$set(_vm.state.newService.externalId, "data", $$v)},expression:"state.newService.externalId.data"}})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-w-full tw-flex tw-justify-center"},[_c('BaseButton',{attrs:{"loading":_vm.state.loading,"disabled":_vm.state.loading || invalid,"native-type":"submit","data-cy":"CreateServiceSubmitButton"}},[_vm._v("\n          "+_vm._s(_vm.$t('actions.create'))+"\n        ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }