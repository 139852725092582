import { ref } from '@nuxtjs/composition-api';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{attrs:{"active":_vm.active,"card":""},on:{"update:active":function (val) { return _vm.$emit('update:active', val); }}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-center modal-card-foot tw-bg-white "},[_c('BaseMessage',{attrs:{"type":"is-danger","has-icon":"","is-heading":""}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-4"},[_c('p',[_vm._v(_vm._s(_vm.$t('establishment.message_creation_warning_title')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('establishment.message_creation_warning_content')))])])]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"name":"domainName","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.domainName'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{attrs:{"size":"is-medium"},model:{value:(_vm.getDomainFormItemField(("commercialName." + ([_vm.ShortToISOEnum[_vm.currentLocale.toUpperCase()]])), 0).value),callback:function ($$v) {_vm.$set(_vm.getDomainFormItemField(("commercialName." + ([_vm.ShortToISOEnum[_vm.currentLocale.toUpperCase()]])), 0), "value", $$v)},expression:"getDomainFormItemField(`commercialName.${[ShortToISOEnum[currentLocale.toUpperCase()]]}`, 0).value"}})],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.fields.booking_email') + ' *',"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{attrs:{"type":"email","aria-required":"true","disabled":_vm.$accessor.users.currentUserIsProvider},model:{value:(_vm.getDomainFormItemField('bookingEmail', 0).value),callback:function ($$v) {_vm.$set(_vm.getDomainFormItemField('bookingEmail', 0), "value", $$v)},expression:"getDomainFormItemField('bookingEmail', 0).value"}})],1)]}}],null,true)}),_vm._v(" "),(_vm.getDomainExternalIdObject)?_c('DomainExternalPlatformDataInput',{staticClass:"tw-w-full",attrs:{"platform-id":_vm.getDomainExternalIdObject.platformId},model:{value:(_vm.getDomainFormExternalIdField('data', 0).value),callback:function ($$v) {_vm.$set(_vm.getDomainFormExternalIdField('data', 0), "value", $$v)},expression:"getDomainFormExternalIdField('data', 0).value"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"has-text-centered"},[_c('BaseButton',{attrs:{"disabled":invalid},on:{"click":_vm.onSubmit}},[_vm._v("\n          "+_vm._s(_vm.$t('actions.save'))+"\n        ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }