import { MutationTypes as BaseMutationTypes, createActions } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { CalendarExternalSource, CalendarExternalSourceSyncStatusEnum } from '~/types/models'
import { CalendarExternalSourceMutationTypes } from './types'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<CalendarExternalSource>(),

  async fetchOne({ commit }, id: number) {
    const res = await this.app.$api.get(`calendarexternalsources/${id}`)
    commit(BaseMutationTypes.CREATE, res)
  },

  async postOne({ commit }, payload: Partial<CalendarExternalSource>) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.ical_creating'))
    try {
      const res = await this.app.$api.post('calendarexternalsources', payload)
      commit(BaseMutationTypes.CREATE, res)
      await this.app.$accessor.calendarExternalSources.syncOne(res.id)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.ical_created'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.ical_create_error'))
    }
  },

  async patchOne({ commit }, payload: Partial<CalendarExternalSource>) {
    if (payload.id) {
      const toast = this.app.$loadingToast(this.app.i18n.t('toast.ical_updating'))
      try {
        const res = await this.app.$api.patch(`calendarexternalsources/${payload.id}`, payload)
        commit(BaseMutationTypes.UPDATE, {
          id: payload.id,
          payload: res,
        })
        toast.goAway(0)
        this.app.$successToast(this.app.i18n.t('toast.ical_updated'))
        await this.app.$accessor.calendarExternalSources.syncOne(payload.id)
      } catch (error) {
        toast.goAway(0)
        this.app.$errorToast(this.app.i18n.t('toast.ical_update_error'))
      }
    }
  },

  async deleteOne({ commit }, id: number) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.ical_deleting'))
    try {
      await this.app.$api.delete(`calendarexternalsources/${id}`)
      commit(BaseMutationTypes.DELETE, id)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.ical_deleted'))
    } catch (error) {
      toast.goAway(0)
      this.app.$errorToast(this.app.i18n.t('toast.ical_delete_error'))
    }
  },

  async syncOne({ commit }, id: number) {
    const toast = this.app.$loadingToast(this.app.i18n.t('toast.ical_syncing'))
    commit(CalendarExternalSourceMutationTypes.UPDATE_FIELD, {
      id,
      path: 'lastSyncStatus',
      value: CalendarExternalSourceSyncStatusEnum.SYNC_STATUS_PENDING,
    })
    try {
      await this.app.$api.post(`calendarexternalsources/${id}/sync`)
      toast.goAway(0)
      this.app.$successToast(this.app.i18n.t('toast.ical_synced'))
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.ical_sync_error'))
    } finally {
      const res = await this.app.$api.get(`calendarexternalsources/${id}`)
      commit(BaseMutationTypes.UPDATE, { id, payload: res })
    }
  },
})

export default actions
