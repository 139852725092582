import { Middleware } from '@nuxt/types'

const defaultServicePricingsMiddleware: Middleware = async({ params, app: { $accessor } }) => {
  $accessor.TOGGLE_APP_IS_LOADING()
  try {
    const domainId = parseInt(params.domain)
    const services = $accessor.services.getWhereArray(service => service.domain === domainId)
    const missingDefaultPricingByServiceId = services
      .filter(
        service => !$accessor.servicePricings.getFirstWhere(
          pricing => pricing.service === service.id && pricing.isDefault,
        ),
      )
      .map(service => service.id)
    if (missingDefaultPricingByServiceId.length > 0) {
      await Promise.all(
        missingDefaultPricingByServiceId.map(id => $accessor.servicePricings.fetchDefaultForService(id)),
      )
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  $accessor.TOGGLE_APP_IS_LOADING()
}

export default defaultServicePricingsMiddleware
