




















































import { DrawerDisplayModeEnum } from '~/store/calendar/types'
import { CalendarDrawerNameEnum, CalendarEntitiesEnum, CalendarFiltersEnum } from '~/types/Calendar'
import { CalendarDrawerOptions, CalendarEvent } from '~/types'
import { computed, ComputedRef, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import { DateTime } from 'luxon'
import { Money, Service, ServicePricing, ServicePricingCalendar } from '~/types/models'
import { toAPIFormat } from '~/helpers'
import useCalendar from '~/composable/useCalendar'
import useServicePricings from '~/composable/useServicePricings'
import useMq from '~/composable/useMq'

export default defineComponent({
  name: 'CalendarRentalOfferLines',
  props: {
    service: {
      type: Object as PropType<Service>,
      required: true,
    },
  },
  setup(props) {
    const { app: { $accessor, $parseAmount } } = useContext()
    const { getCalendarDays, getCalendarPushEvents, lineRef, lineWidth, showPricing, onClickPricing, ui } = useCalendar()
    const { getPricingGuestText } = useServicePricings()
    const { isWide } = useMq()

    const servicePricings: ComputedRef<ServicePricingCalendar | null> = computed(() => {
      return $accessor.calendar.servicePricingCalendars.find((pricing) => pricing.service === props.service.id) || null
    })

    const defaultPricing = computed(() => $accessor.servicePricings.getFirstWhere(pricing => pricing.service === props.service.id && pricing.isDefault))

    function getServicePricingForDay(day: DateTime) {
      if (servicePricings.value) {
        return servicePricings.value.calendar[toAPIFormat(day)]
      }
      return null
    }

    function onDayClicked(day: DateTime) {
      const servicePricing = getServicePricingForDay(day)
      $accessor.calendar.UDPATE_SELECTED_PERIODS([{
        start: day.toJSDate(),
        end: day.toJSDate(),
      }])
      const drawerOpts: CalendarDrawerOptions = {
        drawer: servicePricing ? CalendarDrawerNameEnum.CREATE : CalendarDrawerNameEnum.PRICING,
        mode: servicePricing ? DrawerDisplayModeEnum.CREATE : DrawerDisplayModeEnum.CREATE_DEFAULT,
        data: {
          serviceId: props.service.id,
        },
      }
      $accessor.calendar.UI_OPEN_DRAWER(drawerOpts)
      if ($accessor.calendar.ui.drawer === CalendarDrawerNameEnum.CREATE) {
        $accessor.calendar.UPDATE_SELECTED_SERVICES([props.service.id])
        $accessor.calendar.UPDATE_ENTITY_FORM_OPENED(CalendarEntitiesEnum.PRICING)
      }
    }

    const events: ComputedRef<Record<number, CalendarEvent<ServicePricing> & { label: string, displayKey: number }>> = computed(() => {
      const pricings = servicePricings.value
      if (lineWidth.value && pricings) {
        let key = 0
        return (getCalendarPushEvents<ServicePricing>(pricings.calendar, lineWidth.value)).reduce((acc, event) => {
          key++
          return {
            ...acc,
            [key]: {
              displayKey: key,
              label: getPricingGuestText(event.entity),
              ...event,
            },
          }
        }, {})
      }
      return []
    })

    function getPricingDisplay(day: DateTime) {
      const servicePricingForDay = getServicePricingForDay(day)
      if (!servicePricingForDay) {
        return ''
      }
      if (!isWide.value) {
        return $parseAmount(servicePricingForDay?.baseRate as Money).toFormat('$0')
      }
      return $parseAmount(servicePricingForDay?.baseRate as Money).toFormat('$0,0.00')
    }

    return {
      CalendarFiltersEnum,
      defaultPricing,
      events,
      getCalendarDays,
      getPricingDisplay,
      getPricingGuestText,
      getServicePricingForDay,
      lineRef,
      onClickPricing,
      onDayClicked,
      showPricing,
      ui,
    }
  },
})
