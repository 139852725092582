import "core-js/modules/es6.array.filter.js";
import { defineComponent, computed, useContext } from '@nuxtjs/composition-api';
import { accountRoutes } from '~/routes/accountRoutes';
import useFeatureToggle from '~/composable/useFeatureToggle';
export default defineComponent({
  name: 'AccountDropdown',
  setup: function setup() {
    var _useDomain = useDomain(),
        currentDomainId = _useDomain.currentDomainId;

    var _useContext = useContext(),
        params = _useContext.params;

    var _useFeatureToggle = useFeatureToggle(),
        isEnabledRoute = _useFeatureToggle.isEnabledRoute;

    var providerId = computed(function () {
      return parseInt(params.value.provider);
    });
    var enabledRoutes = computed(function () {
      return accountRoutes.filter(function (route) {
        return isEnabledRoute(route);
      });
    });
    return {
      currentDomainId: currentDomainId,
      enabledRoutes: enabledRoutes,
      providerId: providerId
    };
  }
});