









































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import useFiles from '~/composable/useFiles'
import { EntityFile } from '~/types/models'

export default defineComponent({
  name: 'ImageMetadataForm',
  props: {
    file: {
      type: Object as PropType<EntityFile>,
      required: true,
    },
  },
  setup(props) {
    const { getCurrentFileTranslation, patchOne } = useFiles()

    async function onSubmit() {
      await patchOne(props.file)
    }

    return {
      getCurrentFileTranslation,
      onSubmit,
    }
  },
})
