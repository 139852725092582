




















import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import orderBy from 'lodash/orderBy'
import { ServiceEquipmentTypeEnum } from '~/types/models'
import useServices from '~/composable/useServices'

export default defineComponent({
  name: 'ServiceEquipmentCheckboxes',
  props: {
    serviceEquipmentType: {
      type: String as PropType<ServiceEquipmentTypeEnum>,
      required: true,
    },
  },
  setup(prop) {
    const { app: { $accessor, $translateEntityField }, params } = useContext()
    const { getCurrentServiceField } = useServices()

    const serviceEquipmentArray = computed(() => orderBy(
      $accessor.serviceEquipments.getWhere((e) => e.type === prop.serviceEquipmentType),
      eq => $translateEntityField(eq.name),
    ))

    const getServiceEquipmentById = (id: number) => computed({
      get: () => $accessor.services.getOne(parseInt(params.value.id)).serviceEquipments.includes(id),
      set: (val) => {
        const serviceEquipmentsIds = [...$accessor.services.getOne(parseInt(params.value.id)).serviceEquipments]
        if (val) {
          if (!serviceEquipmentsIds.includes(id)) {
            serviceEquipmentsIds.push(id)
            return getCurrentServiceField('serviceEquipments').value = serviceEquipmentsIds
          }
        } else if (serviceEquipmentsIds.includes(id)) {
          const index = serviceEquipmentsIds.findIndex((el) => el === id)
          serviceEquipmentsIds.includes(id) && serviceEquipmentsIds.splice(index, 1)
          return getCurrentServiceField('serviceEquipments').value = serviceEquipmentsIds
        }
      },
    })

    return {
      serviceEquipmentArray,
      getServiceEquipmentById,
    }
  },
})
