import { Middleware } from '@nuxt/types'

const serviceFilesMiddleware: Middleware = async({ app: { $accessor }, route }) => {
  $accessor.TOGGLE_APP_IS_LOADING()
  try {
    const service = $accessor.services.getOne(parseInt(route.params.id))
    const missingFilesIds = service.files.filter(id => !$accessor.entityFiles.getAllIds().includes(id))

    if (missingFilesIds.length > 0) {
      await $accessor.entityFiles.fetchMany(missingFilesIds)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  $accessor.TOGGLE_APP_IS_LOADING()
}

export default serviceFilesMiddleware
