import { Middleware } from '@nuxt/types'

const serviceDetailMiddleware: Middleware = async({ app: { $accessor }, route }) => {
  $accessor.TOGGLE_APP_IS_LOADING()
  try {
    if (!$accessor.services.getAllIds().includes(parseInt(route.params.id))) {
      await $accessor.services.fetchOne(parseInt(route.params.id))
    }
    await $accessor.services.fetchServiceDetailRelations($accessor.services.getOne(parseInt(route.params.id)))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  $accessor.TOGGLE_APP_IS_LOADING()
}

export default serviceDetailMiddleware
