export type ModerationStatus = 'APPROVED' | 'PENDING' | 'DENIED'

export enum ModerationStatusEnum {
    APPROVED = 'APPROVED',
    DENIED = 'DENIED',
    PENDING = 'PENDING',
}

export enum ModerationScopeEnum {
  DOMAIN = 'DOMAIN',
  FILE = 'FILE',
  SINGLE_OPTION = 'SINGLE_OPTION',
  MULTIPLE_OPTIONS = 'MULTIPLE_OPTIONS',
  SERVICE = 'SERVICE',
}

export type ModerationScope = `${ModerationScopeEnum}`
