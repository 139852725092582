import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { updateField } from 'vuex-map-fields'
import { Notification, NotificationTypeEnum } from '~/types/models'
import { resetState } from '~/helpers/store'
import { NotificationMutationTypes } from './types'
import baseState, { notification } from './state'

export const mutations = mutationTree(baseState, {
  ...createMutations<Notification>(notification),
  [NotificationMutationTypes.RESET_STATE](state) {
    resetState(state, baseState)
  },
  [NotificationMutationTypes.UPDATE_FIELD]: (state, { id, path, value }: { id: number, path: string, value: any }) => updateField(state.entities.byId[id], { path, value }),
  [NotificationMutationTypes.TOGGLE_FILTER](state, filter: NotificationTypeEnum) {
    state.filters[filter] = !state.filters[filter]
  },
})

export default mutations
