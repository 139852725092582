import { getterTree } from 'typed-vuex'
import { getField } from 'vuex-map-fields'
import { UserRoles } from '~/types/models'
import state from './state'

export const getters = getterTree(state, {
  getCurrentUserRoles: (state): UserRoles[] | [] => state.currentUser ? state.currentUser.roles : [],

  currentUserIsAdmin(_, { getCurrentUserRoles }): boolean {
    return getCurrentUserRoles.includes(UserRoles.ADMIN) || getCurrentUserRoles.includes(UserRoles.SUPERADMIN)
  },

  currentUserIsProvider(_, { getCurrentUserRoles }): boolean {
    return getCurrentUserRoles.includes(UserRoles.USER)
  },

  getCreateFormField: state => getField(state.createForm),

  getCreateForm: state => state.createForm,
})

export default getters
