import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { EmailLog } from '~/types/models'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<EmailLog>(),

  async fetchForBooking({ commit }, id: number) {
    const res = await this.app.$api.get(`emaillogs?filters[booking.id]=${id}`)
    if (res.data.length) {
      commit(BaseMutationTypes.CREATE_MANY, res.data)
    }
  },

  async sendMail(_, id: number) {
    try {
      await this.app.$api.post(`emaillogs/${id}/send`)
      this.app.$successToast(this.app.i18n.t('toast.email_resent'))
    } catch (error) {
      this.app.$errorToast(this.app.i18n.t('toast.error_generic'))
    }
  },
})

export default actions
