import serviceThemesMutations from './mutations'
import serviceThemesGetters from './getters'
import serviceThemesActions from './actions'
import serviceThemesState from './state'

export const serviceThemes = {
  namespaced: true,
  state: serviceThemesState,
  actions: serviceThemesActions,
  mutations: serviceThemesMutations,
  getters: serviceThemesGetters,
}
