




























































import { computed, defineComponent, PropType, reactive, toRefs, useContext } from '@nuxtjs/composition-api'
import { Service } from '~/types/models'
import useServices from '~/composable/useServices'
import ServiceDeleteModal from '~/components/modal/ServiceDeleteModal.vue'

export default defineComponent({
  name: 'DeleteServicePage',
  components: { ServiceDeleteModal },
  props: {
    service: {
      type: Object as PropType<Service>,
      required: true,
    },
  },
  setup() {
    const { app: { $accessor } } = useContext()
    const { currentServiceId } = useServices()

    const state = reactive({
      isDeleteRentalModalActive: false,
      service: computed(() => $accessor.services.getOne(currentServiceId.value!)),
    })

    return { ...toRefs(state) }
  },
})
