import { createActions, MutationTypes as BaseMutationTypes } from '@kissmylabs/vuex-entitystore'
import { actionTree } from 'typed-vuex'
import { Payment } from '~/types/models'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<Payment>(),

  async fetchMany({ commit }, paymentIds: number[]) {
    if (paymentIds.length > 0) {
      const ids = paymentIds.join()
      const res = await this.app.$api.get(`payments?filters[id_in]=${ids}&limit=${paymentIds.length}`)
      if (res.data.length) {
        commit(BaseMutationTypes.CREATE_MANY, res.data)
      }
    }
  },
})

export default actions
