import { DegressivityRate } from '~/types/models'
import { ThemeColors } from '~/types/Tailwind'
import { PresenceStatus } from '~/types'

export const statuses = {
  DISABLED: 'DISABLED',
  MAINTENANCE: 'MAINTENANCE',
  ACTIVE: 'ACTIVE',
}

/**
 * @deprecated. use `statusColors` instead
 */
export const statusClasses = {
  [statuses.ACTIVE]: 'is-success',
  [statuses.MAINTENANCE]: 'is-warning',
  [statuses.DISABLED]: 'is-danger',
}

export const statusColors = {
  [statuses.ACTIVE]: ThemeColors.GREEN,
  [statuses.MAINTENANCE]: ThemeColors.ORANGE,
  [statuses.DISABLED]: ThemeColors.RED,
}

export const providerStatus = { ...statuses }

/**
 * @deprecated. Use `providerStatusColors` instead.
 */
export const providerStatusClasses = { ...statusClasses }

export const providerStatusColors = { ...statusColors }

export const providerBookingStatus = {
  DISABLED: 'DISABLED',
  FORM: 'FORM',
  ENABLED: 'ENABLED',
}

/**
 * @deprecated. Use `providerBookingStatusColors` instead.
 */
export const providerBookingStatusClasses = {
  [providerBookingStatus.ENABLED]: 'is-success',
  [providerBookingStatus.FORM]: 'is-warning',
  [providerBookingStatus.DISABLED]: 'is-danger',
}

export const providerBookingStatusColors = {
  [providerBookingStatus.ENABLED]: ThemeColors.GREEN,
  [providerBookingStatus.FORM]: ThemeColors.ORANGE,
  [providerBookingStatus.DISABLED]: ThemeColors.RED,
}

export const serviceStatus = { ...statuses }

/**
 * @deprecated. Use `serviceStatusColors` instead.
 */
export const serviceStatusClasses = { ...statusClasses }

export const serviceStatusColors = { ...statusColors }

export const cartStatus = {
  OPEN: 'open',
  AWAITING_PAYMENT: 'awaiting_payment',
  PAID: 'paid',
  CLOSED: 'closed',
  CANCELLED: 'cancelled',
}

export const cartStatusClasses = {
  [cartStatus.OPEN]: 'is-blue',
  [cartStatus.AWAITING_PAYMENT]: 'is-warning',
  [cartStatus.PAID]: 'is-success',
  [cartStatus.CLOSED]: 'is-purple',
  [cartStatus.CANCELLED]: 'is-danger',
}

export const offerStatus = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
}

export const offerStatusClasses = {
  [offerStatus.INACTIVE]: 'is-danger',
  [offerStatus.ACTIVE]: 'is-success',
}

export const marketplaces = [
  {
    id: 241,
    label: 'Abracadaroom',
  },
  {
    id: 242,
    label: 'Abracadaroom NL',
  },
  {
    id: 239,
    label: 'La Cabane en l\'air',
  },
  {
    id: 240,
    label: 'Treego',
  },
  {
    id: 298,
    label: 'Cabanes de France',
  },
  {
    id: 330,
    label: 'Quality Unearted',
  },
  {
    id: 80,
    label: 'Guest and House',
  },
  {
    id: 250,
    label: 'Marketplace Test',
  },
]

export const discountReasonsEnum = {
  CUSTOMER_DISSATISFACTION: 'customer_dissatisfaction',
  CUSTOMER_LOYALTY: 'customer_loyalty',
  STAFF_DISCOUNT: 'staff_discount',
  TO_DISCOUNT: 'to_discount',
  PACKAGE: 'package',
  OTHER: 'other',
}

export const discountReasons = [
  discountReasonsEnum.CUSTOMER_DISSATISFACTION,
  discountReasonsEnum.CUSTOMER_LOYALTY,
  discountReasonsEnum.STAFF_DISCOUNT,
  discountReasonsEnum.TO_DISCOUNT,
  discountReasonsEnum.PACKAGE,
  discountReasonsEnum.OTHER,
]

export const MINOR_AGE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]

export const phonePreferredCountries = ['FR', 'BE', 'CH', 'NL', 'ES', 'GB', 'LU', 'DE', 'IT', 'CA', 'MC']

export const DOMAIN_PHOTOS_ROUTE = 'domain-photos'
export const SERVICE_PHOTOS_ROUTE = 'service-id-medias'

export const DEFAULT_DISCOUNT: DegressivityRate = {
  nightNumber: 1,
  percentDiscount: 0.00,
}

export const headingLevelTags: Record<number, string> = {
  0: 'div',
  ...[1, 2, 3, 4, 5, 6].reduce((acc, level) => ({
    ...acc,
    [level]: `h${level}`,
  }), {}) as Record<number, string>,
}

export const presenceStatusColors = {
  [PresenceStatus.OFFLINE]: ThemeColors.RED,
  [PresenceStatus.IDLE]: ThemeColors.ORANGE,
  [PresenceStatus.ONLINE]: ThemeColors.GREEN,
}

export default {}
