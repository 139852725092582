import Vue from 'vue'
import Router from 'vue-router'
import i18nConfig from './config/i18n.js'
import { RouteConfig } from '@nuxt/types/config/router'
import { LocaleObject } from './types/Locale.js'
import { mainMenuRoutes, authRoutes, accountRoutes, entityRoutes, marketplaceRoutes } from '~/routes/index'
import { RouteLike } from '~/types'
import NotFoundPage from '~/components/pages/not-found.vue'

Vue.use(Router)

export const baseRoutes: RouteConfig[] = [
  ...authRoutes,
  ...mainMenuRoutes,
  ...accountRoutes,
  ...entityRoutes,
  ...marketplaceRoutes,
  {
    path: '**/*/*',
    name: 'not-found',
    component: NotFoundPage,
  },
]

/**
 * Check if the argument localeCode is configured as the App default locale.
 */
const isDefaultLocale = (localeCode: string) => localeCode === i18nConfig.defaultLocale

/**
 * Generate a localized route for a generic, non-localized route.
 * Handles route children recursively.
 */
const createLocalizedRoute = <T extends RouteLike> (route: T, locale: LocaleObject) => {
  let routeName = `${route.name}___${locale.code}`

  if (isDefaultLocale(locale.code)) {
    routeName += '___default'
  }
  const localizedRoute = {
    ...route,
    path: `/${locale.code}${route.path}`,
    name: routeName,
  }
  if (route.children && route.children.length) {
    localizedRoute.children = route.children.map(childRoute => createLocalizedRoute(childRoute, locale))
  }
  return localizedRoute
}

/**
 * Generate all localized routes.
 */
const createLocalizedRoutes = (routes: any[], locales: LocaleObject[]): RouteConfig[] | undefined => {
  return routes.reduce((i18nRoutes: RouteConfig[], route) => {
    locales.forEach((locale) => {
      i18nRoutes.push(createLocalizedRoute(route, locale))
    })
    return i18nRoutes
  }, [] as RouteConfig[])
}

export function createRouter(): Router {
  return new Router({
    mode: 'history',
    routes: createLocalizedRoutes(baseRoutes, i18nConfig.locales),
  })
}
