import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es7.object.values.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
export default function useServiceType() {
  var _useContext = useContext(),
      $accessor = _useContext.app.$accessor;

  var serviceTypes = computed(function () {
    return Object.values($accessor.serviceTypes.entities.byId);
  });

  function getServiceTypeCategories() {
    return _toConsumableArray(new Set(serviceTypes.value.map(function (type) {
      return type.category;
    })));
  }

  function getServiceTypesByCategory(category) {
    return serviceTypes.value.filter(function (type) {
      return type.category === category;
    });
  }

  return {
    getServiceTypeCategories: getServiceTypeCategories,
    getServiceTypesByCategory: getServiceTypesByCategory
  };
}