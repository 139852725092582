import { Middleware } from '@nuxt/types'

const providerAddressMiddleware: Middleware = async({ app: { $accessor }, route }) => {
  const addressId = $accessor.providers.getOne(parseInt(route.params.provider)).unicstayContactAddress
  if (addressId && !$accessor.addresses.getAllIds().includes(addressId)) {
    await $accessor.addresses.fetchOne(addressId)
  }
}

export default providerAddressMiddleware
