


























































































































































import { defineComponent } from '@nuxtjs/composition-api'
import SymbolInput from '~/components/content/SymbolInput.vue'
import HRSwitch from '~/components/HRSwitch.vue'

export default defineComponent({
  name: 'GiftCardsForm',
  components: { SymbolInput, HRSwitch },
  props: {
    isCreation: {
      type: Boolean,
      default: false,
    },
    getField: {
      type: Function,
      required: true,
    },
  },
})
