import calendarMutations from './mutations'
import calendarGetters from './getters'
import calendarActions from './actions'
import calendarState from './state'

export const calendar = {
  namespaced: true,
  state: calendarState,
  actions: calendarActions,
  mutations: calendarMutations,
  getters: calendarGetters,
}
