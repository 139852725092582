






























import { computed, defineComponent, reactive, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'AppBreadcrumb',
  setup() {
    const { route } = useContext()
    const currentBaseRouteName = computed(() => route.value.name ? route.value.name.split('___')[0] : 'home')

    const state = reactive({ currentRouteIsIndex: computed(() => currentBaseRouteName.value === 'home') })

    return {
      currentBaseRouteName,
      state,
    }
  },
})
