















































import { computed, defineComponent, reactive, toRefs, useContext } from '@nuxtjs/composition-api'
import ArrivalsItem from '~/components/arrivals/ArrivalsItem.vue'
import Badge from '~/components/badges/Badge.vue'

export default defineComponent({
  name: 'ArrivalsPage',
  components: {
    ArrivalsItem,
    Badge,
  },
  middleware: ['todaysBookingsMiddleware'],
  setup() {
    const { app: { $accessor } } = useContext()

    const state = reactive({
      current: 1,
      perPage: 5,
      total: computed(() => $accessor.bookings.todayBookingsForDomain.length),
      paginatedItems: computed(() => {
        const pageNumber = state.current - 1
        const ids = $accessor.bookings.todayBookingsForDomain.slice(pageNumber * state.perPage, (pageNumber + 1) * state.perPage)
        return $accessor.bookings.getWhereArray(b => ids.includes(b.id))
      }),
    })

    return { ...toRefs(state) }
  },
})
